import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
// Redux
import Select from "react-select";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { getCategoriesOptions } from "../../../store/actions";
import { formatMoney } from "../../../helpers/generalFunctions";
import { getDate } from "../../../helpers/globalFunctions";
import { CSVLink } from "react-csv";
import "./stock.scss";

const TopCustomers = (props) => {
  const [stockForTable, setStockForTable] = useState([]);
  const [stockLog, setStockLog] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [logModal, setLogModal] = useState(false);
  const [categoryOption, setCategoryOption] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [taxOptions, setTaxOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedTax, setSelectedTax] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [master, setMaster] = useState({});
  const [dataToBeExported, setDataToBeExported] = useState([]);
  const dispatch = useDispatch();

  const { categoryOptions } = useSelector((state) => state.categories);

  useEffect(() => {
    handleTableData();
    dispatch(getCategoriesOptions());
    fetch_all_gst();
    fetch_all_company();
  }, []);
  useEffect(() => {
    console.log("categoryOptions: ", categoryOptions);
    var data =
      categoryOptions &&
      categoryOptions.map((item) => {
        return {
          label: item.category_name_english,
          value: item.category_id,
        };
      });

    setCategoryOption([
      {
        options: data,
      },
    ]);
  }, [categoryOptions]);

  function handleTableData(
    from_date = "",
    to_date = "",
    branch_id = "",
    category_id = "",
    subcategory_id = "",
    product_id = "",
    tax_id = ""
  ) {
    var url = `${API_URL}report/stock`;

    url =
      url +
      "?from_date=" +
      from_date +
      "&to_date=" +
      to_date +
      "&branch_id=" +
      branch_id +
      "&category_id=" +
      category_id +
      "&subcategory_id=" +
      subcategory_id +
      "&product_id=" +
      product_id +
      "&tax_id=" +
      tax_id;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let stockData = [];
        let dataToBeExported = [];
        result.map((item, index) => {
          let exportItem = {};
          item.stock_created_at = moment(item.stock_created_at).format(
            "DD/MM/YYYY"
          );
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-file-alt"
                style={{
                  fontSize: "1.3em",
                  cursor: "pointer",
                  marginLeft: "1rem",
                  marginRight: "1rem",
                }}
                onClick={() => {
                  handleStockLog(item);
                }}
              ></i>
            </div>
          );
          item.id = index + 1;
          let taxValue = Number(
            parseFloat((item.product_cost * item.tax_slab_percentage) / 100)
          );
          let stock_value = Number(
            parseFloat(item.product_cost * item.stock_item_stock)
          );
          item.tax_value = formatMoney(taxValue);
          item.stock_value = formatMoney(stock_value);
          item.product_cost = formatMoney(item.product_cost);
          item.product_mrp = formatMoney(item.product_mrp);
          item.stock_item_total_cost = formatMoney(item.stock_item_total_cost);

          //set export data
          exportItem.Id = item.id;
          exportItem.Code = item.product_sku_autogen;
          exportItem.Name = item.product_name_english;
          exportItem.MRP = item.product_mrp;
          exportItem.Cost = item.product_cost;
          exportItem.Tax_Percentage = item.tax_slab_name;
          exportItem.Tax_Amount = item.tax_value;
          exportItem.Stock = item.stock_item_stock;
          exportItem.Stock_Value = item.stock_value;

          stockData.push(item);
          dataToBeExported.push(exportItem);
        });
        setStockForTable(stockData);
        setDataToBeExported(dataToBeExported);
      });
  }

  let closeModal = () => {
    setLogModal(false);
  };
  let handleStockLog = (item) => {
    var id = item.product_id;
    axios
      .get(`${API_URL}report/product_stockLog/` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let stockLogData = [];
        result.map((item, index) => {
          item.id = index + 1;
          if (item.stock_log_type == 1) {
            item.stock_log_type = "Purchase";
          } else if (item.stock_log_type == 2) {
            item.stock_log_type = "Purchase Return";
          } else if (item.stock_log_type == 3) {
            item.stock_log_type = "Sale";
          } else if (item.stock_log_type == 4) {
            item.stock_log_type = "Sale Return";
          }
          item.stock_log_created_at = moment(item.stock_log_created_at).format(
            "DD-MM-YYYY hh:m a"
          );
          item.stock_log_product_cost = (
            <div className="text-right">
              {item.stock_log_product_cost.toFixed(2)}
            </div>
          );
          item.fname = "";
          if (item.User != null) {
            item.fname = item.User.fname + " " + item.User.lname;
          }
          item.product_name_english = "";
          item.product_sku = "";
          if (item.product != null) {
            item.product_name_english = item.product.product_name_english;
            item.product_sku = item.product.product_sku_autogen;
          }
          stockLogData.push(item);
          console.log(item);
        });
        setStockLog(stockLogData);
        setLogModal(true);
        console.log(stockLog);
      });
  };
  function fetch_all_gst() {
    axios
      .get(`${API_URL}gst_slab`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var gst_data =
          res.data.result &&
          res.data.result.map((item) => {
            return {
              label: item.tax_slab_name,
              value: item.slab_id,
            };
          });

        setTaxOptions([
          {
            options: gst_data,
          },
        ]);
      });
  }
  function fetch_all_company() {
    axios
      .get(`${API_URL}company/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var company_data =
          res.data.data &&
          res.data.data.map((item) => {
            return {
              label: item.branch_name,
              value: item.branch_id,
            };
          });
        setCompanyOptions([
          {
            options: company_data,
          },
        ]);
      });
  }
  const handleSelectChange = (selected, name) => {
    let category_id = master?.category_id ? master.category_id : "";
    let subcategory_id = master?.sub_category_id ? master.sub_category_id : "";
    let branch_id = master?.company_id ? master.company_id : "";
    let product_id = master?.product_id ? master.product_id : "";
    let tax_id = master?.tax_id ? master.tax_id : "";
    let date1 = master?.from_date ? master.from_date : "";
    let date2 = master?.to_date ? master.to_date : "";
    switch (name) {
      case "category":
        setSelectedCategory(selected);
        setSelectedSubCategory(null);
        setSelectedProduct(null);
        setProductOptions([]);
        setMaster({
          ...master,
          ["category_id"]: selected.value,
          ["sub_category_id"]: "",
          ["product_id"]: "",
        });
        axios
          .get(`${API_URL}subcategory?category_id=` + selected.value, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            var subCategoryData =
              res.data &&
              res.data.map((item) => {
                return {
                  label: item.subcategory_name_english,
                  value: item.subcategory_id,
                };
              });
            setSubCategory([
              {
                options: subCategoryData,
              },
            ]);
          });
        handleTableData(
          date1,
          date2,
          branch_id,
          selected.value,
          "",
          "",
          tax_id
        );
        break;
      case "subcategory":
        setSelectedSubCategory(selected);
        setSelectedProduct(null);
        setMaster({
          ...master,
          ["sub_category_id"]: selected.value,
          ["product_id"]: "",
        });
        axios({
          method: "post",
          url: `${API_URL}product/by_subcategory`,
          data: {
            subcategory_id: selected.value,
          },
          headers: {
            "x-access-token": accessToken,
          },
        }).then((res) => {
          var productData =
            res.data.data &&
            res.data.data.map((item) => {
              return {
                label: item.product_name_english,
                value: item.product_id,
              };
            });
          setProductOptions([
            {
              options: productData,
            },
          ]);
        });
        handleTableData(
          date1,
          date2,
          branch_id,
          category_id,
          selected.value,
          "",
          tax_id
        );
        break;
      case "productwise":
        setSelectedProduct(selected);
        setMaster({
          ...master,
          ["product_id"]: selected.value,
        });
        handleTableData(
          date1,
          date2,
          branch_id,
          category_id,
          subcategory_id,
          selected.value,
          tax_id
        );
        break;
      case "tax":
        setSelectedTax(selected);
        setMaster({
          ...master,
          ["tax_id"]: selected.value,
        });
        handleTableData(
          date1,
          date2,
          branch_id,
          category_id,
          subcategory_id,
          product_id,
          selected.value
        );
        break;
      case "company":
        setSelectedCompany(selected);
        setMaster({
          ...master,
          ["company_id"]: selected.value,
        });
        handleTableData(
          date1,
          date2,
          selected.value,
          category_id,
          subcategory_id,
          product_id,
          tax_id
        );
        break;
      default:
        break;
    }
  };
  const handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
    let category_id = master?.category_id ? master.category_id : "";
    let subcategory_id = master?.sub_category_id ? master.sub_category_id : "";
    let branch_id = master?.company_id ? master.company_id : "";
    let product_id = master?.product_id ? master.product_id : "";
    let tax_id = master?.tax_id ? master.tax_id : "";
    handleTableData(
      date1,
      date2,
      branch_id,
      category_id,
      subcategory_id,
      product_id,
      tax_id
    );
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Code",
        field: "product_sku_autogen",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        sort: "asc",
        field: "product_name_english",
        width: 150,
      },
      {
        label: "MRP",
        field: "product_mrp",
        sort: "asc",
        width: 400,
      },
      {
        label: "Cost",
        field: "product_cost",
        sort: "asc",
        width: 400,
      },
      {
        label: "Tax (%)",
        field: "tax_slab_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Tax (₹)",
        field: "tax_value",
        sort: "asc",
        width: 200,
      },
      {
        label: "Stock",
        field: "stock_item_stock",
        sort: "asc",
        width: 200,
      },
      {
        label: "Stock (₹)",
        field: "stock_value",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "Total Cost",
      //   field: "stock_item_total_cost",
      //   sort: "asc",
      //   width: 200,
      // },
      // {
      //   label: "Created On",
      //   field: "stock_created_at",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Actions",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: stockForTable,
  };

  const dataLog = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "stock_log_created_at",
        sort: "asc",
        width: 400,
      },
      {
        label: "Type",
        field: "stock_log_type",
        sort: "asc",
        width: 200,
      },
      {
        label: "Name",
        field: "product_name_english",
        sort: "asc",
        width: 200,
      },
      {
        label: "Sku",
        field: "product_sku",
        sort: "asc",
        width: 200,
      },
      {
        label: "Qty",
        field: "stock_log_product_qty",
        sort: "asc",
        width: 200,
      },
      {
        label: "Log Qty",
        field: "stock_log_qty",
        sort: "asc",
        width: 200,
      },
      {
        label: "Current Qty",
        field: "stock_log_current_qty",
        sort: "asc",
        width: 200,
      },
      {
        label: "Cost",
        field: "stock_log_product_cost",
        sort: "asc",
        width: 200,
      },
      {
        label: "Comments",
        field: "stock_log_activity",
        sort: "asc",
        width: 200,
      },
      {
        label: "Added By",
        field: "fname",
        sort: "asc",
        width: 200,
      },
    ],
    rows: stockLog,
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Report - Stock" />
          <Card>
            <CardBody>
              <Row>
                <Col md="3">
                  <div className="mb-3">
                    <Label htmlFor="validationCustom05">From</Label>
                    <div className="col-md-10">
                      <input
                        className="form-control"
                        type="date"
                        id="from_date"
                        name="from_date"
                        onChange={handleDate}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="3">
                  <div className="mb-3">
                    <Label htmlFor="validationCustom05">To</Label>
                    <div className="col-md-10">
                      <input
                        className="form-control"
                        type="date"
                        id="to_date"
                        name="to_date"
                        onChange={handleDate}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="3">
                  <div className="mb-3">
                    <Label>Company</Label>
                    <Select
                      name="company_id"
                      value={selectedCompany}
                      onChange={(selected) => {
                        handleSelectChange(selected, "company");
                      }}
                      options={companyOptions}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </Col>
                <Col md="3">
                  <div className="mb-3">
                    <Label>Category</Label>
                    <Select
                      name="category_id"
                      value={selectedCategory}
                      onChange={(selected) => {
                        handleSelectChange(selected, "category");
                      }}
                      options={categoryOption}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </Col>
                <Col md="3">
                  <div className="mb-3">
                    <Label>Sub Category</Label>
                    <Select
                      name="sub_category_id"
                      value={selectedSubCategory}
                      onChange={(selected) => {
                        handleSelectChange(selected, "subcategory");
                      }}
                      options={subCategory}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </Col>
                <Col md="3">
                  <div className="mb-3">
                    <Label>Product</Label>
                    <Select
                      name="product_id"
                      value={selectedProduct}
                      onChange={(selected) => {
                        handleSelectChange(selected, "productwise");
                      }}
                      options={productOptions}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </Col>
                <Col md="3">
                  <div className="mb-3">
                    <Label>Tax</Label>
                    <Select
                      name="tax_id"
                      value={selectedTax}
                      onChange={(selected) => {
                        handleSelectChange(selected, "tax");
                      }}
                      options={taxOptions}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </Col>
                <Col md="1">
                  <div className="mt-4" style={{ paddingTop: "7px" }}>
                    <Button color="success" type="submit">
                      <CSVLink
                        data={dataToBeExported}
                        filename={
                          "StarLee_stock_report_" + getDate(new Date()) + ".xls"
                        }
                        style={{ color: "white" }}
                      >
                        Export
                      </CSVLink>
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="col-12">
                  <MDBDataTable
                    id="viewStock"
                    responsive
                    striped
                    bordered
                    data={data}
                    info={true}
                    searching={true}
                    disableRetreatAfterSorting={true}
                    entries={25}
                  //autoWidth= {true}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
          {/* <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                 
                </CardBody>
              </Card>
            </Col>
          </Row> */}
        </div>
      </div>
      <Modal
        style={{
          maxWidth: "1000px",
        }}
        isOpen={logModal}
        toggle={() => {
          closeModal();
        }}
      >
        <div style={{ maxWidth: "1000px", paddingTop: "0px" }}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">Stock Logs </h5>
            <button
              type="button"
              onClick={() => {
                closeModal();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div>
            <Card>
              <CardBody>
                <MDBDataTable
                  responsive
                  striped
                  bordered
                  data={dataLog}
                  info={false}
                  searching={true}
                  disableRetreatAfterSorting={true}
                  entries={25}
                />
              </CardBody>
            </Card>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => { };

export default withRouter(connect(mapStateToProps, {})(TopCustomers));
