import React, { useState, useEffect, useRef } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SweetAlert from "react-bootstrap-sweetalert";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label, Progress } from "reactstrap";
import toastr from "toastr";
import { AvForm, AvField } from "availity-reactstrap-validation";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import "./adv.scss";
import moment from "moment";
import Select from "react-select";
const Ads = (props) => {
  const formRef = useRef();
  const [masterObject, setMasterObject] = useState(null);
  const [isLoading, setIsLoading] = useState("");
  const [filterObject, setFilterObject] = useState(null);
  const [adsIdTobeUpdated, setAdsIdTobeUpdated] = useState(null);
  const [dataTable, setDataTable] = useState([]);
  const [adsIdTobeDeleted, setAdsIdTobeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(false);
  const [uploadProgress, setUploadProgress] = useState();
  const [uploadProgress1, setUploadProgress1] = useState();
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const handleChangeInput = (e) => {
    setMasterObject({
      ...masterObject,
      [e.target.name]: e.target.value,
    });
  };
  const handleValidSubmit = () => {
    if (
      typeof masterObject?.adv_image_web == "undefined" ||
      masterObject?.adv_image_web == ""
    ) {
      toastr.warning("Please upload Web image");
      return;
    }
    if (
      typeof masterObject?.adv_image_app == "undefined" ||
      masterObject?.adv_image_app == ""
    ) {
      toastr.warning("Please upload App image");
      return;
    }
    setIsLoading(true);
    if (adsIdTobeUpdated) {
      axios
        .put(`${API_URL}advertisement-poster/`, masterObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          setIsLoading("");
          if (res.data.status == 200) {
            toastr.success("Updated Successfully");
            setAdsIdTobeUpdated(null);
            formRef.current.reset();
            setMasterObject(null);
            getDataTable(
              filterObject?.adv_start,
              filterObject?.adv_end,
              filterObject?.adv_active?.value
            );
          } else {
            toastr.error(res.data.message);
          }
        })
        .catch((err) => {
          setIsLoading("");
          toastr.error("Failed to update ads");
        });
    } else {
      axios
        .post(`${API_URL}advertisement-poster/`, masterObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          setIsLoading("");
          if (res.data.status == 200) {
            toastr.success("Submitted Successfully");
            formRef.current.reset();
            setMasterObject(null);
            getDataTable(
              filterObject?.adv_start,
              filterObject?.adv_end,
              filterObject?.adv_active?.value
            );
          } else {
            toastr.error(res.data.message);
          }
        })
        .catch((err) => {
          setIsLoading("");
          toastr.error("Failed to add ads");
        });
    }
  };
  const uploadImage = (e) => {
    setIsLoading(true);
    const fd = new FormData();
    fd.append("image", e.target.files[0]);
    axios
      .post(`${API_URL}advertisement-poster/upload-image`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        setIsLoading("");
        if (response.data.status === "success") {
          setMasterObject({
            ...masterObject,
            [`adv_image_web`]: response.data.file.filename,
          });
          //toastr.success("Title Image Uploaded");
        } else {
          toastr.error(response.data.message);
        }
      });
  };
  const uploadImageApp = (e) => {
    setIsLoading(true);
    const fd = new FormData();
    fd.append("image", e.target.files[0]);
    axios
      .post(`${API_URL}advertisement-poster/upload-image`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress1(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        setIsLoading("");
        if (response.data.status === "success") {
          setMasterObject({
            ...masterObject,
            [`adv_image_app`]: response.data.file.filename,
          });
          //toastr.success("Title Image Uploaded");
        } else {
          toastr.error(response.data.message);
        }
      });
  };
  const preUpdateAds = (item) => {
    setAdsIdTobeUpdated(item.adv_id);
    setMasterObject(item);
  };
  const getDataTable = (start = "", end = "", status = "") => {
    axios
      .get(
        `${API_URL}advertisement-poster/?adv_start=` +
        start +
        "&adv_end=" +
        end +
        "&adv_active=" +
        status,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        var data = res.data.data;
        var list = [];
        data.map((item, index) => {
          item.serial = index + 1;
          item.name = item.addedBy?.fname;
          item.adv_start = moment(item.adv_start).format("DD-MM-YYYY");
          item.adv_end = moment(item.adv_end).format("DD-MM-YYYY");
          item.image = "";
          if (item?.adv_image_web) {
            item.image = (
              <img
                width={"100px"}
                height={"66px"}
                src={API_URL + "uploads/advertisement/" + item?.adv_image_web}
                alt={item?.adv_title}
              />
            );
          }
          if (item?.adv_image_app) {
            item.image2 = (
              <img
                width={"100px"}
                height={"66px"}
                src={API_URL + "uploads/advertisement/" + item?.adv_image_app}
                alt={item?.adv_title}
              />
            );
          }

          item.status = (
            <div className="square-switch">
              <input
                type="checkbox"
                id={"square-switch" + index}
                switch="none"
                checked={item?.adv_active == 0 ? "checked" : ""}
                onChange={(e) => {
                  handleActive(item?.adv_id, !item?.adv_active);
                }}
              />
              <label
                htmlFor={"square-switch" + index}
                data-on-label="On"
                data-off-label="Off"
              />
            </div>
          );
          item.action = (
            <>
              <i
                className="far fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  toTop();
                  preUpdateAds(item);
                }}
              ></i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
                onClick={() => {
                  setAdsIdTobeDeleted(item.adv_id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </>
          );
          list.push(item);
        });
        setDataTable(list);
      });
  };
  const handleActive = (id, status) => {
    axios
      .post(
        API_URL + "advertisement-poster/update-status",
        { adv_active: status, adv_id: id },
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        if (res.data.status == true) {
          getDataTable(
            filterObject?.adv_start,
            filterObject?.adv_end,
            filterObject?.adv_active?.value
          );
        }
      });
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "serial",
        sort: "asc",
        width: 150,
      },
      {
        label: "Title",
        field: "adv_title",
        sort: "asc",
        width: 150,
      },
      {
        label: "Web Image",
        field: "image",
        sort: "asc",
        width: 150,
      },
      {
        label: "App Image",
        field: "image2",
        sort: "asc",
        width: 150,
      },
      {
        label: "Start",
        field: "adv_start",
        sort: "asc",
        width: 150,
      },
      {
        label: "End",
        field: "adv_end",
        sort: "asc",
        width: 150,
      },
      {
        label: "Active",
        field: "status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: dataTable,
  };
  useEffect(() => {
    getDataTable(
      filterObject?.adv_start,
      filterObject?.adv_end,
      filterObject?.adv_active?.value
    );
  }, []);
  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}advertisement-poster?id=` + adsIdTobeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.status === 200) {
                  toastr.success("Deleted successfully");
                  if (
                    masterObject &&
                    masterObject.adv_id === adsIdTobeDeleted
                  ) {
                    formRef.current.reset();
                    setMasterObject(null);
                    setAdsIdTobeUpdated(null);
                  }
                  setAdsIdTobeDeleted(null);
                  getDataTable(
                    filterObject?.adv_start,
                    filterObject?.adv_end,
                    filterObject?.adv_active?.value
                  );
                } else {
                  toastr.error(res.data.message, "Failed to delete");
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message);
                return;
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Manage Advertisement Poster" />
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Title</Label>
                          <AvField
                            name="adv_title"
                            value={masterObject?.adv_title}
                            placeholder="Title"
                            type="text"
                            errorMessage="Title is required"
                            className="form-control"
                            validate={{
                              required: { value: true },
                            }}
                            id="validationCustom02"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Start</Label>
                          <AvField
                            name="adv_start"
                            value={masterObject?.adv_start}
                            placeholder=""
                            type="date"
                            errorMessage="Start Date is required"
                            className="form-control"
                            // min={moment().format("YYYY-MM-DD")}
                            validate={{
                              required: { value: true },
                            }}
                            id="validationCustom02"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>End</Label>
                          <AvField
                            name="adv_end"
                            value={masterObject?.adv_end}
                            placeholder=""
                            type="date"
                            errorMessage="End Date is required"
                            className="form-control"
                            validate={{
                              required: { value: true },
                            }}
                            min={masterObject?.adv_start}
                            id="validationCustom02"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <Label htmlFor="validationCustom03">Web Poster(1900x500  Webp Image)</Label>
                        {!masterObject?.adv_image_web ? (
                          <AvField
                            name="adv_image_web"
                            type="file"
                            errorMessage="Upload Image"
                            className="form-control"
                            validate={{ required: { value: false } }}
                            id="validationCustom03"
                            onChange={uploadImage}
                            rows="1"
                          />
                        ) : null}
                        {uploadProgress && uploadProgress < 100 && (
                          <div className="mt-4">
                            {" "}
                            <Progress color="primary" value={uploadProgress}>
                              Uploading {uploadProgress}%
                            </Progress>
                          </div>
                        )}
                        {masterObject?.adv_image_web ? (
                          <>
                            <div div className="img-wraps">
                              <img
                                alt=""
                                width="100%"
                                height="150"
                                src={`${API_URL}/uploads/advertisement/${masterObject?.adv_image_web}`}
                              />
                              <Button
                                type="button"
                                color="danger"
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light"
                                style={{ width: "100%" }}
                                onClick={() => {
                                  setMasterObject({
                                    ...masterObject,
                                    adv_image_web: "",
                                  });
                                }}
                              >
                                Delete
                              </Button>
                            </div>

                          </>
                        ) : null}
                      </Col>
                      <Col md="3">
                        <Label htmlFor="validationCustom03">App Poster(320x480 Webp Image)</Label>
                        {!masterObject?.adv_image_app ? (
                          <AvField
                            name="adv_image_app"
                            type="file"
                            errorMessage="Upload Image"
                            className="form-control"
                            validate={{ required: { value: false } }}
                            id="validationCustom03"
                            onChange={uploadImageApp}
                            rows="1"
                          />
                        ) : null}
                        {uploadProgress1 && uploadProgress1 < 100 && (
                          <div className="mt-4">
                            {" "}
                            <Progress color="primary" value={uploadProgress1}>
                              Uploading {uploadProgress1}%
                            </Progress>
                          </div>
                        )}
                        {masterObject?.adv_image_app ? (
                          <>
                            <div div className="img-wraps">
                              <img
                                alt=""
                                width="100%"
                                height="150"
                                src={`${API_URL}/uploads/advertisement/${masterObject?.adv_image_app}`}
                              />
                              <Button
                                type="button"
                                color="danger"
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light"
                                style={{ width: "100%" }}
                                onClick={() => {
                                  setMasterObject({
                                    ...masterObject,
                                    adv_image_app: "",
                                  });
                                }}
                              >
                                Delete
                              </Button>
                            </div>

                          </>
                        ) : null}
                      </Col>
                      <Col md="1" >
                        {adsIdTobeUpdated ? (
                          <Button color="primary" type="submit" disabled={isLoading}>
                            Update
                          </Button>
                        ) : (
                          <Button color="primary" type="submit" disabled={isLoading}>
                            Submit
                          </Button>
                        )}
                      </Col>
                      <Col md="1">
                        <Button
                          color="danger"
                          type="reset"
                          onClick={(e) => {
                            setMasterObject(null);
                            setAdsIdTobeDeleted(null);
                            setAdsIdTobeUpdated(null);
                            formRef.current.reset();
                          }}
                        >
                          Reset
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
            <Col md="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Start</Label>
                        <input
                          name="adv_start"
                          value={filterObject?.adv_start}
                          placeholder=""
                          type="date"
                          errorMessage="Start Date is required"
                          className="form-control"
                          validate={{
                            required: { value: true },
                          }}
                          id="validationCustom02"
                          onChange={(e) => {
                            setFilterObject({
                              ...filterObject,
                              [e.target.name]: e.target.value,
                            });
                            getDataTable(
                              e.target.value,
                              filterObject?.adv_end,
                              filterObject?.adv_active?.value
                            );
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>End</Label>
                        <input
                          name="adv_end"
                          value={filterObject?.adv_end}
                          placeholder=""
                          type="date"
                          errorMessage="End Date is required"
                          className="form-control"
                          validate={{
                            required: { value: true },
                          }}
                          min={filterObject?.adv_start}
                          id="validationCustom02"
                          onChange={(e) => {
                            setFilterObject({
                              ...filterObject,
                              [e.target.name]: e.target.value,
                            });
                            getDataTable(
                              filterObject?.start,
                              e.target.value,
                              filterObject?.adv_active?.value
                            );
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <Label htmlFor="validationCustom05">Status</Label>
                      <Select
                        name="shop"
                        value={filterObject?.act_status}
                        onChange={(selected) => {
                          setFilterObject({
                            ...filterObject,
                            adv_active: selected,
                          });
                          getDataTable(
                            filterObject?.adv_start,
                            filterObject?.adv_end,
                            selected.value
                          );
                        }}
                        options={[
                          { label: "All", value: "" },
                          { label: "Active", value: "0" },
                          { label: "Inactive", value: "1" },
                        ]}
                        classNamePrefix="select2-selection"
                      />
                    </Col>
                    <Col md="1" style={{ paddingTop: "26px" }}>
                      <Button
                        color="danger"
                        type="reset"
                        onClick={(e) => {
                          setFilterObject({
                            adv_start: "",
                            adv_end: "",
                            adv_active: null,
                          });
                          getDataTable();
                        }}
                      >
                        Reset
                      </Button>
                    </Col>
                  </Row>
                  <MDBDataTable
                    responsive
                    id="adsPosterTableId"
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Ads;
