import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Card, CardBody, Container, Label, Button } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import { MDBDataTable } from "mdbreact";
import "./recharge.css";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from "react-select";
import toastr from "toastr";
import Pagination from "@mui/material/Pagination";
import { getDate } from "../../helpers/globalFunctions";
import moment from "moment";
import { CSVLink } from "react-csv";

const TopRecharges = (props) => {
  const [dataToBeExported, setDataToBeExported] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [searchData, setsearchData] = useState({ from_date: moment().format("YYYY-MM-DD") });
  const [customerOptions, setcustomerOptions] = useState([]);
  const [selectedCustomer, setselectedCustomer] = useState(null);
  const [dataTable, setdataTable] = useState([]);
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },

      {
        label: "Customer",
        field: "customer",
        sort: "asc",
        width: 150,
      },
      {
        label: "Mobile",
        field: "customer_contact_number",
        sort: "asc",
        width: 150,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 150,
      },
    ],
    rows: dataTable,
  };

  const handleTableData = (customer_id = "", from_date = moment().format("YYYY-MM-DD"), start = 0, end = PerPageCount) => {
    axios
      .get(API_URL + "customer/top-recharges?start=" + start + "&end=" + end + "&customer_id=" + customer_id + "&from_date=" + from_date, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        setTotalPages(res.data.limit)
        let dataToBeExported = [];
        var id = start;
        res.data.data &&
          res.data.data.map((item, index) => {
            let exportItem = {};
            id++;
            item.id = id;
            item.customer = (
              <a href={`/customer/${item.customers_unique_id}`} target="_blank">
                {item.customer_name}
              </a>
            );

            exportItem.Id = item.id;
            exportItem.Customer = item.customer_name;
            exportItem.Mobile = item.customer_contact_number;
            exportItem.Amount = item.amount;
            dataToBeExported.push(exportItem);
          });

        setTotalPages(res.data.limit)
        setdataTable(res.data.data);
        setDataToBeExported(dataToBeExported);
      });
  };
  const getCustomers = () => {
    axios
      .get(API_URL + "customer/optionsNew", {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        setcustomerOptions(res.data.data);
      });
  };
  /** pagination */
  const [searchValue, setSearchValue] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [PerPageCount, setPerPageCount] = useState(20);
  const [indexOfLastTransfer, setindexOfLastTransfer] = useState(
    currentPage * PerPageCount
  );

  const [indexOfFirstTransfer, setindexOfFirstTransfer] = useState(
    indexOfLastTransfer - PerPageCount
  );
  const paginate = (event, value) => {
    setCurrentPage(value);
    const indexOfLastTransfer = value * PerPageCount;
    const indexOfFirstTransfer = indexOfLastTransfer - PerPageCount;
    setindexOfFirstTransfer(indexOfFirstTransfer);
    setindexOfLastTransfer(indexOfLastTransfer);

    handleTableData(selectedCustomer?.value, searchData?.from_date, indexOfFirstTransfer, indexOfLastTransfer);
  };
  const resetPaginate = () => {
    setCurrentPage(1);
    const indexOfLastTransfer = 20;
    const indexOfFirstTransfer = 0;
    setindexOfFirstTransfer(indexOfFirstTransfer);
    setindexOfLastTransfer(indexOfLastTransfer);
  }
  const handleSearch = (value) => {
    setSearchValue(value)
    resetPaginate();
    handleTableData(value);
  }
  useEffect(() => {
    if (dataTable.length == 0 && indexOfFirstTransfer != 0) {
      resetPaginate();
      handleTableData(selectedCustomer?.value, searchData?.from_date);
    }

  }, [dataTable])
  /** end pagination */
  useEffect(() => {
    handleTableData();
    getCustomers();
  }, []);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          <Breadcrumbs title="Home" breadcrumbItem="Recharges" />
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md={3}>
                      <div className="mb-3">
                        <Label>Customer</Label>
                        <Select
                          name="customer_id"
                          id="customer_id"
                          value={selectedCustomer}
                          onChange={(selected) => {
                            setselectedCustomer(selected);
                            resetPaginate()
                            handleTableData(selected.value, searchData?.from_date)
                          }}
                          options={customerOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label >Date</Label>
                        {/* <div className="col-md-10"> */}
                        <input
                          className="form-control"
                          type="date"
                          id="from_date"
                          name="from_date"
                          value={searchData?.from_date}

                          onChange={(selected) => {
                            resetPaginate()
                            setsearchData({ ...searchData, "from_date": selected.target.value })
                            handleTableData(selectedCustomer?.value, selected.target.value)
                          }}
                          max={getDate(new Date())}
                        />
                        {/* </div> */}
                      </div>
                    </Col>

                    <Col md="1" style={{ paddingTop: "26px" }}>
                      <Button
                        color="danger"
                        type="reset"
                        onClick={() => {
                          resetPaginate();
                          setselectedCustomer(null);
                          setsearchData({ from_date: moment().format("YYYY-MM-DD") });
                          handleTableData()
                        }}
                      >
                        Reset
                      </Button>
                    </Col>
                    {
                      dataTable.length > 0 ? (

                        <Col md="1">
                          <div className="mt-4" style={{ paddingTop: "7px" }}>
                            <Button color="success" type="submit">
                              <CSVLink
                                data={dataToBeExported}
                                filename={"Top-Recharges-" + searchData?.from_date + ".xls"}
                                style={{ color: "white" }}
                              >
                                Export
                              </CSVLink>
                            </Button>
                          </div>
                        </Col>
                      ) : null
                    }

                  </Row>
                  <MDBDataTable
                    responsive
                    id="toprechargeTableId"
                    bordered
                    data={data}
                    searching={true}
                    info={false}
                    disableRetreatAfterSorting={true}
                    paging={true}

                    entries={10}
                  />

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div >
    </React.Fragment >
  );
};
export default TopRecharges;
