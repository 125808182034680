import React, { useState, useEffect, useRef } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SweetAlert from 'react-bootstrap-sweetalert';
import { MDBDataTable } from 'mdbreact';
import { Row, Col, Card, CardBody, Button, Label, Modal, Badge } from 'reactstrap';
import toastr from 'toastr';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
// import "./style.scss";
import Select from 'react-select';
const Requests = (props) => {
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
    const formRef = useRef();
    const [transactionLog, setTransactionLog] = useState([]);
    const [approvePid, setApprovePid] = useState(null);
    const [OpenApproval, setOpenApproval] = useState(false);
    const [productIds, setproductIds] = useState([]);
    const [allProducts, setallProducts] = useState([]);
    const data = {
        columns: [
            {
                // label: (
                //     <>
                //         <div class="form-check">
                //             <input class="form-check-input" type="checkbox" id="defaultCheck2" value="" checked={productIds.length == allProducts.length ? "checked" : ""} onChange={handleClickAll} />
                //             <label class="form-check-label" for="defaultCheck2">All</label>
                //         </div>
                //     </>
                // ),
                label: "#",
                field: "series",
                sort: "disabled",
                width: 150,
            },
            {
                label: "Product",
                field: "product_name_english",
                sort: "asc",
                width: 400,
            },
            {
                label: "Catgeory",
                field: "category_name_english",
                sort: "asc",
                width: 200,
            },
            {
                label: "Brand",
                field: "brand_name_english",
                sort: "asc",
                width: 200,
            },
            {
                label: "Image",
                field: "product_image_main1",
                sort: "asc",
                width: 200,
            },
            {
                label: "Request From",
                field: "req_from",
                sort: "asc",
                width: 200,
            },
            {
                label: "Action",
                field: "action",
                sort: "disabled",
                width: 200,
            },
        ],
        rows: transactionLog,
    };
    const getTableData = () => {
        axios
            .get(
                `${API_URL}shop-product/all-buyback-requests`,
                {
                    headers: {
                        "x-access-token": accessToken,
                    },
                }
            )
            .then((res) => {
                var data = res.data.data;
                var all_list = [];
                data.map((item, index) => {
                    all_list.push(item?.product_id);
                    item.id = index + 1;
                    item.series = (
                        <>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id={"defaultCheck" + index} onChange={(e) => {
                                    handleCheckClick(e, item?.product_id)
                                }} value="" />
                                <label class="form-check-label" for={"defaultCheck" + index}>{item.id}</label>
                            </div>
                        </>
                    )
                    if (item?.product_image_main) {
                        item.product_image_main1 = (
                            <>
                                <img
                                    src={`${API_URL}uploads/product_images/product_image_60x60/${item.product_image_main}`}
                                    height="50"
                                    width="50"
                                />
                            </>
                        )

                    }

                    item.action = (
                        <>
                            <i className="fas fa-check-circle" style={{
                                fontSize: "1.5em",
                                cursor: "pointer",
                                marginLeft: "0.1em",
                                marginRight: "0.5em",
                                color: "#1cad88"
                            }} title="Approve"
                                onClick={() => {
                                    setOpenApproval(true);
                                    setApprovePid({
                                        "product": item?.product_id,
                                        "status": 1
                                    })
                                }}
                            ></i>
                            <i className="fa fa-times-circle" style={{
                                fontSize: "1.5em",
                                cursor: "pointer",
                                marginLeft: "0.1em",
                                marginRight: "0.5em",
                                color: "#ff0000"
                            }} title="Reject"
                                onClick={() => {
                                    setOpenApproval(true);
                                    setApprovePid({
                                        "product": item?.product_id,
                                        "status": 2
                                    })
                                }}
                            ></i>
                        </>


                    );
                })
                setTransactionLog(res.data.data);
                setallProducts(all_list);
            });
    }
    const handleValidApproveSubmit = () => {

        axios
            .post(`${API_URL}shop-product/approve-reject`, approvePid, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                if (res.data.success == true) {

                    toastr.success("Submitted successfully");
                    setOpenApproval(false);
                    setApprovePid(null);

                    getTableData();
                } else {
                    toastr.error(res.data.message);
                }
            })
            .catch((err) => {
                toastr.error("Failed to submit");
            });
    }
    const handleCheckClick = (e, pid) => {
        var pids = productIds;
        if (e.target.checked) {
            pids.push(pid);
        }
        else {
            var pos = pids.indexOf(pid);
            pids = pids.splice(pos, 1);
        }
        setproductIds(pids);
    }
    const handleApproveAll = () => {
        if (productIds.length == 0) {
            toastr.warning("Please choose any product");
            return;
        }
        axios
            .post(`${API_URL}shop-product/all-approve-reject`, { products: productIds, status: 1 }, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                if (res.data.success == true) {

                    toastr.success("Approved successfully");
                    setproductIds([]);
                    getTableData()
                    window.location.reload();
                } else {
                    toastr.error(res.data.message);
                }
            })
            .catch((err) => {
                toastr.error("Failed to submit");
            });
    }
    const handleRejectAll = () => {
        if (productIds.length == 0) {
            toastr.warning("Please choose any product");
            return;
        }
        axios
            .post(`${API_URL}shop-product/all-approve-reject`, { products: productIds, status: 2 }, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                if (res.data.success == true) {

                    toastr.success("Rejected successfully");
                    setproductIds([]);
                    getTableData()
                    window.location.reload();
                } else {
                    toastr.error(res.data.message);
                }
            })
            .catch((err) => {
                toastr.error("Failed to submit");
            });
    }
    useEffect(() => {
        getTableData();
    }, [])
    // useEffect(() => {
    //     getTableData();
    // }, [productIds])

    return (
        <React.Fragment>
            <Modal isOpen={OpenApproval} size="sm" centered={true}>
                <div className="modal-header">
                    <h5>{approvePid?.status == "1" ? "Approve" : "Reject"} Request</h5>
                    <button
                        type="button"
                        onClick={() => {
                            setOpenApproval(false);
                            setApprovePid(null);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        style={{ padding: "13px" }}
                    ></button>
                </div>
                <div className="modal-body">
                    <AvForm
                        ref={formRef}
                        className="needs-validation"
                        onValidSubmit={(e, v) => {
                            handleValidApproveSubmit(e, v);
                        }}
                    >
                        <Row>
                            <Col md="12" className="mt-2 text-center">
                                <Button color="primary" type="submit">
                                    Submit
                                </Button>
                                <Button color="dark" className="ms-2" type="button"
                                    onClick={() => {
                                        setOpenApproval(false);
                                        setApprovePid(null);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </AvForm>
                </div>
            </Modal>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Home" breadcrumbItem="Pending Buyback Requests" />
                    <Row>

                        <Col md="12">
                            <Card>
                                <CardBody>
                                    {/* {
                                        productIds.length > 0 ? ( */}
                                    <Row>
                                        <Col md="12" className="text-center mb-3">
                                            <button className="btn btn-sm btn-success" onClick={handleApproveAll}>Approve Selected Items</button>
                                            <button className="btn btn-sm btn-danger ms-2" onClick={handleRejectAll}>Reject  Selected Items</button>
                                        </Col>
                                    </Row>
                                    {/* ) : null
                                    } */}


                                    <MDBDataTable
                                        id="requestedTableID"
                                        responsive
                                        bordered
                                        data={data}
                                        searching={true}
                                        info={false}
                                        disableRetreatAfterSorting={true}
                                        entries={10}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Requests;