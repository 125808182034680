import React, { useEffect, useState } from "react"
import Breadcrumb from "../../../components/Common/Breadcrumb";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { MDBDataTable } from "mdbreact";
import moment from "moment"
import Select from "react-select"
import { getDate } from "../../../helpers/globalFunctions";



function BussinessSummary() {

    const [master, setmaster] = useState({})
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

    const [totalAmount, setTotalAmount] = useState(null)
    const [totalOrders, setTotalOrders] = useState(null)
    const [tableData, setTableData] = useState([])

    const [shopOptions, setShopOptions] = useState([])
    const [selectedShop, setSelectedShop] = useState(null)
    const [districtOptions, setDistrictOptions] = useState([])
    const [selectedDistrict, setSelectedDistrict] = useState(null)
    const [townOptions, setTownOptions] = useState([])
    const [selectedTown, setSelectedTown] = useState(null)
    const [panchayathOptions, setPanchayathOptions] = useState([])
    const [selectedPanchayath, setSelectedPanchayath] = useState(null)




    useEffect(() => {
        getBussinessLog()
        fetch_shop()
        fetch_district()
    }, []);


    function fetch_shop() {
        axios
            .get(`${API_URL}shop/options-new`, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then(res => {
                var shop_data =
                    res.data.data.map((el) => {
                        return {
                            label: el?.label,
                            value: el?.value
                        }
                    })
                setShopOptions(shop_data)
            })
    }

    function fetch_district() {
        axios
            .get(`${API_URL}district/options`, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then(res => {
                var district_data =
                    res.data.data.map((el) => {
                        return {
                            label: el?.dist_name,
                            value: el?.dist_id
                        }
                    })
                setDistrictOptions(district_data)
            })
    }

    function fetch_town(id) {
        axios
            .get(`${API_URL}manage-town/options/?id=${id}`, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then(res => {
                var town_data =
                    res.data.data.map((el) => {
                        return {
                            label: el?.town_name,
                            value: el?.town_id
                        }
                    })
                setTownOptions(town_data)
            })
    }

    function fetch_panchayath(id) {
        axios
            .get(`${API_URL}manage-panchayath/bytown-options?id=${id}`, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then(res => {
                var panchayath_data =
                    res.data.data.map((el) => {
                        return {
                            label: el?.panchayath_name,
                            value: el?.panchayath_id
                        }
                    })
                setPanchayathOptions(panchayath_data)
            })
    }

    function getBussinessLog(from_date = "", to_date = "", district_id = "", town_id = "", panchayath_id = "", shop_id = "") {
        axios
            .get(`${API_URL}vieworders/business-details?from_date=` +
                from_date +
                "&to_date=" +
                to_date +
                "&district_id=" +
                district_id +
                "&town_id=" +
                town_id +
                "&panchayath_id=" +
                panchayath_id +
                "&shop_id=" +
                shop_id,
                {
                    headers: {
                        "x-access-token": accessToken,
                    },
                },
            ).then(res => {
                setTotalAmount(res.data.total_amount)
                setTotalOrders(res.data.no_order)

                let Data = [];
                let result = res.data.businessDetails;

                result.map((item, indx) => {

                    item.id = indx + 1;
                    item.orderDate = moment(item?.order_date).format("DD-MM-YYYY")
                    item.orderTime = moment(item?.order_time, "hh:mm s").format("hh:mm a")
                    Data.push(item);
                })
                setTableData(Data)
            })
    }

    const data = {
        columns: [
            {
                label: "#",
                field: "id",
                width: 50,
            },
            {
                label: "Order Date",
                field: "orderDate",
                width: 150,
            },
            {
                label: "Order Time",
                field: "orderTime",
                width: 50,
            },
            {
                label: "UniqueID",
                field: "orders_unique_id",
                width: 50,
            },
            {
                label: "Customer",
                field: "order_customer_name",
                width: 50,
            },
            {
                label: "Mobile",
                field: "order_contact_number",
                width: 50,
            },
            {
                label: "Amount",
                field: "order_grand_amount",
                width: 50,
            },
        ],
        rows: tableData,
    }

    function handleSelect(selected, name) {
        switch (name) {
            case "shop_id":
                setSelectedShop(selected)
                setmaster({
                    ...master,
                    shop_id: selected.value
                })
                getBussinessLog(master?.from_date, master?.to_date, master.district_id, master.town_id, master.panchayath_id, selected.value)
                break;

            case "district_id":
                setSelectedDistrict(selected)
                setSelectedTown(null)
                setmaster({
                    ...master,
                    district_id: selected.value
                })
                fetch_town(selected.value)
                getBussinessLog(master?.from_date, master?.to_date, selected.value, master.town_id, master.panchayath_id, master.shop_id)
                break;

            case "town_id":
                setSelectedTown(selected)
                setSelectedPanchayath(null)
                setmaster({
                    ...master,
                    town_id: selected.value
                })
                fetch_panchayath(selected.value)
                getBussinessLog(master?.from_date, master?.to_date, master.district_id, selected.value, master.panchayath_id, master.shop_id)
                break;

            case "panchayath_id":
                setSelectedPanchayath(selected)
                setmaster({
                    ...master,
                    panchayath_id: selected.value
                })
                getBussinessLog(master?.from_date, master?.to_date, master.district_id, master.town_id, selected.value, master.shop_id)
                break;

            default:
                break;
        }
    }

    function reset() {
        setSelectedDistrict(null)
        setSelectedPanchayath(null)
        setSelectedShop(null)
        setSelectedTown(null)
        getBussinessLog()
        setmaster({
            ...master, from_date: "", to_date: ""
        })
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumb title="Home" breadcrumbItem="Bussiness Summary" />

                    <Row>
                        <Col md={6} xl={4}>
                            <Card style={{ minHeight: "123px" }}>
                                <CardBody>
                                    <div className="float-end mt-2">
                                        <i
                                            className="fas fa-arrow-alt-circle-up"
                                            style={{ fontSize: "44px", color: "#4cbfa1" }}
                                        ></i>
                                    </div>
                                    <div>
                                        <h4 className="mb-1 mt-1">
                                            <span>
                                                <i className="fas fa-rupee-sign"></i>{" "}
                                                {totalAmount}
                                            </span>
                                        </h4>
                                        <p className="text-muted mb-0">Total Amount</p>
                                    </div>
                                    <p className="text-muted mt-3 mb-0">
                                        <span className={"me-1"} style={{ color: "#4cbfa1" }}>
                                            <i className="mdi mdi-cart"></i>{" "}
                                            {totalOrders}
                                        </span>{" "}
                                        Total Orders
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col md="12">
                            <Card>
                                <CardBody>

                                    <Row className="mb-2">
                                        <Col md="2">
                                            <Label>From Date</Label>
                                            <input
                                                name="from_date"
                                                value={master?.from_date}
                                                type="date"
                                                className="form-control"
                                                onChange={(selected) => {
                                                    setmaster({ ...master, "from_date": selected.target.value })
                                                    getBussinessLog(selected.target.value, master?.to_date, master.district_id, master.town_id, master.panchayath_id, master.shop_id)
                                                }}
                                            />
                                        </Col>

                                        <Col md="2">
                                            <Label>To Date</Label>
                                            <input
                                                name="to_date"
                                                value={master?.to_date}
                                                type="date"
                                                className="form-control"
                                                onChange={(selected) => {
                                                    setmaster({ ...master, "to_date": selected.target.value })
                                                    getBussinessLog(master?.from_date, selected.target.value, master.district_id, master.town_id, master.panchayath_id, master.shop_id)
                                                }}
                                            />
                                        </Col>

                                        <Col md="2">
                                            <Label>District</Label>
                                            <Select
                                                name="district_id"
                                                value={selectedDistrict}
                                                classNamePrefix="select2-selection"
                                                options={districtOptions}
                                                onChange={(selected) => {
                                                    handleSelect(selected, "district_id")
                                                }}
                                            />
                                        </Col>

                                        <Col md="2">
                                            <Label>Town</Label>
                                            <Select
                                                name="town_id"
                                                value={selectedTown}
                                                classNamePrefix="select2-selection"
                                                options={townOptions}
                                                onChange={(selected) => {
                                                    handleSelect(selected, "town_id")
                                                }}
                                            />
                                        </Col>

                                        <Col md="2">
                                            <Label>Panchayat</Label>
                                            <Select
                                                name="panchayath_id"
                                                value={selectedPanchayath}
                                                classNamePrefix="select2-selection"
                                                options={panchayathOptions}
                                                onChange={(selected) => {
                                                    handleSelect(selected, "panchayath_id")
                                                }}
                                            />
                                        </Col>

                                        <Col md="2">
                                            <Label>Shop</Label>
                                            <Select
                                                name="shop_id"
                                                value={selectedShop}
                                                classNamePrefix="select2-selection"
                                                options={shopOptions}
                                                onChange={(selected) => {
                                                    handleSelect(selected, "shop_id")
                                                }}
                                            />
                                        </Col>

                                        <Col md="1" style={{ marginTop: "28px" }}>
                                            <Button type="reset" color="danger" onClick={() => reset()}>Reset</Button>
                                        </Col>
                                    </Row>

                                    <MDBDataTable
                                        id="bussinessLogTable"
                                        responsive
                                        bordered
                                        data={data}
                                        disableRetreatAfterSorting={true}
                                        entries={20}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </div>

        </React.Fragment>
    )

}

export default BussinessSummary;