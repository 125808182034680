import React, { useEffect, useState, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Modal,
  Container,
  ModalHeader,
  ModalBody,
  Table,
  Badge,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import { MDBDataTable } from "mdbreact";
import "./order.css";
import moment from "moment";
import { getDate, getFirstday } from "../../helpers/globalFunctions";

const Orders = (props) => {
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [filterObject, setfilterObject] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });
  const [viewOrderForTable, setViewOrderForTable] = useState([]);
  const [orderViewModalOpen, setOrderViewModalOpen] = useState(null);
  const [order, setOrder] = useState(null);
  const [orderItemsData, setorderItemsData] = useState([]);
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 200,
      },
      {
        label: "Order#",
        field: "orders_unique_id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Source",
        field: "order_from",
        sort: "asc",
        width: 100,
      },
      {
        label: "Name",
        field: "order_customer_name",
        sort: "asc",
        width: 100,
      },
      {
        label: "Moblie",
        field: "order_contact_number",
        sort: "asc",
        width: 100,
      },
      {
        label: "Amount",
        field: "amount_column",
        sort: "asc",
        width: 100,
      },
      {
        label: "Status",
        field: "payment_status",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "disabled",
        width: 100,
      },
    ],
    rows: viewOrderForTable,
  };
  const orderItems = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "SKU",
        field: "product_sku_autogen",
        sort: "asc",
        width: 400,
      },
      {
        label: "Product",
        field: "o_item_product_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Image",
        field: "image",
        sort: "asc",
        width: 100,
      },
      {
        label: "HSN",
        field: "product_hsn",
        sort: "asc",
        width: 100,
      },
      {
        label: "Unit",
        field: "unit_name",
        sort: "asc",
        width: 100,
      },
      {
        label: "Qty",
        field: "item_product_order_quantity",
        sort: "asc",
        width: 100,
      },
      {
        label: "Rate",
        field: "order_item_rate",
        sort: "asc",
        width: 100,
      },
      {
        label: "MRP",
        field: "o_item_product_mrp",
        sort: "asc",
        width: 100,
      },
      {
        label: "Discount",
        field: "order_item_discount",
        sort: "disabled",
        width: 100,
      },
      {
        label: "GST",
        field: "item_gst",
        sort: "disabled",
        width: 100,
      },
      {
        label: "Amount",
        field: "o_item_amount_exc",
        sort: "disabled",
        width: 100,
      },
    ],
    rows: orderItemsData,
  };
  const handleFilter = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    setfilterObject({
      ...filterObject,
      [name]: value,
    });
    switch (name) {
      case "from_date":
        getOrders(value, filterObject?.to_date);
        break;
      case "to_date":
        getOrders(filterObject?.from_date, value);
        break;
    }
  };
  const getOrders = (from_date = "", to_date = "") => {
    if (from_date == "") from_date = getFirstday(new Date());
    if (to_date == "") to_date = getDate(new Date());
    axios
      .get(
        `${API_URL}vieworders?orderDate1=` +
          from_date +
          "&orderDate2=" +
          to_date,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        var data = res.data.data;
        let ordersData = [];
        data.map((item, index) => {
          item.id = index + 1;
          item.date = moment(item.order_date).local().format("DD-MM-YYYY");
          item.time = moment(item.order_time, "h:mm:ss.*").format("hh:mm a");
          if (item.order_payment_status === 1)
            item.payment_status = (
              <div
                class="btn-group btn-group-sm"
                role="group"
                aria-label="Basic example"
              >
                <button
                  class="btn btn-danger"
                  style={{ padding: "1px", minWidth: "50px" }}
                >
                  Not Paid
                </button>
              </div>
            );
          else if (item.order_payment_status === 2)
            item.payment_status = (
              <div
                class="btn-group btn-group-sm"
                role="group"
                aria-label="Basic example"
              >
                <button
                  class="btn btn-success"
                  style={{ padding: "1px", minWidth: "50px" }}
                >
                  Paid
                </button>
              </div>
            );
          else if (item.order_payment_status === 3)
            item.payment_status = (
              <div
                class="btn-group btn-group-sm"
                role="group"
                aria-label="Basic example"
              >
                <button
                  class="btn btn-warning"
                  style={{ padding: "1px", minWidth: "50px" }}
                >
                  Failed
                </button>
              </div>
            );
          item.amount_column = (
            <div className="text-right">
              {parseFloat(item.order_wallet_amount.toFixed(2))}
            </div>
          );
          if (item.order_from == 0) item.order_from = "CRM";
          else if (item.order_from == 1) item.order_from = "Website";
          else if (item.order_from == 2) item.order_from = "Android";
          else if (item.order_from == 3) item.order_from = "IOS";
          else if (item.order_from == 4) item.order_from = "Whatsapp";
          else if (item.order_from == 5) item.order_from = "POS";
          item.order_customer_name = (
            <a href={`/customer/${item.customers_unique_id}`} target="_blank">
              {item.customer_name}
            </a>
          );
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-file-alt"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.5em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  setOrder(null);
                  getOrder(item.order_id);
                  // showSingleOrder();
                }}
              ></i>
              {/* <i
                                className="far fa-edit"
                                style={{
                                    fontSize: "1em",
                                    cursor: "pointer",
                                    marginLeft: "0.5em",
                                    marginRight: "0.5em",
                                }}
                                onClick={() => {
                                    window.location.assign("/add-order/" + item.order_id);
                                }}
                            ></i> */}
            </div>
          );

          ordersData.push(item);
        });
        setViewOrderForTable(ordersData);
      });
  };
  const getOrder = (id) => {
    axios
      .get(`${API_URL}vieworders/` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var data = res.data.data;
        data.orderItems &&
          data.orderItems.map((item, index) => {
            item.id = index + 1;
            item.product_sku_autogen = item?.product?.product_sku_autogen;
            item.product_hsn = item?.product?.product_hsn;
            item.unit_name = item?.product?.productUnit?.unit_short;
            item.order_item_rate = item?.order_item_rate?.toFixed(2);
            item.o_item_product_mrp = item?.o_item_product_mrp?.toFixed(2);
            item.order_item_discount = item?.order_item_discount?.toFixed(2);
            item.o_item_amount_exc = item?.o_item_amount_exc?.toFixed(2);
            if (item?.product?.product_image_main) {
              item.image = (
                <img
                  src={
                    API_URL +
                    "uploads/product_images/product_image_60x60/" +
                    item?.product?.product_image_main
                  }
                  height="50px"
                  width={"50px"}
                />
              );
            }
            if (item?.o_item_buyback_status) {
              data.order_net_amount =
                data.order_net_amount + parseFloat(item.order_item_rate);
              data.order_gst_amount =
                data.order_gst_amount +
                parseFloat(item.item_product_gst_amount);
              data.order_sub_total =
                data.order_sub_total + parseFloat(item.item_product_price);
              item.o_item_product_name = (
                <>
                  {item.o_item_product_name}{" "}
                  <Badge className="bg-success">Buyback Product</Badge>
                </>
              );
            }
          });
        setOrder(data);
        setorderItemsData(data.orderItems);
        setOrderViewModalOpen(true);
      });
  };
  const reset = () => {
    setfilterObject({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    getOrders();
  };
  useEffect(() => {
    getOrders();
  }, []);
  return (
    <React.Fragment>
      <Modal
        isOpen={orderViewModalOpen}
        toggle={() => setOrderViewModalOpen(!orderViewModalOpen)}
        size="xl"
      >
        <div className="modal-header">
          <button
            type="button"
            onClick={() => {
              setOrderViewModalOpen(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <ModalBody>
          <Row>
            <Col md="12" className="text-center">
              <h5 className="modal-title mt-0">
                {" "}
                Order Estimate #{order?.orders_unique_id}{" "}
              </h5>
            </Col>
            <Col md="5" style={{ fontSize: "13px" }}>
              <Row>
                <Col md="4" className="p-1">
                  Customer Name
                </Col>
                <Col md="8">: {order?.customer?.customer_name}</Col>
                <Col md="4" className="p-1">
                  Address
                </Col>
                <Col md="8">: {order?.customer?.customer_address}</Col>
                <Col md="4" className="p-1">
                  Moblie
                </Col>
                <Col md="8">: {order?.customer?.customer_contact_number}</Col>
                <Col md="4" className="p-1">
                  Email
                </Col>
                <Col md="8">: {order?.customer?.customer_email_id}</Col>
                <Col md="4" className="p-1">
                  Shipping Address
                </Col>
                <Col md="8">
                  : {order?.address?.address_name}
                  <br></br>
                  {order?.address?.shipping_address}{" "}
                  {order?.address?.customer_location},
                  {order?.address?.customer_district} -{" "}
                  {order?.address?.customer_pincode}
                  <br></br>
                  <i className="fas fa-phone-square-alt"></i>{" "}
                  {order?.address?.address_contact_number}
                </Col>
              </Row>
            </Col>
            <Col md="3"></Col>
            <Col md="4" style={{ fontSize: "13px" }}>
              <Row>
                <Col
                  md="6"
                  style={{ paddingLeft: "97px", paddingBottom: "10px" }}
                >
                  Date
                </Col>
                <Col md="6" style={{ paddingLeft: "12px" }}>
                  : {moment(order?.order_date).format("DD-MM-YYYY")}
                </Col>
                <Col
                  md="6"
                  style={{ paddingLeft: "97px", paddingBottom: "10px" }}
                >
                  Time
                </Col>
                <Col md="6" style={{ paddingLeft: "12px" }}>
                  : {moment(order?.order_time, "HH:mm:ss").format("hh:mm a")}
                </Col>
                <Col
                  md="6"
                  style={{ paddingLeft: "97px", paddingBottom: "10px" }}
                >
                  Delivery Date
                </Col>
                <Col md="6" style={{ paddingLeft: "12px" }}>
                  :{" "}
                  {moment(order?.order_delivery_date_from).format("DD-MM-YYYY")}
                </Col>
                <Col
                  md="6"
                  style={{ paddingLeft: "97px", paddingBottom: "10px" }}
                >
                  Delivery Time
                </Col>
                <Col md="6" style={{ paddingLeft: "12px" }}>
                  :{" "}
                  {moment(
                    order?.order_delivery_date_from,
                    "YYYY-MM-DD HH:mm:ss"
                  ).format("hh:mm a")}{" "}
                  -{" "}
                  {moment(
                    order?.order_delivery_date_to,
                    "YYYY-MM-DD HH:mm:ss"
                  ).format("hh:mm a")}
                </Col>
                <Col
                  md="6"
                  style={{ paddingLeft: "97px", paddingBottom: "10px" }}
                >
                  Delivery Type
                </Col>
                <Col md="6" style={{ paddingLeft: "12px" }}>
                  :{" "}
                  {order?.order_delivery_type == 1
                    ? "Fast"
                    : order?.order_delivery_type == 2
                    ? "Express"
                    : "Normal"}
                </Col>
              </Row>
            </Col>
          </Row>
          <hr></hr>
          <Row>
            <Col md="12">
              <MDBDataTable
                id="OrderItemsTableID"
                responsive
                bordered
                data={orderItems}
                searching={false}
                info={false}
                paging={false}
                disableRetreatAfterSorting={true}
              />
              <Row>
                <Col md="8"></Col>
                <Col md="4" style={{ textAlign: "right" }}>
                  <table id="OrderSummeryTable">
                    <tr>
                      <th>Total</th>
                      <td>:</td>
                      <td>{order?.order_net_amount?.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <th>GST</th>
                      <td>:</td>
                      <td>{order?.order_gst_amount?.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <th>Total Taxable</th>
                      <td>:</td>
                      <td>{order?.order_sub_total?.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <th>Delivery Charge</th>
                      <td>:</td>
                      <td>{order?.order_delivery_amount?.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <th>Sub Total</th>
                      <td>:</td>
                      <td>
                        {(
                          order?.order_delivery_amount + order?.order_sub_total
                        )?.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <th>Total Discount</th>
                      <td>:</td>
                      <td>{order?.order_discount_amount?.toFixed(2)}</td>
                    </tr>
                    {order?.order_point_used > 0 ? (
                      <tr>
                        <th>Point Redeem</th>
                        <td>:</td>
                        <td>{order?.order_point_used?.toFixed(2)}</td>
                      </tr>
                    ) : null}
                    {/* <tr>
                                            <th>Net Amount</th><td>:</td><td>{(parseFloat(order?.order_sub_total) + parseFloat(order?.order_delivery_amount)).toFixed(2)}</td>
                                        </tr> */}
                    {/* <tr>
                                            <th>Wallet Amount Used</th><td>:</td><td>{order?.order_wallet_amount?.toFixed(2)}</td>
                                        </tr> */}
                    <tr>
                      <th>Grand Total</th>
                      <td>:</td>
                      <td>{order?.order_wallet_amount?.toFixed(2)}</td>
                    </tr>
                  </table>
                </Col>
              </Row>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="StarLee" breadcrumbItem="Orders" />
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="2">
                      <Label htmlFor="validationCustom05">From</Label>
                      <input
                        className="form-control"
                        type="date"
                        id="from_date"
                        name="from_date"
                        onChange={handleFilter}
                        value={filterObject?.from_date}
                      />
                    </Col>
                    <Col md="2">
                      <Label htmlFor="validationCustom05">To</Label>
                      <input
                        className="form-control"
                        type="date"
                        id="to_date"
                        name="to_date"
                        onChange={handleFilter}
                        value={filterObject?.to_date}
                      />
                    </Col>
                    <Col md="1" style={{ marginTop: "25px" }}>
                      <Button color="danger" type="submit" onClick={reset}>
                        Reset
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="orderListTable"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={false}
                    entries={25}
                    disableRetreatAfterSorting={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default Orders;
