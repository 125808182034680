import React, { useState, useEffect, useRef } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SweetAlert from 'react-bootstrap-sweetalert';
import { MDBDataTable } from 'mdbreact';
import { Row, Col, Card, CardBody, Button, Label, Modal, Badge } from 'reactstrap';
import toastr from 'toastr';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import "./style.scss";
import Select from 'react-select';
import { CSVLink } from "react-csv";
import { getDate } from "../../helpers/globalFunctions";
import saveAs from 'file-saver'
import reduxSaga from "redux-saga";

const CashTransfers = (props) => {
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
    const formRef = useRef();
    const [transactionLog, setTransactionLog] = useState([]);
    const [filterObject, setfilterObject] = useState({ category: "", categoryObject: { label: "All", value: "" } });
    const [filterStatusOptions, setfilterStatusOptions] = useState([{ label: "Pending", value: 0 }, { label: "Completed", value: 2 }, { label: "Rejected", value: 3 }]);
    const [OpenApproval, setOpenApproval] = useState(false);
    const [approvalId, setApprovalId] = useState(null);
    const [selectedStatus, setselectedStatus] = useState(null);
    const [approvalObject, setapprovalObject] = useState(null);
    const [isLoading, setisLoading] = useState(false);
    const [dataToBeExported, setDataToBeExported] = useState([])
    const [shopOptions, setShopOptions] = useState([])

    const data = {
        columns: [
            {
                label: "#",
                field: "id",
                sort: "asc",
                width: 150,
            },
            {
                label: "Date",
                field: "date",
                sort: "asc",
                width: 400,
            },
            {
                label: "Time",
                field: "time",
                sort: "asc",
                width: 200,
            },
            {
                label: "Shop",
                field: "shop_name",
                sort: "asc",
                width: 200,
            },
            {
                label: "From",
                field: "suser_name",
                sort: "asc",
                width: 200,
            },
            {
                label: "Amount",
                field: "transfer_amount1",
                sort: "asc",
                width: 200,
            },
            {
                label: "Comment",
                field: "transfer_remarks",
                sort: "asc",
                width: 200,
            },
            {
                label: "Status",
                field: "status1",
                sort: "asc",
                width: 200,
            },
            {
                label: "Action",
                field: "action",
                sort: "asc",
                width: 200,
            },
        ],
        rows: transactionLog,
    };
    const getTableData = (from = "", to = "", status = null, category = "", shop = "") => {
        axios
            .get(
                `${API_URL}shop-cash-transfer/all-log?from=` + from + `&to=` + to + `&status=` + status + `&category=` + category + `&shop=` + shop,
                {
                    headers: {
                        "x-access-token": accessToken,
                    },
                }
            )
            .then((res) => {
                var data = res.data.data;
                let dataToBeExported = [];
                data.map((item, index) => {
                    let exportItem = {};
                    item.id = index + 1;
                    item.shop_name = item?.shop?.shop_name;
                    item.suser_name = item?.addedBy?.suser_name;
                    if (item?.transfer_category == 1) {
                        item.transfer_amount1 = (<><i className="mdi mdi-flash-circle" style={{ color: "#f9b739" }}></i>{" "}{parseFloat(item.transfer_amount).toLocaleString()}</>)
                    }
                    else {
                        item.transfer_amount1 = (<><i className="fas fa-rupee-sign" style={{ fontSize: "10px" }}></i>{" "}{parseFloat(item.transfer_amount).toLocaleString()}</>)
                    }
                    item.status1 = (
                        <Badge style={{ background: item?.color, fontSize: "12px" }}>{item?.status}</Badge>
                    )
                    if (item?.transfer_status == 0) {
                        item.action = (
                            <div style={{ display: "flex", justifyContent: 'center' }}>
                                <i className="mdi mdi-cash-refund" style={{ fontSize: "1.5em", cursor: "pointer", marginLeft: '0.2em', marginRight: '0.5em' }} title="Approve / Reject Transaction"
                                    onClick={() => {
                                        setOpenApproval(true);
                                        setapprovalObject({
                                            "transfer_id": item?.transfer_id
                                        })
                                    }}
                                ></i>

                                <i className="fas fa-file-pdf"
                                    style={{
                                        fontSize: "2.38em",
                                        cursor: "pointer",
                                        marginLeft: '0.56em',
                                        marginRight: '0.5em',
                                        color: 'red',
                                    }}
                                    onClick={() => {
                                        downloadPdf(item)
                                    }}
                                ></i>
                            </div >
                        );
                    }else{
                        item.action = (
                            <div style={{ display: "flex", justifyContent: 'center' }}>
                                <i class=""></i>
                                <i className="fas fa-file-pdf"
                                    style={{
                                        fontSize: "2.38em",
                                        cursor: "pointer",
                                        marginLeft: '1.44em',
                                        marginRight: '0.54em',
                                        color: 'red',
                                    }}
                                    onClick={() => {
                                        downloadPdf(item)
                                    }}
                                ></i>
                            </div >
                        );
                    }


                    // export
                    exportItem.Id = item.id;
                    exportItem.Date = item.date;
                    exportItem.Time = item.time;
                    exportItem.Shop = item.shop_name;
                    exportItem.From = item.suser_name;
                    exportItem.Amount = item.transfer_amount;
                    exportItem.Comment = item.transfer_remarks;
                    exportItem.Status = item.status;

                    dataToBeExported.push(exportItem)
                })
                setTransactionLog(res.data.data);

                setDataToBeExported(dataToBeExported)
            });
    }

    function downloadPdf(item) {
        saveAs(`${API_URL}uploads/receipt/` + item?.transfer_receipt)
    }

    const handleValidApproveSubmit = () => {

        if (!approvalObject?.status) {
            toastr.warning("Please select status");
            return;
        }
        setisLoading(true);
        axios
            .post(`${API_URL}shop-cash-transfer/approve-reject`, approvalObject, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                setisLoading(false);
                if (res.data.success == true) {

                    toastr.success("Submitted successfully");
                    setOpenApproval(false);
                    setapprovalObject({ transfer_id: "", status: "", remarks: "" });
                    setselectedStatus(null);
                    getTableData(filterObject?.from, filterObject?.to, filterObject?.status, filterObject?.category, filterObject?.shop);
                } else {
                    toastr.error(res.data.message);
                }
            })
            .catch((err) => {
                setisLoading(false);
                toastr.error("Failed to submit");
            });
    }
    useEffect(() => {
        getTableData();
        fetch_shop();
    }, [])

    function fetch_shop() {
        axios
            .get(`${API_URL}shop/options-new`, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then(res => {
                var shop_data =
                    res.data.data &&
                    res.data.data.map((el) => {
                        return {
                            label: el.label,
                            value: el.value
                        }
                    })
                setShopOptions(shop_data)
            })
    }

    return (
        <React.Fragment>
            <Modal isOpen={OpenApproval} size="sm" centered={true}>
                <div className="modal-header">
                    <h5>Approve/Reject Transaction</h5>
                    <button
                        type="button"
                        onClick={() => {
                            setOpenApproval(false);
                            setapprovalObject({ transfer_id: "", status: "", remarks: "" });
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        style={{ padding: "13px" }}
                    ></button>
                </div>
                <div className="modal-body">
                    <AvForm
                        ref={formRef}
                        className="needs-validation"
                        onValidSubmit={(e, v) => {
                            handleValidApproveSubmit(e, v);
                        }}
                    >
                        <Row>
                            <Col md="12">
                                <Label htmlFor="validationCustom01">
                                    Status
                                </Label>
                                <Select
                                    name="status"
                                    value={selectedStatus}
                                    options={[{ label: "Completed/Approved", "value": 2 }, { label: "Rejected", "value": 3 }]}
                                    onChange={(e) => {
                                        setselectedStatus(e);
                                        setapprovalObject({
                                            ...approvalObject,
                                            "status": e.value
                                        });
                                    }}
                                />
                            </Col>
                            <Col md="12" className="mt-2">
                                <Label htmlFor="validationCustom01">
                                    Remarks{" "}
                                </Label>
                                <AvField
                                    name="remarks"
                                    placeholder="Enter your message"
                                    type="textarea"
                                    errorMessage="Remarks is required"
                                    className="form-control"
                                    validate={{
                                        required: { value: true },
                                        //max: { value: master?.balance, errorMessage: "Max.amount you can withdraw is Rs." + master?.balance }
                                    }}
                                    onChange={(e) => {
                                        setapprovalObject({
                                            ...approvalObject,
                                            "remarks": e.target.value
                                        });
                                    }}
                                    id="validationCustom01"
                                    value={approvalObject?.remarks}
                                />
                            </Col>
                            <Col md="12" className="mt-2 text-right">
                                <Button color="primary" type="submit" disabled={isLoading}>
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </AvForm>
                </div>
            </Modal>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Home" breadcrumbItem="Merchant Transactions" />
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md="2">
                                            <Label>From</Label>
                                            <input type="date"
                                                name="from"
                                                className="form-control"
                                                onChange={(e) => {
                                                    setfilterObject({
                                                        ...filterObject,
                                                        [e.target.name]: e.target.value
                                                    })
                                                }}
                                                value={filterObject?.from} />
                                        </Col>

                                        <Col md="2">
                                            <Label>To</Label>
                                            <input type="date"
                                                name="to"
                                                className="form-control"
                                                onChange={(e) => {
                                                    setfilterObject({
                                                        ...filterObject,
                                                        [e.target.name]: e.target.value
                                                    })
                                                }}
                                                value={filterObject?.to} />
                                        </Col>

                                        <Col md="2">
                                            <Label>Status</Label>
                                            <Select
                                                name="status"
                                                value={filterObject?.statusObject}
                                                options={filterStatusOptions}
                                                onChange={(e) => {
                                                    setfilterObject({
                                                        ...filterObject,
                                                        "status": e.value,
                                                        "statusObject": e
                                                    });
                                                }}
                                            //className="form-control"
                                            />
                                        </Col>

                                        <Col md="2">
                                            <Label>Type</Label>
                                            <Select
                                                name="category"
                                                value={filterObject?.categoryObject}
                                                options={[{ label: "All", value: "" }, { label: "Wallet", value: "0" }, { label: "Point", value: "1" }]}
                                                onChange={(e) => {
                                                    setfilterObject({
                                                        ...filterObject,
                                                        "category": e.value,
                                                        "categoryObject": e
                                                    });
                                                }}
                                            //className="form-control"
                                            />
                                        </Col>

                                        <Col md="2">
                                            <Label>Shop</Label>
                                            <Select
                                                name="shop"
                                                value={filterObject?.shopObject}
                                                options={shopOptions}
                                                onChange={(e) => {
                                                    setfilterObject({
                                                        ...filterObject,
                                                        "shop": e.value,
                                                        "shopObject": e
                                                    })
                                                }}
                                            />
                                        </Col>

                                        <Col md="4" style={{ paddingTop: "26px" }}>
                                            <div style={{ display: "flex" }}>
                                                <Button color="primary"
                                                    onClick={() => {
                                                        getTableData(filterObject?.from, filterObject?.to, filterObject?.status, filterObject?.category, filterObject?.shop);
                                                    }}
                                                ><i className="fas fa-filter"></i>{" "}Filter</Button>

                                                <div style={{ marginLeft: "10px" }}>
                                                    <Button color="danger"
                                                        onClick={() => {
                                                            setfilterObject({ from: "", to: "", status: null, statusObject: null, category: "", categoryObject: { label: "All", value: "" }, shop: null, shopObject: null });
                                                            getTableData();
                                                        }}
                                                    ><i className="fas fa-sync-alt"></i>{" "}Reset</Button>
                                                </div>

                                                <div style={{ marginLeft: '10px' }}>
                                                    <Button color="success" type="submit">
                                                        <CSVLink data={dataToBeExported}
                                                            filename={
                                                                "Merchant_transactions_" +
                                                                getDate(new Date()) +
                                                                ".xls"
                                                            }
                                                            style={{ color: "white" }}><i className="fas fa-file-pdf"></i>{""} Export</CSVLink>
                                                    </Button>
                                                </div>
                                            </div>

                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <MDBDataTable
                                        id="transactionList"
                                        responsive
                                        bordered
                                        data={data}
                                        searching={true}
                                        info={false}
                                        disableRetreatAfterSorting={true}
                                        entries={25}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}
export default CashTransfers;