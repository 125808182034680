import React, { useEffect, useState } from "react";
import { Container, Row, Col, CardBody, Card } from "reactstrap";
import { Link } from "react-router-dom";
import errorImg from "../../assets/images/404-error.svg"

const PageNotFound = (props) => {
    return (
        <React.Fragment>
            <div className="my-5 pt-sm-5">
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="text-center">
                                <div>
                                    <Row className="row justify-content-center">
                                        <Col sm={4}>
                                            <div className="error-img">
                                                <img src={errorImg} alt="" className="img-fluid mx-auto d-block" style={{ height: '280px' }} />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <h4 className="text-uppercase mt-4">Sorry, page not found</h4>
                                <div className="mt-5">
                                    <Link className="btn btn-primary waves-effect waves-light" to="/">Back to Dashboard</Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default PageNotFound;