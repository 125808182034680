import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import accessToken from "../../helpers/jwt-token-access/accessToken";
import {jwtDecode} from 'jwt-decode';
// import  jwtDecode from 'jwt-decode';


const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  roles,
  path,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (isAuthProtected && !localStorage.getItem("authUser")) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
      else if (isAuthProtected) {
        const decoded = jwtDecode(localStorage.getItem('authUser'));
        // console.log(decoded.routes);
        if (!decoded.routes.includes(path))
          return (
            <Redirect
              to={{ pathname: "/logout", state: { from: props.location } }}
            />
          )
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
)

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware
