import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Card, CardBody, Container, Label, Button, Spinner, Modal } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import { MDBDataTable } from "mdbreact";
import "./recharge.css";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from "react-select";
import toastr from "toastr";
import Item from "antd/lib/list/Item";
import { useHistory } from "react-router-dom";


const Executiverecharge = (props) => {
  let history = useHistory();
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  const [dataObject, setdataObject] = useState(null);
  const [master, setMaster] = useState({
    total_count: 0,
    tot_customers: 0,
    total_amount: 0,
    today_amount: 0,
    total_buyback: 0,
    total_jio: 0,
    tot_buyback_cust: 0,
    tot_jio_cust: 0,
  });
  const [customerOptions, setcustomerOptions] = useState([]);
  const [selectedCustomer, setselectedCustomer] = useState(null);
  const [dataTable, setdataTable] = useState([]);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isUpdate, setisUpdate] = useState(false);
  const [amountOptions, setAmountOptions] = useState([
    { label: "Rs.200", value: 200 },
    { label: "Rs.400", value: 400 },
    { label: "Rs.600", value: 600 },
    { label: "Rs.800", value: 800 },
    { label: "Rs.1000", value: 1000 },
    { label: "Rs.2000", value: 2000 },
    { label: "Rs.3000", value: 3000 },
    { label: "Rs.4000", value: 4000 },
    { label: "Rs.5000", value: 5000 },
    { label: "Rs.10000", value: 10000 },
    { label: "Rs.25000", value: 25000 },
    { label: "Rs.50000", value: 50000 },
    { label: "Rs.100000", value: 100000 },
    // { label: "Rs.200000", value: 200000 },
    // { label: "Rs.500000", value: 500000 },
    // { label: "Rs.1000000", value: 1000000 },
  ]);
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 150,
      },
      {
        label: "Customer",
        field: "customer",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Unclaimed Recharge",
      //   field: "unclaimed",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Pending Balance",
      //   field: "balance",
      //   sort: "asc",
      //   width: 150,
      // },
      // {
      //   label: "Action",
      //   field: "action",
      //   sort: "asc",
      //   width: 150,
      // },
      // {
      //   label: "Buyback Offer",
      //   field: "point1",
      //   sort: "asc",
      //   width: 150,
      // },
      // {
      //   label: "Jio Offer",
      //   field: "point2",
      //   sort: "asc",
      //   width: 150,
      // },
    ],
    rows: dataTable,
  };
  const handleData = () => {
    axios
      .get(API_URL + "executive-recharge/recharges-dashboard", {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        setMaster(res.data.data);
      });
  };
  const preUpdate = (item) => {
    setSelectedAmount({
      label: "Rs." + item.amount,
      value: parseInt(item.amount),
    });
    setselectedCustomer({ label: item.customer_name, value: item.customer_id });
    setdataObject({
      customer_id: item.customer_id,
      amount: item.amount,
      block_id: item.block_id,
      wlog_id: item.wlog_id,
    });
    setisUpdate(true);
  };
  const handleTableData = () => {
    axios
      .get(`${API_URL}executive-recharge/recharges?customer_id=${selectedCustomer?.value}`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.data) {
          const data = res.data.data.map((item, index) => {
            let can_edit = 0;
  
            let point1Content = item.point1;
            if (item.locked === 0 && item.point1 === "Earned") {
              can_edit = 1;
              point1Content = (
                <>
                  <i className="mdi mdi-lock-alert" style={{ color: "#dc3545" }}></i> Locked
                </>
              );
            } else if (item.locked === 1 && item.point1 === "Earned") {
              can_edit = 1;
              point1Content = (
                <>
                  <i className="mdi mdi-lock-open-check" style={{ color: "#4cae89" }}></i> {item.point1}
                </>
              );
            }

            let point2Content = item.point2;
            if (item.locked2 === 0 && item.point2 === "Earned") {
              can_edit = 1;
              point2Content = (
                <>
                  <i className="mdi mdi-lock-alert" style={{ color: "#dc3545" }}></i> Locked
                </>
              );
            } else if (item.locked2 === 1 && item.point2 === "Earned") {
              can_edit = 1;
              point2Content = (
                <>
                  <i className="mdi mdi-lock-open-check" style={{ color: "#4cae89" }}></i> {item.point2}
                </>
              );
            }
            let action = null;
            if (can_edit === 0) {
              action = (
                <i
                  className="far fa-edit"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    preUpdate(item);
                  }}
                ></i>
              );
            }
  
            return {
              id: index + 1,
              date: item.date,
              time: item.time,
              customer: (
                <a href={`/customer/${item.customers_unique_id}`} target="_blank" rel="noopener noreferrer">
                  {item.customer_name}
                </a>
              ),
              amount: item.amount,
              // claimed: item.claimedAmount || 0, 
              // balance: item.pending_balance || item.amount, 
              // point1: point1Content,
              // point2: point2Content,
              // action: action,
            };
          });
          setdataTable(data);
        }
      })
      .catch((err) => console.error(err));
  };
  const getCustomers = () => {
    axios
      .get(API_URL + "executive-recharge/optionsNew", {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        setcustomerOptions(res.data.data);
      });
  };
  const handleValidSubmit = () => {
    if (!dataObject?.customer_id) {
      toastr.warning("Please choose a customer");
      return;
    }
    if (!dataObject?.amount) {
      toastr.warning("Please choose a recharge amount");
      return;
    }
    setLoading(true);
    if (isUpdate) {
      axios
        .post(API_URL + "executive-recharge/update", dataObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.success) {
            toastr.success("Updated successfully");
            setLoading(false);
            handleData();
            handleTableData();
            setdataObject(null);
            setSelectedAmount(null);
            setselectedCustomer(null);
            setisUpdate(false);
            formRef.current.reset();
          } else {
            toastr.error("Failed to recharge");
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          toastr.error("Failed to recharge");
          setLoading(false);
        });
    } else {
      axios
        .post(API_URL + "executive-recharge/add-amount", dataObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.success) {
            toastr.success("Recharged successfully");
            setLoading(false);
            handleData();
            handleTableData();
            setdataObject(null);
            setSelectedAmount(null);
            setselectedCustomer(null);
            formRef.current.reset();
          } else {
            toastr.error(res.data.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          //toastr.error("Failed to recharge3");
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    handleData();
    handleTableData();
    getCustomers();
  }, []);
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isLoading) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isLoading]);
  useEffect(() => {
    const unblock = history.block((tx) => {
      if (isLoading) {
        const shouldBlock = window.confirm(
          "Recharging processing is ongoing. If you reload the page, it may affect this process. Are you sure you want to reload?"
        );
        if (shouldBlock) {
          return true; // Allow navigation
        } else {
          return false; // Prevent navigation
        }
      }
      return true; // Allow navigation
    });

    return () => {
      unblock();
    };
  }, [isLoading, history]);
  return (
    <React.Fragment>

      <div className="page-content">
        <div className="container-fluid">
          {/* <Container> */}
          <Breadcrumbs title="Home" breadcrumbItem="Recharges" />
          <Row>
            <Col md="6">
              <Card>
                <CardBody style={{ padding: "7px" }}>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                    encType="multipart/form-data">
                    <Row>
                      {/* <Col md={12} className="balance-div">
                        Balance : <i className="fas fa-rupee-sign"></i> {parseFloat(master?.balance).toLocaleString()}
                      </Col> */}
                      <Col md={4}>
                        <div className="mb-4">
                          <Label>Customer</Label>
                          <Select
                            name="customer_id"
                            id="customer_id"
                            value={selectedCustomer}
                            onChange={(selected) => {
                              setselectedCustomer(selected);
                              setdataObject({
                                ...dataObject,
                                customer_id: selected.value,
                              });
                            }}
                            options={customerOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label>Amount</Label>
                          <Select
                            name="amount"
                            id="amount"
                            value={selectedAmount}
                            onChange={(selected) => {
                              setSelectedAmount(selected);
                              setdataObject({
                                ...dataObject,
                                amount: selected.value,
                              });
                            }}
                            options={amountOptions}
                            classNamePrefix="select2-selection"
                            isDisabled={isUpdate ? true : ""}
                          />
                        </div>
                      </Col>

                      {isLoading ? (
                        <Col md="4" style={{ paddingTop: "33px", fontSize: "13px", fontWeight: "bold" }}>
                          Processing <Spinner type="grow" className="m-1" color="success" style={{ height: "5px", width: "5px" }} />
                          <Spinner type="grow" className="m-1" color="primary" style={{ height: "5px", width: "5px" }} />
                          <Spinner type="grow" className="m-1" color="danger" style={{ height: "5px", width: "5px" }} />
                          <Spinner type="grow" className="m-1" color="warning" style={{ height: "5px", width: "5px" }} />
                          <Spinner type="grow" className="m-1" color="dark" style={{ height: "5px", width: "5px" }} />
                        </Col>
                      ) : (
                        <>
                          <Col md="2" style={{ paddingTop: "26px" }}>
                            {isUpdate ? (
                              <Button color="primary" type="submit" disabled={isLoading}>
                                Update
                              </Button>
                            ) : (
                              <Button color="primary" type="submit" disabled={isLoading}>
                                Recharge
                              </Button>
                            )}
                          </Col>
                          <Col md="1" style={{ paddingTop: "26px" }}>
                            <Button
                              color="danger"
                              type="reset"
                              onClick={() => {
                                setdataObject(null);
                                setSelectedAmount(null);
                                setselectedCustomer(null);
                                setisUpdate(false);
                                setLoading("");
                                formRef.current.reset();
                              }}>
                              Reset
                            </Button>
                          </Col>
                          
                          
                        </>
                      )}
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} xl={3}>
              <Card>
                <CardBody>
                  <div className="float-end mt-2">
                    <i className="bx bx-money dashboard-icon1"></i>
                  </div>
                  <div>
                    <h4 className="mb-1 mt-1">
                      <span>
                        <i className="fas fa-rupee-sign"></i> {master?.today_amount}
                      </span>
                    </h4>
                    <p className="text-muted mb-0">Today's Recharge </p>
                  </div>
                  <p className="text-muted mt-3 mb-0">
                    <span style={{ color: "#e8418f" }} className={"me-1"}>
                      <i className="fas fa-rupee-sign"></i> {master?.total_amount}
                    </span>{" "}
                    Total Recharge
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} xl={3}>
              <Card>
                <CardBody>
                  <div className="float-end mt-2">
                    <i className="bx bx-collection dashboard-icon1"></i>
                  </div>
                  <div>
                    <h4 className="mb-1 mt-1">
                      <span>{master?.total_count}</span>
                    </h4>
                    <p className="text-muted mb-0">Total No.of Recharges </p>
                  </div>
                  <p className="text-muted mt-3 mb-0">
                    <span style={{ color: "#e8418f" }} className={"me-1"}>
                      <i className="fas fa-user"></i> {master?.tot_customers}
                    </span>{" "}
                    Recharged Customers
                  </p>
                </CardBody>
              </Card>
            </Col>
          <Col md="12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    responsive
                    id="rechargeTableId"
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={100}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* </Container> */}

        </div>
      </div>
    </React.Fragment>
  );
};
export default Executiverecharge;
