import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import {jwtDecode }from "jwt-decode";
import toastr from "toastr";
import moment from "moment";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Modal,
  Table,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import $ from "jquery";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import Select from "react-select";
import {
  apiError,
  getSectionTypes,
  getAllSectionProduct,
  addSectionProduct,
  updateSectionProduct,
  deleteSectionProduct,
} from "../../../store/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import axios from "axios";

const OfferProducts = (props) => {
  const [sectionProductTable, setSectionProductTable] = useState([]);
  const [master, setMaster] = useState({});
  const [values, setValues] = useState([
    {
      section_product_id: null,
      section_product_price: null,
      section_maximum_order_quantity: null,
      section_product_from: "",
      section_product_to: "",
      product_sku: "",
    },
  ]);
  const [sectionType, setSectionType] = useState([]);
  const [selectedSectionType, setSelectedSectionType] = useState(null);
  const [productList, setProductList] = useState([]);
  const [userId, setUserId] = useState(1);
  const [sectionProductIdTobeUpdated, setSectionProductIdTobeUpdated] =
    useState(null);
  const [sectionProductIdToBeDeleted, setSectionProductIdToBeDeleted] =
    useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const { sectionTypes } = useSelector((state) => state.sectionType);
  const {
    addingSectionProduct,
    addSectionProductResponse,
    error,
    sectionProducts,
    updateSectionProductResponse,
    deleteSectionProductResponse,
  } = useSelector((state) => state.sectionProducts);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  let dispatch = useDispatch();
  const formRef = useRef();
  useEffect(() => {
    dispatch(getSectionTypes());
    dispatch(getAllSectionProduct());
  }, []);
  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = jwtDecode(localStorage.getItem("authUser"));
      setUserId(obj.user);
      setMaster({
        ...master,
        ["auth_userid"]: obj.user,
      });
    }
  }, [userId]);
  useEffect(() => {
    var data =
      sectionTypes &&
      sectionTypes.map((section) => {
        return {
          label: section.section_name,
          value: section.section_id,
        };
      });

    setSectionType([
      {
        options: data,
      },
    ]);
  }, [sectionTypes]);
  useEffect(() => {
    let sectionProductsData = JSON.parse(JSON.stringify(sectionProducts));
    console.log("sectionProductsData: ", sectionProductsData);
    let data = [];
    sectionProductsData.map((item, index) => {
      item.x_User = item.fname;
      item.fname = "";
      if (item.User) {
        item.x_User = item.User.fname + " " + item.User.lname;
      }
      item.auth_userid = userId;
      item.id = index + 1;
      item.section_product_from1 = moment(item.section_product_from).format(
        "DD/MM/YYYY"
      );
      item.section_product_to1 = moment(item.section_product_to).format(
        "DD/MM/YYYY"
      );
      item.amount_column = (
        <div className="text-right">
          {item.section_product_price.toFixed(2)}
        </div>
      );
      item.action = (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <i
            className="far fa-edit"
            style={{
              fontSize: "1em",
              cursor: "pointer",
              marginLeft: "0.5em",
              marginRight: "0.5em",
            }}
            onClick={() => {
              toTop();
              preUpdateSectionProduct(item);
            }}
          ></i>
          <i
            className="far fa-trash-alt"
            style={{ fontSize: "1em", cursor: "pointer" }}
            onClick={() => {
              setSectionProductIdToBeDeleted(item.section_p_id);
              setConfirmDeleteAlert(true);
            }}
          ></i>
        </div>
      );
      data.push(item);
    });
    setSectionProductTable(data);
  }, [sectionProducts]);
  useEffect(() => {
    console.log("addSectionProductResponse: ", addSectionProductResponse);
    if (addSectionProductResponse.type === "success") {
      toastr.success(addSectionProductResponse.message);
    } else if (addSectionProductResponse.type === "failure") {
      toastr.error(error.data.message, addSectionProductResponse.message);
    }
  }, [addSectionProductResponse]);

  useEffect(() => {
    if (updateSectionProductResponse.type === "success") {
      setSectionProductIdTobeUpdated(null);
      toastr.success(updateSectionProductResponse.message);
      dispatch(getAllSectionProduct());
    } else if (updateSectionProductResponse.type === "failure") {
      toastr.error(error.data.message, updateSectionProductResponse.message);
    }
  }, [updateSectionProductResponse]);

  useEffect(() => {
    if (deleteSectionProductResponse.type === "success") {
      toastr.success(deleteSectionProductResponse.message);
      dispatch(getAllSectionProduct());
    } else if (deleteSectionProductResponse.type === "failure") {
      toastr.error(error.data.message, deleteSectionProductResponse.message);
    }
  }, [deleteSectionProductResponse]);

  let preUpdateSectionProduct = (item) => {
    let options = {
      label: item.section_name,
      value: item.section_id,
    };
    setSelectedSectionType(options);
    setSectionProductIdTobeUpdated(item.section_p_id);
    setMaster({
      ["section_id"]: item.section_id,
      ["auth_userid"]: item.auth_userid,
      ["section_p_id"]: item.section_p_id,
    });
    setValues([
      {
        section_product_id: item.product_id,
        section_product_price: item.section_product_price,
        section_maximum_order_quantity: item.section_maximum_order_quantity,
        section_product_from: item.section_product_from,
        section_product_to: item.section_product_to,
        product_sku: item.product_sku,
        section_product_name: item.product_name_english,
      },
    ]);
  };

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const addProduct = () => {
    //setProductList([]);
    setValues([
      ...values,
      {
        section_product_id: null,
        section_product_price: null,
        section_maximum_order_quantity: null,
        section_product_from: "",
        section_product_to: "",
        product_sku: "",
      },
    ]);
  };
  const handleSelectedSection = (selected) => {
    setSelectedSectionType(selected);
    setMaster({
      ...master,
      ["section_id"]: selected.value,
    });
  };
  const handleProductSearch = (query, index) => {
    let url = `${API_URL}section_product/search_product`;
    axios({
      method: "post",
      url: url,
      data: {
        product_name_english: query,
      },
      headers: {
        "x-access-token": accessToken,
      },
    }).then((res) => {
      let result = res.data.data;
      console.log("result: ", result);
      var data =
        res.data.data &&
        res.data.data.map((item) => {
          return {
            label: item.product_name_english,
            value: item.product_id,
            sku: item.product_sku,
          };
        });
      setProductList([
        {
          options: data,
        },
      ]);
    });
  };
  const handleChangeProduct = (selected, index) => {
    console.log("selected: ", selected);
    const list = [...values];
    list[index]["section_product_id"] = selected.value;
    list[index]["section_product_name"] = selected.label;
    list[index]["product_sku"] = selected.sku;
    setValues(list);
  };
  const handleTableInputChange = (e, index) => {
    const list = [...values];
    list[index][e.target.name] = e.target.value;
    setValues(list);
  };
  const handleValidSubmit = (e, v) => {
    if (selectedSectionType === null) {
      toastr.error("Section is required");
      return;
    }
    let valid = true;
    values.forEach((element) => {
      if (!element.section_product_name) {
        valid = false;
      } else valid = true;
    });
    console.log("valid: ", valid);
    if (valid === false) {
      toastr.error("Provide product details");
      return;
    }
    let data = {
      auth_userid: master?.auth_userid,
      section_id: master?.section_id,
      products: [...values],
    };

    sectionProductIdTobeUpdated
      ? dispatch(
          updateSectionProduct({
            auth_userid: master?.auth_userid,
            section_id: master?.section_id,
            section_p_id: master?.section_p_id,
            products: [...values],
          })
        )
      : dispatch(addSectionProduct(data));

    formRef.current.reset();
    setSelectedSectionType(null);
    setMaster({ ["auth_userid"]: userId });
    setValues([
      {
        section_product_id: null,
        section_product_price: null,
        section_maximum_order_quantity: null,
        section_product_from: "",
        section_product_to: "",
        product_sku: "",
      },
    ]);
    dispatch(getAllSectionProduct());
    // if (sectionProductIdTobeUpdated === null) 
    window.location.reload();
  };
  const handleRemove = (index) => {
    const list = [...values];
    list.splice(index, 1);
    setValues(list);
  };
  const reset = () => {
    setSelectedSectionType(null);
    setSectionProductIdTobeUpdated(null);
    setMaster({ ["auth_userid"]: userId });
    setValues([
      {
        section_product_id: null,
        section_product_price: null,
        section_maximum_order_quantity: null,
        section_product_from: "",
        section_product_to: "",
        product_sku: "",
      },
    ]);
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "From",
        field: "section_product_from1",
        sort: "asc",
        width: 400,
      },
      {
        label: "To",
        field: "section_product_to1",
        sort: "asc",
        width: 400,
      },
      {
        label: "Section",
        field: "section_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Product",
        field: "product_name_english",
        sort: "asc",
        width: 200,
      },
      {
        label: "Price",
        field: "amount_column",
        sort: "asc",
        width: 200,
      },
      {
        label: "MOQ",
        field: "section_maximum_order_quantity",
        sort: "asc",
        width: 200,
      },
      {
        label: "User",
        field: "x_User",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "disabled",
        width: 200,
      },
    ],
    rows: sectionProductTable,
  };

  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            dispatch(deleteSectionProduct(sectionProductIdToBeDeleted, userId));
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Section Products" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Section</Label>
                          <Select
                            name="section_id"
                            value={selectedSectionType}
                            onChange={(selected) => {
                              handleSelectedSection(selected);
                            }}
                            options={sectionType}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Table
                        style={{ textAlign: "center" }}
                        id="product_tale_id"
                        className="table table-bordered dataTable"
                      >
                        <TableHead>
                          <TableRow style={{ textAlign: "center" }}>
                            <TableCell
                              style={{ width: "10px", textAlign: "center" }}
                            >
                              #
                            </TableCell>
                            <TableCell
                              style={{ width: "123px", textAlign: "center" }}
                            >
                              SKU
                            </TableCell>
                            <TableCell
                              style={{ width: "250px", textAlign: "center" }}
                            >
                              Product Name
                            </TableCell>
                            <TableCell
                              style={{ width: "170px", textAlign: "center" }}
                            >
                              Section Product Price
                            </TableCell>
                            <TableCell
                              style={{ width: "90px", textAlign: "center" }}
                            >
                              Max. OQ
                            </TableCell>
                            <TableCell
                              style={{ width: "90px", textAlign: "center" }}
                            >
                              From
                            </TableCell>
                            <TableCell
                              style={{ width: "90px", textAlign: "center" }}
                            >
                              To
                            </TableCell>
                            {values.length !== 1 ? (
                              <TableCell
                                style={{ width: "10px", textAlign: "center" }}
                              >
                                Action
                              </TableCell>
                            ) : null}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {values &&
                            values.map((item, index) => (
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  {index + 1}
                                </TableCell>
                                <TableCell>
                                  <AvField
                                    onChange={(e) =>
                                      handleTableInputChange(e, index)
                                    }
                                    // validate={{ required: { value: true } }}
                                    // style={{ width: "120px" }}
                                    type="text"
                                    value={item.product_sku}
                                    placeholder="SKU"
                                    //errorMessage="Enter SKU"
                                    className="form-control"
                                    name="product_sku"
                                    id={`product_sku_${index}`}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Select
                                    onChange={(selected) =>
                                      handleChangeProduct(selected, index)
                                    }
                                    name="section_product_name"
                                    //validate={{ required: { value: true } }}
                                    // style={{ width: "280px" }}
                                    classNamePrefix="select2-selection"
                                    options={productList}
                                    value={{
                                      label: item.section_product_name,
                                      value: item.section_product_id,
                                    }}
                                    //errorMessage="Choose product"
                                    onInputChange={(input) =>
                                      handleProductSearch(input, index)
                                    }
                                    id={`validationCustom03${index}`}
                                  />
                                </TableCell>
                                <TableCell>
                                  <AvField
                                    onChange={(e) =>
                                      handleTableInputChange(e, index)
                                    }
                                    //validate={{ required: { value: true } }}
                                    // style={{ width: "200px"}
                                    type="number"
                                    value={item.section_product_price}
                                    placeholder="Product price"
                                    //errorMessage="Enter product price"
                                    className="form-control"
                                    name="section_product_price"
                                    id={`section_product_price_${index}`}
                                  />
                                </TableCell>
                                <TableCell>
                                  <AvField
                                    onChange={(e) =>
                                      handleTableInputChange(e, index)
                                    }
                                    //validate={{ required: { value: true } }}
                                    type="number"
                                    value={item.section_maximum_order_quantity}
                                    placeholder="QTY"
                                    //errorMessage="Enter quantity"
                                    className="form-control"
                                    name="section_maximum_order_quantity"
                                    id={`section_maximum_order_quantity_${index}`}
                                  />
                                </TableCell>
                                <TableCell>
                                  <AvField
                                    onChange={(e) =>
                                      handleTableInputChange(e, index)
                                    }
                                    //validate={{ required: { value: true } }}
                                    type="date"
                                    value={item.section_product_from}
                                    //errorMessage="Choose from date"
                                    className="form-control"
                                    name="section_product_from"
                                    id={`section_product_from_${index}`}
                                  />
                                </TableCell>
                                <TableCell>
                                  <AvField
                                    onChange={(e) =>
                                      handleTableInputChange(e, index)
                                    }
                                    //validate={{ required: { value: true } }}
                                    type="date"
                                    value={item.section_product_to}
                                    // placeholder=""
                                    //errorMessage="Choose to date"
                                    className="form-control"
                                    name="section_product_to"
                                    id={`section_product_to_${index}`}
                                  />
                                </TableCell>

                                {values.length !== 1 && (
                                  <i
                                    className="far fa-trash-alt"
                                    style={{
                                      fontSize: "23px",
                                      cursor: "pointer",
                                      paddingLeft: "5px",
                                      paddingTop: "19px",
                                    }}
                                    onClick={() => handleRemove(index)}
                                  ></i>
                                )}
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                      <span
                        onClick={() => addProduct()}
                        style={{ width: "190px", cursor: "pointer" }}
                      >
                        {sectionProductIdTobeUpdated ? "" : "+ Add Product"}
                      </span>
                    </Row>
                    <Row>
                      <Col md="1">
                        <div className="mt-4">
                          {sectionProductIdTobeUpdated ? (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingSectionProduct ? true : false}
                            >
                              {addingSectionProduct ? "Updating" : "Update"}
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingSectionProduct ? true : false}
                            >
                              {addingSectionProduct ? "Adding" : "Submit"}
                            </Button>
                          )}
                        </div>
                      </Col>
                      <Col md="1">
                        <div className="mt-4">
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={false}
                    disableRetreatAfterSorting={true}
                    entries={25}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {};

export default withRouter(
  connect(mapStateToProps, { apiError })(OfferProducts)
);
