import React, { useState, useEffect ,useRef} from "react";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import PropTypes from "prop-types";
import toastr from "toastr";
import {jwtDecode }from "jwt-decode";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import { MDBDataTable } from "mdbreact";
import $ from 'jquery';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Modal,
  Progress,
} from "reactstrap";

import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

import {
  getBrands,
  addBrand,
  deleteBrand,
  togglebrandActiveStatus,
  apiError,
  updateBrand,
} from "../../../store/actions";


// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

const Brands = (props) => {
  const [toggleSwitch, settoggleSwitch] = useState(true);

  const [brandObject, setBrandObject] = useState({});
  const [brandsTemp, setBrandsTemp] = useState([]);

  const [brandIdTobeUpdated, setBrandIdToBeUpdated] = useState(null);
  const [brandIdToBeDeleted, setBrandIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [userId, setUserId] = useState(1);
  const formRef = useRef();
  const [uploadProgress, setUploadProgress] = useState()
  const [selectedType, setselectedType] = useState(null);
  const [invalidtype, setinvalidtype] = useState('none');
  const [isUploadjpg, setIsUploadjpg] = useState(false);
  const [isUploadwebp, setIsUploadwebp] = useState(false);
  const [brandFileName,setBrandFileName] = useState(null);
  let premiumOptionsData =[{label:"Yes",value:1},{label:"No",value:0}];

  const premiumOptionsGroup = [
    {
      options: premiumOptionsData,
    },
  ];
  const {
    brands,
    addingBrand,
    addBrandResponse,
    deleteBrandResponse,
    updateBrandResponse,
    statusBrandResponse,
    error,
  } = useSelector((state) => state.brands);

  const dispatch = useDispatch();
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    }); 
  };
  useEffect(() => {
    dispatch(getBrands());
  }, []);

  useEffect(() => { 
    if (localStorage.getItem("authUser")) {
      const obj = jwtDecode(localStorage.getItem("authUser"));

      setUserId(obj.user);
      setBrandObject({
        ["auth_userid"]: userId,
      });
      console.log(userId);
    }
  }, [props.success, props]);

  useEffect(() => {
    if (addBrandResponse.type === "success") {
      toastr.success(addBrandResponse.message);
      setBrandObject({})
    } else if (addBrandResponse.type === "failure") {
      toastr.error(error.data.message, addBrandResponse.message);
    }
  }, [addBrandResponse]);

  useEffect(() => {
    if (deleteBrandResponse.type === "success") {
      toastr.success(deleteBrandResponse.message);
    } else if (deleteBrandResponse.type === "failure") {
      toastr.error(error.data.message, deleteBrandResponse.message);
    }
  }, [deleteBrandResponse]);

  useEffect(() => {
    if (statusBrandResponse.type === "success") {
      toastr.success(statusBrandResponse.message);
    } else if (statusBrandResponse.type === "failure") {
      toastr.error(error.data.message, statusBrandResponse.message);
    }
  }, [statusBrandResponse]);
const clearBrandDetails = () => {
  // setBrandObject({})
  // setBrandObject({
  //   ...brandObject,
  //   [`brand_name`]: " ",
  //   [`brand_image`]: " ",
  //   [`brand_seo_title`]: " ",
  //   [`brand_seo_keys`]: " ",
  //   [`brand_seo_desc`]: " ",
  // });
}
  useEffect(() => {
    if (updateBrandResponse.type === "success") {
      setShowModal(false);
      setBrandIdToBeUpdated(null);
      // setPasswordObject({});
      setBrandIdToBeUpdated(null);
      // setBrandObject({})
      clearBrandDetails()

      console.log("=====UPDATED BRAND======");
      toastr.success(updateBrandResponse.message);
    } else if (updateBrandResponse.type === "failure") {
      toastr.error(error.data.message, updateBrandResponse.message);
    }
  }, [updateBrandResponse]);

  let preupdateBrand = (item) => {

    if (item.brand_premium_status===1) {
      var types = {
        label: "Yes",
        value: 1,
      };
    }
    if (item.brand_premium_status===0) {
      var types = {
        label: "No",
        value: 0,
      };
    }
    if(item.brand_image!='')
    {
      var pre_image = item.brand_image;
      pre_image = pre_image.split('.');
      setIsUploadjpg(true);
      setBrandFileName(pre_image[0]);
    }
    handleSelectedTypes(types);
    setBrandIdToBeUpdated(item.brand_id);
    setBrandObject(item);
    // setBrandObject({
    //   ...item,
    //   [`brand_image`]: item.brand_image
    //     ? item.brand_image
    //     : "",
    // });

    if (item.brand_image &&
      item.brand_image.length > 2) {
      if (item.brand_image.indexOf('uploads/brand/brand_150x75/') > -1) {   
        console.log(item.brand_image);
        console.log(item.brand_image.slice(item.brand_image.indexOf('public/uploads/brand/brand_150x75/') + ((API_URL.length-1)+12)))   ;      
        setBrandObject({
          ...item,
          [`brand_image`]: item.brand_image.slice(item.brand_image.indexOf('public/uploads/brand/brand_150x75/') + ((API_URL.length-1)+12))
        });
      
      }else{
      
        setBrandObject({
          ...item,
          [`brand_image`]: item.brand_image,
        });
      }
    }else{
      setBrandObject({
        ...item,
        [`brand_image`]: "",
      });
    }


    if (item.brand_image_webp &&
      item.brand_image_webp.length > 2) {
      if (item.brand_image_webp.indexOf('uploads/brand/brand_150x75/') > -1) {   
        console.log(item.brand_image_webp);
        console.log(item.brand_image_webp.slice(item.brand_image_webp.indexOf('public/uploads/brand/brand_150x75/') + ((API_URL.length-1)+10)))   ;      
        setBrandObject({
          ...item,
          [`brand_image_webp`]: item.brand_image_webp.slice(item.brand_image_webp.indexOf('public/uploads/brand/brand_150x75/') + ((API_URL.length-1)+10))
        });
      
      }else{
      
        setBrandObject({
          ...item,
          [`brand_image_webp`]: item.brand_image_webp,
        });
      }
    }else{
      setBrandObject({
        ...item,
        [`brand_image_webp`]: "",
      });
    }

   
  };

  useEffect(() => {
    console.log(brands);
    let unitsDuplicate = JSON.parse(JSON.stringify(brands));
    console.log(unitsDuplicate);
    let unitData = [];
    unitsDuplicate.map((item, index) => {
    //  item.fname = item.User.fname + " " + item.User.lname;
    item.fname = "";
    if(item.User!=null)
      {
        item.fname = item.User.fname + ' ' + item.User.lname;
      }
      item.auth_userid = userId;

      item.action = (
        <div
          style={{
            textAlign: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            {/* //   <i
                        className="uil-key-skeleton"
                        style={{ fontSize: "1.3em", cursor: "pointer" }}
                        onClick={() => {
                          preUpdateUserPassword(item);
                        }}
                      ></i> */}{" "}
            <i
              className="far fa-edit"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.5em",
                marginRight: "0.5em",
              }}
              onClick={() => {
                toTop();
                preupdateBrand(item);
              }}
            ></i>{" "}
            <i
              className="far fa-trash-alt"
              style={{
                fontSize: "1em",
                cursor: "pointer",
              }}
              onClick={() => {
                setBrandIdToBeDeleted(item.brand_id);
                setConfirmDeleteAlert(true);
              }}
            ></i>{" "}
          </div>{" "}
        </div>
      );
      item.id = index + 1;
      item.brand_images='';
      if (item.brand_image != "") {
        item.brand_images = (
          <div
            style={{
              textAlign: "center",
            }}
          >
            <img
              src={`${API_URL}public/uploads/brand/brand_150x75/${item.brand_image}`}
              height="50"
              width="50"
            />
          </div>
        );
      }

      item.brand_is_active = (
        <div
          style={{
            textAlign: "center",
            width: "100%",
          }}
        >
          <div
            class="form-check form-switch mb-3"
            style={{
              textAlign: "center",
              marginLeft: "40%",
            }}
          >
            <input
              type="checkbox"
              class="form-check-input"
              id="customSwitch2"
              checked={(item.brand_is_active==0)?true:false}
              onClick={(e) => {
                dispatch(togglebrandActiveStatus(item.brand_id, userId));
                settoggleSwitch(!toggleSwitch);
              }}
            />{" "}
          </div>{" "}
        </div>
      );

      unitData.push(item);
    });
    setBrandsTemp(unitData);
  }, [brands]);

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 10,
      },

      {
        label: "English",
        field: "brand_name_english",
        sort: "asc",
        width: 70,
      },
      {
        label: "Malayalam",
        field: "brand_name_malayalam",
        sort: "asc",
        width: 70,
      },
      {
        label: "Image",
        field: "brand_images",
        sort: "asc",
        width: 70,
      },
      {
        label: "Status",
        field: "brand_is_active",
        sort: "asc",
        width: 70,
      },

      {
        label: "User",
        field: "fname",
        sort: "asc",
        width: 100,
      },

      {
        label: "Action",
        field: "action",
        sort: "disabled",
        width: 100,
      },
    ],
    rows: brandsTemp,
  };

  const deleteBrandIcon = () => {
   
    setIsUploadjpg(false);
    setBrandObject({
      ...brandObject,
      [`brand_image`]: "",
    });
  };
  const deleteBrandIconwebp = () => {
    
    setIsUploadwebp(false);
    setBrandObject({
      ...brandObject,
      [`brand_image_webp`]: "",
    });
  };
  const make_file_id = (e) => {
    var length           = 16;
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
 charactersLength));
   }
   result = result +'_brand';
   setBrandFileName(result);
   return result;
}
  const uploadBrandIcon = (e) => {
 //check extension

    if(!isUploadwebp)
    {
     var barnd_file_name=make_file_id();
    }
    else
    {
      var barnd_file_name = brandFileName;
    }
    setIsUploadjpg(true);
    const fd = new FormData();
    fd.append("image", e.target.files[0]);
    axios
      .post(`${API_URL}brands/uploadBrandSingleImage?name=${barnd_file_name}`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
          'x-access-token': accessToken,
        },
        onUploadProgress: data => {
          //Set the progress value to show the progress bar          
          setUploadProgress(Math.round((100 * data.loaded) / data.total))
        }
      })
      .then((response) => {
        if (response.data.status === "success") {
          setBrandObject({
            ...brandObject,
            [`brand_image`]: response.data.file.filename,
          });
        } else {
          deleteBrandIcon();
          toastr.error(response.data.message);
        }
      }).catch(err=>{
        deleteBrandIcon();
        toastr.error("File extension not supported");
      });
  };

  const uploadBrandIconwebp = (e) => {

    var fileInput = 
    document.getElementById('brand_image_webp');
  
var filePath = fileInput.value;

// Allowing file type
//var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
var allowedExtensions = /(\.webp)$/i;
        
  
if (!allowedExtensions.exec(filePath)) {
    toastr.error("File extension not supported")
    return false;
} 

    if(!isUploadjpg)
    {
     var barnd_file_name=make_file_id();
    }
    else
    {
      var barnd_file_name = brandFileName;
    }
    setIsUploadwebp(true);
    const fd = new FormData();
    fd.append("image", e.target.files[0]);
    axios
      .post(`${API_URL}brands/uploadBrandSingleImageWebp?name=${barnd_file_name}`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
          'x-access-token': accessToken,
        },
        onUploadProgress: data => {
          //Set the progress value to show the progress bar          
          setUploadProgress(Math.round((100 * data.loaded) / data.total))
        }
      })
      .then((response) => {
        if (response.data.status === "success") {
          setBrandObject({
            ...brandObject,
            [`brand_image_webp`]: response.data.file.filename,
          });
        } else {
          deleteBrandIconwebp();
          toastr.error(response.data.message);
        }
      }).catch(err=>{
        deleteBrandIconwebp();
        toastr.error("File extension not supported");
      });;
  };

  let handleChangeInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setBrandObject({
      ...brandObject,
      [name]: value,
    });
  };
  function handleSelectedTypes(e) {
    console.log(e);
    let name = 'brand_premium_status';
    let value = e.value;
    if(e.value==0||e.value)
    {
      $("#brand_premium_status").attr('class',"select2-selection");
      setinvalidtype('none');
    }
    setselectedType(e);
    setBrandObject({
      ...brandObject,
      [name]: value,
    });
  }
  let onChangeHandler = (event) => {
    //console.log(event.target.files[0])
    //setSelectedFile(event.target.files[0]);
    //brandObject.push(image:event.target.files[0])
    //setBrandObject({...brandObject, file:event.target.files[0]})
  };

  const handleValidSubmitUnit = (event, values) => {
    if(selectedType==null)
    {
        setinvalidtype('block');
        $("#brand_premium_status").attr('class',"select2-selection border border-danger");
      return;
    }
    // var formData = new FormData();
    // var imagefile = document.querySelector('#brand_image');
    // formData.append('brand_name', 'test');
    // formData.append('file', imagefile.files[0]);

    // console.log(Object.keys(formData));
    console.log("===brandObject======",brandObject);
    if (brandObject.brand_image &&
      brandObject.brand_image.length > 2) {
      if (brandObject.brand_image.indexOf('uploads/brand/brand_150x75/') > -1) {
        console.log("====exist uploads in name===");
        // brandObject.brand_image.slice(brandObject.brand_image.indexOf('uploads/brand-icons/') + 23)
        setBrandObject({
          ...brandObject,
          [`brand_image`]:  brandObject.brand_image.slice(brandObject.brand_image.indexOf('uploads/brand/brand_150x75/') + 23),
        });
        console.log("================================brandObject.brand_image after slice=======",brandObject.brand_image)
      }else{
        console.log("====dose not exist uploads in name===");
      }
    }
    console.log("===brandObject2222======",brandObject);

    brandIdTobeUpdated
      ? dispatch(updateBrand(brandObject))
      : dispatch(addBrand(brandObject));

      formRef.current.reset();
    setselectedType(null);
    setBrandObject({ ['auth_userid']: userId });
    setIsUploadwebp(false);
    setIsUploadjpg(false);
  };

  //   let handleChangeImageUpload =(event) => {
  // setBrandObject({...brandObject, unitlogo:event.target.files[0]})
  //   }

  return (
    <React.Fragment>
      {" "}
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            dispatch(deleteBrand(brandIdToBeDeleted, userId));
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it ?
        </SweetAlert>
      ) : null}{" "}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Brands" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                   ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmitUnit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                          Brand Name{" "}
                          </Label>
                          <AvField
                            name="brand_name_english"
                            value={brandObject.brand_name_english}
                            placeholder="Brand Name English"
                            type="text"
                            errorMessage="Enter Brand Name English"
                            className="form-control"
                            validate={{
                              required: {
                                value: true,
                              },
                            }}
                            id="validationCustom01"
                            onChange={handleChangeInput}
                          />{" "}
                        </div>{" "}
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                          Brand Name Malayalam{" "}
                          </Label>{" "}
                          <AvField
                            name="brand_name_malayalam"
                            value={brandObject.brand_name_malayalam}
                            placeholder="Brand Name Malayalam"
                            type="text"
                            errorMessage="Enter Brand Name Malayalam"
                            className="form-control"
                            validate={{
                              required: {
                                value: true,
                              },
                            }}
                            id="validationCustom03"
                            onChange={handleChangeInput}
                          />{" "}
                        </div>{" "}
                      </Col>{" "}
                     
                     
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">
                            {" "}
                            Brand PNG Image (250x125){" "}
                          </Label>{" "}
                          {brandObject.brand_image &&
                            brandObject.brand_image.length > 2 ? (
                            <div div className="img-wraps">
                              {" "}
                              {brandObject.brand_image &&
                                brandObject.brand_image.startsWith(
                                  "http"
                                ) ? (
                                <img
                                  className="img-thumbnail"
                                  alt=""
                                  width="200"
                                  src={`${brandObject.brand_image}`}
                                />
                              ) : (
                                <img
                                  className="img-thumbnail"
                                  alt=""
                                  width="200"
                                  src={`${API_URL}public/uploads/brand/brand_150x75/${brandObject.brand_image}`}
                                />
                              )}
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={deleteBrandIcon}
                                style={{ width: "200px" }}
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <AvField
                              name="brand_image"
                              id="brand_image"
                              type="file"
                              value=""
                              errorMessage="Upload jpg Brand Image"
                              className="form-control"
                              validate={{
                                required: {
                                  value: true,
                                },
                              }}
                              onChange={uploadBrandIcon}
                            />
                          )
                          }

                          {uploadProgress && uploadProgress < 100 && <div className="mt-4"> <Progress color="primary" value={uploadProgress}>
                            Uploading  {uploadProgress}%
                          </Progress>
                          </div>}

                        </div>
                      </Col>


                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">
                            {" "}
                            Brand Webp Image (250x125){" "}
                          </Label>{" "}
                          {brandObject.brand_image_webp &&
                            brandObject.brand_image_webp.length > 2 ? (
                            <div div className="img-wraps">
                              {" "}
                              {brandObject.brand_image_webp &&
                                brandObject.brand_image_webp.startsWith(
                                  "http"
                                ) ? (
                                <img
                                  className="img-thumbnail"
                                  alt=""
                                  width="200"
                                  src={`${brandObject.brand_image_webp}`}
                                />
                              ) : (
                                <img
                                  className="img-thumbnail"
                                  alt=""
                                  width="200"
                                  src={`${API_URL}public/uploads/brand/brand_150x75/${brandObject.brand_image_webp}`}
                                />
                              )}
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={deleteBrandIconwebp}
                                style={{ width: "200px" }}
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <AvField
                              name="brand_image_webp"
                              id="brand_image_webp"
                              type="file"
                              errorMessage="Upload webp Brand Image"
                              className="form-control"
                              validate={{
                                required: {
                                  value: true,
                                },
                              }}
                              // onChange={onChangeHandler}
                              onChange={uploadBrandIconwebp}
                            />
                          )
                          }

                          {uploadProgress && uploadProgress < 100 && <div className="mt-4"> <Progress color="primary" value={uploadProgress}>
                            Uploading  {uploadProgress}%
                          </Progress>
                          </div>}

                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                          Brand Priority{" "}
                          </Label>{" "}
                          <AvField
                            name="brand_priority"
                            value={brandObject.brand_priority}
                            placeholder="Brand Priority"
                            type="text"
                            errorMessage="Enter Brand Priority"
                            className="form-control"
                            validate={{
                              required: {
                                value: true,
                              },
                              pattern: {value: '^[0-9]+$', errorMessage: 'Only numbers are allowed'}
                            }}
                            id="validationCustom03"
                            onChange={handleChangeInput}
                          />{" "}
                        </div>{" "}
                      </Col>{" "}
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Premium Status
                          </Label>

                          <Select
                            name="brand_premium_status"
                            id="brand_premium_status"
                            value={selectedType}
                            onChange={(value) => {
                              handleSelectedTypes(value);
                            }}
                            options={premiumOptionsGroup}
                            classNamePrefix="select2-selection"
                          />
                          <div style={{ display:invalidtype,Width: '100%',marginTop:'0.25rem',color: '#f46a6a',fontSize:'87.5%'}}>Select Premium Status</div>
                        
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mt-4">
                          {brandIdTobeUpdated ? (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingBrand ? true : false}
                            >
                              {addingBrand ? "Updating" : "Update"}
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingBrand ? true : false}
                            >
                              {addingBrand ? "Adding" : "Submit"}
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable responsive bordered data={data} disableRetreatAfterSorting={true} entries={25} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  // const { error } = state.Users;
  // return { error };
};

export default withRouter(connect(mapStateToProps, { apiError })(Brands));

Brands.propTypes = {
  error: PropTypes.any,
  brands: PropTypes.array,
};
