import React, { useEffect, useState } from "react";
import { Container, Row, Col, CardBody, Card } from "reactstrap";
import { withRouter, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import toastr from "toastr";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import "./dashboard.scss";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {jwtDecode} from 'jwt-decode';
import moment from "moment";

const Dashboard = (props) => {
  const decoded = jwtDecode(localStorage.getItem('authUser'));
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const loggedPrivilege = decoded.Privilege;
  const [master, setMaster] = useState(null);
  const history = useHistory();
  const getData = () => {
    axios
      .get(`${API_URL}dashboard`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        setMaster({
          ...master,
          data: res.data
        })
      });
  }
  useEffect(() => {
    getData();
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="StarLee" breadcrumbItem="Dashboard" />
          <Row>
            {
              (loggedPrivilege == 1 || loggedPrivilege == 3) ? (
                <Col md={6} xl={3}>
                  <Card style={{ cursor: 'pointer' }}
                    onClick={() => {
                      history.push('/recharge-log');
                    }}
                  >
                    <CardBody>
                      <div className="float-end mt-2">
                        <i className="mdi mdi-account-cash dashboard-icon1"></i>
                      </div>
                      <div>
                        <h4 className="mb-1 mt-1"><span><i className="fas fa-rupee-sign"></i> {master?.data?.tot_amount}</span></h4>
                        <p className="text-muted mb-0">Today's Recharge </p>
                      </div>
                      <p className="text-muted mt-3 mb-0"><span style={{ color: "#e8418f" }} className={"me-1"}><i className="mdi mdi-account-cash"></i> {master?.data?.tot_customers}</span>{" "}Recharged Customers
                      </p>

                    </CardBody>
                  </Card>
                </Col>
              ) : null
            }

            <Col md={6} xl={3}>
              <Card style={{ cursor: 'pointer' }}
                onClick={() => {
                  history.push('/view-order');
                }}
              >
                <CardBody>
                  <div className="float-end mt-2">
                    <i className="mdi mdi-cart dashboard-icon1" ></i>
                  </div>
                  <div>
                    <h4 className="mb-1 mt-1"><span>{master?.data?.tot_online_today}</span></h4>
                    <p className="text-muted mb-0">Today's Online Orders </p>
                  </div>
                  <p className="text-muted mt-3 mb-0"><span style={{ color: "#e8418f" }} className={"me-1"}><i className="mdi mdi-cart"></i> {master?.data?.tot_online}</span>{" "}Total Online Orders
                  </p>

                </CardBody>
              </Card>
            </Col>
            <Col md={6} xl={3}>
              <Card style={{ cursor: 'pointer' }}
                onClick={() => {
                  history.push('/offline-orders');
                }}
              >
                <CardBody>
                  <div className="float-end mt-2">
                    <i className="mdi mdi-shopping dashboard-icon1"></i>
                  </div>
                  <div>
                    <h4 className="mb-1 mt-1"><span>{master?.data?.tot_offline_today}</span></h4>
                    <p className="text-muted mb-0">Today's Offline Orders </p>
                  </div>
                  <p className="text-muted mt-3 mb-0"><span style={{ color: "#e8418f" }} className={"me-1"}><i className="mdi mdi-shopping"></i> {master?.data?.tot_offline}</span>{" "}Total Offline Orders
                  </p>

                </CardBody>
              </Card>
            </Col>

            <Col md={6} xl={3}>
              <Card style={{ cursor: 'pointer' }}
                onClick={() => {
                  history.push('/customer');
                }}
              >
                <CardBody>
                  <div className="float-end mt-2">
                    <i className="mdi mdi-account-group dashboard-icon1"></i>
                  </div>
                  <div>
                    <h4 className="mb-1 mt-1"><span>{master?.data?.tot_customer}</span></h4>
                    <p className="text-muted mb-0">Total Customers </p>
                  </div>
                  <p className="text-muted mt-3 mb-0"><span style={{ color: "#e8418f" }} className={"me-1"}><i className="fas fa-users"></i> {master?.data?.today_customers}</span>{" "}Today's Registrtaions
                  </p>

                </CardBody>
              </Card>
            </Col>
            <Col md={6} xl={3}>
              <Card style={{ cursor: 'pointer' }}
                onClick={() => {
                  history.push('/manage-shops');
                }}
              >
                <CardBody>
                  <div className="float-end mt-2">
                    <i className="mdi mdi-office-building dashboard-icon1"></i>
                  </div>
                  <div>
                    <h4 className="mb-1 mt-1"><span>{master?.data?.tot_shops}</span></h4>
                    <p className="text-muted mb-0">Total Shops </p>
                  </div>
                  <p className="text-muted mt-3 mb-0"><span style={{ color: "#e8418f" }} className={"me-1"}><i className="fas fa-user"></i> {master?.data?.tot_shop_users}</span>{" "}Staffs
                  </p>

                </CardBody>
              </Card>
            </Col>
            {
              ((loggedPrivilege == 1 || loggedPrivilege == 3) && master?.data?.latest_buyback) ? (
                <Col md={6} xl={3}>
                  <Card>
                    <CardBody>
                      <div className="float-end mt-2">
                        <i className="mdi mdi-flash-circle dashboard-icon1"></i>
                      </div>
                      <div>
                        <h4 className="mb-1 mt-1"><span>{master?.data?.latest_buyback?.customer_name}</span></h4>
                        <p className="text-muted mb-0">Recent Buyback </p>
                      </div>
                      <p className="text-muted mt-3 mb-0">On <span style={{ color: "#e8418f" }} className={"me-1"}>{moment(master?.data?.latest_buyback?.point_created_at).format("DD/MM/YYYY")}</span>{" "}Bill No: <span style={{ color: "#e8418f" }} className={"me-1"}>{master?.data?.latest_buyback?.point_recharge_id}</span>
                      </p>

                    </CardBody>
                  </Card>
                </Col>
              ) : null
            }
            {
              ((loggedPrivilege == 1 || loggedPrivilege == 3) && master?.data?.latest_jio) ? (
                <Col md={6} xl={3}>
                  <Card>
                    <CardBody>
                      <div className="float-end mt-2">
                        <i className="bx bxs-offer text-white dashboard-icon1"></i>
                      </div>
                      <div>
                        <h4 className="mb-1 mt-1"><span>{master?.data?.latest_jio?.customer_name}</span></h4>
                        <p className="text-muted mb-0">Recent Jio Offer </p>
                      </div>
                      <p className="text-muted mt-3 mb-0">On <span style={{ color: "#e8418f" }} className={"me-1"}>{moment(master?.data?.latest_jio?.point_created_at).format("DD/MM/YYYY")}</span>{" "}Bill No: <span style={{ color: "#e8418f" }} className={"me-1"}>{master?.data?.latest_jio?.point_recharge_id}</span>
                      </p>

                    </CardBody>
                  </Card>
                </Col>
              ) : null
            }

          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
