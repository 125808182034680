import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import {jwtDecode }from "jwt-decode";
import toastr from "toastr";
import axios from "axios";
import $ from "jquery";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap";
import Select from "react-select";
import {
  apiError,
  getIncomeExpenseAccType,
  getAllFundSource,
  addIncomeExpense,
  getAllIncomeExpenses,
  getUsers,
} from "../../../store/actions";
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { getDateTime, getDate } from "../../../helpers/globalFunctions";
import moment from "moment";

const IncomeExpense = (props) => {
  const [incomeExpenseList, setIncomeExpenseList] = useState([]);
  const [accountType, setAccountType] = useState([]);
  const [accountDetailType, setAccountDetailType] = useState([]);
  const [selectedAccountType, setSelectedAccountType] = useState(null);
  const [selectedAccountDetailType, setSelectedAccountDetailType] =
    useState(null);
  const [chartOfAccount, setChartOfAccount] = useState([]);
  const [selectedChartOfAccount, setSelectedChartOfAccont] = useState(null);
  const [fundSourceOptions, setFundSourceOptions] = useState([]);
  const [selectedFundSource, setSelectedFundSource] = useState(null);
  const [master, setMaster] = useState({
    income_expense_date: getDate(new Date()),
    income_expense_time: moment(getDateTime(new Date())).format("hh:mm a"),
  });
  const [userId, setUserId] = useState(1);
  const [searchData, setSearchData] = useState({});
  const [selectedChartOfAccForSearch, setSelectedChartOfAccForSearch] =
    useState(null);
  const [accDetailTypeForSearch, setAccDetailTypeForSearch] = useState(null);
  const [accTypeForSearch, setAccTypeForSearch] = useState(null);
  const [accountDetailTypeSearch, setAccountDetailTypeSearch] = useState([]);
  const [chartOfAccountSearch, setChartOfAccountSearch] = useState([]);
  const [user, setUser] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  const dispatch = useDispatch();
  const formRef = useRef();

  const {
    incomeExpenseAccTypes,
    incomeExpenses,
    addIncomeExpenseResponse,
    addingIncomeExpense,
    error,
  } = useSelector((state) => state.incomeExpenses);

  useEffect(() => {
    dispatch(getIncomeExpenseAccType());
    dispatch(getAllFundSource());
    dispatch(getAllIncomeExpenses());
    dispatch(getUsers());
  }, []);

  const { fundSource } = useSelector((state) => state.fundTransfers);
  const { users } = useSelector((state) => state.Users);
  useEffect(() => {
    var data =
      incomeExpenseAccTypes &&
      incomeExpenseAccTypes.map((item) => {
        return {
          label: item.acc_type_name,
          value: item.acc_type_id,
        };
      });

    setAccountType([
      {
        options: data,
      },
    ]);
  }, [incomeExpenseAccTypes]);

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = jwtDecode(localStorage.getItem("authUser"));
      setUserId(obj.user);
      setMaster({
        ...master,
        ["auth_userid"]: obj.user,
      });
    }
  }, [userId]);

  useEffect(() => {
    var data =
      fundSource &&
      fundSource.map((item) => {
        return {
          label: item.acc_chart_name,
          value: item.acc_chart_id,
        };
      });

    setFundSourceOptions([
      {
        options: data,
      },
    ]);
  }, [fundSource]);

  useEffect(() => {
    var data =
      users &&
      users.map((user) => {
        return {
          label: user.fname,
          value: user.id,
        };
      });

    setUser([
      {
        options: data,
      },
    ]);
  }, [users]);

  useEffect(() => {
    let incomeExpenseData = JSON.parse(JSON.stringify(incomeExpenses));

    let data = [];
    incomeExpenseData.map((item, index) => {
      item.auth_userid = userId;
      item.id = index + 1;
      item.income_expense_amount1 = (
        <div className="text-right">
          {item.income_expense_amount.toFixed(2)}
        </div>
      );
      item.income_expense_total1 = (
        <div className="text-right">{item.income_expense_total.toFixed(2)}</div>
      );
      item.income_expense_date1 = moment(item.income_expense_date).format(
        "DD/MM/YYYY"
      );
      data.push(item);
    });
    setIncomeExpenseList(data);
  }, [incomeExpenses]);

  useEffect(() => {
    if (addIncomeExpenseResponse.type === "success") {
      toastr.success(addIncomeExpenseResponse.message);
    } else if (addIncomeExpenseResponse.type === "failure") {
      toastr.error(error.data.message, addIncomeExpenseResponse.message);
    }
  }, [addIncomeExpenseResponse]);

  const handleSelectedAccDetailType = (selected) => {
    setSelectedAccountDetailType(selected);
    setSelectedChartOfAccont(null);
    setMaster({
      ...master,
      ["income_expense_subhead"]: selected.value,
    });
    getChartOfAccount(selected.value, "fromAccDetailType");
  };
  const handleSelectedAccType = (selected) => {
    setSelectedAccountType(selected);
    setSelectedAccountDetailType(null);
    setSelectedChartOfAccont(null);
    setChartOfAccount([]);
    setMaster({
      ...master,
      ["income_expense_head"]: selected.value,
    });
    getDetailType(selected.value, "fromAccType");
  };
  const getDetailType = (type, name) => {
    var url = `${API_URL}account_detail/options` + "?acc_type_id=" + type;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;

        let data =
          result &&
          result.map((item) => {
            return {
              label: item.acc_detail_name,
              value: item.acc_detail_id,
            };
          });
        if (name === "fromAccType") {
          setAccountDetailType([
            {
              options: data,
            },
          ]);
        } else {
          setAccountDetailTypeSearch([
            {
              options: data,
            },
          ]);
        }
      });
  };
  const getChartOfAccount = (type, name) => {
    var url = `${API_URL}chart_of_account/options` + "?acc_detail_id=" + type;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        console.log("result: ", result);
        let data =
          result &&
          result.map((item) => {
            return {
              label: item.acc_chart_name,
              value: item.acc_chart_id,
            };
          });
        if (name === "fromAccDetailType") {
          setChartOfAccount([
            {
              options: data,
            },
          ]);
        } else {
          setChartOfAccountSearch([
            {
              options: data,
            },
          ]);
        }
      });
  };
  const handleSelectedChartOfAccount = (selected) => {
    setSelectedChartOfAccont(selected);
    setMaster({
      ...master,
      ["income_expense_chartofaccounts"]: selected.value,
    });
  };
  const handleSelectedFundSource = (selected) => {
    setSelectedFundSource(selected);
    setMaster({
      ...master,
      ["income_expense_fundsource"]: selected.value,
    });
  };

  const handleDate = (e) => {
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeInput = (e) => {
    console.log("master: ", master);
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
  };
  const onAmountChange = (e) => {
    let amount = Number(e.target.value);
    let tax = Number(
      master?.income_expense_gst ? master.income_expense_gst : 0
    );
    let Total = parseFloat((amount * tax) / 100).toFixed(2);
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
      ["income_expense_total"]: Total,
    });
  };
  const onGstChange = (e) => {
    let amount = Number(
      master?.income_expense_amount ? master.income_expense_amount : 0
    );
    let tax = Number(e.target.value);

    let Total = parseFloat((amount * tax) / 100).toFixed(2);
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
      ["income_expense_total"]: Total,
    });
  };
  const handleValidSubmit = (e, v) => {
    if (selectedAccountType === null) {
      toastr.error("Account type is required");
      return;
    }
    if (selectedAccountDetailType === null) {
      toastr.error("Account detail type is required");
      return;
    }
    if (selectedChartOfAccount === null) {
      toastr.error("Chart of account is required");
      return;
    }
    if (selectedFundSource === null) {
      toastr.error("Fund source is required");
      return;
    }
    dispatch(addIncomeExpense(master));
    formRef.current.reset();
    setSelectedFundSource(null);
    setSelectedChartOfAccont(null);
    setSelectedAccountType(null);
    setSelectedAccountDetailType(null);
    setMaster({
      ["auth_userid"]: userId,
      ["income_expense_date"]: getDate(new Date()),
      ["income_expense_time"]: moment(getDateTime(new Date())).format(
        "hh:mm a"
      ),
    });
    dispatch(getAllIncomeExpenses());
  };
  const reset = () => {
    setMaster({
      ["auth_userid"]: userId,
      ["income_expense_date"]: "",
      ["income_expense_time"]: "",
    });
    setSelectedFundSource(null);
    setSelectedChartOfAccont(null);
    setSelectedAccountType(null);
    setSelectedAccountDetailType(null);
  };

  const handleSelectedForSearch = (selected, name) => {
    console.log("searchData: ", searchData);
    let date1 = searchData?.from_date ? searchData.from_date : "";
    let date2 = searchData?.to_date ? searchData.to_date : "";
    let acc_type = searchData?.income_expense_head
      ? searchData.income_expense_head
      : "";
    let acc_detail = searchData?.income_expense_subhead
      ? searchData.income_expense_subhead
      : "";
    let user_id = searchData?.income_expense_staff
      ? searchData.income_expense_staff
      : "";
    let acc_chart = searchData?.income_expense_chartofaccounts
      ? searchData.income_expense_chartofaccounts
      : "";
    switch (name) {
      case "chart":
        setSelectedChartOfAccForSearch(selected);
        setSearchData({
          ...searchData,
          ["income_expense_chartofaccounts"]: selected.value,
        });
        handleTableData(
          date1,
          date2,
          acc_type,
          acc_detail,
          selected.value,
          user_id
        );
        break;
      case "accDetailType":
        setAccDetailTypeForSearch(selected);
        setSelectedChartOfAccForSearch(null);
        setSearchData({
          ...searchData,
          ["income_expense_subhead"]: selected.value,
        });
        getChartOfAccount(selected.value, "fromAccDetailTypeSearch");
        handleTableData(date1, date2, acc_type, selected.value, "", user_id);
        break;
      case "accType":
        setAccTypeForSearch(selected);
        setAccDetailTypeForSearch(null);
        setSelectedChartOfAccForSearch(null);
        setChartOfAccountSearch([]);
        setSearchData({
          ...searchData,
          ["income_expense_head"]: selected.value,
        });
        getDetailType(selected.value, "fromAccTypeSearch");
        handleTableData(date1, date2, selected.value, "", "", user_id);
        break;
      case "staff":
        setSelectedUser(selected);
        setSearchData({
          ...searchData,
          ["income_expense_staff"]: selected.value,
        });
        handleTableData(
          date1,
          date2,
          acc_type,
          acc_detail,
          acc_chart,
          selected.value
        );
        break;
      default:
        break;
    }
  };
  const handleDateSearch = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();
    let acc_type = searchData?.income_expense_head
      ? searchData.income_expense_head
      : "";
    let acc_detail = searchData?.income_expense_subhead
      ? searchData.income_expense_subhead
      : "";
    let user_id = searchData?.income_expense_staff
      ? searchData.income_expense_staff
      : "";
    let acc_chart = searchData?.income_expense_chartofaccounts
      ? searchData.income_expense_chartofaccounts
      : "";
    setSearchData({
      ...searchData,
      [e.target.name]: e.target.value,
    });

    handleTableData(date1, date2, acc_type, acc_detail, acc_chart, user_id);
  };
  const handleTableData = (
    from_date = "",
    to_date = "",
    acc_type = "",
    acc_detail = "",
    acc_chart = "",
    user_id= ""
  ) => {
    console.log("acc_type: ", acc_type);
    console.log("acc_detail: ", acc_detail);
    console.log("acc_chart: ", acc_chart);
    console.log("user_id: ", user_id);
    from_date = from_date?from_date:"";
    to_date = to_date?to_date:"";
    console.log('to_date: ', to_date);
    console.log('from_date: ', from_date);
    var url = `${API_URL}income_expense`;

    url =
      url +
      "?from_date=" +
      from_date +
      "&to_date=" +
      to_date +
      "&acc_type=" +
      acc_type +
      "&acc_detail=" +
      acc_detail +
      "&acc_chart=" +
      acc_chart +
      "&user_id=" +
      user_id;
   
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let Data = [];
        result.map((item, index) => {
          item.id = index + 1;
          item.income_expense_amount1 = (
            <div className="text-right">
              {item.income_expense_amount.toFixed(2)}
            </div>
          );
          item.income_expense_total1 = (
            <div className="text-right">
              {item.income_expense_total.toFixed(2)}
            </div>
          );
          item.income_expense_date1 = moment(item.income_expense_date).format(
            "DD/MM/YYYY"
          );
          Data.push(item);
        });
        setIncomeExpenseList(Data);
      });
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "income_expense_date1",
        sort: "asc",
        width: 400,
      },
      {
        label: "Type",
        field: "acc_type_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Detail Type",
        field: "acc_detail_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Chart of Account",
        field: "acc_chart_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Fund Source",
        field: "fund_source",
        sort: "asc",
        width: 200,
      },
      {
        label: "Amount",
        field: "income_expense_amount1",
        sort: "asc",
        width: 200,
      },
      {
        label: "Total Amount",
        field: "income_expense_total1",
        sort: "asc",
        width: 200,
      },
      {
        label: "Comment",
        field: "income_expense_comment",
        sort: "asc",
        width: 200,
      },
      {
        label: "Added By",
        field: "added_user",
        sort: "asc",
        width: 200,
      }
    ],
    rows: incomeExpenseList,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Income/Expense" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Date</Label>
                          <div className="col-md-10">
                            <input
                              className="form-control"
                              type="date"
                              id="income_expense_date"
                              name="income_expense_date"
                              //defaultValue={master?.income_expense_date}
                              value={master?.income_expense_date}
                              onChange={handleDate}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Time</Label>
                          <AvField
                            name="income_expense_time"
                            placeholder="Time"
                            type="time"
                            errorMessage="Enter Time"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                            value={master?.income_expense_time}
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Account Type</Label>
                          <Select
                            name="acc_type_id"
                            value={selectedAccountType}
                            onChange={(selected) => {
                              handleSelectedAccType(selected);
                            }}
                            options={accountType}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label> Account Details Type</Label>
                          <Select
                            name="acc_detail_id"
                            value={selectedAccountDetailType}
                            onChange={(selected) => {
                              handleSelectedAccDetailType(selected);
                            }}
                            options={accountDetailType}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Charts of Account</Label>
                          <Select
                            name="acc_chart_id"
                            value={selectedChartOfAccount}
                            onChange={(selected) => {
                              handleSelectedChartOfAccount(selected);
                            }}
                            options={chartOfAccount}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Fund Source</Label>
                          <Select
                            name="fund_source_id"
                            value={selectedFundSource}
                            onChange={(selected) => {
                              handleSelectedFundSource(selected);
                            }}
                            options={fundSourceOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Amount</Label>
                          <AvField
                            name="income_expense_amount"
                            placeholder="Amount"
                            type="number"
                            errorMessage="Enter Amount"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                            value={master?.income_expense_amount}
                            onChange={onAmountChange}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">GST(%)</Label>
                          <AvField
                            name="income_expense_gst"
                            placeholder="GST"
                            type="number"
                            errorMessage="Enter GST"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                            value={master?.income_expense_gst}
                            onChange={onGstChange}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Comments</Label>
                          <AvField
                            name="income_expense_comment"
                            placeholder="Comments"
                            type="text"
                            errorMessage="Enter Comments"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                            value={master?.income_expense_comment}
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">
                            Total Amount
                          </Label>
                          <AvField
                            name="income_expense_total"
                            placeholder="Total Amount"
                            type="number"
                            className="form-control"
                            id="validationCustom05"
                            value={master?.income_expense_total}
                            readOnly
                          />
                        </div>
                      </Col>

                      {/* <Col className="mb-3">
                        <label
                          htmlFor="example-date-input"
                          className="col-md-2 col-form-label"
                        >
                          Date
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            defaultValue="2019-08-19"
                            id="example-date-input"
                          />
                        </div>
                      </Col> */}

                      <Col md="1">
                        <div className="mt-4">
                          <Button
                            color="primary"
                            type="submit"
                            disabled={addingIncomeExpense ? true : false}
                          >
                            {addingIncomeExpense ? "Adding" : "Submit"}
                          </Button>
                        </div>
                      </Col>
                      <Col md="1">
                        <div className="mt-4">
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            id="from_date"
                            name="from_date"
                            onChange={handleDateSearch}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To</Label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            id="to_date"
                            name="to_date"
                            onChange={handleDateSearch}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Account Type</Label>
                        <Select
                          name="customer_community_id"
                          value={accTypeForSearch}
                          onChange={(selected) => {
                            handleSelectedForSearch(selected, "accType");
                          }}
                          options={accountType}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Account Detail Type</Label>
                        <Select
                          name="customer_community_id"
                          value={accDetailTypeForSearch}
                          onChange={(selected) => {
                            handleSelectedForSearch(selected, "accDetailType");
                          }}
                          options={accountDetailTypeSearch}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Account Chart</Label>
                        <Select
                          name="customer_community_id"
                          value={selectedChartOfAccForSearch}
                          onChange={(selected) => {
                            handleSelectedForSearch(selected, "chart");
                          }}
                          options={chartOfAccountSearch}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Staff</Label>
                        <Select
                          name="customer_community_id"
                          value={selectedUser}
                          onChange={(selected) => {
                            handleSelectedForSearch(selected, "staff");
                          }}
                          options={user}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                  </Row>

                  <MDBDataTable
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={false}
                    disableRetreatAfterSorting={true}
                    entries={25}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {};

export default withRouter(
  connect(mapStateToProps, { apiError })(IncomeExpense)
);

// Users.propTypes = {
//   error: PropTypes.any,
//   users: PropTypes.array,
// };
