import React, { useState, useEffect, useRef } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SweetAlert from 'react-bootstrap-sweetalert';
import { MDBDataTable } from 'mdbreact';
import { Row, Col, Card, CardBody, Button, Label, Modal } from 'reactstrap';
import toastr from 'toastr';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import "./company.scss";
import Select from 'react-select';
import Form from 'react-bootstrap/Form';


const Shop = (props) => {
    const formRef = useRef();
    const [masterObject, setMasterObject] = useState(null);
    const [selectedCategory, setselectedCategory] = useState(null);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [selectedDistrict, setselectedDistrict] = useState(null);
    const [districtOptions, setdistrictOptions] = useState([]);
    const [selectedPanchayat, setSelectedPanchayat] = useState(null);
    const [panchayatOptions, setpanchayatOptions] = useState([]);
    const [selectedTown, setselectedTown] = useState(null);
    const [townOptions, settownOptions] = useState([]);
    const [companyIdTobeUpdated, setcompanyIdTobeUpdated] = useState(null);
    const [companyIdTobeDeleted, setcompanyIdTobeDeleted] = useState(null);
    const [dataTable, setDataTable] = useState([]);
    const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [isModelOpen, showModel] = useState(false);
    const [selectedState, setselectedState] = useState(null);
    const [stateOptions, setstateOptions] = useState([]);
    const [selectedFlrCategory, setSelectedFlrCategory] = useState(null);
    const [selectedFlrState, setselectedFlrState] = useState(null);
    const [selectedFlrDistrict, setselectedFlrDistrict] = useState(null);
    const [selectedFlrPanchayat, setSelectedFlrPanchayat] = useState(null);
    const [selectedFlrTown, setselectedFlrTown] = useState(null);
    const [districtOptionsFlr, setdistrictOptionsFlr] = useState([]);
    const [townOptionsFlr, settownOptionsFlr] = useState([]);
    const [panchayathOptionsFlr, setpanchayathOptionsFlr] = useState([]);
    const [isModelOpenSuccess, showModelSuccess] = useState(false);
    const [successModalData, setsuccessModalData] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";


    const toTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };


    const handleChangeInput = (e) => {
        setMasterObject({
            ...masterObject,
            [e.target.name]: e.target.value
        })
    }

    const handleSelectChange = (selected, type) => {
        switch (type) {
            case "1":
                setselectedState(selected);
                setMasterObject({
                    ...masterObject,
                    "shop_state": selected.value,
                    "shop_district": null,
                    "shop_town": null,
                    "shop_panchayath": null
                });
                setselectedDistrict(null)
                setSelectedPanchayat(null);
                setselectedTown(null);
                setdistrictOptions([]);
                setpanchayatOptions([]);
                settownOptions([]);
                getDistricts(selected.value);
                break;
            case "2":
                setselectedDistrict(selected);
                setMasterObject({
                    ...masterObject,
                    "shop_district": selected.value,
                    "shop_town": null,
                    "shop_panchayath": null
                });
                setSelectedPanchayat(null);
                setselectedTown(null);
                getTowns(selected.value);
                break;
            case "4":
                setSelectedPanchayat(selected);
                setMasterObject({
                    ...masterObject,
                    "shop_panchayath": selected.value
                });
                break;
            case "3":
                setselectedTown(selected);
                setMasterObject({
                    ...masterObject,
                    "shop_town": selected.value
                });
                getPanchayats(selected.value);
                setSelectedPanchayat(null)
                break;
            case "5":
                setselectedCategory(selected);
                setMasterObject({
                    ...masterObject,
                    "shop_category": selected.value
                });
                break;
        }
    }

    const getStates = () => {
        axios
            .get(`${API_URL}state`, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                var list = [];
                res.data?.data?.map((item) => {
                    list.push({ label: item.name, value: item.id });
                })
                setstateOptions(list);
            })
    }
    const getCategories = () => {
        axios
            .get(`${API_URL}shop-category/options`, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                var list = [];
                res.data?.data?.map((item) => {
                    list.push({ label: item.category_name, value: item.category_id });
                })
                setCategoryOptions(list);
            })
    }
    const getDistricts = (id, type = "") => {
        axios
            .get(`${API_URL}district/options/?id=` + id, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                var list = [];
                res.data?.data?.map((item) => {
                    list.push({ label: item.dist_name, value: item.dist_id });
                })
                if (type == "")
                    setdistrictOptions(list);
                else
                    setdistrictOptionsFlr(list);
            })
    }

    const getTowns = (id, type = "") => {
        axios
            .get(`${API_URL}manage-town/options?id=` + id, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                var list = [];
                res.data?.data?.map((item) => {
                    list.push({ label: item.town_name, value: item.town_id });
                })
                if (type == "")
                    settownOptions(list);
                else
                    settownOptionsFlr(list);
            })
    }

    const getPanchayats = (id, type = "") => {
        axios
            .get(`${API_URL}manage-panchayath/bytown-options?id=` + id, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                var list = [];
                res.data?.data?.map((item) => {
                    list.push({ label: item.panchayath_name, value: item.panchayath_id });
                })
                if (type == "")
                    setpanchayatOptions(list);
                else
                    setpanchayathOptionsFlr(list);
            })
    }

    const uploadImage = (e) => {
        const fd = new FormData();
        fd.append("photo", e.target.files[0]);
        axios
            .post(`${API_URL}shop/upload_image/`, fd, {
                headers: {
                    "x-access-token": accessToken,
                }
            })
            .then((response) => {
                if (response.data.status === "success") {
                    setMasterObject({
                        ...masterObject,
                        [`shop_logo`]: response.data.file.filename,
                    });
                } else {
                    toastr.error(response.data.message);
                }
            });
    };

    const deleteImage = () => {
        setMasterObject({
            ...masterObject,
            [`shop_logo`]: "",
        });
    };

    const handleValidSubmit = () => {
        if (!selectedCategory) {
            toastr.error("Please select a category");
            return;
        }
        if (!selectedState || typeof selectedState.value == "undefined") {
            toastr.error("Please select a state");
            return;
        }
        if (!selectedDistrict) {
            toastr.error("Please select a district");
            return;
        }
        if (!selectedTown) {
            toastr.error("Please select a town");
            return;
        }
        if (!selectedPanchayat) {
            toastr.error("Please select a panchayat");
            return;
        }
        setProcessing(true);
        if (companyIdTobeUpdated) {
            axios
                .put(`${API_URL}shop/`, masterObject, {
                    headers: {
                        "x-access-token": accessToken,
                    },
                })
                .then((res) => {
                    if (res.data.status == 200) {
                        toastr.success("Updated Successfully");
                        reset();
                    }
                    else {
                        toastr.error(res.data.message);
                    }
                    setProcessing(false);
                }).catch((err) => {
                    toastr.error("Failed to update Shop");
                    setProcessing(false);
                })
        }
        else {
            axios
                .post(`${API_URL}shop/`, masterObject, {
                    headers: {
                        "x-access-token": accessToken,
                    },
                })
                .then((res) => {
                    if (res.data.status == 200) {
                        //toastr.success("Submitted Successfully");
                        showModelSuccess(true);
                        setsuccessModalData(res.data.username);
                        reset();
                        setProcessing(false);
                    }
                    else {
                        toastr.error(res.data.message);
                        setProcessing(false);
                    }
                }).catch((err) => {
                    toastr.error("Failed to add Shop");
                    setProcessing(false);
                })
        }
    }

    const preUpdateCompany = (item) => {
        setcompanyIdTobeUpdated(item?.shop_id);
        setMasterObject(item);
        setselectedState({ label: item?.State?.name, value: item?.State?.id });
        getDistricts(item?.State?.id);
        setselectedDistrict({ label: item?.District?.dist_name, value: item?.District?.dist_id });
        getPanchayats(item?.District?.dist_id);
        setSelectedPanchayat({ label: item?.Panchayath?.panchayath_name, value: item?.Panchayath?.panchayath_id });
        setselectedTown({ label: item?.Town?.town_name, value: item?.Town?.town_id });
        getTowns(item?.District?.dist_id);
        setselectedCategory({ label: item?.shopCategory?.category_name, value: item?.shopCategory?.category_id });
    }

    const getDataTable = (category = "", state = "", district = "", town = "", panchayath = "") => {
        axios
            .get(`${API_URL}shop/?category=` + category + "&state=" + state + "&district=" + district + "&town=" + town + "&panchayath=" + panchayath, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((res) => {
                var data = res.data.data;
                var list = [];
                data.map((item, index) => {
                    item.serial = index + 1;
                    item.name = item.addedUser?.fname;
                    item.dist_name = item.District?.dist_name;
                    item.town_name = item.Town?.town_name;
                    item.panchayath_name = item.Panchayath?.panchayath_name;
                    item.category_name = item.shopCategory?.category_name;
                    item.account_chart = item?.accountChart?.acc_chart_balance;
                    item.enable = (
                        <div
                            onClick={() => toggleShopStatus(item.shop_id, item.isActive)}
                            className="form-check form-switch form-switch-md make-center"
                        >
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id={`customSwitchsizemd_${item.shop_id}`}
                                checked={item.isActive === true}
                                onChange={() => toggleShopStatus(item.shop_id, item.isActive)}
                            />
                        </div>
                    );
        
                    
                    item.action = (
                        <>
                            <i
                                className="far fa-eye"
                                style={{
                                    fontSize: "1em",
                                    cursor: "pointer",
                                    marginLeft: "0.1em",
                                    marginRight: "0.5em",
                                }}
                                onClick={() => {
                                    handlePopup(item);
                                }}
                            ></i>
                            <i
                                className="far fa-edit"
                                style={{
                                    fontSize: "1em",
                                    cursor: "pointer",
                                    marginLeft: "0.1em",
                                    marginRight: "0.5em",
                                }}
                                onClick={() => {
                                    toTop();
                                    preUpdateCompany(item);
                                }}
                            ></i>
                            <i
                                className="far fa-trash-alt"
                                style={{ fontSize: "1em", cursor: "pointer" }}
                                onClick={() => {
                                    setcompanyIdTobeDeleted(item.shop_id);
                                    setConfirmDeleteAlert(true);
                                }}
                            ></i>
                            
                        </>
                    )
                    list.push(item);
                })
                
                setDataTable(list);
            })
    }

    const reset = () => {
        formRef.current.reset();
        setMasterObject(null);
        setMasterObject({ shop_address: "" })
        setselectedDistrict(null);
        setSelectedPanchayat(null);
        setselectedState(null);
        setdistrictOptions([]);
        setpanchayatOptions([]);
        setselectedTown(null);
        settownOptions([]);
        setselectedCategory(null);
        getDataTable();
        setcompanyIdTobeUpdated(null);
        setcompanyIdTobeDeleted(null);
        setselectedCategory(null);
    }

    const handlePopup = (item) => {
        setModalData(item);
        if (item?.shop_logo) {
            item.image = (
                <img src={API_URL + "uploads/shop/" + item?.shop_logo} className="avatar-lg img-thumbnail" />
            )
        }
        showModel(true);
    }
    const toggleShopStatus = (shopId, currentStatus) => {
        const newStatus = currentStatus === false ? 2 : 1; 
        axios.post(`${API_URL}shop/visibility/${shopId}`, { status: newStatus }, {
            headers: {
                "x-access-token": accessToken,
            },
        }).then(response => {  
            getDataTable(selectedFlrCategory?.value, selectedFlrState?.value, selectedFlrDistrict?.value, selectedFlrTown?.value, selectedFlrPanchayat?.value);
        }).catch(error => {
            console.error('Failed to update shop status:', error);
        });
    };
    const data = {
        columns: [
            {
                label: "#",
                field: "serial",
                sort: "asc",
                width: 150,
            },
            {
                label: "Category",
                field: "category_name",
                sort: "asc",
                width: 150,
            },
            {
                label: "Name",
                field: "shop_name",
                sort: "asc",
                width: 150,
            },
            {
                label: "Short Code",
                field: "shop_shortcode",
                sort: "asc",
                width: 150,
            },
            // {
            //     label: "Contact Person",
            //     field: "shop_person",
            //     sort: "asc",
            //     width: 150,
            // },
            {
                label: "Mobile",
                field: "shop_mob",
                sort: "asc",
                width: 150,
            },
            {
                label: "District",
                field: "dist_name",
                sort: "asc",
                width: 150,
            },
            {
                label: "Town",
                field: "town_name",
                sort: "asc",
                width: 150,
            },
            {
                label: "Panchayat",
                field: "panchayath_name",
                sort: "asc",
                width: 150,
            },
            {
                label: "Pincode",
                field: "shop_pin",
                sort: "asc",
                width: 150,
            },
            {
                label: "Wallet",
                field: "shop_wallet",
                sort: 'asc',
                width: 150,
            },
            {
                label: "Buyback",
                field: "buyback_wallet",
                sort: 'asc',
                width: 150,
            },
            {
                label: "Account Chart",
                field: "account_chart",
                sort: "asc",
                width: 150,
            },
            {
                label: "Staff",
                field: "name",
                sort: "asc",
                width: 150,
            },
            {
                label: "Enable/Disable",
                field: "enable",
                sort: "asc",
                width: 80,
            },
            
            {
                label: "Action",
                field: "action",
                sort: "asc",
                width: 150,
            },
        ],
        rows: dataTable
    }

    useEffect(() => {
        getStates();
        getCategories();
        getDataTable();
    }, [])


    return (
        <React.Fragment>
            {
                isModelOpenSuccess ? (
                    <SweetAlert success title="Submitted Successfully"
                        closeOnClickOutside={false}
                        onConfirm={() => showModelSuccess(false)}
                        onCancel={() => showModelSuccess(false)}
                        confirmBtnBsStyle="primary">
                        <b style={{
                            fontWeight: 'bold',
                            color: 'brown'
                        }}>Merchant Login Details</b><br></br>
                        Username : <b style={{
                            fontSize: '12px',
                            color: 'black',
                        }}>{successModalData}</b><br></br>
                        Default password is <b
                            style={{
                                fontSize: '12px',
                                color: 'black',
                            }}>123456</b><br></br>
                    </SweetAlert>
                ) : ""
            }

            {confirmDeleteAlert ? (

                <SweetAlert
                    title=""
                    showCancel
                    confirmButtonText="Delete"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        axios
                            .delete(`${API_URL}shop/?id=` + companyIdTobeDeleted, {
                                headers: {
                                    "x-access-token": accessToken,
                                },
                            })
                            .then((res) => {
                                if (res.data.status === 200) {
                                    toastr.success("Deleted successfully");
                                    setcompanyIdTobeDeleted(null);
                                    reset();
                                } else {
                                    toastr.error(res.data.message, "Failed to delete");
                                    return;
                                }
                            })
                            .catch((err) => {
                                toastr.error(err.response.data.message);
                                return;
                            });
                        setConfirmDeleteAlert(false);
                    }}
                    onCancel={() => setConfirmDeleteAlert(false)}
                >
                    Are you sure you want to delete it?
                </SweetAlert>
            ) : null}
            <Modal
                size="xl" centered={true}
                isOpen={isModelOpen}
                toggle={() => {
                    showModel(!isModelOpen)
                }}
            >
                <div className="modal-header">
                    <h5>{modalData?.shop_name} - {modalData?.shop_unqid}</h5>
                    <button
                        type="button"
                        onClick={() => {
                            showModel(!isModelOpen)
                        }}
                        className="close p-4"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <table id="companyModalTable">
                        <tr>
                            <td><b>Shop</b></td><td>: {modalData?.shop_name}</td>
                            <td><b>Short Code</b></td><td>: {modalData?.shop_shortcode}</td>
                            <td><b>Category</b></td><td>: {modalData?.shopCategory?.category_name}</td>
                            <td><b>Contact Person</b></td><td>: {modalData?.shop_person}</td>
                        </tr>
                        <tr>
                            <td><b>Designation</b></td><td>: {modalData?.shop_design}</td>
                            <td><b>Email</b></td><td>: {modalData?.shop_email}</td>
                            <td><b>Mobile</b></td><td>: {modalData?.shop_mob}</td>
                            <td><b>Landline</b></td><td>: {modalData?.shop_land}</td>
                        </tr>
                        <tr>
                            <td><b>Website</b></td><td>: {modalData?.shop_web}</td>
                            <td><b>State</b></td><td>: {modalData?.State?.name}</td>
                            <td><b>District</b></td><td>: {modalData?.District?.dist_name}</td>
                            <td><b>Town</b></td><td>: {modalData?.Town?.town_name}</td>
                        </tr>
                        <tr>
                            <td><b>Panchayat</b></td><td>: {modalData?.Panchayath?.panchayath_name}</td>
                            <td><b>Pincode</b></td><td>: {modalData?.shop_pin}</td>
                            <td><b>Address</b></td><td colSpan={3}>: {modalData?.shop_address}</td>
                        </tr>
                        <tr>
                            <td><b>GSTIN No.</b></td><td>: {modalData?.shop_gstin}</td>
                            <td><b>PAN No.</b></td><td>: {modalData?.shop_pan}</td>
                            <td><b>CIN No.</b></td><td>: {modalData?.shop_cin}</td>
                            {/* <td><b>TDS No.</b></td><td>: {modalData?.shop_tds}</td> */}
                            <td><b>Latitude</b></td><td>: {modalData?.shop_latitude}</td>
                        </tr>
                        <tr>
                            <td><b>Longitude</b></td><td>: {modalData?.shop_longitude}</td>
                            <td><b>Image</b></td><td>: {modalData?.image}</td>
                            <td><b>Reference Code</b></td><td>: {modalData?.shop_reference_code}</td>
                            <td><b>Added By</b></td><td>: {modalData?.addedUser?.fname}</td>
                        </tr>
                        <tr><td colSpan={8}><h5><b>Bank Details :- </b></h5></td></tr>
                        <tr>
                            <td><b>Bank Name</b></td><td>: {modalData?.shop_bank_name}</td>
                            <td><b>Branch Name</b></td><td>: {modalData?.shop_bank_branch}</td>
                            <td><b>Account No. </b></td><td>: {modalData?.shop_account_no}</td>
                            <td><b>IFSC Code</b></td><td>: {modalData?.shop_ifsc}</td>
                        </tr>
                    </table>
                </div>
            </Modal>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Home" breadcrumbItem="Manage Shops" />
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <AvForm
                                        ref={formRef}
                                        className="needs-validation"
                                        onValidSubmit={(e, v) => {
                                            handleValidSubmit(e, v);
                                        }}
                                    >
                                        <Row>
                                            <Col md="3">
                                                <Label>Category</Label>
                                                <Select
                                                    name="category"
                                                    value={selectedCategory}
                                                    onChange={(selected) => {
                                                        handleSelectChange(selected, "5");
                                                    }}
                                                    options={categoryOptions}
                                                    classNamePrefix="select2-selection"
                                                    menuPosition="auto"
                                                    menuPlacement="auto"
                                                />

                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Shop Name</Label>
                                                    <AvField
                                                        name="shop_name"
                                                        value={masterObject?.shop_name}
                                                        placeholder="Shop Name"
                                                        type="text"
                                                        errorMessage="Shop Name is required"
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: true }
                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Short Code</Label>
                                                    <AvField
                                                        name="shop_shortcode"
                                                        value={masterObject?.shop_shortcode}
                                                        placeholder="Short Code"
                                                        type="text"
                                                        errorMessage="Short Code is required"
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: true }
                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Contact Person</Label>
                                                    <AvField
                                                        name="shop_person"
                                                        value={masterObject?.shop_person}
                                                        placeholder="Contact Person"
                                                        type="text"
                                                        errorMessage="Contact Person is required"
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: true }
                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>

                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Designation</Label>
                                                    <AvField
                                                        name="shop_design"
                                                        value={masterObject?.shop_design}
                                                        placeholder="Designation"
                                                        type="text"
                                                        errorMessage="Designation is required"
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: true }
                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Mobile</Label>
                                                    <AvField
                                                        name="shop_mob"
                                                        value={masterObject?.shop_mob}
                                                        placeholder="Mobile"
                                                        type="text"
                                                        errorMessage="Mobile is required"
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: true },
                                                            pattern: { value: '^[0-9]{10}$', errorMessage: "Invalid mobile number" }

                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Email</Label>
                                                    <AvField
                                                        name="shop_email"
                                                        value={masterObject?.shop_email}
                                                        placeholder="Email"
                                                        type="email"
                                                        errorMessage="Email is required"
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: true }
                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Website</Label>
                                                    <AvField
                                                        name="shop_web"
                                                        value={masterObject?.shop_web}
                                                        placeholder="Website"
                                                        type="url"
                                                        errorMessage="Website is required"
                                                        className="form-control"
                                                        // validate={{
                                                        //     required: { value: true },
                                                        // }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>

                                            <Col md="2">
                                                <div className="mb-3">
                                                    <Label >Landline No.</Label>
                                                    <AvField
                                                        name="shop_land"
                                                        value={masterObject?.shop_land}
                                                        placeholder="Landline No."
                                                        type="text"
                                                        errorMessage="Landline No. is required"
                                                        className="form-control"
                                                        validate={{
                                                            pattern: { value: '^[0-9]{11}$', errorMessage: "Invalid landline number" }
                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="2">
                                                <Label>State</Label>
                                                <Select
                                                    name="customer_state"
                                                    value={selectedState}
                                                    onChange={(selected) => {
                                                        handleSelectChange(selected, "1");
                                                    }}
                                                    options={stateOptions}
                                                    classNamePrefix="select2-selection"
                                                    menuPosition="auto"
                                                    menuPlacement="auto"
                                                />
                                            </Col>
                                            <Col md="2">
                                                <div className="mb-3">
                                                    <Label >District</Label>
                                                    <Select
                                                        name="shop_district"
                                                        value={selectedDistrict}
                                                        onChange={(selected) => {
                                                            handleSelectChange(selected, "2");
                                                        }}
                                                        options={districtOptions}
                                                        classNamePrefix="select2-selection"
                                                        menuPosition="auto"
                                                        menuPlacement="auto"
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="2">
                                                <Label>Town</Label>
                                                <Select
                                                    name="town"
                                                    value={selectedTown}
                                                    onChange={(selected) => {
                                                        handleSelectChange(selected, "3");
                                                    }}
                                                    options={townOptions}
                                                    classNamePrefix="select2-selection"
                                                    menuPosition="auto"
                                                    menuPlacement="auto"
                                                />
                                            </Col>
                                            <Col md="2">
                                                <div className="mb-3">
                                                    <Label >Panchayat</Label>
                                                    <Select
                                                        name="panchayat"
                                                        value={selectedPanchayat}
                                                        onChange={(selected) => {
                                                            handleSelectChange(selected, "4");
                                                        }}
                                                        options={panchayatOptions}
                                                        classNamePrefix="select2-selection"
                                                        menuPosition="auto"
                                                        menuPlacement="auto"
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="2">
                                                <div className="mb-3">
                                                    <Label >Pincode</Label>
                                                    <AvField
                                                        name="shop_pin"
                                                        value={masterObject?.shop_pin}
                                                        placeholder="Pincode"
                                                        type="text"
                                                        errorMessage="Pincode is required"
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: true },
                                                            pattern: { value: '^[0-9]{6}$', errorMessage: "Invalid pincode" }
                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label >Address</Label>
                                                    <AvField
                                                        name="shop_address"
                                                        value={masterObject?.shop_address}
                                                        placeholder="Address"
                                                        type="textarea"
                                                        errorMessage="Address is required"
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: true }
                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>

                                            <Col md="2">
                                                <div className="mb-3">
                                                    <Label >GSTIN</Label>
                                                    <AvField
                                                        name="shop_gstin"
                                                        value={masterObject?.shop_gstin}
                                                        placeholder="22AAAAA0000A1Z5"
                                                        type="text"
                                                        errorMessage="GSTIN is required"
                                                        className="form-control"
                                                        validate={{
                                                            pattern: { value: '^[A-Z0-9]{15}$', errorMessage: "Invalid No." }
                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="2">
                                                <div className="mb-3">
                                                    <Label >PAN No.</Label>
                                                    <AvField
                                                        name="shop_pan"
                                                        value={masterObject?.shop_pan}
                                                        placeholder="ABCTY1234D"
                                                        type="text"
                                                        errorMessage="PAN No. is required"
                                                        className="form-control"
                                                        validate={{
                                                            pattern: { value: '^[A-Z0-9]{10}$', errorMessage: "Invalid No." }
                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="2">
                                                <div className="mb-3">
                                                    <Label >CIN No.</Label>
                                                    <AvField
                                                        name="shop_cin"
                                                        value={masterObject?.shop_cin}
                                                        placeholder="L17110MH1973PLC019786"
                                                        type="text"
                                                        errorMessage="CIN No. is required"
                                                        className="form-control"
                                                        validate={{
                                                            pattern: { value: '^[A-Z0-9]{21}$', errorMessage: "Invalid No." }
                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            {/* <Col md="2">
                                                <div className="mb-3">
                                                    <Label >TDS No.</Label>
                                                    <AvField
                                                        name="shop_tds"
                                                        value={masterObject?.shop_tds}
                                                        placeholder="AAACB1234E"
                                                        type="text"
                                                        errorMessage="TDS No. is required"
                                                        className="form-control"
                                                        validate={{
                                                            pattern: { value: '^[A-Z0-9]{10}$', errorMessage: "Invalid No." }
                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col> */}
                                            <Col md="2">
                                                <div className="mb-3">
                                                    <Label >Latitude</Label>
                                                    <AvField
                                                        name="shop_latitude"
                                                        value={masterObject?.shop_latitude}
                                                        placeholder="Latitude"
                                                        type="text"
                                                        errorMessage="Latitude is required"
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: true },
                                                            pattern: { value: '^[0-9.]+$', errorMessage: "Invalid Latitude" }
                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="2">
                                                <div className="mb-3">
                                                    <Label >Longitude</Label>
                                                    <AvField
                                                        name="shop_longitude"
                                                        value={masterObject?.shop_longitude}
                                                        placeholder="Longitude"
                                                        type="text"
                                                        errorMessage="Longitude is required"
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: true },
                                                            pattern: { value: '^[0-9.]+$', errorMessage: "Invalid Longitude" }
                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="2" >
                                                <Label htmlFor="validationCustom03">
                                                    Image
                                                </Label>
                                                {masterObject?.shop_logo ? (
                                                    <div div className="img-wraps">
                                                        <img
                                                            alt=""
                                                            width="150"
                                                            height="150"
                                                            src={`${API_URL}/uploads/shop/${masterObject?.shop_logo}`}
                                                        />
                                                        <button
                                                            className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                                            onClick={deleteImage}
                                                            style={{ width: "150px" }}
                                                        >
                                                            Delete
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <AvField
                                                        name="photo"
                                                        type="file"
                                                        className="form-control"
                                                        id="validationCustom03"
                                                        onChange={uploadImage}
                                                        rows="1"
                                                    />
                                                )}
                                            </Col>
                                            <Col md="2">
                                                <div className="mb-3">
                                                    <Label >Reference Code</Label>
                                                    <AvField
                                                        name="shop_reference_code"
                                                        value={masterObject?.shop_reference_code}
                                                        placeholder="Reference Code"
                                                        type="text"
                                                        errorMessage="Longitude is required"
                                                        className="form-control"
                                                        // validate={{
                                                        //     required: { value: true },
                                                        //     pattern: { value: '^[0-9.]+$', errorMessage: "Invalid Longitude" }

                                                        // }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6"></Col>
                                            <Col md="12"><h5><b>Bank Account Details :</b></h5></Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Bank Name</Label>
                                                    <AvField
                                                        name="shop_bank_name"
                                                        value={masterObject?.shop_bank_name}
                                                        placeholder="Bank Name"
                                                        type="text"
                                                        errorMessage="Bank Name is required"
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: false }
                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Branch</Label>
                                                    <AvField
                                                        name="shop_bank_branch"
                                                        value={masterObject?.shop_bank_branch}
                                                        placeholder="Branch Name"
                                                        type="text"
                                                        errorMessage="Branch Name is required"
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: false }
                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Account No.</Label>
                                                    <AvField
                                                        name="shop_account_no"
                                                        value={masterObject?.shop_account_no}
                                                        placeholder="Account No."
                                                        type="text"
                                                        errorMessage="Account No. is required"
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: false }
                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >IFSC Code</Label>
                                                    <AvField
                                                        name="shop_ifsc"
                                                        value={masterObject?.shop_ifsc}
                                                        placeholder="IFSC Code"
                                                        type="text"
                                                        errorMessage="IFSC Code is required"
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: false }
                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="1">
                                                {companyIdTobeUpdated ? (
                                                    <Button
                                                        color="primary"
                                                        type="submit"
                                                        disabled={processing ? true : false}
                                                    >
                                                        {processing ? "Updating" : "Update"}
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        color="primary"
                                                        type="submit"
                                                        disabled={processing ? true : false}
                                                    >
                                                        {processing ? "Submitting" : "Submit"}
                                                    </Button>
                                                )}
                                            </Col>
                                            <Col md="1">
                                                <Button
                                                    color="danger"
                                                    type="reset"
                                                    onClick={(e) => {
                                                        reset()

                                                    }}
                                                >
                                                    Reset
                                                </Button>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md="2" className="mb-2">
                                            <Label>Category</Label>
                                            <Select
                                                name="category"
                                                value={selectedFlrCategory}
                                                onChange={(selected) => {
                                                    setSelectedFlrCategory(selected);
                                                    getDataTable(selected.value, selectedFlrState?.value, selectedFlrDistrict?.value, selectedFlrTown?.value, selectedFlrPanchayat?.value);
                                                }}
                                                options={categoryOptions}
                                                classNamePrefix="select2-selection"
                                                menuPosition="auto"
                                                menuPlacement="auto"
                                            />

                                        </Col>
                                        <Col md="2">
                                            <Label>State</Label>
                                            <Select
                                                name="customer_state"
                                                value={selectedFlrState}
                                                onChange={(selected) => {
                                                    setselectedFlrState(selected);
                                                    setselectedFlrDistrict(null);
                                                    setselectedFlrTown(null);
                                                    setSelectedFlrPanchayat(null);
                                                    setdistrictOptionsFlr([]);
                                                    settownOptionsFlr([]);
                                                    setpanchayathOptionsFlr([]);
                                                    getDataTable(selectedFlrCategory?.value, selected?.value);
                                                    getDistricts(selected.value, 1);
                                                }}
                                                options={stateOptions}
                                                classNamePrefix="select2-selection"
                                                menuPosition="auto"
                                                menuPlacement="auto"
                                            />

                                        </Col>
                                        <Col md="2">
                                            <div className="mb-3">
                                                <Label >District</Label>
                                                <Select
                                                    name="shop_district"
                                                    value={selectedFlrDistrict}
                                                    onChange={(selected) => {
                                                        setselectedFlrDistrict(selected);
                                                        setselectedFlrTown(null);
                                                        setSelectedFlrPanchayat(null);
                                                        getTowns(selected.value, 1);
                                                        getDataTable(selectedFlrCategory?.value, selectedFlrState?.value, selected?.value);
                                                    }}
                                                    options={districtOptionsFlr}
                                                    classNamePrefix="select2-selection"
                                                    menuPosition="auto"
                                                    menuPlacement="auto"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <Label>Town</Label>
                                            <Select
                                                name="town"
                                                value={selectedFlrTown}
                                                onChange={(selected) => {
                                                    setselectedFlrTown(selected);
                                                    getPanchayats(selected.value, 1);
                                                    getDataTable(selectedFlrCategory?.value, selectedFlrState?.value, selectedFlrDistrict?.value, selected?.value, selectedFlrPanchayat?.value);
                                                }}
                                                options={townOptionsFlr}
                                                classNamePrefix="select2-selection"
                                                menuPosition="auto"
                                                menuPlacement="auto"
                                            />

                                        </Col>
                                        <Col md="2">
                                            <div className="mb-3">
                                                <Label >Panchayat</Label>
                                                <Select
                                                    name="panchayat"
                                                    value={selectedFlrPanchayat}
                                                    onChange={(selected) => {
                                                        setSelectedFlrPanchayat(selected);
                                                        getDataTable(selectedFlrCategory?.value, selectedFlrState?.value, selectedFlrDistrict?.value, selectedFlrTown?.value, selected?.value);
                                                    }}
                                                    options={panchayathOptionsFlr}
                                                    classNamePrefix="select2-selection"
                                                    menuPosition="auto"
                                                    menuPlacement="auto"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="1" style={{ paddingTop: "26px" }}>
                                            <Button
                                                color="danger"
                                                type="reset"
                                                onClick={(e) => {
                                                    setSelectedFlrCategory(null);
                                                    setselectedFlrState(null);
                                                    setselectedFlrDistrict(null);
                                                    setselectedFlrTown(null);
                                                    setSelectedFlrPanchayat(null);
                                                    setdistrictOptionsFlr([]);
                                                    settownOptionsFlr([]);
                                                    setpanchayathOptionsFlr([]);
                                                    getDataTable();
                                                }}
                                            >
                                                Reset
                                            </Button>
                                        </Col>
                                    </Row>
                                    <MDBDataTable
                                        responsive
                                        id="localbodyTableId"
                                        bordered
                                        data={data}
                                        searching={true}
                                        info={true}
                                        disableRetreatAfterSorting={true}
                                        entries={20}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
};
export default Shop;