import React, { useEffect, useState } from "react"
import Breadcrumb from "../../components/Common/Breadcrumb";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import Select from "react-select"
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import { MDBDataTable } from 'mdbreact';
import { Modal } from "react-bootstrap";
import moment from "moment"
import "./customer.scss"
import Item from "antd/lib/list/Item";
import { CSVLink } from 'react-csv';



function MarketingExecutive() {

    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
    const [selectedTown, setSelectedTown] = useState(null)
    const [townOptions, setTownOptions] = useState([])
    const [searchData, setSearchData] = useState([])

    const [selectedCode, setSelectedCode] = useState(null)
    const [codeOptions, setCodeOptions] = useState([])
    const [tableData, setTableData] = useState([])

    const [tableDetails, setTableDetails] = useState([])
    const [popupView, setPopupView] = useState(false)



    useEffect(() => {
        fetch_town()
        handleTableData()
    }, [])


    function fetch_town() {
        axios
            .get(`${API_URL}manage-town/`, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((res) => {
                var town_data =
                    res.data.data &&
                    res.data.data.map((el) => {
                        return {
                            label: el?.town_name,
                            value: el?.town_id
                        }
                    })
                setTownOptions([{ options: town_data }])
            })
    }

    function get_code(id) {
        axios
            .get(`${API_URL}customer/marketing-executive-options?town_id=${id}`, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((res) => {
                var code_data =
                    res.data.marketinExecutives &&
                    res.data.marketinExecutives.map((el) => {
                        return {
                            label: el?.fname,
                            value: el?.reference_id
                        }
                    })
                setCodeOptions([{ options: code_data }])
            })
    }

    function handleSelect(selected, name) {
        switch (name) {
            case "town":
                setSelectedTown(selected)
                setSelectedCode(null)
                setSearchData({
                    ...searchData,
                    town: selected.value
                })
                get_code(selected.value)
                handleTableData(selected.value, searchData?.customer_reference_code);
                break

            case "customer_reference_code":
                setSelectedCode(selected)
                setSearchData({
                    ...searchData,
                    customer_reference_code: selected.value,
                })
                handleTableData(searchData?.town, selected.value);
                break

            default:
                break
        }
    }

    function handleTableData(town = "", customer_reference_code = "") {
        axios
            .get(`${API_URL}customer/customer-by-marketing-executive?town=${town}&customer_reference_code=${customer_reference_code}`, {
                headers: {
                    'x-access-token': accessToken,
                },
            }).then(res => {
                var list = []
                let result = res.data.CustomerList
                result.map((item, indx) => {

                    item.id = indx + 1

                    item.action = (
                        <div style={{ justifyContent: "center" }}>
                            <i className="fas fa-eye"
                                style={{
                                    cursor: "pointer",
                                    fontSize: "1em",
                                    marginRight: "0.5rem",
                                    marginLeft: "0.2rem"
                                }}
                                onClick={() => {
                                    handleClickOpenModal(item?.customer_id)
                                }}
                            ></i>
                        </div>
                    )

                    list.push(item)
                })
                setTableData(list)
            })
    }

    const data = {
        columns: [
            {
                label: "#",
                field: "id",
                width: 50,
            },
            {
                label: "Customer",
                field: "customer_name",
                width: 150,
            },
            {
                label: "Mobile",
                field: "customer_contact_number",
                width: 150,
            },
            {
                label: "Wallet Amount",
                field: "customer_wallet_amount",
                width: 150,
            },
            {
                label: "Customer Point",
                field: "customer_point1",
                width: 50,
            },
            {
                label: "Action",
                field: "action",
                width: 50,
            },
        ],
        rows: tableData,
    }

    function reset() {
        setSelectedCode(null)
        setSelectedTown(null)
        handleTableData()
    }

    function handleClickOpenModal(id) {
        axios
            .get(`${API_URL}customer/marketing-executive-customer-details?customerId=${id}`, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then(res => {
                let result = res.data.customerDetails;
                let Data = [];

                result.map((item, indx) => {

                    item.id = indx + 1
                    item.orderDate = moment(item?.order_date).format("DD-MM-YYYY")
                    item.orderTime = moment(item?.order_time, "hh:mm s").format("hh:mm a")
                    item.shopName = item?.shop?.shop_name;
                    item.shopNo = item?.shop?.shop_mob;

                    Data.push(item)
                })
                setTableDetails(Data)
            })
        setPopupView(true)
    }

    const countData = {
        columns: [
            {
                label: "#",
                field: "id",
                width: 50,
            },
            {
                label: "Order Date",
                field: "orderDate",
                width: 50,
            },
            {
                label: "Order Time",
                field: "orderTime",
                width: 50,
            },
            {
                label: "UniqueID",
                field: "orders_unique_id",
                width: 50,
            },
            {
                label: "Shop Name",
                field: "shopName",
                width: 50,
            },
            {
                label: "Shop Mob.",
                field: "shopNo",
                width: 50,
            },
            {
                label: "Amount",
                field: "order_grand_amount",
                width: 50,
            },
        ],
        rows: tableDetails,
    }

    function handleCloseModal() {
        setPopupView(false)
    }

    const exportData = tableData.map((item, index) => ({
        No: index + 1,
        "Customer": item.customer_name,
        "Mobile":`'${item.customer_contact_number}'`,
        "Wallet Amount": item.customer_wallet_amount,
        "Customer Point": item.customer_point1,
    }));

   
    function getFileName() {
        return `Customer List${moment().format("YYYY-MM-DD_HH-mm-ss")}.csv`;
    }

    return (
        <>
            <Modal show={popupView} centered={true} size="lg" toggle={handleCloseModal}>
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Purchase Details</h5>
                    <button type="button" onClick={() => setPopupView(false)}
                        className="close" data-dismiss="modal" aria-label="close"
                    ></button>
                </div>

                <div className="modal-body">
                    <MDBDataTable
                        responsive
                        bordered
                        id="countTableId"
                        data={countData}
                        searching={true}
                        info={true}
                        entries={20}
                        disableRetreatAfterSorting={true}
                    />
                </div>
            </Modal>

            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumb title='Home' breadcrumbItem="Marketing Executive" />

                    <Row>
                        <Col md="12">
                            <Card>
                                <CardBody>

                                    <Row>
                                        <Col md="3">
                                            <Label>Town</Label>
                                            <Select
                                                name="town"
                                                classNamePrefix="select2-selection"
                                                value={selectedTown}
                                                onChange={(selected) => {
                                                    handleSelect(selected, "town")
                                                }}
                                                options={townOptions}
                                            />
                                        </Col>

                                        <Col md="3">
                                            <Label>Reference Code</Label>
                                            <Select
                                                name="customer_reference_code"
                                                classNamePrefix="select2-selection"
                                                value={selectedCode}
                                                onChange={(selected) => {
                                                    handleSelect(selected, "customer_reference_code")
                                                }}
                                                options={codeOptions}
                                            />
                                        </Col>

                                        <Col md="3" style={{ marginTop: "27px" }}>
                                            <Button color="danger" type="submit" onClick={() => reset()}>Reset</Button>
                                            <Button color="success" type="submit"tag="a" style={{ color: 'white',marginLeft:"4px" }}><CSVLink data={exportData} filename={getFileName()} style={{ color: 'white', textDecoration: 'none'}}>Export</CSVLink></Button>
                                        </Col>
                                    </Row>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <MDBDataTable
                                        responsive
                                        id="executiveTableId"
                                        bordered
                                        data={data}
                                        searching={true}
                                        info={true}
                                        disableRetreatAfterSorting={true}
                                        entries={20}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </div>
        </>
    )
}

export default MarketingExecutive;