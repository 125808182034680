import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import moment from "moment";
import $ from "jquery";
import { getCategoriesOptions } from "../../../store/actions";
// Redux
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { formatMoney } from "../../../helpers/generalFunctions";
import { getDate } from "../../../helpers/globalFunctions";
import { CSVLink } from "react-csv";
import "./stockLog.scss";

const TopCustomers = (props) => {
  const [stockForTable, setStockForTable] = useState([]);

  const [subcategoryOptionsGroup, setsubcategoryOptionsGroup] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedsubCategory, setSelectedSubCategory] = useState("");
  const [productOptions, setProductOptions] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [master, setMaster] = useState({});
  const [dataToBeExported, setDataToBeExported] = useState([]);

  const categoryListOptions = useSelector(
    (state) => state.categories.categoryOptions
  );
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const dispatch = useDispatch();
  useEffect(() => {
    handleTableData();
    dispatch(getCategoriesOptions());
    fetch_all_company();
  }, []);

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "stock_log_created_at",
        sort: "asc",
        width: 400,
      },
      {
        label: "Time",
        field: "stock_log_time",
        sort: "asc",
        width: 400,
      },
      {
        label: "Code",
        field: "product_sku",
        sort: "asc",
        width: 200,
      },
      {
        label: "Name",
        field: "product_name_english",
        sort: "asc",
        width: 200,
      },
      {
        label: "IN",
        field: "stock_in",
        sort: "asc",
        width: 200,
      },
      {
        label: "OUT",
        field: "stock_out",
        sort: "asc",
        width: 200,
      },
      {
        label: "Opening",
        field: "stock_log_product_qty",
        sort: "asc",
        width: 200,
      },
      {
        label: "Closing",
        field: "stock_log_current_qty",
        sort: "asc",
        width: 200,
      },
      {
        label: "Cost",
        field: "stock_log_product_cost",
        sort: "asc",
        width: 200,
      },
      {
        label: "Comments",
        field: "stock_log_activity",
        sort: "asc",
        width: 200,
      },
      {
        label: "User",
        field: "fname",
        sort: "asc",
        width: 200,
      },
    ],
    rows: stockForTable,
  };
  let categoryOptionsData =
    categoryListOptions &&
    categoryListOptions.map((item) => {
      return {
        label: item.category_name_english,
        value: item.category_id,
      };
    });

  const categoriesOptionsGroup = [
    {
      options: categoryOptionsData,
    },
  ];

  function fetch_all_company() {
    axios
      .get(`${API_URL}company/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var company_data =
          res.data.data &&
          res.data.data.map((item) => {
            return {
              label: item.branch_name,
              value: item.branch_id,
            };
          });
        setCompanyOptions([
          {
            options: company_data,
          },
        ]);
      });
  }
  function handleTableData(
    from_date = "",
    to_date = "",
    branch_id = "",
    category_id = "",
    subcategory_id = "",
    product_id = ""
  ) {
    var url = `${API_URL}report/stockLog`;

    url =
      url +
      "?from_date=" +
      from_date +
      "&to_date=" +
      to_date +
      "&branch_id=" +
      branch_id +
      "&category_id=" +
      category_id +
      "&subcategory_id=" +
      subcategory_id +
      "&product_id=" +
      product_id;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let stockData = [];
        let dataToBeExported = [];
        result.map((item, index) => {
          let exportItem = {};

          item.id = index + 1;
          if (item.stock_log_type == 1) {
            item.stock_log_type = "Purchase";
          } else if (item.stock_log_type == 2) {
            item.stock_log_type = "Purchase Return";
          } else if (item.stock_log_type == 3) {
            item.stock_log_type = "Sale";
          } else if (item.stock_log_type == 4) {
            item.stock_log_type = "Sale Return";
          }
          item.stock_log_created_at = moment(item.stock_log_created_at).format(
            "DD/MM/YYYY"
          );
          item.stock_log_product_cost = formatMoney(
            item.stock_log_product_cost
          );
          item.fname = "";
          if (item.User != null) {
            item.fname = item.User.fname;
          }
          item.product_name_english = "";
          item.product_sku = "";
          if (item.product != null) {
            item.product_name_english = item.product.product_name_english;
            item.product_sku = item.product.product_sku_autogen;
          }
          item.stock_in = "";
          item.stock_out = "";
          if (item.stock_log_in_out == "1") item.stock_in = item.stock_log_qty;
          else item.stock_out = item.stock_log_qty;

          //setting export data
          exportItem.Id = item.id;
          exportItem.Date = item.stock_log_created_at;
          exportItem.Time = item.stock_log_time;
          exportItem.Code = item.product_sku;
          exportItem.Name = item.product_name_english;
          exportItem.IN = item.stock_in;
          exportItem.OUT = item.stock_out;
          exportItem.Opening = item.stock_log_product_qty;
          exportItem.Closing = item.stock_log_current_qty;
          exportItem.Cost = item.stock_log_product_cost;
          exportItem.Comments = item.stock_log_activity;
          exportItem.User = item.fname;

          stockData.push(item);
          dataToBeExported.push(exportItem);
        });
        setStockForTable(stockData);
        setDataToBeExported(dataToBeExported);
      });
  }

  // #####################################################################

  const handleSelectChange = (selected, name) => {
    let category_id = master?.category_id ? master.category_id : "";
    let subcategory_id = master?.sub_category_id ? master.sub_category_id : "";
    let branch_id = master?.company_id ? master.company_id : "";
    let product_id = master?.product_id ? master.product_id : "";
    let date1 = master?.from_date ? master.from_date : "";
    let date2 = master?.to_date ? master.to_date : "";
    switch (name) {
      case "category":
        setSelectedCategory(selected);
        setSelectedSubCategory(null);
        setSelectedProduct(null);
        setProductOptions([]);
        setMaster({
          ...master,
          ["category_id"]: selected.value,
          ["sub_category_id"]: "",
          ["product_id"]: "",
        });
        axios
          .get(`${API_URL}subcategory?category_id=` + selected.value, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            var subCategoryData =
              res.data &&
              res.data.map((item) => {
                return {
                  label: item.subcategory_name_english,
                  value: item.subcategory_id,
                };
              });
            setsubcategoryOptionsGroup([
              {
                options: subCategoryData,
              },
            ]);
          });
        handleTableData(date1, date2, branch_id, selected.value, "", "");
        break;
      case "sub_category":
        setSelectedSubCategory(selected);
        setSelectedProduct(null);
        setMaster({
          ...master,
          ["sub_category_id"]: selected.value,
          ["product_id"]: "",
        });
        axios({
          method: "post",
          url: `${API_URL}product/by_subcategory`,
          data: {
            subcategory_id: selected.value,
          },
          headers: {
            "x-access-token": accessToken,
          },
        }).then((res) => {
          var productData =
            res.data.data &&
            res.data.data.map((item) => {
              return {
                label: item.product_name_english,
                value: item.product_id,
              };
            });
          setProductOptions([
            {
              options: productData,
            },
          ]);
        });
        handleTableData(
          date1,
          date2,
          branch_id,
          category_id,
          selected.value,
          ""
        );
        break;
      case "product":
        setSelectedProduct(selected);
        setMaster({
          ...master,
          ["product_id"]: selected.value,
        });
        handleTableData(
          date1,
          date2,
          branch_id,
          category_id,
          subcategory_id,
          selected.value
        );
        break;
      case "company":
        setSelectedCompany(selected);
        setMaster({
          ...master,
          ["company_id"]: selected.value,
        });
        handleTableData(
          date1,
          date2,
          selected.value,
          category_id,
          subcategory_id,
          product_id
        );
        break;
      default:
        break;
    }
  };

  function handleDate(e) {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
    let category_id = master?.category_id ? master.category_id : "";
    let subcategory_id = master?.sub_category_id ? master.sub_category_id : "";
    let branch_id = master?.company_id ? master.company_id : "";
    let product_id = master?.product_id ? master.product_id : "";
    handleTableData(
      date1,
      date2,
      branch_id,
      category_id,
      subcategory_id,
      product_id
    );
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Report - Stock Log" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="3">
                      <div className="mb-2">
                        <Label htmlFor="validationCustom05">From</Label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            id="from_date"
                            name="from_date"
                            onChange={handleDate}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-2">
                        <Label htmlFor="validationCustom05">To</Label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            id="to_date"
                            name="to_date"
                            onChange={handleDate}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Company</Label>
                        <Select
                          name="company_id"
                          value={selectedCompany}
                          onChange={(selected) => {
                            handleSelectChange(selected, "company");
                          }}
                          options={companyOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="mb-2">
                        <Label>Category</Label>
                        <Select
                          name="category_id"
                          id="category_id"
                          required
                          value={selectedCategory}
                          onChange={(selected) => {
                            handleSelectChange(selected, "category");
                          }}
                          options={categoriesOptionsGroup}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="mb-2">
                        <Label>Sub Category</Label>
                        <Select
                          name="sub_category_id"
                          value={selectedsubCategory}
                          onChange={(selected) => {
                            handleSelectChange(selected, "sub_category");
                          }}
                          options={subcategoryOptionsGroup}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Product</Label>
                        <Select
                          name="product_id"
                          value={selectedProduct}
                          onChange={(selected) => {
                            handleSelectChange(selected, "product");
                          }}
                          options={productOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="1">
                      <div className="mt-4" style={{ paddingTop: "7px" }}>
                        <Button color="success" type="submit">
                          <CSVLink
                            data={dataToBeExported}
                            filename={"StarLee_stock_log_report_" + getDate(new Date()) + ".xls"}
                            style={{ color: "white" }}
                          >
                            Export
                          </CSVLink>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <MDBDataTable
                    id="stockLog"
                    responsive
                    striped
                    bordered
                    data={data}
                    info={true}
                    searching={true}
                    disableRetreatAfterSorting={true}
                    entries={25}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => { };

export default withRouter(connect(mapStateToProps, {})(TopCustomers));
