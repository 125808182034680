import React, { useState, useEffect,useRef } from "react";
import PropTypes from "prop-types";
import toastr from "toastr";
import {jwtDecode }from "jwt-decode";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import { MDBDataTable } from "mdbreact";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import moment from "moment";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Modal,
  Progress,
} from "reactstrap";

import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  getSliders,
  addSlider,
  deleteSlider,
  toggleSliderActiveStatus,
  apiError,
  updateSlider,
} from "../../store/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../components/Common/Breadcrumb";

const Sliders = (props) => {

  const [toggleSwitch, settoggleSwitch] = useState(true);
  const [sliderObject, setSliderObject] = useState({});
  const [sliderDetails, setsliderdetails] = useState({});
  const [slidersTemp, setSlidersTemp] = useState([]);
  const [sliderIdTobeUpdated, setSliderIdToBeUpdated] = useState(null);
  const [sliderIdToBeDeleted, setSliderIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [userId, setUserId] = useState(1);
  const formRef = useRef();
  const [isUploadjpg, setIsUploadWebjpg] = useState(false);
  const [isUploadwebp, setIsUploadwebp] = useState(false);
  const [isUploadApp, setIsUploadAppjpg] = useState(false);
  const [isUploadappwebp,setIsUploadAppwebp]=useState(false);
  const [sliderWebFileName,setsliderWebFileName] = useState(null);
  const [sliderAppFileName,setsliderAppFileName] = useState(null);
  const [selectedType,setSelectedType] = useState(null);
  const [selectedFor,setSelectedFor] = useState(null);
  const [sliderForOptionsGroup, setsliderforOptionsGroup] = useState([]);
  const [isDisplayFor,setisDisplayFor]=useState('block');
  let slidertypeOptionsData =[{label:"Home Page",value:1},{label:"Section",value:2},{label:"Brand",value:3},{label:"Category",value:4},{label:"Sub Category",value:5},{label:"thirdlevel Category",value:6}];
  const types_arr =["Home Page","Section","Brand","Category","Sub Category","thirdlevel Category"];
  const slidertypeOptionsGroup = [
    {
      options: slidertypeOptionsData,
    },
  ];
  const [uploadProgress, setUploadProgress] = useState();
  const {
    sliders,
    addingSlider,
    addSliderResponse,
    deleteSliderResponse,
    updateSliderResponse,
    statusSliderResponse,
    error,
  } = useSelector((state) => state.sliders);

  const dispatch = useDispatch();
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  let closeModal = () => {
    setShowModal(false);
    setsliderdetails({});
  };
  useEffect(() => {
    //getting the slider list
    dispatch(getSliders());
  }, []);

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = jwtDecode(localStorage.getItem("authUser"));

      setUserId(obj.user);
      setSliderObject({
        ["auth_userid"]: userId,
      });
    }
  }, [props.success, props]);

  useEffect(() => {
    if (addSliderResponse.type === "success") {
      toastr.success(addSliderResponse.message);
      setSliderObject({});
    } else if (addSliderResponse.type === "failure") {
      toastr.error(error.data.message, addSliderResponse.message);
    }
  }, [addSliderResponse]);

  useEffect(() => {
    if (deleteSliderResponse.type === "success") {
      toastr.success(deleteSliderResponse.message);
    } else if (deleteSliderResponse.type === "failure") {
      toastr.error(error.data.message, deleteSliderResponse.message);
    }
  }, [deleteSliderResponse]);

  useEffect(() => {
    if (statusSliderResponse.type === "success") {
      toastr.success(statusSliderResponse.message);
    } else if (statusSliderResponse.type === "failure") {
      toastr.error(error.data.message, statusSliderResponse.message);
    }
  }, [statusSliderResponse]);

  useEffect(() => {
    if (updateSliderResponse.type === "success") {
      setShowModal(false);
      setSliderIdToBeUpdated(null);
      // setPasswordObject({});
      setSliderIdToBeUpdated(null);
      setSliderObject({});
      // clearSliderDetails();
      toastr.success(updateSliderResponse.message);
    } else if (updateSliderResponse.type === "failure") {
      toastr.error(error.data.message, updateSliderResponse.message);
    }
  }, [updateSliderResponse]);

  let preupdateSlider = (item) => {

    setSliderIdToBeUpdated(item.slider_id);
    setSliderObject(item);
    let type = {
      label  : types_arr[item.slider_type-1],
      value:item.slider_type
    }
    if(item.slider_type==2)
    {
      if(item.section)
      {
        var slider_for = {
          label  : item.section.section_name,
          value:item.slider_type_id
        }
      }
      else
      {
        var slider_for = {
          label  : '',
          value:''
        }
      }
      
    }
    else if(item.slider_type==3)
    {
      if(item.brand)
      {
        var slider_for = {
          label  : item.brand.brand_name_english,
          value:item.slider_type_id
        }
      }
      else
      {
        var slider_for = {
          label  : '',
          value:''
        }
      }
     
    }
    else if(item.slider_type==4)
    {
      if(item.category)
      {
        var slider_for = {
          label  : item.category.category_name_english,
          value:item.slider_type_id
        }
      }
      else
      {
        var slider_for = {
          label  : '',
          value:''
        }
      }
     
      
    }
    else if(item.slider_type==5)
    {
      if(item.subCategory)
      {
        var slider_for = {
          label  : item.subCategory.subcategory_name_english,
          value:item.slider_type_id
        }
      }
      else
      {
        var slider_for = {
          label  : '',
          value:''
        }
      }
     
    }
    else if(item.slider_type==6)
    {
      if(item.third_category)
      {
        var slider_for = {
          label  : item.third_category.thrdcate_name_english,
          value:item.slider_type_id
        }
      }
      else
      {
        var slider_for = {
          label  : '',
          value:''
        }
      }
      
    }
   
    setSelectedType(type);
    if(item.slider_type==1)
    {
      setisDisplayFor('none');
    }
    handleSelectedType(type);
    setSelectedFor(slider_for);
    if (
      item.slider_web &&
      item.slider_web.length > 2
    ) {
      if (
        item.slider_web.indexOf(
          "public/uploads/slider/"
        ) > -1
      ) {
        setSliderObject({
          ...item,
          [`slider_web`]:
            item.slider_web.slice(
              item.slider_web.indexOf(
                "public/uploads/slider/"
              ) ((API_URL.length-1)+12)
            ),
        });
      } else {
        setSliderObject({
          ...item,
          [`slider_web`]:
            item.slider_web,
        });
      }
    } else {
      setSliderObject({
        ...item,
        [`slider_web`]: "",
      });
    }



    if (
      item.slider_webp &&
      item.slider_webp.length > 2
    ) {
      if (
        item.slider_webp.indexOf(
          "public/uploads/slider/"
        ) > -1
      ) {
        setSliderObject({
          ...item,
          [`slider_webp`]:
            item.slider_webp.slice(
              item.slider_webp.indexOf(
                "public/uploads/slider/"
              ) + ((API_URL.length-1)+12)
            ),
        });
      } else {
        setSliderObject({
          ...item,
          [`slider_webp`]:
            item.slider_webp,
        });
      }
    } else {
      setSliderObject({
        ...item,
        [`slider_webp`]: "",
      });
    }

    if (
      item.slider_app &&
      item.slider_app.length > 2
    ) {
      if (
        item.slider_app.indexOf(
          "public/uploads/slider/"
        ) > -1
      ) {
        setSliderObject({
          ...item,
          [`slider_app`]:
            item.slider_app.slice(
              item.slider_app.indexOf(
                "public/uploads/slider/"
              ) + ((API_URL.length-1)+12)
            ),
        });
      } else {
        setSliderObject({
          ...item,
          [`slider_app`]:
            item.slider_app,
        });
      }
    } else {
      setSliderObject({
        ...item,
        [`slider_app`]: "",
      });
    }

    if (
      item.slider_app_webp &&
      item.slider_app_webp.length > 2
    ) {
      if (
        item.slider_app_webp.indexOf(
          "public/uploads/slider/"
        ) > -1
      ) {
        setSliderObject({
          ...item,
          [`slider_app_webp`]:
            item.slider_app_webp.slice(
              item.slider_app_webp.indexOf(
                "public/uploads/slider/"
              ) + ((API_URL.length-1)+12)
            ),
        });
      } else {
        setSliderObject({
          ...item,
          [`slider_app_webp`]:
            item.slider_app_webp,
        });
      }
    } else {
      setSliderObject({
        ...item,
        [`slider_app_webp`]: "",
      });
    }
  };

  useEffect(() => {
    let unitsDuplicate = JSON.parse(JSON.stringify(sliders));
    // console.log(unitsDuplicate);
    let unitData = [];
    unitsDuplicate.map((item, index) => {
        item.fname = "";
      if(item.User!=null)
      {
        item.fname = item.User.fname+" "+item.User.lname;
      }
      
      item.auth_userid = userId;
      item.slider_from_date = moment(new Date(item.slider_from_date)).format("YYYY-MM-DD");
      item.slider_to_date = moment(new Date(item.slider_to_date)).format("YYYY-MM-DD");
      item.action = (
        <div
          style={{
            textAlign: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
             <i
            className="fas fa-eye"
            style={{
              fontSize: "1em",
              cursor: "pointer",
              marginLeft: "0.5em",
              marginRight: "0.5em",
            }}
            onClick={() => {
              fetch_slider_details(item.slider_id);
              setShowModal(true);
            }}
          ></i>
            <i
              className="far fa-edit"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.5em",
                marginRight: "0.5em",
              }}
              onClick={() => {
                toTop();
                setSliderIdToBeUpdated(item.slider_id);
                preupdateSlider(item);
              }}
            ></i>{" "}
            <i
              className="far fa-trash-alt"
              style={{
                fontSize: "1em",
                cursor: "pointer",
              }}
              onClick={() => {
                setSliderIdToBeDeleted(item.slider_id);
                setConfirmDeleteAlert(true);
              }}
            ></i>{" "}
          </div>{" "}
        </div>
      );
      item.id = index + 1;
      item.slider_icon_svgs="";
      // console.log(item.slider_icon_svg, "item.slider_icon_svg");
      if(item.slider_type==1)
      {
        item.slider_type1="Home Page";
      }
      else if(item.slider_type==2)
      {
        item.slider_type1="Section";
      }
      else if(item.slider_type==3)
      {
        item.slider_type1="Brand";
      }
      else if(item.slider_type==4)
      {
        item.slider_type1="Category";
      }
      else if(item.slider_type==5)
      {
        item.slider_type1="Sub Category";
      }
      else if(item.slider_type==6)
      {
        item.slider_type1="Thirdlevel Category";
      }
      else
      {
        item.slider_type1="";
      }
      item.slider_web1="";
      if (item.slider_web != "") {
        
        item.slider_web1 = (
          <div
            style={{
              textAlign: "center",
            }}
          >
            <img
              src={
                process.env.REACT_APP_APIURL +
                `public/uploads/slider/${item.slider_web}`
              }
              height="50"
              width="50"
            />
          </div>
        );
      }
      item.slider_app1="";
      if (item.slider_app != "") {
        
        item.slider_app1 = (
          <div
            style={{
              textAlign: "center",
            }}
          >
            <img
              src={
                process.env.REACT_APP_APIURL +
                `public/uploads/slider/${item.slider_app}`
              }
              height="50"
              width="50"
            />
          </div>
        );
      }
      if (item.slider_is_active ==0) {
        item.slider_is_active1 = (
          <div
            style={{
              textAlign: "center",
              width: "100%",
            }}
          >
            <div
              class="form-check form-switch mb-3"
              style={{
                textAlign: "center",
                marginLeft: "40%",
              }}
            >
              <input
                type="checkbox"
                class="form-check-input"
                id="customSwitch2"
                defaultChecked
                onClick={(e) => {
                  dispatch(toggleSliderActiveStatus(item.slider_id, userId));
                  settoggleSwitch(!toggleSwitch);
                }}
              />{" "}
            </div>{" "}
          </div>
        );
      } else {
        // console.log(item.slider_is_active);
        item.slider_is_active1 = (
          <div
            style={{
              textAlign: "center",
              width: "100%",
            }}
          >
            <div
              class="form-check form-switch mb-3"
              style={{
                textAlign: "center",
                marginLeft: "40%",
              }}
            >
              <input
                type="checkbox"
                class="form-check-input"
                id="customSwitch2"
                onClick={(e) => {
                  dispatch(toggleSliderActiveStatus(item.slider_id, userId));
                  settoggleSwitch(!toggleSwitch);
                }}
              />{" "}
            </div>{" "}
          </div>
        );
      }

      unitData.push(item);
    });
    setSlidersTemp(unitData);
  }, [sliders]);

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 10,
      },

      {
        label: "Name",
        field: "slider_name",
        sort: "asc",
        width: 70,
      },
      {
        label: "Type",
        field: "slider_type1",
        sort: "asc",
        width: 70,
      },
      {
        label: "Web",
        field: "slider_web1",
        sort: "asc",
        width: 90,
      },
      {
        label: "App",
        field: "slider_app1",
        sort: "asc",
        width: 90,
      },
      {
        label: "Status",
        field: "slider_is_active1",
        sort: "asc",
        width: 90,
      },

      {
        label: "Added by",
        field: "fname",
        sort: "asc",
        width: 100,
      },

      {
        label: "Action",
        field: "action",
        sort: "disabled",
        width: 100,
      },
    ],
    rows: slidersTemp,
  };
  const make_sliderfilename_web = (e) => {
    var length           = 16;
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
 charactersLength));
   }
   result = result +'_slider';
   setsliderWebFileName(result);
   return result;
}
const make_sliderfilename_app = (e) => {
  var length           = 16;
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * 
charactersLength));
 }
 result = result +'_slider';
 setsliderAppFileName(result);
 return result;
}
  const deleteSliderWeb = () => {
    setIsUploadWebjpg(false);
    setSliderObject({
      ...sliderObject,
      [`slider_web`]: "",
    });
  };
  const deleteSliderApp = () => {
    setIsUploadAppjpg(false);
    setSliderObject({
      ...sliderObject,
      [`slider_app`]: "",
    });
  };

  const deleteSliderWebwebp = () => {
    setIsUploadwebp(false);
    setSliderObject({
      ...sliderObject,
      [`slider_webp`]: "",
    });
  };
  const deleteSliderAppwebp = () => {
    setIsUploadAppwebp(false);
    setSliderObject({
      ...sliderObject,
      [`slider_app_webp`]: "",
    });
  };
  const uploadSliderWeb = (e) => {
    
    e.preventDefault();
    var fileInput = 
    document.getElementById('slider_web');
  
var filePath = fileInput.value;

// Allowing file type
//var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
        
  
if (!allowedExtensions.exec(filePath)) {
    toastr.error("File extension not supported")
    return false;
} 

if(!isUploadwebp)
    {
     var barnd_file_name=make_sliderfilename_web();
    }
    else
    {
      var barnd_file_name = sliderWebFileName;
    }
    setIsUploadWebjpg(true);

    let fd = new FormData();
    fd.append("image", e.target.files[0]);
    axios
      .post(`${API_URL}slider/uploadSliderSingleImage?name=${barnd_file_name}`, fd, {
        headers: {
          "x-access-token": accessToken,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setSliderObject({
            ...sliderObject,
            [`slider_web`]: response.data.file.filename,
          });
        } else {
          toastr.error(response.data.message);
        }
      })
      .catch(err=>{
        deleteSliderWeb();
        toastr.error("File extension not supported");
      });
  };

  const uploadSliderWebp = (e) => {
    e.preventDefault();
    var fileInput = 
    document.getElementById('slider_webp');
  
var filePath = fileInput.value;

// Allowing file type
//var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
var allowedExtensions = /(\.webp)$/i;
        
  
if (!allowedExtensions.exec(filePath)) {
    toastr.error("File extension not supported")
    return false;
} 

if(!isUploadjpg)
    {
     var barnd_file_name=make_sliderfilename_web();
    }
    else
    {
      var barnd_file_name = sliderWebFileName;
    }
    setIsUploadwebp(true);

    let fd = new FormData();
    fd.append("image", e.target.files[0]);
    axios
      .post(`${API_URL}slider/uploadSliderSingleImageWebp?name=${barnd_file_name}`, fd, {
        headers: {
          "x-access-token": accessToken,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setSliderObject({
            ...sliderObject,
            [`slider_webp`]: response.data.file.filename,
          });
        } else {
          toastr.error(response.data.message);
        }
      })
      .catch(err=>{
        deleteSliderWebwebp();
        toastr.error("File extension not supported");
      });;
  };

  const uploadSliderApp = (e) => {
    
    e.preventDefault();
    var fileInput = 
    document.getElementById('slider_app');
  
var filePath = fileInput.value;

// Allowing file type
//var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
        
  
if (!allowedExtensions.exec(filePath)) {
    toastr.error("File extension not supported")
    return false;
} 

if(!isUploadappwebp)
    {
     var barnd_file_name=make_sliderfilename_app();
    }
    else
    {
      var barnd_file_name = sliderAppFileName;
    }
    setIsUploadAppjpg(true);

    let fd = new FormData();
    fd.append("image", e.target.files[0]);
    axios
      .post(`${API_URL}slider/uploadSliderSingleImage?name=${barnd_file_name}`, fd, {
        headers: {
          "x-access-token": accessToken,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setSliderObject({
            ...sliderObject,
            [`slider_app`]: response.data.file.filename,
          });
        } else {
          toastr.error(response.data.message);
        }
      })
      .catch(err=>{
        deleteSliderApp();
        toastr.error("File extension not supported");
      });
  };
  const uploadSliderAppWebp = (e) => {
    e.preventDefault();
    var fileInput = 
    document.getElementById('slider_app_webp');
  
var filePath = fileInput.value;

// Allowing file type
//var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
var allowedExtensions = /(\.webp)$/i;
        
  
if (!allowedExtensions.exec(filePath)) {
    toastr.error("File extension not supported")
    return false;
} 

if(!isUploadApp)
    {
     var barnd_file_name=make_sliderfilename_app();
    }
    else
    {
      var barnd_file_name = sliderAppFileName;
    }
    setIsUploadAppwebp(true);

    let fd = new FormData();
    fd.append("image", e.target.files[0]);
    axios
      .post(`${API_URL}slider/uploadSliderSingleImageWebp?name=${barnd_file_name}`, fd, {
        headers: {
          "x-access-token": accessToken,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setSliderObject({
            ...sliderObject,
            [`slider_app_webp`]: response.data.file.filename,
          });
        } else {
          toastr.error(response.data.message);
        }
      })
      .catch(err=>{
        deleteSliderWebwebp();
        toastr.error("File extension not supported");
      });;
  };

  let handleSelectedType = (e)=>{
    let name = "slider_type";
    let value = e.value;
    setSelectedFor(null);
    setsliderforOptionsGroup([]);
    sliderObject.slider_type_id='';
    setSliderObject(sliderObject=>{
      return{
        ...sliderObject,
        ["slider_type_id"]:sliderObject.slider_type_id
      }
    })
    if(value=="1")
    {
      setisDisplayFor('none');
  
    }
    else
    {
      setisDisplayFor('block');
      if(value==2)
      {
        axios.get(`${API_URL}section/getOptions`,
        {
          headers: {
            'x-access-token': accessToken,
          },
        }).then((res) => {
          var sectionOptionsData =
            res.data.data &&
            res.data.data.map((item) => {
              return {
                label: item.section_name,
                value: item.section_id,
              };
            });
            setsliderforOptionsGroup([
            {
              options: sectionOptionsData,
            },
          ]);
        });
      }
      else if(value==3)
      {
        axios.get(`${API_URL}brands`,
        {
          headers: {
            'x-access-token': accessToken,
          },
        }).then((res) => {
          var brandOptionsData =
            res.data.data &&
            res.data.data.map((item) => {
              return {
                label: item.brand_name_english,
                value: item.brand_id,
              };
            });
            setsliderforOptionsGroup([
            {
              options: brandOptionsData,
            },
          ]);
        });
      }
      else if(value==4)
      {
        axios.get(`${API_URL}category/getOptions`,
        {
          headers: {
            'x-access-token': accessToken,
          },
        }).then((res) => {
          var categoryOptionsData =
            res.data.data &&
            res.data.data.map((item) => {
              return {
                label: item.category_name_english,
                value: item.category_id,
              };
            });
            setsliderforOptionsGroup([
            {
              options: categoryOptionsData,
            },
          ]);
        });
      }
      else if(value==5)
      {
        axios.get(`${API_URL}subcategory`,
        {
          headers: {
            'x-access-token': accessToken,
          },
        }).then((res) => {
          var categoryOptionsData =
            res.data.data &&
            res.data.data.map((item) => {
              return {
                label: item.subcategory_name_english,
                value: item.subcategory_id,
              };
            });
            setsliderforOptionsGroup([
            {
              options: categoryOptionsData,
            },
          ]);
        });
      }
      else if(value==6)
      {
        axios.get(`${API_URL}thirdcategory`,
        {
          headers: {
            'x-access-token': accessToken,
          },
        }).then((res) => {
          var categoryOptionsData =
            res.data.data &&
            res.data.data.map((item) => {
              return {
                label: item.thrdcate_name_english,
                value: item.thrdcate_id,
              };
            });
            setsliderforOptionsGroup([
            {
              options: categoryOptionsData,
            },
          ]);
        });
      }

    }
    let Type={
      label:e.label,
      value:e.value
    }
    setSelectedType(Type);
    setSliderObject({
      ...sliderObject,
      [name]: value,
    });

  }
  let handleSelectedFor = (e)=>{
    let name = "slider_type_id";
    let value = e.value;
    let Type={
      label:e.label,
      value:e.value
    }
    setSelectedFor(Type);
    setSliderObject({
      ...sliderObject,
      [name]: value,
    });

  }

  let handleChangeInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setSliderObject({
      ...sliderObject,
      [name]: value,
    });
  };
  function fetch_slider_details(product_id) {
    let product_fetch_result = {};
    axios.get(`${API_URL}slider?id=` + product_id,
    {
      headers: {
        'x-access-token': accessToken,
      },
    }).then((res) => {
      if(res.data.data)
      {
        res.data.data.slider_type_name="";
        res.data.data.slider_from_date = moment(new Date(res.data.data.slider_from_date)).format("DD-MM-YYYY");
        res.data.data.slider_to_date = moment(new Date(res.data.data.slider_to_date)).format("DD-MM-YYYY");
        if(res.data.data.User!=null)
        {
          res.data.data.fname = (res.data.data.User.fname+" "+res.data.data.User.lname);
        }
        if(res.data.data.slider_type==1)
        {
          res.data.data.slider_type_name="";
        }
        else if(res.data.data.slider_type==2)
        {
          if(res.data.data.section!=null)
          {
            res.data.data.slider_type_name=res.data.data.section.section_name;
          }
          
        }
        else if(res.data.data.slider_type==3)
        {
          if(res.data.data.brand!=null)
          {
            res.data.data.slider_type_name=res.data.data.brand.brand_name_english;
          }
          
        }
        else if(res.data.data.slider_type==4)
        {
          if(res.data.data.category!=null)
          {
            res.data.data.slider_type_name=res.data.data.category.category_name_english;
          }
          
        }
        else if(res.data.data.slider_type==5)
        {
          if(res.data.data.subCategory!=null)
          {
            res.data.data.slider_type_name=res.data.data.subCategory.subcategory_name_english;
          }
          
        }
        else if(res.data.data.slider_type==6)
        {
          if(res.data.data.third_category!=null)
          {
            res.data.data.slider_type_name=res.data.data.third_category.thrdcate_name_english;
          }
          
        }
        res.data.data.slider_type = types_arr[res.data.data.slider_type-1];
        setsliderdetails(res.data.data);
      }
      else
      {
        setsliderdetails({});
      }
      
    }).catch(err=>{
      setsliderdetails({});
    });
  }

  const handleValidSubmitSlider = (event, values) => {
    //  console.log(sliderObject);
    //  return;
    event.preventDefault();
    if (
      sliderObject.slider_web &&
      sliderObject.slider_web.length > 2
    ) {
      if (sliderObject.slider_web.indexOf("uploads/slider/") > -1) {
        // sliderObject.slider_icon_svg.slice(sliderObject.slider_icon_svg.indexOf('uploads/slider-icons/') + 23)
        setSliderObject({
          ...sliderObject,
          [`slider_web`]: sliderObject.slider_web.slice(
            sliderObject.slider_web.indexOf("uploads/slider/") ((API_URL.length-1)+12)
          ),
        });
      }
    }
    if (
      sliderObject.slider_webp &&
      sliderObject.slider_webp.length > 2
    ) {
      if (sliderObject.slider_webp.indexOf("uploads/slider/") > -1) {
        // sliderObject.slider_icon_svg.slice(sliderObject.slider_icon_svg.indexOf('uploads/slider-icons/') + 23)
        setSliderObject({
          ...sliderObject,
          [`slider_webp`]: sliderObject.slider_webp.slice(
            sliderObject.slider_webp.indexOf("uploads/slider/") ((API_URL.length-1)+12)
          ),
        });
      }
    }
    if (
      sliderObject.slider_app &&
      sliderObject.slider_app.length > 2
    ) {
      if (sliderObject.slider_app.indexOf("uploads/slider/") > -1) {
        // sliderObject.slider_icon_svg.slice(sliderObject.slider_icon_svg.indexOf('uploads/slider-icons/') + 23)
        setSliderObject({
          ...sliderObject,
          [`slider_webp`]: sliderObject.slider_app.slice(
            sliderObject.slider_app.indexOf("uploads/slider/") ((API_URL.length-1)+12)
          ),
        });
      }
    }

    sliderIdTobeUpdated
      ? dispatch(updateSlider(sliderObject))
      : dispatch(addSlider(sliderObject));
      formRef.current.reset();
      setSliderObject({ ['auth_userid']: userId });
      setIsUploadwebp(false);
      setIsUploadAppjpg(false);
      setIsUploadAppwebp(false);
      setIsUploadWebjpg(false);
      setSelectedType(null);
      setSelectedFor(null);
      setsliderforOptionsGroup([]);
  };

  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            dispatch(deleteSlider(sliderIdToBeDeleted, userId));
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it ?
        </SweetAlert>
      ) : null}{" "}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Slider" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                <AvForm ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmitSlider(e, v);
                    }}>
                    <Row>
                      <Col md="3">
                        <Label>Slider Type</Label>
                        <Select
                            name="slider_type"
                            id='slider_type'
                            required
                            value={selectedType}
                            required
                            validate={{ required: { value: true } }}
                            errorMessage="Slider Type required"
                            onChange={(value) => {
                              handleSelectedType(value);
                            }}
                            options={slidertypeOptionsGroup}
                            classNamePrefix="select2-selection"
                          />
                      </Col>
                      <Col md="3" style={{display:isDisplayFor}}>
                      <Label>Slider For</Label>
                        <Select
                            name="slider_for"
                            id='slider_for'
                            required
                            value={selectedFor}
                            required
                            validate={{ required: { value: true } }}
                            errorMessage="Slider For required"
                            onChange={(value) => {
                              handleSelectedFor(value);
                            }}
                            options={sliderForOptionsGroup}
                            classNamePrefix="select2-selection"
                          />
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <AvField
                            type="text"
                            name="slider_name"
                            id="slider_name"
                            value={sliderObject.slider_name}
                            label="Slider Name"
                            errorMessage="Slider Name required"
                            onChange={handleChangeInput}
                            validate={{ required: { value: true } }}
                            // helpMessage="please select role"
                          ></AvField>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <AvField
                            type="text"
                            name="slider_position"
                            id="slider_position"
                            value={sliderObject.slider_position}
                            label="Slider Position"
                            errorMessage="Slider Position required"
                            onChange={handleChangeInput}
                            validate={{ required: { value: true }, pattern: {value: '^[0-9]+$', errorMessage: 'Only numbers are allowed'}, }}

                            // helpMessage="please select role"
                          ></AvField>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                        {sliderObject.slider_web &&
                          sliderObject.slider_web.length > 2 ? (
                            <div div className="img-wraps">
                              {" "}
                              {sliderObject.slider_web &&
                              sliderObject.slider_web.startsWith(
                                "http"
                              ) ? (
                                <img
                                  className="img-thumbnail"
                                  alt=""
                                  width="200"
                                  src={`${sliderObject.slider_web}`}
                                />
                              ) : (
                                <img
                                  className="img-thumbnail"
                                  alt=""
                                  width="200"
                                  src={`${API_URL}public/uploads/slider/${sliderObject.slider_web}`}
                                />
                              )}
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={deleteSliderWeb}
                                style={{ width: "200px" }}
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                          <AvField
                            type="file"
                            name="slider_web"
                            id="slider_web"
                            label={selectedType&&selectedType.value === 1 ?"Web JPG Image (1920x700)":"Web JPG Image (1920x500)"}
                            className="form-control"
                            errorMessage="Web Image required"
                            validate={{ required: { value: false } }}
                            onChange={uploadSliderWeb}
                            // helpMessage="please select role"
                          ></AvField>
                          )}
                          {uploadProgress && uploadProgress < 100 && (
                            <div className="mt-4">
                              {" "}
                              <Progress color="primary" value={uploadProgress}>
                                Uploading {uploadProgress}%
                              </Progress>
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                        {sliderObject.slider_webp &&
                          sliderObject.slider_webp.length > 2 ? (
                            <div div className="img-wraps">
                              {" "}
                              {sliderObject.slider_webp &&
                              sliderObject.slider_webp.startsWith(
                                "http"
                              ) ? (
                                <img
                                  className="img-thumbnail"
                                  alt=""
                                  width="200"
                                  src={`${sliderObject.slider_webp}`}
                                />
                              ) : (
                                <img
                                  className="img-thumbnail"
                                  alt=""
                                  width="200"
                                  src={`${API_URL}public/uploads/slider/${sliderObject.slider_webp}`}
                                />
                              )}
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={deleteSliderWebwebp}
                                style={{ width: "200px" }}
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                          <AvField
                            type="file"
                            name="slider_webp"
                            id="slider_webp"
                            label = {selectedType&&selectedType.value === 1 ?"Web Webp Image (1920x700)":"Web Webp Image (1920x500)"}
                            // label="Web Webp Image (1920x700)"
                            className="form-control"
                            errorMessage="Web webp Image required"
                            onChange={uploadSliderWebp}
                            validate={{ required: { value: false } }}
                            // helpMessage="please select role"
                          ></AvField>
                          )}
                          {uploadProgress && uploadProgress < 100 && (
                            <div className="mt-4">
                              {" "}
                              <Progress color="primary" value={uploadProgress}>
                                Uploading {uploadProgress}%
                              </Progress>
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                        {sliderObject.slider_app &&
                          sliderObject.slider_app.length > 2 ? (
                            <div div className="img-wraps">
                              {" "}
                              {sliderObject.slider_app &&
                              sliderObject.slider_app.startsWith(
                                "http"
                              ) ? (
                                <img
                                  className="img-thumbnail"
                                  alt=""
                                  width="200"
                                  src={`${sliderObject.slider_app}`}
                                />
                              ) : (
                                <img
                                  className="img-thumbnail"
                                  alt=""
                                  width="200"
                                  src={`${API_URL}public/uploads/slider/${sliderObject.slider_app}`}
                                />
                              )}
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={deleteSliderApp}
                                style={{ width: "200px" }}
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                          <AvField
                            type="file"
                            name="slider_app"
                            id="slider_app"
                            label="App JPG Image (600x330)"
                            className="form-control"
                            errorMessage="App Image required"
                            validate={{ required: { value: false } }}
                            onChange={uploadSliderApp}
                            // helpMessage="please select role"
                          ></AvField>
                          )}
                          {uploadProgress && uploadProgress < 100 && (
                            <div className="mt-4">
                              {" "}
                              <Progress color="primary" value={uploadProgress}>
                                Uploading {uploadProgress}%
                              </Progress>
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                        {sliderObject.slider_app_webp &&
                          sliderObject.slider_app_webp.length > 2 ? (
                            <div div className="img-wraps">
                              {" "}
                              {sliderObject.slider_app_webp &&
                              sliderObject.slider_app_webp.startsWith(
                                "http"
                              ) ? (
                                <img
                                  className="img-thumbnail"
                                  alt=""
                                  width="200"
                                  src={`${sliderObject.slider_app_webp}`}
                                />
                              ) : (
                                <img
                                  className="img-thumbnail"
                                  alt=""
                                  width="200"
                                  src={`${API_URL}public/uploads/slider/${sliderObject.slider_app_webp}`}
                                />
                              )}
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={deleteSliderAppwebp}
                                style={{ width: "200px" }}
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                          <AvField
                            type="file"
                            name="slider_app_webp"
                            id="slider_app_webp"
                            label="App Webp Image (600x330)"
                            className="form-control"
                            errorMessage="App Image required"
                            validate={{ required: { value: false } }}
                            onChange={uploadSliderAppWebp}
                            // helpMessage="please select role"
                          ></AvField>
                          )}
                          {uploadProgress && uploadProgress < 100 && (
                            <div className="mt-4">
                              {" "}
                              <Progress color="primary" value={uploadProgress}>
                                Uploading {uploadProgress}%
                              </Progress>
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <AvField
                            type="text"
                            name="slider_url"
                            id="slider_url"
                            label="URL"
                            value={sliderObject.slider_url}
                            errorMessage="URL required"
                            validate={{ required: { value: true } }}
                            onChange={handleChangeInput}
                            // helpMessage="please select role"
                          ></AvField>
                        </div>
                      </Col>
                      <Col md="3">
                        {/* <AvField
                          type="text"
                          name="from_time"
                          label="From Time"
                          // helpMessage="please select role"
                        ></AvField> */}
                        <div className="mb-3">
                          <Label>From Time</Label>
                          <AvField
                          type="date"
                          name="slider_from_date"
                          id="slider_from_date"
                          value={sliderObject.slider_from_date}
                            className="form-control"
                            placeholder="dd M,yyyy"
                            errorMessage="From Date required"
                            validate={{ required: { value: true } }}
                            onChange={handleChangeInput}
                           
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>To Time</Label>
                          <AvField
                          type="date"
                          name="slider_to_date"
                          id="slider_to_date"
                          value={sliderObject.slider_to_date}
                            className="form-control"
                            placeholder="dd M,yyyy"
                            errorMessage="To Date required"
                            validate={{ required: { value: true } }}
                            onChange={handleChangeInput}
                           
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div style={{ marginTop: "1.8em" }}>
                        {sliderIdTobeUpdated ? (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingSlider ? true : false}
                            >
                              {addingSlider ? "Updating" : "Update"}
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingSlider ? true : false}
                            >
                              {addingSlider ? "Adding" : "Submit"}
                            </Button>
                          )}
                        </div>
                      </Col>
                      <Col md="2" style={{display:'none'}}>
                        <div style={{ marginTop: "1.8em" }}>
                          <Button
                            color="danger"
                            style={{
                              fontSize: "1em",
                              cursor: "pointer",
                              marginRight: "0.5rem",
                            }}
                          >
                            <i className="bx bx-sync"></i> Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable responsive bordered data={data} disableRetreatAfterSorting={true} entries={25} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        style={{
          maxWidth: "800px",
        }}
        isOpen={showModal}
        toggle={() => {
          closeModal();
        }}
      >
        <div style={{ maxWidth: "800px", paddingTop: "0px" }}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">Slider Details </h5>
            <button
              type="button"
              onClick={() => {
                closeModal();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <AvForm className="needs-validation" onValidSubmit={(e, v) => {}}>
            <div className="modal-body">
              <table
                id="product_available_day"
                className="table table-bordered dataTable"
              >
                <tr>
                  <td style={{ width: "160px" }}>Name :</td>
                  <td style={{ width: "220px" }}>
                    {sliderDetails.slider_name}
                  </td>
                  <td style={{ width: "160px" }}>Slider Type :</td>
                  <td style={{ width: "220px" }}>
                    {sliderDetails.slider_type}
                  </td>
                </tr>
                <tr>
                  <td>Slider For :</td>
                  <td>{sliderDetails.slider_type_name}</td>
                  <td>Slider URL :</td>
                  <td>{sliderDetails.slider_url}</td>
                </tr>
                <tr>
                  <td>Slider Position :</td>
                  <td>{sliderDetails.slider_position}</td>
                  <td>Slider Start Date :</td>
                  <td>{sliderDetails.slider_from_date}</td>
                </tr>
                <tr>
                  <td>Slider To Date :</td>
                  <td>{sliderDetails.slider_to_date}</td>
                  <td>Added By :</td>
                  <td>{sliderDetails.fname}</td>
                </tr>

                <tr>
                  <td colSpan={4}>
                    <table>
                      <tr>
                        <th style={{ width: "190px" }}>
                          <img
                            style={{ width: "150px", height: "150px" }}
                            src={`${API_URL}public/uploads/slider/${sliderDetails.slider_web}`}
                          ></img>
                        </th>
                        <th style={{ width: "190px" }}>
                          <img
                            style={{ width: "150px", height: "150px" }}
                            src={`${API_URL}public/uploads/slider/${sliderDetails.slider_webp}`}
                          ></img>
                        </th>
                        <th style={{ width: "190px" }}>
                          <img
                            style={{ width: "150px", height: "150px" }}
                            src={`${API_URL}public/uploads/slider/${sliderDetails.slider_app}`}
                          ></img>
                        </th>
                        <th style={{ width: "190px" }}>
                          <img
                            style={{ width: "150px", height: "150px" }}
                            src={`${API_URL}public/uploads/slider/${sliderDetails.slider_app_webp}`}
                          ></img>
                        </th>
              
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </div>
          </AvForm>
        </div>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  // const { error } = state.Users;
  // return { error };
};

export default withRouter(connect(mapStateToProps, { apiError })(Sliders));

Sliders.propTypes = {
  error: PropTypes.any,
  sliders: PropTypes.array,
};
