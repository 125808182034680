import React, { useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row, Table } from 'reactstrap';
import { isEmpty, map } from 'lodash';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

//Import Image


const InvoiceDetail = (props) => {
  // const {
  //   invoiceDetail,
  //   match: { params },
  //   onGetInvoiceDetail,
  // } = props;

  // useEffect(() => {
  //   if (params && params.id) {
  //     onGetInvoiceDetail(params.id);
  //   } else {
  //     onGetInvoiceDetail(1); //remove this after full integration
  //   }
  // }, [params, onGetInvoiceDetail]);

  //Print the Invoice
  const printInvoice = () => {
    window.print();
  };
  let paystatus;
  const showPaystatus = () => {
    if (props.paystatus === 1) {
      paystatus = 'Paid';
      return true;
    } else {
      paystatus = 'Not Paid';
      return false;
    }
    return paystatus;
  };

  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{
          paddingTop: '0px',
        }}
      >
        <Container fluid>
          {/* Render Breadcrumbs */}

          {/* <div className="mb-4">
            <div className="d-print-none mt-4">
              <div className="float-end">
                <Link
                  to="#"
                  onClick={printInvoice}
                  className="btn btn-success waves-effect waves-light me-1"
                >
                  <i className="fa fa-print"></i>
                </Link>{' '}
              </div>
            </div>
          </div> */}
          {/* <Breadcrumbs title="" breadcrumbItem="" /> */}

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div
                    className="invoice-title"
                    style={{ paddingBottom: '10px' }}
                  >
                    <h4 className="float-end font-size-14">
                      Order#{props.OrderId}
                      {showPaystatus() ? (
                        <span className="badge bg-success font-size-12 ms-2">
                          {' '}
                          Paid{' '}
                        </span>
                      ) : (
                        <span className="badge bg-danger font-size-12 ms-2">
                          {' '}
                          Not Paid{' '}
                        </span>
                      )}
                    </h4>

                    {/* <div className="d-print-none mt-4"> */}
                    <div className="float-start">
                      <Link
                        to="#"
                        onClick={printInvoice}
                        className="btn btn-success waves-effect waves-light me-1"
                      >
                        <i className="fa fa-print"></i>
                      </Link>{' '}
                    </div>
                    {/* </div> */}

                    {/* <div className="mb-4">
                      <img src={logo} alt="logo" height="20" />
                    </div> */}

                    {/* <div className="text-muted"> */}
                    {/* <p className="mb-1">{invoiceDetail.shippingAddress}</p> */}
                    {/* <p className="mb-1">{props.ShippingAddress}</p>
                      <p className="mb-1">
                        <i className="uil uil-envelope-alt me-1"></i>{' '}
                        {props.ShopName}
                      </p>
                      <p>
                        <i className="uil uil-phone me-1"></i> {props.ShopPhone}
                      </p>
                    </div> */}
                  </div>
                  <hr className="my-4" />
                  <Row>
                    <Col sm="6">
                      <div className="text-muted">
                        <h5 className="font-size-16 mb-3">Billed To:</h5>
                        <h5 className="font-size-16 mb-2">
                          {props.CustomerName}
                        </h5>
                        {/* <p className="mb-1">
                          Community Id : {props.CommunityId}
                        </p> */}

                        <p> Mobile : {props.CustomerMobile}</p>
                      </div>
                    </Col>
                    <Col sm="6">
                      <div className="text-muted text-sm-end">
                        {/* <div>
                          <h5 className="font-size-16 mb-1">Order #</h5>
                          <p className="mb-1">{props.OrderId}</p>
                        </div> */}
                        <div className="mt-4">
                          <h5 className="font-size-16 mb-1">Order Date:</h5>

                          <p className="mb-1">{props.OrderDate}</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="py-2">
                    <h5 className="font-size-15">Order summary</h5>
                    <div className="table-responsive">
                      <Table className="table-nowrap table-centered mb-0">
                        <thead>
                          <tr>
                            <th>SKU #</th>
                            <th>Item</th>
                            <th>Price</th>
                            <th>Qty</th>
                            <th className="text-end" style={{ width: '50px' }}>
                              Total
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {map(props.OrderItems, (item, key) => (
                            <tr key={key}>
                              <td>
                                <h5 className="font-size-14 mb-1 text-wrap">
                                  {item.product.product_sku_autogen}
                                </h5>
                              </td>

                              <td>
                                <h5 className="font-size-14 mb-1 ">
                                  {item.o_item_name}
                                </h5>
                              </td>
                              <td>
                                <h5 className="font-size-14 mb-1 text-wrap">
                                  {item.o_item_amount}
                                </h5>
                              </td>
                              <td>
                                <h5 className="font-size-14 mb-1 text-wrap">
                                  {item.o_item_qty}
                                </h5>
                              </td>
                              <td>
                                <h5 className="font-size-14 mb-1 text-wrap">
                                  {item.o_item_amount}
                                </h5>
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <th colSpan="4" className="text-end">
                              Sub Total
                            </th>
                            <td className="text-end">{props.subtotal}</td>
                          </tr>
                          {/* <tr>
                            <th colSpan="4" className="border-0 text-end">
                              Discount :
                            </th>
                            <td className="border-0 text-end">- 90</td>
                          </tr> */}
                          {/* <tr>
                            <th colSpan="4" className="border-0 text-end">
                              Shipping Charge :
                            </th>
                            <td className="border-0 text-end">0</td>
                          </tr> */}
                          {/* <tr>
                            <th colSpan="4" className="border-0 text-end">
                              Tax
                            </th>
                            <td className="border-0 text-end">0</td>
                          </tr> */}
                          {/* <tr>
                            <th colSpan="4" className="border-0 text-end">
                              Total
                            </th>
                            <td className="border-0 text-end">
                              <h4 className="m-0">{props.subtotal}</h4>
                            </td>
                          </tr> */}
                        </tbody>
                      </Table>
                    </div>
                    {/* <div className="d-print-none mt-4">
                      <div className="float-end">
                        <Link
                          to="#"
                          onClick={printInvoice}
                          className="btn btn-success waves-effect waves-light me-1"
                        >
                          <i className="fa fa-print"></i>
                        </Link>{' '}
                        <Link
                          to="#"
                          className="btn btn-primary w-md waves-effect waves-light"
                        >
                          Send
                        </Link>
                      </div>
                    </div> */}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* )} */}
        </Container>
      </div>
    </React.Fragment>
  );
};


export default InvoiceDetail;
