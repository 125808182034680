import React, { useState,useEffect } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Row, Col, Label, Button, FormGroup, Input } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { useRef } from "react";
import { MDBDataTable } from "mdbreact";

const Notification = () => {
  const [alertMessage, setAlertMessage] = useState("");
  const [alertMessages, setAlertMessages] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef()
  useEffect(() => {
    fetchAlertMessages();
  }, []);
  
  const handleValidSubmit = async (event, values) => {
    try {
      const response = await axios.post(API_URL + "alert-message", {
        alert_message: values.alert_message,
      },
        {
          headers: {
            "x-access-token": accessToken
          }
        });
        alert("Notification Message Added....");
         setAlertMessage(""); 
      formRef.current.reset();
      fetchAlertMessages();
    } catch (error) {
      console.error("Error adding alert message:", error);
      alert("Failed to add alert message. Please try again.");
    }
  };
  const fetchAlertMessages = async () => {
    try {
      const response = await axios.get(API_URL + "alert-message/list", {
        headers: {
          "x-access-token": accessToken,
        },
      });
      console.log("data",response)
      setAlertMessages(response.data.data);
    } catch (error) {
      console.error("Error fetching alert messages:", error);
      alert("Failed to fetch alert messages. Please try again.");
    }
  };

  const data = {
    columns: [
        {
            label: "#",
            field: "id",
            sort: "asc",
            width: 150,
        },
        // {
        //     label: "Date",
        //     field: "date",
        //     sort: "asc",
        //     width: 400,
        // },
        // {
        //     label: "Time",
        //     field: "time",
        //     sort: "asc",
        //     width: 200,
        // },
        {
          label:"Message",
          field:"alert_message",
          sort:"asc",
          width:200
        },
        {
        label: "      ",
        field: "action",
        sort: "asc",
        width: 150,
        },
    ],
    rows: alertMessages.map(item => ({
        id: item.alert_id,
        alert_message:item.alert_message
    }))
};



  function reset() {
    formRef.current.reset()
    setAlertMessage("");
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Notification" />
          <Card>
            <CardBody>
              <AvForm onValidSubmit={handleValidSubmit} ref={formRef}>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label for="alertMessage">Message</Label>
                      <AvField
                        name="alert_message"
                        id="alertMessage"
                        placeholder="Enter the message"
                        type="text"
                        validate={{ required: { value: true } }}
                        value={alertMessage}
                        onChange={(e) => setAlertMessage(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="1" style={{ paddingTop: "26px", }}>
                       <Button color="primary" type="submit">Add</Button>
                  </Col>
                  <Col md="1" style={{ paddingTop: "26px", }} >
                       <Button color="danger" onClick={reset}>Reset</Button>
                  </Col>
                  
                </Row>
              </AvForm>
              <MDBDataTable
                                responsive
                                bordered
                                data={data}
                                searching={true}
                                info={false}
                                disableRetreatAfterSorting={true}
                                entries={400}
                                data-mdb-fixed-header="true"
                            />
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Notification;
