import React, { useState, useEffect, useRef } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SweetAlert from 'react-bootstrap-sweetalert';
import { MDBDataTable } from 'mdbreact';
import { Row, Col, Card, CardBody, Button, Label, Modal } from 'reactstrap';
import toastr from 'toastr';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import Select from 'react-select';
import "./customer.scss";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { get } from "lodash";
import { CSVLink } from "react-csv";
const Customers = (props) => {
    const formRef = useRef();
    const [dataToBeExported, setDataToBeExported] = useState([]);
    const [masterObject, setMasterObject] = useState(null);
    const [selectedDistrict, setselectedDistrict] = useState(null);
    const [districtOptions, setdistrictOptions] = useState([]);
    const [selectedPanchayat, setselectedPanchayat] = useState(null);
    const [panchayatOptions, setpanchayatOptions] = useState([]);
    const [selectedState, setselectedState] = useState(null);
    const [stateOptions, setstateOptions] = useState([]);
    const [selectedTown, setselectedTown] = useState(null);
    const [townOptions, settownOptions] = useState([]);
    const [customerIdTobeUpdated, setCustomerIdTobeUpdated] = useState(null);
    const [dataTable, setDataTable] = useState([]);
    const [customerIdTobeDeleted, setCustomerIdTobeDeleted] = useState(null);
    const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [isModelOpen, showModel] = useState(false);
    const [searchData, setSearchData] = useState({});
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
    const history = useHistory();
    const toTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };
    const handleChangeInput = (e) => {
        setMasterObject({
            ...masterObject,
            [e.target.name]: e.target.value
        })
    }
    const handleSelectChange = (e, type) => {
        switch (type) {
            case "1":
                setselectedState(e);
                getDistricts(e.value);
                setselectedDistrict(null);
                setMasterObject({
                    ...masterObject,
                    "customer_state": e.value,
                    "customer_district": null
                });
                break;
            case "2":
                setselectedDistrict(e);
                setMasterObject({
                    ...masterObject,
                    "customer_district": e.value,
                    "customer_town_id": null
                });
                setselectedTown(null)
                getTown(e.value)
                break;
            case "3":
                setselectedTown(e);
                setMasterObject({
                    ...masterObject,
                    'customer_town_id': e.value,
                    'customer_panchayath_id': null
                });
                setselectedPanchayat(null)
                getPanchayat(e.value)
                break;
            case "4":
                setselectedPanchayat(e);
                setMasterObject({
                    ...masterObject,
                    'customer_panchayath_id': e.value
                });
                break;


        }
    }
    const getStates = () => {
        axios
            .get(`${API_URL}state`, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                var list = [];
                res.data?.data?.map((item) => {
                    list.push({ label: item.name, value: item.id });
                })
                setstateOptions(list);
            })
    }
    const getDistricts = (id) => {
        axios
            .get(`${API_URL}district/options/?id=` + id, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                var list = [];
                res.data?.data?.map((item) => {
                    list.push({ label: item.dist_name, value: item.dist_id });
                })
                setdistrictOptions(list);
            })
    }
    const getTown = (id) => {
        axios
            .get(`${API_URL}manage-town/options/?id=` + id, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                var list = [];
                res.data?.data?.map((item) => {
                    list.push({ label: item.town_name, value: item.town_id, type: item.town_type });
                })
                settownOptions(list);
            })
    }
    const getPanchayat = (id) => {
        axios
            .get(`${API_URL}manage-panchayath/bytown-options?id=` + id, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                var list = [];
                res.data?.data?.map((item) => {
                    list.push({ label: item.panchayath_name, value: item.panchayath_id });
                })
                setpanchayatOptions(list);
            })
    }
    const uploadImage = (e) => {
        const fd = new FormData();
        fd.append("photo", e.target.files[0]);
        axios
            .post(`${API_URL}customer/upload_image/`, fd, {
                headers: {
                    "x-access-token": accessToken,
                }
            })
            .then((response) => {
                if (response.data.status === "success") {
                    setMasterObject({
                        ...masterObject,
                        [`customer_image`]: response.data.file.filename,
                    });
                } else {
                    toastr.error(response.data.message);
                }
            });
    };
    const deleteImage = () => {
        setMasterObject({
            ...masterObject,
            [`customer_image`]: "",
        });
    };
    const handleValidSubmit = () => {
        if (!selectedState || typeof selectedState.value == "undefined") {
            toastr.error("Please select a state");
            return;
        }
        if (!selectedDistrict) {
            toastr.error("Please select a district");
            return;
        }
        if (customerIdTobeUpdated) {
            axios
                .put(`${API_URL}customer/`, masterObject, {
                    headers: {
                        "x-access-token": accessToken,
                    },
                })
                .then((res) => {
                    if (res.data.status == 200) {
                        toastr.success("Updated Successfully");
                        reset();
                        getDataTable(searchData?.source, searchData?.from_date, searchData?.to_date);
                    }
                    else {
                        toastr.error(res.data.message);
                    }
                }).catch((err) => {
                    toastr.error("Failed to update customer");
                })
        }
        else {
            axios
                .post(`${API_URL}customer/`, masterObject, {
                    headers: {
                        "x-access-token": accessToken,
                    },
                })
                .then((res) => {
                    if (res.data.status == 200) {
                        toastr.success("Submitted Successfully");
                        reset();
                        getDataTable(searchData?.source, searchData?.from_date, searchData?.to_date);

                    }
                    else {
                        toastr.error(res.data.message);
                    }
                }).catch((err) => {
                    toastr.error("Failed to add customer");
                })
        }
    }
    const reset = () => {
        formRef.current.reset();
        setMasterObject({ customer_address: "" });
        setselectedState(null);
        setselectedDistrict(null);
        setselectedTown(null)
        setselectedPanchayat(null)
        setdistrictOptions([]);
    }
    const data = {
        columns: [
            {
                label: "#",
                field: "serial",
                sort: "asc",
                width: 150,
            },
            {
                label: "ID",
                field: "customers_unique_id",
                sort: "asc",
                width: 150,
            },
            {
                label: "Name",
                field: "customer_name",
                sort: "asc",
                width: 150,
            },
            {
                label: "Mobile",
                field: "customer_contact_number",
                sort: "asc",
                width: 150,
            },
            {
                label: "Email ID",
                field: "customer_email_id",
                sort: "asc",
                width: 150,
            },
            {
                label: "Ref. Code",
                field: "customer_reference_code",
                sort: "asc",
                width: 150,
            },
            // {
            //     label: "Address",
            //     field: "customer_address",
            //     sort: "asc",
            //     width: 150,
            // },
            {
                label: "District",
                field: "dist_name",
                sort: "asc",
                width: 150,
            },
            {
                label: "Wallet",
                field: "customer_wallet_amount",
                sort: "asc",
                width: 150,
            },
            {
                label: "Staff",
                field: "name",
                sort: "asc",
                width: 150,
            },
            {
                label: "Action",
                field: "action",
                sort: "asc",
                width: 150,
            },
        ],
        rows: dataTable
    }
    const handlePopup = (item) => {
        setModalData(item);
        if (item?.customer_image) {
            item.image = (
                <img src={API_URL + "uploads/customer/" + item?.customer_image} className="avatar-lg img-thumbnail" />
            )
        }
        showModel(true);
    }
    const getDataTable = (source = "", from_date = "", to_date = "") => {
        axios
            .get(`${API_URL}customer/?source=${source}&from_date=${from_date}&to_date=${to_date}`, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((res) => {
                var data = res.data.data;
                var list = [];
                let dataToBeExported = [];
                data.map((item, index) => {
                    let exportItem = {};
                    item.serial = index + 1;
                    item.dist_name = item.District?.dist_name;
                    item.name = item.addedUser?.fname;
                    item.action = (
                        <>
                            <i
                                className="far fa-eye"
                                style={{
                                    fontSize: "1em",
                                    cursor: "pointer",
                                    marginLeft: "0.1em",
                                    marginRight: "0.5em",
                                }}
                                onClick={() => {
                                    window.open('/customer/' + item.customers_unique_id)
                                    //handlePopup(item);
                                }}
                            ></i>
                            <i
                                className="far fa-edit"
                                style={{
                                    fontSize: "1em",
                                    cursor: "pointer",
                                    marginLeft: "0.1em",
                                    marginRight: "0.5em",
                                }}
                                onClick={() => {
                                    toTop();
                                    preUpdateCustomer(item);
                                }}
                            ></i>
                            <i
                                className="far fa-trash-alt"
                                style={{ fontSize: "1em", cursor: "pointer" }}
                                onClick={() => {
                                    setCustomerIdTobeDeleted(item.customer_id);
                                    setConfirmDeleteAlert(true);
                                }}
                            ></i>
                        </>
                    )
                    list.push(item);
                    exportItem.ID = item.customers_unique_id;
                    exportItem.Name = item.customer_name;
                    exportItem.Mobile = item.customer_contact_number;
                    exportItem.Email = item.customer_email_id;
                    exportItem.ReferenceCode = item.customer_reference_code;
                    exportItem.District = item?.dist_name;
                    exportItem.Wallet = item?.customer_wallet_amount;
                    exportItem.AppVersion = item?.customer_android_version;
                    dataToBeExported.push(exportItem);
                });
                setDataTable(list);
                setDataToBeExported(dataToBeExported);
            });
    }
    const preUpdateCustomer = (item) => {
        setCustomerIdTobeUpdated(item.customer_id);
        setMasterObject(item);
        setselectedState({ label: item?.State?.name, value: item?.State?.id });
        getDistricts(item?.State?.id);
        setselectedDistrict({ label: item?.District?.dist_name, value: item?.District?.dist_id });
        getTown(item?.District?.dist_id)
        setselectedTown({ label: item?.Town?.town_name, value: item?.Town?.town_id });
        getPanchayat(item?.Town?.town_id)
        setselectedPanchayat({ label: item?.Panchayath?.panchayath_name, value: item?.Panchayath?.panchayath_id })
    }
    const handleFilter = (selected, type) => {
        switch (type) {
            case "from":
                setSearchData({ ...searchData, "fromObject": selected, "source": selected.value });
                getDataTable(selected.value, searchData?.from_date, searchData?.to_date);
                break;
            case "from_date":
                setSearchData({ ...searchData, "from_date": selected.target.value });
                getDataTable(searchData?.source, selected.target.value, searchData?.to_date);
                break;
            case "to_date":
                setSearchData({ ...searchData, "to_date": selected.target.value });
                getDataTable(searchData?.source, searchData?.from_date, selected.target.value);
                break;

        }
    }
    useEffect(() => {
        getStates();
        getDataTable();
    }, [])


    return (
        <React.Fragment>
            {confirmDeleteAlert ? (
                <SweetAlert
                    title=""
                    showCancel
                    confirmButtonText="Delete"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        axios
                            .delete(`${API_URL}customer/?id=` + customerIdTobeDeleted, {
                                headers: {
                                    "x-access-token": accessToken,
                                },
                            })
                            .then((res) => {
                                if (res.data.status === 200) {
                                    toastr.success("Deleted successfully");
                                    setCustomerIdTobeDeleted(null);
                                    reset();
                                    getDataTable(searchData?.source, searchData?.from_date, searchData?.to_date);
                                } else {
                                    toastr.error(res.data.message, "Failed to delete");
                                    return;
                                }
                            })
                            .catch((err) => {
                                toastr.error(err.response.data.message);
                                return;
                            });
                        setConfirmDeleteAlert(false);
                    }}
                    onCancel={() => setConfirmDeleteAlert(false)}
                >
                    Are you sure you want to delete it?
                </SweetAlert>
            ) : null}
            <Modal
                size="xl" centered={true}
                isOpen={isModelOpen}
                toggle={() => {
                    showModel(!isModelOpen)
                }}
            >
                <div className="modal-header">
                    <h5>{modalData?.customers_unique_id} - {modalData?.customer_name}</h5>
                    <button
                        type="button"
                        onClick={() => {
                            showModel(!isModelOpen)
                        }}
                        className="close p-4"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <table id="companyModalTable">
                        <tr>
                            <td><b>Date</b></td><td>: {moment(modalData?.customer_created_at).format('DD-MM-YYYY hh:mm a')}</td>
                            <td><b>Name</b></td><td>: {modalData?.customer_name}</td>
                            <td><b>Mobile</b></td><td>: {modalData?.customer_contact_number}</td>
                            <td><b>Email ID</b></td><td>: {modalData?.customer_email_id}</td>
                        </tr>
                        <tr>
                            <td><b>State</b></td><td>: {modalData?.State?.name}</td>
                            <td><b>District</b></td><td>: {modalData?.District?.dist_name}</td>
                            <td><b>Address</b></td><td colSpan={3}>: {modalData?.customer_address}</td>
                        </tr>
                        <tr>
                            <td><b>Pincode</b></td><td>: {modalData?.customer_pincode}</td>

                            <td><b>Latitude</b></td><td>: {modalData?.customer_lattitude}</td>
                            <td><b>Longitude</b></td><td>: {modalData?.customer_longitude}</td>
                            <td><b>GSTIN</b></td><td>: {modalData?.customer_gst}</td>
                        </tr>
                        <tr>
                            <td><b>Wallet Amount</b></td><td>: {modalData?.customer_wallet_amount}</td>
                            <td><b>Added By</b></td><td>: {modalData?.addedUser?.fname}</td>

                            <td><b>Image</b></td><td>: {modalData?.image}</td>

                        </tr>
                    </table>
                </div>
            </Modal>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Home" breadcrumbItem="Manage Customers" />
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <AvForm
                                        ref={formRef}
                                        className="needs-validation"
                                        onValidSubmit={(e, v) => {
                                            handleValidSubmit(e, v);
                                        }}
                                    >
                                        <Row>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Name</Label>
                                                    <AvField
                                                        name="customer_name"
                                                        value={masterObject?.customer_name}
                                                        placeholder="Name"
                                                        type="text"
                                                        errorMessage="Name is required"
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: true }
                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Mobile</Label>
                                                    <AvField
                                                        name="customer_contact_number"
                                                        value={masterObject?.customer_contact_number}
                                                        placeholder="Mobile"
                                                        type="text"
                                                        errorMessage="Mobile is required"
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: true },
                                                            pattern: { value: "^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$", errorMessage: "Invalid Mobile No." }
                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Email</Label>
                                                    <AvField
                                                        name="customer_email_id"
                                                        value={masterObject?.customer_email_id}
                                                        placeholder="Email"
                                                        type="email"
                                                        errorMessage="Email is required"
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: true }
                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <Label>State</Label>
                                                <Select
                                                    name="customer_state"
                                                    value={selectedState}
                                                    onChange={(selected) => {
                                                        handleSelectChange(selected, "1");
                                                    }}
                                                    options={stateOptions}
                                                    classNamePrefix="select2-selection"
                                                    menuPosition="auto"
                                                    menuPlacement="auto"
                                                />

                                            </Col>
                                            <Col md="3">
                                                <Label>District</Label>
                                                <Select
                                                    name="customer_district"
                                                    value={selectedDistrict}
                                                    onChange={(selected) => {
                                                        handleSelectChange(selected, "2");
                                                    }}
                                                    options={districtOptions}
                                                    classNamePrefix="select2-selection"
                                                    menuPosition="auto"
                                                    menuPlacement="auto"
                                                />
                                            </Col>
                                            <Col md="3">
                                                <Label>Town</Label>
                                                <Select
                                                    name="customer_town_id"
                                                    value={selectedTown}
                                                    onChange={(selected) => {
                                                        handleSelectChange(selected, "3");
                                                    }}
                                                    options={townOptions}
                                                    classNamePrefix="select2-selection"
                                                    menuPosition="auto"
                                                    menuPlacement="auto"
                                                />
                                            </Col>
                                            <Col md="3">
                                                <Label>Panchayat</Label>
                                                <Select
                                                    name="customer_panchayath_id"
                                                    value={selectedPanchayat}
                                                    onChange={(selected) => {
                                                        handleSelectChange(selected, "4");
                                                    }}
                                                    options={panchayatOptions}
                                                    classNamePrefix="select2-selection"
                                                    menuPosition="auto"
                                                    menuPlacement="auto"
                                                />
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Address</Label>
                                                    <AvField
                                                        name="customer_address"
                                                        value={masterObject?.customer_address}
                                                        placeholder="Address"
                                                        type="textarea"
                                                        errorMessage="Address is required"
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: true }
                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Pincode</Label>
                                                    <AvField
                                                        name="customer_pincode"
                                                        value={masterObject?.customer_pincode}
                                                        placeholder="Pincode"
                                                        type="text"
                                                        errorMessage="Pincode is required"
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: true },
                                                            pattern: { value: '^[0-9]{6}$', errorMessage: "Invalid pincode" }

                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Latitude</Label>
                                                    <AvField
                                                        name="customer_lattitude"
                                                        value={masterObject?.customer_lattitude}
                                                        placeholder="Latitude"
                                                        type="text"
                                                        errorMessage="Latitude is required"
                                                        className="form-control"
                                                        validate={{
                                                            //required: { value: true },
                                                            pattern: { value: '^[0-9.]+$', errorMessage: "Invalid Latitude" }

                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Longitude</Label>
                                                    <AvField
                                                        name="customer_longitude"
                                                        value={masterObject?.customer_longitude}
                                                        placeholder="Longitude"
                                                        type="text"
                                                        errorMessage="Longitude is required"
                                                        className="form-control"
                                                        validate={{
                                                            //required: { value: true },
                                                            pattern: { value: '^[0-9.]+$', errorMessage: "Invalid Longitude" }

                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >GSTIN</Label>
                                                    <AvField
                                                        name="customer_gst"
                                                        value={masterObject?.customer_gst}
                                                        placeholder="22AAAAA0000A1Z5"
                                                        type="text"
                                                        errorMessage="GSTIN is required"
                                                        className="form-control"
                                                        validate={{
                                                            pattern: { value: '^[A-Z0-9]{15}$', errorMessage: "Invalid No." }
                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3" >
                                                <Label htmlFor="validationCustom03">
                                                    Image
                                                </Label>
                                                {masterObject?.customer_image ? (
                                                    <div div className="img-wraps">
                                                        <img
                                                            alt=""
                                                            width="150"
                                                            height="150"
                                                            src={`${API_URL}/uploads/customer/${masterObject?.customer_image}`}
                                                        />
                                                        <button
                                                            className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                                            onClick={deleteImage}
                                                            style={{ width: "150px" }}
                                                        >
                                                            Delete
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <AvField
                                                        name="photo"
                                                        type="file"
                                                        className="form-control"
                                                        id="validationCustom03"
                                                        onChange={uploadImage}
                                                        rows="1"
                                                    />
                                                )}
                                            </Col>
                                            <Col md="1" style={{ paddingTop: "26px" }}>
                                                {customerIdTobeUpdated ? (
                                                    <Button
                                                        color="primary"
                                                        type="submit"
                                                    >
                                                        Update
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        color="primary"
                                                        type="submit"
                                                    >
                                                        Submit
                                                    </Button>
                                                )}
                                            </Col>
                                            <Col md="1" style={{ paddingTop: "26px" }}>
                                                <Button
                                                    color="danger"
                                                    type="reset"
                                                    onClick={(e) => {
                                                        reset()

                                                    }}
                                                >
                                                    Reset
                                                </Button>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <Row className="mb-2">
                                        <Col md="2">
                                            <div className="mb-3">
                                                <Label >From</Label>
                                                {/* <div className="col-md-10"> */}
                                                <input
                                                    className="form-control"
                                                    type="date"
                                                    id="from_date"
                                                    name="from_date"
                                                    value={searchData?.from_date}

                                                    onChange={(selected) => {
                                                        handleFilter(selected, "from_date");
                                                    }}
                                                />
                                                {/* </div> */}
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-3">
                                                <Label >To</Label>
                                                {/* <div className="col-md-10"> */}
                                                <input
                                                    className="form-control"
                                                    type="date"
                                                    id="to_date"
                                                    name="to_date"
                                                    value={searchData?.to_date}
                                                    onChange={(selected) => {
                                                        handleFilter(selected, "to_date");
                                                    }}
                                                    min={searchData?.from_date}
                                                />
                                                {/* </div> */}
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <Label>Source</Label>
                                            <Select
                                                name="from"
                                                value={searchData?.fromObject}
                                                onChange={(selected) => {
                                                    handleFilter(selected, "from");
                                                }}
                                                options={[{ label: "All", value: "" }, { label: "CRM", value: "0" }, { label: "APP", value: "2" }, { label: "WEB", value: "1" }]}
                                                classNamePrefix="select2-selection"
                                                menuPosition="auto"
                                                menuPlacement="auto"
                                            />

                                        </Col>
                                        <Col md="1" style={{ paddingTop: "26px" }}>
                                            <Button
                                                color="danger"
                                                type="reset"
                                                onClick={() => {
                                                    setSearchData({ "source": "", "fromObject": null, "from_date": "", "to_date": "" });
                                                    getDataTable();
                                                }}
                                            >
                                                Reset
                                            </Button>
                                        </Col>
                                        {
                                            dataTable.length > 0 ? (

                                                <Col md="1">
                                                    <div className="mt-4" style={{ paddingTop: "7px" }}>
                                                        <Button color="success" type="submit">
                                                            <CSVLink
                                                                data={dataToBeExported}
                                                                filename={"starlee-customers.xls"}
                                                                style={{ color: "white" }}
                                                            >
                                                                Export
                                                            </CSVLink>
                                                        </Button>
                                                    </div>
                                                </Col>
                                            ) : null
                                        }
                                    </Row>
                                    <MDBDataTable
                                        responsive
                                        id="customerTableId"
                                        bordered
                                        data={data}
                                        searching={true}
                                        info={true}
                                        disableRetreatAfterSorting={true}
                                        entries={20}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Customers;