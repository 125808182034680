import React, { useState, useEffect, useRef } from 'react';
import toastr from 'toastr';
import {jwtDecode} from 'jwt-decode';

import SweetAlert from 'react-bootstrap-sweetalert';
import { MDBDataTable } from 'mdbreact';
import { Row, Col, Card, CardBody, Button, Label } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import $ from 'jquery';
import {
  getTaxes,
  addTax,
  deleteTax,
  updateTax,
} from '../../../store/actions';

import { AvForm, AvField } from 'availity-reactstrap-validation';
//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';

import './tax.scss';

const Taxes = (props) => {
  const [taxObject, setTaxObject] = useState({});
  const [taxesTemp, setTaxesTemp] = useState([]);

  const [taxIdTobeUpdated, setTaxIdToBeUpdated] = useState(null);
  const [taxIdToBeDeleted, setTaxIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [selectedType, setselectedType] = useState(null);
  const [invalidtype, setinvalidtype] = useState('none');
  const [userId, setUserId] = useState(1);
  const formRef = useRef();

  let taxtypeOptionsData = [{ label: "GST", value: "GST" }, { label: "TDS", value: "TDS" }];

  const taxtypesOptionsGroup = [
    {
      options: taxtypeOptionsData,
    },
  ];

  const {
    taxes,
    addingTax,
    addTaxResponse,
    deleteTaxResponse,
    updateTaxResponse,
    error,
  } = useSelector((state) => state.taxes);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTaxes());
  }, []);

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      const obj = jwtDecode(localStorage.getItem('authUser'));
      console.log(obj);

      setUserId(obj.user);
      setTaxObject({ ['auth_userid']: userId });
    }
  }, [userId]);

  useEffect(() => {
    if (addTaxResponse.type === 'success') {
      toastr.success(addTaxResponse.message);
    } else if (addTaxResponse.type === 'failure') {
      toastr.error(error.data.message, addTaxResponse.message);
    }
  }, [addTaxResponse]);

  useEffect(() => {
    if (deleteTaxResponse.type === 'success') {
      toastr.success(deleteTaxResponse.message);
      dispatch(getTaxes());
    } else if (deleteTaxResponse.type === 'failure') {
      toastr.error(error.data.message, deleteTaxResponse.message);
    }
  }, [deleteTaxResponse]);

  useEffect(() => {
    if (updateTaxResponse.type === 'success') {
      setTaxIdToBeUpdated(null);
      dispatch(getTaxes());
      toastr.success(updateTaxResponse.message);
    } else if (updateTaxResponse.type === 'failure') {
      toastr.error(error.data.message, updateTaxResponse.message);
    }
  }, [updateTaxResponse]);

  let preUpdateTax = (item) => {
    if (item.tax_slab_type == "GST") {
      var types = {
        label: "GST",
        value: "GST",
      };
    }
    if (item.tax_slab_type == "TDS") {
      var types = {
        label: "TDS",
        value: "TDS",
      };
    }
    handleSelectedTaxTypes(types);

    setTaxIdToBeUpdated(item.slab_id);
    setTaxObject(item);
  };

  useEffect(() => {
    let taxesDuplicate = JSON.parse(JSON.stringify(taxes));
    let taxData = [];
    taxesDuplicate.map((item, index) => {
      if (item.User) {
        item.fname = item.User?.fname + ' ' + item.User?.lname;
      }
      if (item.tax_slab_type == "GST") {
        item.tax_type = "GST";
      }
      else if (item.tax_slab_type == "TDS") {
        item.tax_type = "TDS";
      }
      else {
        item.tax_type = "";
      }

      item.auth_userid = userId;
      item.action = (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <i
            className="far fa-edit"
            style={{
              fontSize: "1em",
              cursor: "pointer",
              marginLeft: "0.5em",
              marginRight: "0.5em",
            }}
            onClick={() => {
              preUpdateTax(item);
            }}
          ></i>
          <i
            className="far fa-trash-alt"
            style={{ fontSize: '1em', cursor: 'pointer' }}
            onClick={() => {
              setTaxIdToBeDeleted(item.slab_id);
              setConfirmDeleteAlert(true);
            }}
          ></i>
        </div>
      );
      item.id = index + 1;

      taxData.push(item);
    });
    setTaxesTemp(taxData);
  }, [taxes]);

  const data = {
    columns: [
      {
        label: '#',
        field: 'id',
        sort: 'asc',
        width: 10,
      },

      {
        label: 'Type',
        field: 'tax_type',
        sort: 'asc',
        width: 70,
      },
      {
        label: 'Name',
        field: 'tax_slab_name',
        sort: 'asc',
        width: 70,
      },
      {
        label: 'Percentage',
        field: 'tax_slab_percentage',
        sort: 'asc',
        width: 70,
      },
      {
        label: 'Added by',
        field: 'fname',
        sort: 'asc',
        width: 100,
      },

      {
        label: 'Action',
        field: 'action',
        sort: 'disabled',
        width: 100,
      },
    ],
    rows: taxesTemp,
  };

  let handleChangeInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setTaxObject({
      ...taxObject,
      [name]: value,
    });
  };

  function handleSelectedTaxTypes(e) {
    console.log(e);
    let name = 'tax_slab_type';
    let value = e.value;
    if (e.value) {
      $("#tax_slab_type").attr('class', "select2-selection");
      setinvalidtype('none');
    }
    setselectedType(e);
    setTaxObject({
      ...taxObject,
      [name]: value,
    });
  }
  const handleValidSubmitTax = (event, values) => {
    if (selectedType == null) {
      setinvalidtype('block');
      $("#tax_slab_type").attr('class', "select2-selection border border-danger");
      return;
    }

    taxIdTobeUpdated
      ? dispatch(updateTax(taxObject))
      : dispatch(addTax(taxObject));

    formRef.current.reset();
    setselectedType(null);
    setTaxObject({ ['auth_userid']: userId });
  };

  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            dispatch(deleteTax(taxIdToBeDeleted, userId));
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Tax Slab" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmitTax(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Tax Type
                          </Label>

                          <Select
                            name="tax_slab_type"
                            id="tax_slab_type"
                            value={selectedType}
                            onChange={(value) => {
                              handleSelectedTaxTypes(value);
                            }}
                            options={taxtypesOptionsGroup}
                            classNamePrefix="select2-selection"
                          />
                          <div style={{ display: invalidtype, Width: '100%', marginTop: '0.25rem', color: '#f46a6a', fontSize: '87.5%' }}>Select Tax Slab type</div>

                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Tax Name</Label>
                          <AvField
                            name="tax_slab_name"
                            value={taxObject.tax_slab_name}
                            placeholder="Enter Name"
                            type="text"
                            errorMessage="Enter Name"
                            className="form-control"
                            validate={{
                              required: { value: true }
                            }}
                            id="validationCustom02"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>


                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Tax Percentage
                          </Label>
                          <AvField
                            name="tax_slab_percentage"
                            value={taxObject.tax_slab_percentage}
                            placeholder="Enter Percentage"
                            type="text"
                            errorMessage="Enter Percentage"
                            className="form-control"
                            validate={{
                              required: { value: true },
                              pattern: { value: '^[0-9.]+$', errorMessage: 'Invalid Percentage' }
                            }}
                            id="validationCustom03"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mt-4">
                          {taxIdTobeUpdated ? (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingTax ? true : false}
                            >
                              {addingTax ? 'Updating' : 'Update'}
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingTax ? true : false}
                            >
                              {addingTax ? 'Adding' : 'Submit'}
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable responsive bordered data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Taxes;
