import React, { useState, useEffect, useRef } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { MDBDataTable } from "mdbreact";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Modal,
  ModalBody,
  Container,
  BreadcrumbItem,
  TabContent,
  TabPane,
  NavLink,
  NavItem,
  Nav,
  Spinner,
  Badge,
} from "reactstrap";
import toastr from "toastr";
import { AvForm, AvField } from "availity-reactstrap-validation";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import Select from "react-select";
import "./customer.scss";
import moment from "moment";
import errorImg from "../../assets/images/404-error.svg";
import avatar from "../../assets/images/users/user.png";
import classnames from "classnames";
import { getDate, getFirstday } from "../../helpers/globalFunctions";
const CustomersDashboard = (props) => {
  const customerID = props?.match?.params.id;
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [hasProfile, sethasProfile] = useState(true);
  const [profile, setProfile] = useState(null);
  const [activeTabJustify, setactiveTabJustify] = useState("1");
  const [isLoading, setIsLoading] = useState("none");
  const getProfile = () => {
    axios
      .get(API_URL + "customer/profile/?id=" + customerID, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.success == true) {
          setProfile(res.data.data);
          sethasProfile(true);
        } else {
          sethasProfile(false);
        }
      });
  };
  function toggleCustomJustified(tab) {
    if (activeTabJustify !== tab) {
      setactiveTabJustify(tab);
    }
  }
  /** Online Orders */
  const [onlinefilterObject, setonlinefilterObject] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });
  const [viewOnlineOrderForTable, setviewOnlineOrderForTable] = useState([]);
  const [orderViewModalOpen, setOrderViewModalOpen] = useState(null);
  const [order, setOrder] = useState(null);
  const [orderItemsData, setorderItemsData] = useState([]);
  const onlinedata = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      // {
      //     label: "Time",
      //     field: "time",
      //     sort: "asc",
      //     width: 200,
      // },
      {
        label: "Order#",
        field: "orders_unique_id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Source",
        field: "order_from",
        sort: "asc",
        width: 100,
      },
      // {
      //     label: "Name",
      //     field: "order_customer_name",
      //     sort: "asc",
      //     width: 100,
      // },
      // {
      //     label: "Moblie",
      //     field: "order_contact_number",
      //     sort: "asc",
      //     width: 100,
      // },
      {
        label: "Amount",
        field: "amount_column",
        sort: "asc",
        width: 100,
      },
      {
        label: "Status",
        field: "payment_status",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "disabled",
        width: 100,
      },
    ],
    rows: viewOnlineOrderForTable,
  };
  const orderItems = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "SKU",
        field: "product_sku_autogen",
        sort: "asc",
        width: 400,
      },
      {
        label: "Product",
        field: "o_item_product_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Image",
        field: "image",
        sort: "asc",
        width: 100,
      },
      {
        label: "HSN",
        field: "product_hsn",
        sort: "asc",
        width: 100,
      },
      {
        label: "Unit",
        field: "unit_name",
        sort: "asc",
        width: 100,
      },
      {
        label: "Qty",
        field: "item_product_order_quantity",
        sort: "asc",
        width: 100,
      },
      {
        label: "Rate",
        field: "order_item_rate",
        sort: "asc",
        width: 100,
      },
      {
        label: "MRP",
        field: "o_item_product_mrp",
        sort: "asc",
        width: 100,
      },
      {
        label: "Discount",
        field: "order_item_discount",
        sort: "disabled",
        width: 100,
      },
      {
        label: "GST",
        field: "item_gst",
        sort: "disabled",
        width: 100,
      },
      {
        label: "Amount",
        field: "o_item_amount_exc",
        sort: "disabled",
        width: 100,
      },
    ],
    rows: orderItemsData,
  };
  const handleFilter = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    setonlinefilterObject({
      ...onlinefilterObject,
      [name]: value,
    });
    switch (name) {
      case "from_date":
        getOnlineOrders(value, onlinefilterObject?.to_date);
        break;
      case "to_date":
        getOnlineOrders(onlinefilterObject?.from_date, value);
        break;
    }
  };
  const getOnlineOrders = (from_date = "", to_date = "") => {
    if (from_date == "") from_date = getFirstday(new Date());
    if (to_date == "") to_date = getDate(new Date());
    axios
      .get(
        `${API_URL}vieworders?orderDate1=` +
        from_date +
        "&orderDate2=" +
        to_date +
        "&cid=" +
        customerID,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        var data = res.data.data;
        let ordersData = [];
        data.map((item, index) => {
          item.id = index + 1;
          item.date = moment(item.order_date).local().format("DD/MM/YYYY");
          item.time = moment(item.order_time, "h:mm:ss.*").format("hh:mm a");
          if (item.order_payment_status === 1)
            item.payment_status = (
              <div
                class="btn-group btn-group-sm"
                role="group"
                aria-label="Basic example"
              >
                <button
                  class="btn btn-danger"
                  style={{ padding: "1px", minWidth: "50px" }}
                >
                  Not Paid
                </button>
              </div>
            );
          else if (item.order_payment_status === 2)
            item.payment_status = (
              <div
                class="btn-group btn-group-sm"
                role="group"
                aria-label="Basic example"
              >
                <button
                  class="btn btn-success"
                  style={{ padding: "1px", minWidth: "50px" }}
                >
                  Paid
                </button>
              </div>
            );
          else if (item.order_payment_status === 3)
            item.payment_status = (
              <div
                class="btn-group btn-group-sm"
                role="group"
                aria-label="Basic example"
              >
                <button
                  class="btn btn-warning"
                  style={{ padding: "1px", minWidth: "50px" }}
                >
                  Failed
                </button>
              </div>
            );
          item.amount_column = (
            <div className="text-right">
              {item.order_wallet_amount.toFixed(2)}
            </div>
          );
          if (item.order_from == 0) item.order_from = "CRM";
          else if (item.order_from == 1) item.order_from = "Website";
          else if (item.order_from == 2) item.order_from = "Android";
          else if (item.order_from == 3) item.order_from = "IOS";
          else if (item.order_from == 4) item.order_from = "Whatsapp";
          else if (item.order_from == 5) item.order_from = "POS";
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-file-alt"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.5em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  setOrder(null);
                  getOrder(item.order_id);
                  // showSingleOrder();
                }}
              ></i>
            </div>
          );

          ordersData.push(item);
        });
        setviewOnlineOrderForTable(ordersData);
      });
  };
  const getOrder = (id) => {
    axios
      .get(`${API_URL}vieworders/` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        setOrder(res.data.data);
        res.data.data.orderItems &&
          res.data.data.orderItems.map((item, index) => {
            item.id = index + 1;
            item.product_sku_autogen = item?.product?.product_sku_autogen;
            item.product_hsn = item?.product?.product_hsn;
            item.unit_name = item?.product?.productUnit?.unit_short;
            item.order_item_rate = item?.order_item_rate?.toFixed(2);
            item.o_item_product_mrp = item?.o_item_product_mrp?.toFixed(2);
            item.order_item_discount = item?.order_item_discount?.toFixed(2);
            item.o_item_amount_exc = item?.o_item_amount_exc?.toFixed(2);
            if (item?.product?.product_image_main) {
              item.image = (
                <img
                  src={
                    API_URL +
                    "uploads/product_images/product_image_60x60/" +
                    item?.product?.product_image_main
                  }
                  height="50px"
                  width={"50px"}
                />
              );
            }
          });
        setorderItemsData(res.data.data.orderItems);
        setOrderViewModalOpen(true);
      });
  };
  const resetOnlineOrder = () => {
    setonlinefilterObject({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    getOnlineOrders();
  };

  /** Offline Orders */
  const [offlinefilterObject, setofflinefilterObject] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });
  const [viewOfflineOrderForTable, setviewOfflineOrderForTable] = useState([]);
  const [offlineorderIdToBeDeleted, setofflineorderIdToBeDeleted] =
    useState(null);
  const [confirmOfflineDeleteAlert, setconfirmOfflineDeleteAlert] =
    useState(null);
  const [selectedOfflineShop, setselectedOfflineShop] = useState(null);
  const [shopOfflineOptions, setshopOfflineOptions] = useState([]);
  const offlinedata = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 200,
      },
      {
        label: "Order#",
        field: "orders_unique_id",
        sort: "asc",
        width: 100,
      },
      // {
      //     label: "Source",
      //     field: "order_from",
      //     sort: "asc",
      //     width: 100,
      // },
      // {
      //     label: "Name",
      //     field: "order_customer_name",
      //     sort: "asc",
      //     width: 100,
      // },
      // {
      //     label: "Moblie",
      //     field: "order_contact_number",
      //     sort: "asc",
      //     width: 100,
      // },
      {
        label: "Amount",
        field: "amount_column",
        sort: "asc",
        width: 100,
      },
      {
        label: "Shop",
        field: "shop_name",
        sort: "asc",
        width: 100,
      },
      // {
      //     label: "Action",
      //     field: "action",
      //     sort: "disabled",
      //     width: 100,
      // },
    ],
    rows: viewOfflineOrderForTable,
  };
  const handleOfflineFilter = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    setofflinefilterObject({
      ...offlinefilterObject,
      [name]: value,
    });
    switch (name) {
      case "from_date":
        getOfflineOrders(
          value,
          offlinefilterObject?.to_date,
          selectedOfflineShop?.value
        );
        break;
      case "to_date":
        getOfflineOrders(
          offlinefilterObject?.from_date,
          value,
          selectedOfflineShop?.value
        );
        break;
    }
  };
  const handleselectedOfflineShop = (selected) => {
    setselectedOfflineShop(selected);
    getOfflineOrders(
      offlinefilterObject?.from_date,
      offlinefilterObject?.to_date,
      selected?.value
    );
  };
  const getOfflineShops = () => {
    axios
      .get(API_URL + "shop/options-new", {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        setshopOfflineOptions(res.data.data);
      });
  };
  const getOfflineOrders = (from_date = "", to_date = "", shop = "") => {
    if (from_date == "") from_date = getFirstday(new Date());
    if (to_date == "") to_date = getDate(new Date());
    axios
      .get(
        `${API_URL}vieworders/all-offline-orders/?orderDate1=` +
        from_date +
        "&orderDate2=" +
        to_date +
        "&shop=" +
        shop +
        "&cid=" +
        customerID,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        var data = res.data.data;
        let ordersData = [];
        data.map((item, index) => {
          item.id = index + 1;
          item.date = moment(item.order_date).local().format("DD-MM-YYYY");
          item.time = moment(item.order_time, "h:mm:ss.*").format("hh:mm a");
          if (item.order_payment_status === 1)
            item.payment_status = (
              <div
                class="btn-group btn-group-sm"
                role="group"
                aria-label="Basic example"
              >
                <button
                  class="btn btn-danger"
                  style={{ padding: "1px", minWidth: "50px" }}
                >
                  Not Paid
                </button>
              </div>
            );
          else if (item.order_payment_status === 2)
            item.payment_status = (
              <div
                class="btn-group btn-group-sm"
                role="group"
                aria-label="Basic example"
              >
                <button
                  class="btn btn-success"
                  style={{ padding: "1px", minWidth: "50px" }}
                >
                  Paid
                </button>
              </div>
            );
          else if (item.order_payment_status === 3)
            item.payment_status = (
              <div
                class="btn-group btn-group-sm"
                role="group"
                aria-label="Basic example"
              >
                <button
                  class="btn btn-warning"
                  style={{ padding: "1px", minWidth: "50px" }}
                >
                  Failed
                </button>
              </div>
            );
          item.amount_column = (
            <div className="text-right">
              {item.order_grand_amount.toFixed(2)}
            </div>
          );
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
                onClick={() => {
                  setofflineorderIdToBeDeleted(item.order_id);
                  setconfirmOfflineDeleteAlert(true);
                }}
              ></i>
            </div>
          );

          ordersData.push(item);
        });
        setviewOfflineOrderForTable(ordersData);
      });
  };
  const resetOffline = () => {
    setofflinefilterObject({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    setselectedOfflineShop(null);
    getOfflineOrders();
  };
  /** Wallet */
  const [walletFilterObject, setwalletFilterObject] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });
  const [walletDataTable, setwalletDataTable] = useState([]);

  const walletdata = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      // {
      //     label: "Time",
      //     field: "time",
      //     sort: "asc",
      //     width: 150,
      // },
      {
        label: "Credit",
        field: "credit",
        sort: "asc",
        width: 150,
      },
      {
        label: "Debit",
        field: "debit",
        sort: "asc",
        width: 150,
      },
      {
        label: "Balance",
        field: "wallet_current_amount",
        sort: "asc",
        width: 150,
      },
      {
        label: "Coment",
        field: "wallet_message",
        sort: "asc",
        width: 150,
      },
    ],
    rows: walletDataTable,
  };
  const getWalletLog = (from_date = "", to_date = "") => {
    axios
      .get(
        `${API_URL}customer/customer-wallet-log?from_date=` +
        from_date +
        "&to_date=" +
        to_date +
        "&cid=" +
        customerID,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        setwalletDataTable(res.data.data);
      });
  };
  const walletreset = () => {
    setwalletFilterObject({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    getWalletLog(getFirstday(new Date()), getDate(new Date()));
  };

  /** Point */
  const [pointFilterObject, setpointFilterObject] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });
  const [pointDataTable, setpointDataTable] = useState([]);

  const pointdata = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      // {
      //     label: "Time",
      //     field: "time",
      //     sort: "asc",
      //     width: 150,
      // },
      {
        label: "Credit",
        field: "credit",
        sort: "asc",
        width: 150,
      },
      {
        label: "Debit",
        field: "debit",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Balance",
      //   field: "point_balance",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Comments",
        field: "point_message",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "point_locked",
        sort: "asc",
        width: 150,
      },
    ],
    rows: pointDataTable,
  };
  const getPointLog = (from_date = "", to_date = "", type = "") => {
    setIsLoading("none");
    axios
      .get(
        `${API_URL}customer/customer-point-log?from_date=` +
        from_date +
        "&to_date=" +
        to_date +
        "&cid=" +
        customerID +
        "&type=" +
        type,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        res.data.data &&
          res.data?.data?.map((item, index) => {
            if (item.point_lock_status == 0)
              item.point_locked = (
                <>
                  <i
                    className="mdi mdi-lock-alert"
                    style={{ color: "#dc3545" }}
                  ></i>{" "}
                  Locked
                </>
              );
            else
              item.point_locked = (
                <>
                  <i
                    className="mdi mdi-lock-open-check"
                    style={{ color: "#4cae89" }}
                  ></i>{" "}
                  Earned
                </>
              );
          });
        setpointDataTable(res.data.data);
        setIsLoading("none");
      });
  };
  const pointreset = () => {
    setpointFilterObject({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
      type: "",
    });
    getPointLog(getFirstday(new Date()), getDate(new Date()));
  };

  /** Shipping Address */
  const [addressDataTable, setaddressDataTable] = useState([]);
  const [addressModel, setAddressModel] = useState(false);
  const [address_data, setAddressData] = useState(null);
  const addressdata = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "address_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Mobile",
        field: "address_contact_number",
        sort: "asc",
        width: 150,
      },
      {
        label: "Address",
        field: "shipping_address",
        sort: "asc",
        width: 150,
      },
      // {
      //     label: "Location",
      //     field: "customer_location",
      //     sort: "asc",
      //     width: 150,
      // },
      {
        label: "Default",
        field: "default_address",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: addressDataTable,
  };
  const getAddress = () => {
    axios
      .get(`${API_URL}customer/customer-shipping-address?&cid=` + customerID, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var list = [];
        res.data.data &&
          res.data.data.map((item, index) => {
            item.id = index + 1;
            if (item.customer_address_type == "1")
              item.customer_address_type = "Home";
            else if (item.customer_address_type == "2")
              item.customer_address_type = "Office";
            else item.customer_address_type = "";
            if (item.default_address == 1)
              item.default_address = <Badge className="bg-success">Yes</Badge>;
            else item.default_address = <Badge className="bg-danger">No</Badge>;
            item.customer_location = (
              <>
                {item.customer_location} - {item.customer_pincode}
              </>
            );
            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.5em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    setAddressModel(true);
                    setAddressData(item);
                  }}
                ></i>
              </div>
            );
            list.push(item);
          });
        setaddressDataTable(list);
      });
  };
  useEffect(() => {
    getProfile();
    getOnlineOrders();
    getOfflineShops();
    getOfflineOrders();
    getWalletLog(getFirstday(new Date()), getDate(new Date()));
    getPointLog(getFirstday(new Date()), getDate(new Date()));
    getAddress();
  }, [customerID]);
  return (
    <React.Fragment>
      {confirmOfflineDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(
                `${API_URL}vieworders/offline` +
                "/" +
                offlineorderIdToBeDeleted,
                {
                  headers: {
                    "x-access-token": accessToken,
                  },
                }
              )
              .then((res) => {
                if (res.data.status === 200) {
                  toastr.success("Order deleted successfully");
                  getOfflineOrders(
                    offlinefilterObject?.from_date,
                    offlinefilterObject?.to_date,
                    selectedOfflineShop?.value
                  );
                } else {
                  toastr.error(res.data.message, "Failed to delete order");
                  return;
                }
              });
            setconfirmOfflineDeleteAlert(false);
          }}
          onCancel={() => setconfirmOfflineDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <Modal
        isOpen={orderViewModalOpen}
        toggle={() => setOrderViewModalOpen(!orderViewModalOpen)}
        size="xl"
      >
        <div className="modal-header">
          <button
            type="button"
            onClick={() => {
              setOrderViewModalOpen(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <ModalBody>
          <Row>
            <Col md="12" className="text-center">
              <h5 className="modal-title mt-0">
                {" "}
                Order Estimate #{order?.orders_unique_id}{" "}
              </h5>
            </Col>
            <Col md="5" style={{ fontSize: "13px" }}>
              <Row>
                <Col md="4" className="p-1">
                  Customer Name
                </Col>
                <Col md="8">: {order?.customer?.customer_name}</Col>
                <Col md="4" className="p-1">
                  Address
                </Col>
                <Col md="8">: {order?.customer?.customer_address}</Col>
                <Col md="4" className="p-1">
                  Moblie
                </Col>
                <Col md="8">: {order?.customer?.customer_contact_number}</Col>
                <Col md="4" className="p-1">
                  Email
                </Col>
                <Col md="8">: {order?.customer?.customer_email_id}</Col>
                <Col md="4" className="p-1">
                  Shipping Address
                </Col>
                <Col md="8">
                  : {order?.address?.address_name}
                  <br></br>
                  {order?.address?.shipping_address}{" "}
                  {order?.address?.customer_location},
                  {order?.address?.customer_district} -{" "}
                  {order?.address?.customer_pincode}
                  <br></br>
                  <i className="fas fa-phone-square-alt"></i>{" "}
                  {order?.address?.address_contact_number}
                </Col>
              </Row>
            </Col>
            <Col md="3"></Col>
            <Col md="4" style={{ fontSize: "13px" }}>
              <Row>
                <Col
                  md="6"
                  style={{ paddingLeft: "97px", paddingBottom: "10px" }}
                >
                  Date
                </Col>
                <Col md="6" style={{ paddingLeft: "12px" }}>
                  : {moment(order?.order_date).format("DD-MM-YYYY")}
                </Col>
                <Col
                  md="6"
                  style={{ paddingLeft: "97px", paddingBottom: "10px" }}
                >
                  Time
                </Col>
                <Col md="6" style={{ paddingLeft: "12px" }}>
                  : {moment(order?.order_time, "HH:mm:ss").format("hh:mm a")}
                </Col>
                <Col
                  md="6"
                  style={{ paddingLeft: "97px", paddingBottom: "10px" }}
                >
                  Delivery Date
                </Col>
                <Col md="6" style={{ paddingLeft: "12px" }}>
                  :{" "}
                  {moment(order?.order_delivery_date_from).format("DD-MM-YYYY")}
                </Col>
                <Col
                  md="6"
                  style={{ paddingLeft: "97px", paddingBottom: "10px" }}
                >
                  Delivery Time
                </Col>
                <Col md="6" style={{ paddingLeft: "12px" }}>
                  :{" "}
                  {moment(
                    order?.order_delivery_date_from,
                    "YYYY-MM-DD HH:mm:ss"
                  ).format("hh:mm a")}{" "}
                  -{" "}
                  {moment(
                    order?.order_delivery_date_to,
                    "YYYY-MM-DD HH:mm:ss"
                  ).format("hh:mm a")}
                </Col>
                <Col
                  md="6"
                  style={{ paddingLeft: "97px", paddingBottom: "10px" }}
                >
                  Delivery Type
                </Col>
                <Col md="6" style={{ paddingLeft: "12px" }}>
                  :{" "}
                  {order?.order_delivery_type == 1
                    ? "Fast"
                    : order?.order_delivery_type == 2
                      ? "Express"
                      : "Normal"}
                </Col>
              </Row>
            </Col>
          </Row>
          <hr></hr>
          <Row>
            <Col md="12">
              <MDBDataTable
                id="OrderItemsTableID"
                responsive
                bordered
                data={orderItems}
                searching={false}
                info={false}
                paging={false}
                disableRetreatAfterSorting={true}
              />
              <Row>
                <Col md="8"></Col>
                <Col md="4" style={{ textAlign: "right" }}>
                  <table id="OrderSummeryTable">
                    <tr>
                      <th>Total</th>
                      <td>:</td>
                      <td>{order?.order_net_amount?.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <th>GST</th>
                      <td>:</td>
                      <td>{order?.order_gst_amount?.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <th>Total Taxable</th>
                      <td>:</td>
                      <td>{order?.order_sub_total?.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <th>Total Discount</th>
                      <td>:</td>
                      <td>{order?.order_discount_amount?.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <th>Delivery Charge</th>
                      <td>:</td>
                      <td>{order?.order_delivery_amount?.toFixed(2)}</td>
                    </tr>
                    {/* <tr>
                                            <th>Net Amount</th><td>:</td><td>{(parseFloat(order?.order_sub_total) + parseFloat(order?.order_delivery_amount)).toFixed(2)}</td>
                                        </tr>
                                        <tr>
                                            <th>Wallet Amount Used</th><td>:</td><td>{order?.order_wallet_amount?.toFixed(2)}</td>
                                        </tr> */}
                    <tr>
                      <th>Grand Total</th>
                      <td>:</td>
                      <td>{order?.order_wallet_amount?.toFixed(2)}</td>
                    </tr>
                  </table>
                </Col>
              </Row>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={addressModel}
        toggle={() => setAddressModel(!addressModel)}
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <button
            type="button"
            onClick={() => {
              setAddressModel(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <ModalBody>
          <Row>
            <Col md="12">
              <table className="addressTable">
                <tr>
                  <th>Name</th>
                  <td>: {address_data?.address_name}</td>
                  <th>Mobile</th>
                  <td>: {address_data?.address_contact_number}</td>
                </tr>
                <tr>
                  <th>Alternative No.</th>
                  <td>: {address_data?.address_alternative_number}</td>
                  <th>Email</th>
                  <td>
                    :{" "}
                    {address_data?.address_email != "0"
                      ? address_data?.address_email
                      : ""}
                  </td>
                </tr>
                <tr>
                  <th>Address</th>
                  <td>: {address_data?.shipping_address}</td>
                  <th>Location</th>
                  <td>: {address_data?.customer_location}</td>
                </tr>
                <tr>
                  <th>Pincode</th>
                  <td>: {address_data?.customer_pincode}</td>
                  <th>Building/Flat No.</th>
                  <td>: {address_data?.building_or_flat_no}</td>
                </tr>
                <tr>
                  <th>Alternative No.</th>
                  <td>: {address_data?.address_alternative_number}</td>
                  <th>Landmark</th>
                  <td>: {address_data?.landmark}</td>
                </tr>
                <tr>
                  <th>Type</th>
                  <td>: {address_data?.customer_address_type}</td>
                  <th>GST</th>
                  <td>: {address_data?.customer_gst}</td>
                </tr>

                <tr>
                  <th>Created On</th>
                  <td>: {address_data?.date}</td>
                  <th>Default</th>
                  <td>: {address_data?.default_address}</td>
                </tr>
              </table>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <div className="page-content">
        <Container>
          {hasProfile ? (
            <>
              <Row>
                <Col className="col-12">
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h4 className="mb-0">Customer - {customerID}</h4>

                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <BreadcrumbItem>
                          <Link to="/dashboard">Home</Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                          <Link to="/customer">Customers</Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>
                          <Link to="#">{customerID}</Link>
                        </BreadcrumbItem>
                      </ol>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl="4">
                  <Card>
                    <CardBody>
                      <div className="text-center">
                        <div className="clearfix"></div>
                        <div>
                          {profile?.customer_image ? (
                            <img
                              src={
                                API_URL +
                                "uploads/customer/" +
                                profile?.customer_image
                              }
                              alt=""
                              className="avatar-lg rounded-circle img-thumbnail"
                            />
                          ) : (
                            <img
                              src={avatar}
                              alt=""
                              className="avatar-lg rounded-circle img-thumbnail"
                            />
                          )}
                        </div>
                        <h5 className="mt-3 mb-1">
                          {profile?.customer_name?.toUpperCase()}
                        </h5>
                        <p className="text-muted">
                          {profile?.customer_contact_number}
                        </p>
                        <div class="row">
                          <div
                            class="col-3 col div-link"
                            onClick={() => {
                              toggleCustomJustified("3");
                            }}
                          >
                            <div class="social-source text-center mt-3">
                              <div class="avatar-xs mx-auto mb-3">
                                <span class="avatar-title font-size-30 dashboard-icon2">
                                  <i class="mdi mdi-wallet text-white"></i>
                                </span>
                              </div>
                              <h5 class="font-size-12">Balance</h5>
                              <p class="text-muted mb-0">
                                <i className="fas fa-rupee-sign" style={{ fontSize: "10px" }}></i>{" "}
                                {profile?.customer_wallet_amount?.toFixed(2)}
                              </p>
                            </div>
                          </div>
                          <div
                            class="col-3 col div-link"
                            onClick={() => {
                              setpointFilterObject({
                                ...pointFilterObject,
                                type: { label: "Buyback Offer", value: 0 },
                              });
                              getPointLog(
                                pointFilterObject?.from_date,
                                pointFilterObject?.to_date,
                                0
                              );
                              toggleCustomJustified("4");
                            }}
                          >
                            <div class="social-source text-center mt-3">
                              <div class="avatar-xs mx-auto mb-3">
                                <span class="avatar-title font-size-30 dashboard-icon2">
                                  <i class="mdi mdi-flash-circle text-white"></i>
                                </span>
                              </div>
                              <h5 class="font-size-12">Buyback <i class="mdi mdi-lock-alert" style={{ color: "rgb(220, 53, 69)" }}></i></h5>
                              <p class="text-muted mb-0">
                                <i className="fas fa-rupee-sign" style={{ fontSize: "10px" }}></i>{" "}
                                {(profile?.customer_point1 - profile?.customer_avail_point1)?.toFixed(2)}
                              </p>
                            </div>
                          </div>
                          <div
                            class="col-3 col div-link"
                            onClick={() => {
                              setpointFilterObject({
                                ...pointFilterObject,
                                type: { label: "Buyback Offer", value: 0 },
                              });
                              getPointLog(
                                pointFilterObject?.from_date,
                                pointFilterObject?.to_date,
                                0
                              );
                              toggleCustomJustified("4");
                            }}
                          >
                            <div class="social-source text-center mt-3">
                              <div class="avatar-xs mx-auto mb-3">
                                <span class="avatar-title font-size-30 dashboard-icon2">
                                  <i class="mdi mdi-flash-circle text-white"></i>
                                </span>
                              </div>
                              <h5 class="font-size-12">Buyback <i class="mdi mdi-lock-open-check" style={{ color: "rgb(76, 174, 137)" }}></i></h5>
                              <p class="text-muted mb-0">
                                <i className="fas fa-rupee-sign" style={{ fontSize: "10px" }}></i>{" "}
                                {profile?.customer_avail_point1?.toFixed(2)}
                              </p>
                            </div>
                          </div>
                          <div
                            class="col-3 col div-link"
                            onClick={() => {
                              setpointFilterObject({
                                ...pointFilterObject,
                                type: { label: "Jio Offer", value: 1 },
                              });
                              getPointLog(
                                pointFilterObject?.from_date,
                                pointFilterObject?.to_date,
                                1
                              );
                              toggleCustomJustified("4");
                            }}
                          >
                            <div class="social-source text-center mt-3">
                              <div class="avatar-xs mx-auto mb-3">
                                <span class="avatar-title bg-primary font-size-30 dashboard-icon2">
                                  <i class="bx bxs-offer text-white"></i>
                                </span>
                              </div>
                              <h5 class="font-size-12">Jio Offer</h5>
                              <p class="text-muted mb-0">
                                <i className="fas fa-rupee-sign" style={{ fontSize: "10px" }}></i>{" "}
                                {profile?.customer_point2?.toFixed(2)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr className="my-4" />

                      <div className="text-muted">
                        <h5 className="font-size-16">Address</h5>
                        <p>
                          {profile?.customer_address} {profile?.name}{" "}
                          {profile?.dist_name} {profile?.customer_pincode}{" "}
                          <br></br>Latitude:{profile?.customer_lattitude}
                          <br></br>Longitude:{profile?.customer_longitude}
                        </p>
                        <div className="table-responsive mt-4">
                          <div>
                            <p className="mb-1">Name :</p>
                            <h5 className="font-size-16">
                              {profile?.customer_name}
                            </h5>
                          </div>
                          <div className="mt-4">
                            <p className="mb-1">Mobile :</p>
                            <h5 className="font-size-16">
                              {profile?.customer_contact_number}
                            </h5>
                          </div>
                          <div className="mt-4">
                            <p className="mb-1">E-mail :</p>
                            <h5 className="font-size-16">
                              {profile?.customer_email_id}
                            </h5>
                          </div>
                          <div className="mt-4">
                            <p className="mb-1">Reference Code :</p>
                            <h5 className="font-size-16">
                              {profile?.customer_reference_code}
                            </h5>
                          </div>
                          <div className="mt-4">
                            <p className="mb-1">GST :</p>
                            <h5 className="font-size-16">
                              {profile?.customer_gst}
                            </h5>
                          </div>
                          <div className="mt-4">
                            <p className="mb-1">Android Version :</p>
                            <h5 className="font-size-16">
                              {profile?.customer_android_version}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="8">
                  <Card>
                    <CardBody>
                      <Nav tabs className="nav-tabs-custom nav-justified">
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTabJustify === "1",
                            })}
                            onClick={() => {
                              toggleCustomJustified("1");
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="fas fa-home"></i>
                            </span>
                            <span className="d-none d-sm-block">
                              Online Orders
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTabJustify === "2",
                            })}
                            onClick={() => {
                              toggleCustomJustified("2");
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="far fa-user"></i>
                            </span>
                            <span className="d-none d-sm-block">
                              Offline Orders
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTabJustify === "3",
                            })}
                            onClick={() => {
                              toggleCustomJustified("3");
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="far fa-envelope"></i>
                            </span>
                            <span className="d-none d-sm-block">
                              Wallet Log
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTabJustify === "4",
                            })}
                            onClick={() => {
                              setpointFilterObject({
                                ...pointFilterObject,
                                type: "",
                              });
                              getPointLog(
                                pointFilterObject?.from_date,
                                pointFilterObject?.to_date
                              );

                              toggleCustomJustified("4");
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="fas fa-cog"></i>
                            </span>
                            <span className="d-none d-sm-block">Point Log</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTabJustify === "5",
                            })}
                            onClick={() => {
                              toggleCustomJustified("5");
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="fas fa-cog"></i>
                            </span>
                            <span className="d-none d-sm-block">
                              Addresses
                            </span>
                          </NavLink>
                        </NavItem>
                      </Nav>

                      <TabContent activeTab={activeTabJustify}>
                        <TabPane tabId="1" className="pl-1 pt-3">
                          <Row>
                            <Col md="3">
                              <Label htmlFor="validationCustom05">From</Label>
                              <input
                                className="form-control"
                                type="date"
                                id="from_date"
                                name="from_date"
                                onChange={handleFilter}
                                value={onlinefilterObject?.from_date}
                              />
                            </Col>
                            <Col md="3">
                              <Label htmlFor="validationCustom05">To</Label>
                              <input
                                className="form-control"
                                type="date"
                                id="to_date"
                                name="to_date"
                                onChange={handleFilter}
                                value={onlinefilterObject?.to_date}
                              />
                            </Col>
                            <Col md="1" style={{ marginTop: "25px" }}>
                              <Button
                                color="danger"
                                type="submit"
                                onClick={resetOnlineOrder}
                              >
                                Reset
                              </Button>
                            </Col>
                            <Col md="12" className="mt-2">
                              <MDBDataTable
                                id="orderListTable1"
                                responsive
                                bordered
                                data={onlinedata}
                                searching={true}
                                info={false}
                                entries={25}
                                displayEntries={false}
                                disableRetreatAfterSorting={true}
                              />
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="2" className="pl-1 pt-3">
                          <Row>
                            <Col md="3">
                              <Label htmlFor="validationCustom05">From</Label>
                              <input
                                className="form-control"
                                type="date"
                                id="from_date"
                                name="from_date"
                                onChange={handleOfflineFilter}
                                value={offlinefilterObject?.from_date}
                              />
                            </Col>
                            <Col md="3">
                              <Label htmlFor="validationCustom05">To</Label>
                              <input
                                className="form-control"
                                type="date"
                                id="to_date"
                                name="to_date"
                                onChange={handleOfflineFilter}
                                value={offlinefilterObject?.to_date}
                              />
                            </Col>
                            <Col md="3">
                              <Label htmlFor="validationCustom05">Shop</Label>
                              <Select
                                name="shop"
                                value={selectedOfflineShop}
                                onChange={(value) => {
                                  handleselectedOfflineShop(value);
                                }}
                                options={shopOfflineOptions}
                                classNamePrefix="select2-selection"
                              />
                            </Col>
                            <Col md="1" style={{ marginTop: "25px" }}>
                              <Button
                                color="danger"
                                type="submit"
                                onClick={resetOffline}
                              >
                                Reset
                              </Button>
                            </Col>
                            <Col md="12" className="mt-2">
                              <MDBDataTable
                                id="offlineorderListTable1"
                                responsive
                                bordered
                                data={offlinedata}
                                searching={true}
                                info={false}
                                entries={25}
                                displayEntries={false}
                                disableRetreatAfterSorting={true}
                              />
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="3" className="pl-1 pt-3">
                          <Row>
                            <Col md="12">
                              <Row className="mb-2">
                                <Col md="3">
                                  <Label htmlFor="validationCustom05">
                                    From
                                  </Label>
                                  <input
                                    type="date"
                                    value={walletFilterObject?.from_date}
                                    name="from_date"
                                    className="form-control"
                                    onChange={(e) => {
                                      setwalletFilterObject({
                                        ...walletFilterObject,
                                        [e.target.name]: e.target.value,
                                      });
                                      getWalletLog(
                                        e.target.value,
                                        walletFilterObject?.to_date
                                      );
                                    }}
                                  />
                                </Col>
                                <Col md="3">
                                  <Label htmlFor="validationCustom05">To</Label>
                                  <input
                                    type="date"
                                    name="to_date"
                                    className="form-control"
                                    value={walletFilterObject?.to_date}
                                    onChange={(e) => {
                                      setwalletFilterObject({
                                        ...walletFilterObject,
                                        [e.target.name]: e.target.value,
                                      });
                                      getWalletLog(
                                        walletFilterObject?.from_date,
                                        e.target.value
                                      );
                                    }}
                                  />
                                </Col>
                                <Col md="1" style={{ marginTop: "25px" }}>
                                  <Button
                                    color="danger"
                                    type="submit"
                                    onClick={walletreset}
                                  >
                                    Reset
                                  </Button>
                                </Col>
                              </Row>
                              <MDBDataTable
                                id="walletLogTable"
                                responsive
                                bordered
                                data={walletdata}
                                disableRetreatAfterSorting={true}
                                entries={25}
                                displayEntries={false}
                              />
                            </Col>
                          </Row>
                        </TabPane>

                        <TabPane tabId="4" className="pl-1 pt-3">
                          <Row>
                            <Col md="12">
                              <Row className="mb-2">
                                <Col md="3">
                                  <Label htmlFor="validationCustom05">
                                    From
                                  </Label>
                                  <input
                                    type="date"
                                    value={pointFilterObject?.from_date}
                                    name="from_date"
                                    className="form-control"
                                    onChange={(e) => {
                                      setpointFilterObject({
                                        ...pointFilterObject,
                                        [e.target.name]: e.target.value,
                                      });
                                      getPointLog(
                                        e.target.value,
                                        pointFilterObject?.to_date,
                                        pointFilterObject?.type?.value
                                      );
                                    }}
                                  />
                                </Col>
                                <Col md="3">
                                  <Label htmlFor="validationCustom05">To</Label>
                                  <input
                                    type="date"
                                    name="to_date"
                                    className="form-control"
                                    value={pointFilterObject?.to_date}
                                    onChange={(e) => {
                                      setpointFilterObject({
                                        ...pointFilterObject,
                                        [e.target.name]: e.target.value,
                                      });
                                      getPointLog(
                                        pointFilterObject?.from_date,
                                        e.target.value,
                                        pointFilterObject?.type?.value
                                      );
                                    }}
                                  />
                                </Col>
                                <Col md="3">
                                  <Label htmlFor="validationCustom05">
                                    Point Type
                                  </Label>
                                  <Select
                                    name="shop"
                                    value={pointFilterObject?.type}
                                    onChange={(value) => {
                                      setpointFilterObject({
                                        ...pointFilterObject,
                                        type: value,
                                      });
                                      getPointLog(
                                        pointFilterObject?.from_date,
                                        pointFilterObject?.to_date,
                                        value.value
                                      );
                                    }}
                                    options={[
                                      { label: "Buyback Offer", value: "0" },
                                      { label: "Jio Offer", value: "1" },
                                    ]}
                                    classNamePrefix="select2-selection"
                                  />
                                </Col>
                                <Col md="1" style={{ marginTop: "25px" }}>
                                  <Button
                                    color="danger"
                                    type="submit"
                                    onClick={pointreset}
                                  >
                                    Reset
                                  </Button>
                                </Col>
                              </Row>
                              <div
                                className="spinner1"
                                style={{ display: isLoading }}
                              >
                                <Spinner
                                  type="grow"
                                  className="m-1"
                                  color="secondary"
                                  style={{ display: isLoading }}
                                />
                              </div>
                              <MDBDataTable
                                id="pointLogTable"
                                responsive
                                bordered
                                data={pointdata}
                                disableRetreatAfterSorting={true}
                                entries={25}
                                displayEntries={false}
                              />
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="5" className="pl-1 pt-3">
                          <Row>
                            <Col md="12">
                              <MDBDataTable
                                id="shippingAddressTable"
                                responsive
                                bordered
                                data={addressdata}
                                disableRetreatAfterSorting={true}
                                entries={25}
                                displayEntries={false}
                              />
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row>
                <Col md={12}>
                  <div className="text-center">
                    <div>
                      <Row className="row justify-content-center">
                        <Col sm={4}>
                          <div className="error-img">
                            <img
                              src={errorImg}
                              alt=""
                              className="img-fluid mx-auto d-block"
                              style={{ height: "280px" }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <h4 className="text-uppercase mt-4">
                      Sorry, details not found
                    </h4>
                    <div className="mt-5">
                      <Link
                        className="btn btn-primary waves-effect waves-light"
                        to="/customer"
                      >
                        Back to Customer
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};
export default CustomersDashboard;
