import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import {jwtDecode }from "jwt-decode";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap";
import Select from "react-select";
import {
  getAllFundSource,
  apiError,
  getAllCreditDebit,
  addCreditDebit,
} from "../../../store/actions";
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
// import "./user.scss";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
const Credit = (props) => {
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  const [creditDebitList, setCreditDebitList] = useState([]);
  const [selectedTransationType, setSelectedTransationType] = useState(null);
  const [master, setMaster] = useState({});
  const [fundSourceOptions, setFundSourceOptions] = useState([]);
  const [selectedFundSource, setSelectedFundSource] = useState(null);
  const [userId, setUserId] = useState(1);

  const dispatch = useDispatch();
  const formRef = useRef();

  const { fundSource } = useSelector((state) => state.fundTransfers);

  const { creditDebits, addingCrediDebit, addCrediDebitResponse, error } =
    useSelector((state) => state.creditDebits);
  const getFundSources = () => {
    axios
      .get(`${API_URL}chart_of_account/all_fund_source`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var list = [];
        res.data?.data?.map((item) => {
          list.push({
            label: item.acc_chart_name,
            value: item.acc_chart_id,
          });
        });
        setFundSourceOptions(list);
      });
  };
  useEffect(() => {
    getFundSources();
    // dispatch(getAllFundSource());
    dispatch(getAllCreditDebit());
  }, []);

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = jwtDecode(localStorage.getItem("authUser"));
      setUserId(obj.user);
    }
  }, [userId]);

  // useEffect(() => {
  //   var data =
  //     fundSource &&
  //     fundSource.map((item) => {
  //       return {
  //         label: item.acc_chart_name,
  //         value: item.acc_chart_id,
  //       };
  //     });

  //   setFundSourceOptions([
  //     {
  //       options: data,
  //     },
  //   ]);
  // }, [fundSource]);

  useEffect(() => {
    let creditDebitData = JSON.parse(JSON.stringify(creditDebits));
    console.log("creditDebitData:---> ", creditDebitData);

    let data = [];
    creditDebitData.map((item, index) => {
      item.x_User = item.fname;
      item.fname = "";
      if (item.User) {
        item.fname = item.User.fname + " " + item.User.lname;
      }
      if (item.fund_credit_debit_type == 0) {
        item.n_Credit = (
          <div className="text-right">
            {item.fund_credit_debit_amount.toFixed(2)}
          </div>
        );
      } else
        item.n_Debit = (
          <div className="text-right">
            {item.fund_credit_debit_amount.toFixed(2)}
          </div>
        );
      item.auth_userid = userId;
      item.id = index + 1;
      data.push(item);
    });
    setCreditDebitList(data);
  }, [creditDebits]);

  useEffect(() => {
    console.log("creditDebits: ", creditDebits);
    if (addCrediDebitResponse.type === "success") {
      toastr.success(addCrediDebitResponse.message);
    } else if (addCrediDebitResponse.type === "failure") {
      toastr.error(error.data.message, addCrediDebitResponse.message);
    }
  }, [addCrediDebitResponse]);

  let handleChangeInput = (e) => {
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectedTransactionType = (selected) => {
    setSelectedTransationType(selected);
    setMaster({
      ...master,
      ["auth_userid"]: userId,
      ["fund_credit_debit_type"]: selected.value,
    });
  };
  const handleSelectedFundSource = (selected) => {
    setSelectedFundSource(selected);
    setMaster({
      ...master,
      ["fund_credit_debit_fund_source"]: selected.value,
    });
  };

  const handleValidSubmit = (e, v) => {
    if (selectedFundSource == null) {
      toastr.error("Fund source  is required");
      return;
    }
    if (selectedTransationType == null) {
      toastr.error("Type is required");
      return;
    }
    dispatch(addCreditDebit(master));

    formRef.current.reset();
    setSelectedTransationType(null);
    setSelectedFundSource(null);
    setMaster({ ["auth_userid"]: userId });
    dispatch(getAllCreditDebit());
  };

  const reset = () => {
    setMaster({});
    setSelectedTransationType(null);
    setSelectedFundSource(null);
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Reference ID",
        field: "fund_credit_debit_unqid",
        sort: "asc",
        width: 400,
      },
      {
        label: "Chart Name",
        field: "acc_chart_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Debit",
        field: "n_Debit",
        sort: "asc",
        width: 200,
      },
      {
        label: "Credit",
        field: "n_Credit",
        sort: "asc",
        width: 200,
      },
      {
        label: "Comment",
        field: "fund_credit_debit_comment",
        sort: "asc",
        width: 200,
      },
      {
        label: "User",
        field: "x_User",
        sort: "asc",
        width: 200,
      },
    ],
    rows: creditDebitList,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Credit/Debit Fund" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Fund Source</Label>
                          <Select
                            name="fund_source_id"
                            value={selectedFundSource}
                            onChange={(selected) => {
                              handleSelectedFundSource(selected);
                            }}
                            options={fundSourceOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Type</Label>
                          <Select
                            name="credit_debit_type_id"
                            value={selectedTransationType}
                            onChange={(selected) => {
                              handleSelectedTransactionType(selected);
                            }}
                            options={[
                              { label: "Credit", value: 0 },
                              { label: "Debit", value: 1 },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Amount</Label>
                          <AvField
                            name="fund_credit_debit_amount"
                            placeholder="Amount"
                            type="number"
                            errorMessage="Enter Amount"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                            value={master?.fund_credit_debit_amount}
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Comments</Label>
                          <AvField
                            name="fund_credit_debit_comment"
                            placeholder="Comments"
                            type="text"
                            errorMessage="Enter Comments"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                            value={master?.fund_credit_debit_comment}
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      <Col md="1">
                        <div className="mt-4">
                          <Button
                            color="primary"
                            type="submit"
                            disabled={addingCrediDebit ? true : false}
                          >
                            {addingCrediDebit ? "Adding" : "Submit"}
                          </Button>
                        </div>
                      </Col>
                      <Col md="1">
                        <div className="mt-4">
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={false}
                    disableRetreatAfterSorting={true}
                    entries={25}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {};

export default withRouter(connect(mapStateToProps, { apiError })(Credit));
