import React, { useState, useEffect, useRef } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import SweetAlert from 'react-bootstrap-sweetalert';
import { MDBDataTable } from 'mdbreact';
import { Row, Col, Card, CardBody, Button, Label } from 'reactstrap';
import toastr from 'toastr';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import "./district.scss";
import Select from 'react-select';
const District = (props) => {
    const formRef = useRef();
    const [masterObject, setMasterObject] = useState(null);
    const [districtIdTobeUpdated, setDistrictIdTobeUpdated] = useState(null);
    const [dataTable, setDataTable] = useState([]);
    const [distIdTobeDeleted, setDistIdTobeDeleted] = useState(null);
    const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(false);
    const [selectedState, setselectedState] = useState(null);
    const [stateOptions, setstateOptions] = useState([]);
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
    const toTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };
    const handleChangeInput = (e) => {
        setMasterObject({
            ...masterObject,
            [e.target.name]: e.target.value
        })
    }
    const getStates = () => {
        axios
            .get(`${API_URL}state`, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                var list = [];
                res.data?.data?.map((item) => {
                    list.push({ label: item.name, value: item.id });
                })
                setstateOptions(list);
            })
    }
    const handleValidSubmit = () => {
        if (!selectedState) {
            toastr.error("Please select state");
            return;
        }
        if (districtIdTobeUpdated) {
            axios
                .put(`${API_URL}district/`, masterObject, {
                    headers: {
                        "x-access-token": accessToken,
                    },
                })
                .then((res) => {
                    if (res.data.status == 200) {
                        toastr.success("Updated Successfully");
                        setDistrictIdTobeUpdated(null);
                        formRef.current.reset();
                        setselectedState(null);
                        setMasterObject(null);
                        getDataTable();
                    }
                    else {
                        toastr.error(res.data.message);
                    }
                }).catch((err) => {
                    toastr.error("Failed to update district");
                })
        }
        else {
            axios
                .post(`${API_URL}district/`, masterObject, {
                    headers: {
                        "x-access-token": accessToken,
                    },
                })
                .then((res) => {
                    if (res.data.status == 200) {
                        toastr.success("Submitted Successfully");
                        formRef.current.reset();
                        setMasterObject(null);
                        setselectedState(null);
                        getDataTable();
                    }
                    else {
                        toastr.error(res.data.message);
                    }
                }).catch((err) => {
                    toastr.error("Failed to add district");
                })
        }
    }
    const handleSelectChange = (selected, type) => {
        switch (type) {
            case "3":
                setselectedState(selected);
                setMasterObject({
                    ...masterObject,
                    "dist_state": selected.value,
                });
                break;
        }
    }
    const preUpdateDistrict = (item) => {
        setDistrictIdTobeUpdated(item.dist_id);
        setMasterObject(item);
        setselectedState({ label: item?.State?.name, value: item?.State?.id });
    }
    const getDataTable = () => {
        axios
            .get(`${API_URL}district/`, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((res) => {
                var data = res.data.data;
                var list = [];
                data.map((item, index) => {
                    item.serial = index + 1;
                    item.name = item.addedUser?.fname;
                    item.state = item.State?.name;
                    item.action = (
                        <>
                            <i
                                className="far fa-edit"
                                style={{
                                    fontSize: "1em",
                                    cursor: "pointer",
                                    marginLeft: "0.1em",
                                    marginRight: "0.5em",
                                }}
                                onClick={() => {
                                    toTop();
                                    preUpdateDistrict(item);
                                }}
                            ></i>
                            <i
                                className="far fa-trash-alt"
                                style={{ fontSize: "1em", cursor: "pointer" }}
                                onClick={() => {
                                    setDistIdTobeDeleted(item.dist_id);
                                    setConfirmDeleteAlert(true);
                                }}
                            ></i>
                        </>
                    )
                    list.push(item);
                })
                setDataTable(list);
            })
    }
    const data = {
        columns: [
            {
                label: "#",
                field: "serial",
                sort: "asc",
                width: 150,
            },
            {
                label: "State",
                field: "state",
                sort: "asc",
                width: 150,
            },
            {
                label: "District",
                field: "dist_name",
                sort: "asc",
                width: 150,
            },
            {
                label: "Code",
                field: "dist_code",
                sort: "asc",
                width: 150,
            },
            {
                label: "Staff",
                field: "name",
                sort: "asc",
                width: 150,
            },
            {
                label: "Action",
                field: "action",
                sort: "asc",
                width: 150,
            },
        ],
        rows: dataTable
    }
    useEffect(() => {
        getStates();
        getDataTable();
    }, [])
    return (
        <React.Fragment>
            {confirmDeleteAlert ? (
                <SweetAlert
                    title=""
                    showCancel
                    confirmButtonText="Delete"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        axios
                            .delete(`${API_URL}district?id=` + distIdTobeDeleted, {
                                headers: {
                                    "x-access-token": accessToken,
                                },
                            })
                            .then((res) => {
                                if (res.data.status === 200) {
                                    toastr.success("Deleted successfully");
                                    if (masterObject && masterObject.dist_id === distIdTobeDeleted) {
                                        formRef.current.reset();
                                        setMasterObject(null);
                                        setDistrictIdTobeUpdated(null);
                                    }
                                    setDistIdTobeDeleted(null);
                                    getDataTable();
                                } else {
                                    toastr.error(res.data.message, "Failed to delete");
                                    return;
                                }
                            })
                            .catch((err) => {
                                toastr.error(err.response.data.message);
                                return;
                            });
                        setConfirmDeleteAlert(false);
                    }}
                    onCancel={() => setConfirmDeleteAlert(false)}
                >
                    Are you sure you want to delete it?
                </SweetAlert>
            ) : null}
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Home" breadcrumbItem="Manage District" />
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardBody>

                                    <AvForm
                                        ref={formRef}
                                        className="needs-validation"
                                        onValidSubmit={(e, v) => {
                                            handleValidSubmit(e, v);
                                        }}
                                    >
                                        <Row>
                                            <Col md="3">
                                                <Label>State</Label>
                                                <Select
                                                    name="customer_state"
                                                    value={selectedState}
                                                    onChange={(selected) => {
                                                        handleSelectChange(selected, "3");
                                                    }}
                                                    options={stateOptions}
                                                    classNamePrefix="select2-selection"
                                                    menuPosition="auto"
                                                    menuPlacement="auto"
                                                    isDisabled={districtIdTobeUpdated ? true : ""}
                                                />

                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >District Name</Label>
                                                    <AvField
                                                        name="dist_name"
                                                        value={masterObject?.dist_name}
                                                        placeholder="District Name"
                                                        type="text"
                                                        errorMessage="District Name is required"
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: true }
                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="1" style={{ paddingTop: "26px" }}>
                                                {districtIdTobeUpdated ? (
                                                    <Button
                                                        color="primary"
                                                        type="submit"
                                                    >
                                                        Update
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        color="primary"
                                                        type="submit"
                                                    >
                                                        Submit
                                                    </Button>
                                                )}
                                            </Col>
                                            <Col md="1" style={{ paddingTop: "26px" }}>
                                                <Button
                                                    color="danger"
                                                    type="reset"
                                                    onClick={(e) => {
                                                        setMasterObject(null);
                                                        setselectedState(null);
                                                        formRef.current.reset();
                                                        setDistIdTobeDeleted(null);
                                                        setDistrictIdTobeUpdated(null);

                                                    }}
                                                >
                                                    Reset
                                                </Button>
                                            </Col>
                                        </Row>
                                    </AvForm>

                                </CardBody>

                            </Card>
                        </Col>
                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <MDBDataTable
                                        responsive
                                        id="districtTableId"
                                        bordered
                                        data={data}
                                        searching={true}
                                        info={true}
                                        disableRetreatAfterSorting={true}
                                        entries={20}
                                    />
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment >
    )
}
export default District;