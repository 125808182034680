import React, { useState, useEffect, useRef } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import SweetAlert from 'react-bootstrap-sweetalert';
import { MDBDataTable } from 'mdbreact';
import { Row, Col, Card, CardBody, Button, Label, Modal } from 'reactstrap';
import toastr from 'toastr';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import "./company.scss";
import Select from 'react-select';
const Company = (props) => {
    const formRef = useRef();
    const [masterObject, setMasterObject] = useState(null);
    const [selectedDistrict, setselectedDistrict] = useState(null);
    const [districtOptions, setdistrictOptions] = useState([]);
    const [selectedLocalbody, setSelectedLocalbody] = useState(null);
    const [localbodyOptions, setLocalbodyOptions] = useState([]);
    const [companyIdTobeUpdated, setcompanyIdTobeUpdated] = useState(null);
    const [companyIdTobeDeleted, setcompanyIdTobeDeleted] = useState(null);
    const [dataTable, setDataTable] = useState([]);
    const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [isModelOpen, showModel] = useState(false);
    const [selectedState, setselectedState] = useState(null);
    const [stateOptions, setstateOptions] = useState([]);
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
    const toTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };
    const handleChangeInput = (e) => {
        setMasterObject({
            ...masterObject,
            [e.target.name]: e.target.value
        })
    }
    const handleSelectChange = (selected, type) => {
        switch (type) {
            case "3":
                setselectedState(selected);
                setMasterObject({
                    ...masterObject,
                    "branch_state": selected.value,
                    "branch_district": null,
                    "branch_localbody": null
                });
                setselectedDistrict(null)
                setSelectedLocalbody(null);
                setdistrictOptions([]);
                setLocalbodyOptions([]);
                getDistricts(selected.value);
                break;
            case "1":
                setselectedDistrict(selected);
                setMasterObject({
                    ...masterObject,
                    "branch_district": selected.value,
                    "branch_localbody": null
                });
                setSelectedLocalbody(null);
                getLocalbodies(selected.value);
                break;
            case "2":
                setSelectedLocalbody(selected);
                setMasterObject({
                    ...masterObject,
                    "branch_localbody": selected.value
                });
                break;
        }
    }
    const getStates = () => {
        axios
            .get(`${API_URL}state`, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                var list = [];
                res.data?.data?.map((item) => {
                    list.push({ label: item.name, value: item.id });
                })
                setstateOptions(list);
            })
    }
    const getDistricts = (id) => {
        axios
            .get(`${API_URL}district/options/?id=` + id, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                var list = [];
                res.data?.data?.map((item) => {
                    list.push({ label: item.dist_name, value: item.dist_id });
                })
                setdistrictOptions(list);
            })
    }
    const getLocalbodies = (id) => {
        axios
            .get(`${API_URL}localbody/options?id=` + id, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                var list = [];
                res.data?.data?.map((item) => {
                    list.push({ label: item.localbody_name, value: item.localbody_id });
                })
                setLocalbodyOptions(list);
            })
    }
    const uploadImage = (e) => {
        const fd = new FormData();
        fd.append("photo", e.target.files[0]);
        axios
            .post(`${API_URL}company/upload_image/`, fd, {
                headers: {
                    "x-access-token": accessToken,
                }
            })
            .then((response) => {
                if (response.data.status === "success") {
                    setMasterObject({
                        ...masterObject,
                        [`branch_logo`]: response.data.file.filename,
                    });
                } else {
                    toastr.error(response.data.message);
                }
            });
    };
    const deleteImage = () => {
        setMasterObject({
            ...masterObject,
            [`branch_logo`]: "",
        });
    };
    const handleValidSubmit = () => {
        if (!selectedState) {
            toastr.error("Please select a state");
            return;
        }
        if (!selectedDistrict) {
            toastr.error("Please select a district");
            return;
        }
        if (!selectedLocalbody) {
            toastr.error("Please select a localbody");
            return;
        }
        if (companyIdTobeUpdated) {
            axios
                .put(`${API_URL}company/`, masterObject, {
                    headers: {
                        "x-access-token": accessToken,
                    },
                })
                .then((res) => {
                    if (res.data.status == 200) {
                        toastr.success("Updated Successfully");
                        reset();
                    }
                    else {
                        toastr.error(res.data.message);
                    }
                }).catch((err) => {
                    toastr.error("Failed to update company");
                })
        }
        else {
            axios
                .post(`${API_URL}company/`, masterObject, {
                    headers: {
                        "x-access-token": accessToken,
                    },
                })
                .then((res) => {
                    if (res.data.status == 200) {
                        toastr.success("Submitted Successfully");
                        reset();

                    }
                    else {
                        toastr.error(res.data.message);
                    }
                }).catch((err) => {
                    toastr.error("Failed to add company");
                })
        }
    }
    const preUpdateCompany = (item) => {
        setcompanyIdTobeUpdated(item?.branch_id);
        setMasterObject(item);
        setselectedState({ label: item?.State?.name, value: item?.State?.id });
        getDistricts(item?.State?.id);
        setselectedDistrict({ label: item?.District?.dist_name, value: item?.District?.dist_id });
        getLocalbodies(item?.District?.dist_id);
        setSelectedLocalbody({ label: item?.Localbody?.localbody_name, value: item?.Localbody?.localbody_id });

    }
    const getDataTable = () => {
        axios
            .get(`${API_URL}company/`, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((res) => {
                var data = res.data.data;
                var list = [];
                data.map((item, index) => {
                    item.serial = index + 1;
                    item.name = item.addedUser?.fname;
                    item.dist_name = item.District?.dist_name;
                    item.localbody_name = item.Localbody?.localbody_name;
                    item.action = (
                        <>
                            <i
                                className="far fa-eye"
                                style={{
                                    fontSize: "1em",
                                    cursor: "pointer",
                                    marginLeft: "0.1em",
                                    marginRight: "0.5em",
                                }}
                                onClick={() => {
                                    handlePopup(item);
                                }}
                            ></i>
                            <i
                                className="far fa-edit"
                                style={{
                                    fontSize: "1em",
                                    cursor: "pointer",
                                    marginLeft: "0.1em",
                                    marginRight: "0.5em",
                                }}
                                onClick={() => {
                                    toTop();
                                    preUpdateCompany(item);
                                }}
                            ></i>
                            <i
                                className="far fa-trash-alt"
                                style={{ fontSize: "1em", cursor: "pointer" }}
                                onClick={() => {
                                    setcompanyIdTobeDeleted(item.branch_id);
                                    setConfirmDeleteAlert(true);
                                }}
                            ></i>
                        </>
                    )
                    list.push(item);
                })
                setDataTable(list);
            })
    }
    const reset = () => {
        formRef.current.reset();
        setMasterObject(null);
        setMasterObject({ branch_address: "" })
        setselectedDistrict(null);
        setSelectedLocalbody(null);
        setselectedState(null);
        setdistrictOptions([]);
        setLocalbodyOptions([]);
        getDataTable();
        setcompanyIdTobeUpdated(null);
        setcompanyIdTobeDeleted(null);
    }
    const handlePopup = (item) => {
        setModalData(item);
        if (item?.branch_logo) {
            item.image = (
                <img src={API_URL + "uploads/company/" + item?.branch_logo} className="avatar-lg img-thumbnail" />
            )
        }
        showModel(true);
    }
    const data = {
        columns: [
            {
                label: "#",
                field: "serial",
                sort: "asc",
                width: 150,
            },
            {
                label: "District",
                field: "dist_name",
                sort: "asc",
                width: 150,
            },
            {
                label: "Localbody",
                field: "localbody_name",
                sort: "asc",
                width: 150,
            },
            {
                label: "Name",
                field: "branch_name",
                sort: "asc",
                width: 150,
            },
            {
                label: "Short Code",
                field: "branch_shortcode",
                sort: "asc",
                width: 150,
            },
            {
                label: "Contact Person",
                field: "branch_person",
                sort: "asc",
                width: 150,
            },
            {
                label: "Mobile",
                field: "branch_mob",
                sort: "asc",
                width: 150,
            },
            {
                label: "Staff",
                field: "name",
                sort: "asc",
                width: 150,
            },
            {
                label: "Action",
                field: "action",
                sort: "asc",
                width: 150,
            },
        ],
        rows: dataTable
    }
    useEffect(() => {
        getStates();
        // getDistricts();
        getDataTable();
    }, [])
    return (
        <React.Fragment>
            {confirmDeleteAlert ? (
                <SweetAlert
                    title=""
                    showCancel
                    confirmButtonText="Delete"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        axios
                            .delete(`${API_URL}company/?id=` + companyIdTobeDeleted, {
                                headers: {
                                    "x-access-token": accessToken,
                                },
                            })
                            .then((res) => {
                                if (res.data.status === 200) {
                                    toastr.success("Deleted successfully");
                                    setcompanyIdTobeDeleted(null);
                                    reset();
                                } else {
                                    toastr.error(res.data.message, "Failed to delete");
                                    return;
                                }
                            })
                            .catch((err) => {
                                toastr.error(err.response.data.message);
                                return;
                            });
                        setConfirmDeleteAlert(false);
                    }}
                    onCancel={() => setConfirmDeleteAlert(false)}
                >
                    Are you sure you want to delete it?
                </SweetAlert>
            ) : null}
            <Modal
                size="xl" centered={true}
                isOpen={isModelOpen}
                toggle={() => {
                    showModel(!isModelOpen)
                }}
            >
                <div className="modal-header">
                    <h5>{modalData?.branch_name} - {modalData?.branch_unqid}</h5>
                    <button
                        type="button"
                        onClick={() => {
                            showModel(!isModelOpen)
                        }}
                        className="close p-4"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <table id="companyModalTable">
                        <tr>
                            <td><b>Branch</b></td><td>: {modalData?.branch_name}</td>
                            <td><b>Short Code</b></td><td>: {modalData?.branch_shortcode}</td>
                            <td><b>Contact Person</b></td><td>: {modalData?.branch_person}</td>
                            <td><b>Designation</b></td><td>: {modalData?.branch_design}</td>
                        </tr>
                        <tr>
                            <td><b>Email</b></td><td>: {modalData?.branch_email}</td>
                            <td><b>Mobile</b></td><td>: {modalData?.branch_mob}</td>
                            <td><b>Landline</b></td><td>: {modalData?.branch_land}</td>
                            <td><b>Website</b></td><td>: {modalData?.branch_web}</td>
                        </tr>
                        <tr>
                            <td><b>State</b></td><td>: {modalData?.State?.name}</td>
                            <td><b>District</b></td><td>: {modalData?.District?.dist_name}</td>
                            <td><b>Localbody</b></td><td>: {modalData?.Localbody?.localbody_name}</td>
                            <td><b>Pincode</b></td><td>: {modalData?.branch_pin}</td>
                        </tr>
                        <tr>
                            <td><b>Address</b></td><td colSpan={3}>: {modalData?.branch_address}</td>
                            <td><b>GSTIN No.</b></td><td>: {modalData?.branch_gstin}</td>
                            <td><b>PAN No.</b></td><td>: {modalData?.branch_pan}</td>

                        </tr>
                        <tr>

                            <td><b>CIN No.</b></td><td>: {modalData?.branch_cin}</td>
                            <td><b>TDS No.</b></td><td>: {modalData?.branch_tds}</td>
                            <td><b>Latitude</b></td><td>: {modalData?.branch_latitude}</td>
                            <td><b>Longitude</b></td><td>: {modalData?.branch_longitude}</td>

                        </tr>
                        <tr>
                            <td><b>Logo</b></td><td>: {modalData?.image}</td>
                            <td><b>Added By</b></td><td>: {modalData?.addedUser?.fname}</td>
                        </tr>

                    </table>
                </div>
            </Modal>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Home" breadcrumbItem="Manage Branch" />
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <AvForm
                                        ref={formRef}
                                        className="needs-validation"
                                        onValidSubmit={(e, v) => {
                                            handleValidSubmit(e, v);
                                        }}
                                    >
                                        <Row>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Branch Name</Label>
                                                    <AvField
                                                        name="branch_name"
                                                        value={masterObject?.branch_name}
                                                        placeholder="Branch Name"
                                                        type="text"
                                                        errorMessage="Branch Name is required"
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: true }
                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Short Code</Label>
                                                    <AvField
                                                        name="branch_shortcode"
                                                        value={masterObject?.branch_shortcode}
                                                        placeholder="Short Code"
                                                        type="text"
                                                        errorMessage="Short Code is required"
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: true }
                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Contact Person</Label>
                                                    <AvField
                                                        name="branch_person"
                                                        value={masterObject?.branch_person}
                                                        placeholder="Contact Person"
                                                        type="text"
                                                        errorMessage="Contact Person is required"
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: true }
                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Designation</Label>
                                                    <AvField
                                                        name="branch_design"
                                                        value={masterObject?.branch_design}
                                                        placeholder="Designation"
                                                        type="text"
                                                        errorMessage="Designation is required"
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: true }
                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Email</Label>
                                                    <AvField
                                                        name="branch_email"
                                                        value={masterObject?.branch_email}
                                                        placeholder="Email"
                                                        type="email"
                                                        errorMessage="Email is required"
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: true }
                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Website</Label>
                                                    <AvField
                                                        name="branch_web"
                                                        value={masterObject?.branch_web}
                                                        placeholder="Website"
                                                        type="url"
                                                        errorMessage="Website is required"
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Mobile</Label>
                                                    <AvField
                                                        name="branch_mob"
                                                        value={masterObject?.branch_mob}
                                                        placeholder="Mobile"
                                                        type="text"
                                                        errorMessage="Mobile is required"
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: true },
                                                            pattern: { value: '^[0-9]{10}$', errorMessage: "Invalid mobile number" }

                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Landline No.</Label>
                                                    <AvField
                                                        name="branch_land"
                                                        value={masterObject?.branch_land}
                                                        placeholder="Landline No."
                                                        type="text"
                                                        errorMessage="Landline No. is required"
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: true },
                                                            pattern: { value: '^[0-9]{11}$', errorMessage: "Invalid landline number" }

                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <Label>State</Label>
                                                <Select
                                                    name="customer_state"
                                                    value={selectedState}
                                                    onChange={(selected) => {
                                                        handleSelectChange(selected, "3");
                                                    }}
                                                    options={stateOptions}
                                                    classNamePrefix="select2-selection"
                                                    menuPosition="auto"
                                                    menuPlacement="auto"
                                                />

                                            </Col>

                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >District</Label>
                                                    <Select
                                                        name="branch_district"
                                                        value={selectedDistrict}
                                                        onChange={(selected) => {
                                                            handleSelectChange(selected, "1");
                                                        }}
                                                        options={districtOptions}
                                                        classNamePrefix="select2-selection"
                                                        menuPosition="auto"
                                                        menuPlacement="auto"
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Localbody</Label>
                                                    <Select
                                                        name="branch_localbody"
                                                        value={selectedLocalbody}
                                                        onChange={(selected) => {
                                                            handleSelectChange(selected, "2");
                                                        }}
                                                        options={localbodyOptions}
                                                        classNamePrefix="select2-selection"
                                                        menuPosition="auto"
                                                        menuPlacement="auto"
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Address</Label>
                                                    <AvField
                                                        name="branch_address"
                                                        value={masterObject?.branch_address}
                                                        placeholder="Address"
                                                        type="textarea"
                                                        errorMessage="Address is required"
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: true }
                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="2">
                                                <div className="mb-3">
                                                    <Label >Pincode</Label>
                                                    <AvField
                                                        name="branch_pin"
                                                        value={masterObject?.branch_pin}
                                                        placeholder="Pincode"
                                                        type="text"
                                                        errorMessage="Pincode is required"
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: true },
                                                            pattern: { value: '^[0-9]{6}$', errorMessage: "Invalid pincode" }

                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="2">
                                                <div className="mb-3">
                                                    <Label >GSTIN</Label>
                                                    <AvField
                                                        name="branch_gstin"
                                                        value={masterObject?.branch_gstin}
                                                        placeholder="22AAAAA0000A1Z5"
                                                        type="text"
                                                        errorMessage="GSTIN is required"
                                                        className="form-control"
                                                        validate={{
                                                            pattern: { value: '^[A-Z0-9]{15}$', errorMessage: "Invalid No." }
                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="2">
                                                <div className="mb-3">
                                                    <Label >PAN No.</Label>
                                                    <AvField
                                                        name="branch_pan"
                                                        value={masterObject?.branch_pan}
                                                        placeholder="ABCTY1234D"
                                                        type="text"
                                                        errorMessage="PAN No. is required"
                                                        className="form-control"
                                                        validate={{
                                                            pattern: { value: '^[A-Z0-9]{10}$', errorMessage: "Invalid No." }
                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="2">
                                                <div className="mb-3">
                                                    <Label >CIN No.</Label>
                                                    <AvField
                                                        name="branch_cin"
                                                        value={masterObject?.branch_cin}
                                                        placeholder="L17110MH1973PLC019786"
                                                        type="text"
                                                        errorMessage="CIN No. is required"
                                                        className="form-control"
                                                        validate={{
                                                            pattern: { value: '^[A-Z0-9]{21}$', errorMessage: "Invalid No." }
                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="2">
                                                <div className="mb-3">
                                                    <Label >TDS No.</Label>
                                                    <AvField
                                                        name="branch_tds"
                                                        value={masterObject?.branch_tds}
                                                        placeholder="AAACB1234E"
                                                        type="text"
                                                        errorMessage="TDS No. is required"
                                                        className="form-control"
                                                        validate={{
                                                            pattern: { value: '^[A-Z0-9]{10}$', errorMessage: "Invalid No." }
                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="2">
                                                <div className="mb-3">
                                                    <Label >Latitude</Label>
                                                    <AvField
                                                        name="branch_latitude"
                                                        value={masterObject?.branch_latitude}
                                                        placeholder="Latitude"
                                                        type="text"
                                                        errorMessage="Latitude is required"
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: true },
                                                            pattern: { value: '^[0-9.]+$', errorMessage: "Invalid Latitude" }

                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="2">
                                                <div className="mb-3">
                                                    <Label >Longitude</Label>
                                                    <AvField
                                                        name="branch_longitude"
                                                        value={masterObject?.branch_longitude}
                                                        placeholder="Longitude"
                                                        type="text"
                                                        errorMessage="Longitude is required"
                                                        className="form-control"
                                                        validate={{
                                                            required: { value: true },
                                                            pattern: { value: '^[0-9.]+$', errorMessage: "Invalid Longitude" }

                                                        }}
                                                        id="validationCustom02"
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="2" >
                                                <Label htmlFor="validationCustom03">
                                                    Logo
                                                </Label>
                                                {masterObject?.branch_logo ? (
                                                    <div div className="img-wraps">
                                                        <img
                                                            alt=""
                                                            width="150"
                                                            height="150"
                                                            src={`${API_URL}/uploads/company/${masterObject?.branch_logo}`}
                                                        />
                                                        <button
                                                            className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                                            onClick={deleteImage}
                                                            style={{ width: "150px" }}
                                                        >
                                                            Delete
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <AvField
                                                        name="photo"
                                                        type="file"
                                                        className="form-control"
                                                        id="validationCustom03"
                                                        onChange={uploadImage}
                                                        rows="1"
                                                    />
                                                )}
                                            </Col>
                                            <Col md="1" style={{ paddingTop: "26px" }}>
                                                {companyIdTobeUpdated ? (
                                                    <Button
                                                        color="primary"
                                                        type="submit"
                                                    >
                                                        Update
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        color="primary"
                                                        type="submit"
                                                    >
                                                        Submit
                                                    </Button>
                                                )}
                                            </Col>
                                            <Col md="1" style={{ paddingTop: "26px" }}>
                                                <Button
                                                    color="danger"
                                                    type="reset"
                                                    onClick={(e) => {
                                                        reset()

                                                    }}
                                                >
                                                    Reset
                                                </Button>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <MDBDataTable
                                        responsive
                                        id="localbodyTableId"
                                        bordered
                                        data={data}
                                        searching={true}
                                        info={true}
                                        disableRetreatAfterSorting={true}
                                        entries={20}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
};
export default Company;