import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import moment from "moment";
import $ from "jquery";
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { formatMoney } from "../../../helpers/generalFunctions";
import { CSVLink } from "react-csv";
import { getDate } from "../../../helpers/globalFunctions";
import "./purchaseReport.scss";

const TopCustomers = (props) => {
  const [reportForTable, setreportForTable] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [searchData, setSearchData] = useState({});
  const [dataToBeExported, setDataToBeExported] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  useEffect(() => {
    handleTableData();
    fetch_all_supplier();
  }, []);

  const fetch_all_supplier = () => {
    var url = `${API_URL}supplier/options`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        console.log("res: ", res);
        var supplierData =
          res.data.data &&
          res.data.data.map((element) => {
            return {
              label: element.supplier_name,
              value: element.supplier_id,
            };
          });
        setSupplierOptions(supplierData);
      });
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "purchase_created_at",
        sort: "asc",
        width: 400,
      },
      {
        label: "Time",
        field: "purchase_created_time",
        sort: "asc",
        width: 400,
      },
      {
        label: "Purchase ID",
        field: "purchase_unique_id",
        sort: "asc",
        width: 200,
      },
      {
        label: "Bill No",
        field: "purchase_billno",
        sort: "asc",
        width: 200,
      },
      {
        label: "Name",
        field: "supplier_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "State",
        field: "p_supplier_state",
        sort: "asc",
        width: 200,
      },
      {
        label: "GSTIN",
        field: "supplier_gst",
        sort: "asc",
        width: 200,
      },
      {
        label: "Amount",
        field: "p_taxable",
        sort: "asc",
        width: 200,
      },
      {
        label: "SGST",
        field: "sgst",
        sort: "asc",
        width: 200,
      },
      {
        label: "CGST",
        field: "cgst",
        sort: "asc",
        width: 200,
      },
      {
        label: "IGST",
        field: "igst",
        sort: "asc",
        width: 200,
      },
      {
        label: "Total",
        field: "purchase_grand_total",
        sort: "asc",
        width: 200,
      },
    ],
    rows: reportForTable,
  };

  function handleTableData(from_date = "", to_date = "", supplier_id = "") {
    var url = `${API_URL}report/purchase`;

    url =
      url +
      "?from_date=" +
      from_date +
      "&to_date=" +
      to_date +
      "&supplier_id=" +
      supplier_id;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let reportData = [];
        let dataToBeExported = [];
        result.map((item, index) => {
          let exportItem = {};
          item.id = index + 1;
          item.purchase_created_at = moment(item.purchase_created_at).format(
            "DD/MM/YYYY"
          );
          if (item.purchase_igst != 0) {
            item.sgst = "";
            item.cgst = "";
            item.igst = item.puchase_gst_amount.toFixed(2);
          } else {
            item.sgst = (item.puchase_gst_amount / 2).toFixed(2);
            item.cgst = (item.puchase_gst_amount / 2).toFixed(2);
            item.igst = "";
          }
          // item.purchase_grand_total = (
          //   <div className="text-right">{item.purchase_grand_total.toFixed(2)}</div>
          // );
          item.p_taxable = formatMoney(item.p_taxable);
          item.igst = formatMoney(item.igst);
          item.sgst = formatMoney(item.sgst);
          item.cgst = formatMoney(item.cgst);
          item.purchase_grand_total = formatMoney(item.purchase_grand_total);

          // set export data
          exportItem.Id = item.id;
          exportItem.Date = item.purchase_created_at;
          exportItem.Time = item.purchase_created_time;
          exportItem.Purchase_ID = item.purchase_unique_id;
          exportItem.Bill_No = item.purchase_billno;
          exportItem.Name = item.supplier_name;
          exportItem.State = item.p_supplier_state;
          exportItem.GSTIN = item.supplier_gst;
          exportItem.Amount = item.p_taxable;
          exportItem.SGST = item.sgst;
          exportItem.CGST = item.cgst;
          exportItem.IGST = item.igst;
          exportItem.Total = item.purchase_grand_total;

          reportData.push(item);
          dataToBeExported.push(exportItem);
        });
        setreportForTable(reportData);
        setDataToBeExported(dataToBeExported);
        console.log('dataToBeExported: ', dataToBeExported);
      });
  }

  const handleSelectedSupplier = (selected) => {
    setSelectedSupplier(selected);
    let date1 = searchData?.from_date ? searchData.from_date : "";
    let date2 = searchData?.to_date ? searchData.to_date : "";
    setSearchData({
      ...searchData,
      ["supplier_id"]: selected.value,
    });
    handleTableData(date1, date2, selected.value);
  };

  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();
    let supplier_id = searchData?.supplier_id ? searchData.supplier_id : "";
    setSearchData({
      ...searchData,
      [e.target.name]: e.target.value,
    });
    handleTableData(date1, date2, supplier_id);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Report - Purchase" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            id="from_date"
                            name="from_date"
                            onChange={handleDate}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To</Label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            id="to_date"
                            name="to_date"
                            onChange={handleDate}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Supplier</Label>
                        <Select
                          name="supplier_id"
                          value={selectedSupplier}
                          onChange={(selected) => {
                            handleSelectedSupplier(selected);
                          }}
                          options={supplierOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="1">
                      <div className="mt-4" style={{ paddingTop: "7px" }}>
                        <Button color="success" type="submit">
                          <CSVLink
                            data={dataToBeExported}
                            filename={"StarLee_purchase_report_" + getDate(new Date()) + ".xls"}
                            style={{ color: "white" }}
                          >
                            Export
                          </CSVLink>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <MDBDataTable
                    id="purchaseReport1"
                    responsive
                    striped
                    bordered
                    data={data}
                    info={true}
                    searching={true}
                    disableRetreatAfterSorting={true}
                    entries={25}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => { };

export default withRouter(connect(mapStateToProps, {})(TopCustomers));
