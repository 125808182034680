import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, CardBody, Card, Label, Button, Modal } from "reactstrap";
import { useHistory } from "react-router-dom";
import toastr from "toastr";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Select from 'react-select';
import "./staff.css";
const ManageStaff = (props) => {
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
    const formRef = useRef();
    const [master, setMaster] = useState(null);
    const [staffList, setStaffList] = useState([]);
    const [userIdToBeUpdated, setuserIdToBeUpdated] = useState(null);
    const [userIdToBeDeleted, setuserIdToBeDeleted] = useState(null);
    const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
    const [isModelOpenSuccess, showModelSuccess] = useState(false);
    const [successModalData, setsuccessModalData] = useState(null);
    const [selectedShop, setselectedShop] = useState(null);
    const [shopOptions, setshopOptions] = useState([]);
    const [viewModal, setViewModal] = useState(false);
    const [viewModalData, setViewModalData] = useState(null);

    const toTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };
    const data = {
        columns: [
            {
                label: "#",
                field: "id",
                sort: "asc",
                width: 150,
            },
            {
                label: "ID",
                field: "suser_username",
                sort: "asc",
                width: 150,
            },
            {
                label: "Name",
                field: "suser_name",
                sort: "asc",
                width: 150,
            },
            {
                label: "Mobile",
                field: "suser_mobile",
                sort: "asc",
                width: 150,
            },
            {
                label: "Email",
                field: "suser_email",
                sort: "asc",
                width: 150,
            },
            {
                label: "Designation",
                field: "suser_designation",
                sort: "asc",
                width: 150,
            },
            // {
            //     label: "Address",
            //     field: "suser_adderss",
            //     sort: "asc",
            //     width: 150,
            // },
            {
                label: "Shop",
                field: "shop_shortcode",
                sort: "asc",
                width: 150,
            },
            {
                label: "Staff",
                field: "staff_name",
                sort: "asc",
                width: 150,
            },
            {
                label: "Action",
                field: "action",
                sort: "asc",
                width: 150,
            },
        ],
        rows: staffList
    };
    const handleChangeInput = (e) => {
        setMaster({
            ...master,
            [e.target.name]: e.target.value
        });
    }
    const handleValidSubmit = () => {
        if (userIdToBeUpdated) {
            axios
                .put(API_URL + "shop-user/update-staff", master, {
                    headers: {
                        'x-access-token': accessToken
                    }
                }).then((res) => {
                    if (res.data.success == true) {
                        toastr.success("Updated Successfully");
                        reset();
                    }
                    else {
                        toastr.error(res.data.message);
                    }
                }).catch((err) => {
                    toastr.error(err.message);
                })
        }
        else {
            axios
                .post(API_URL + "shop-user/add-staff", master, {
                    headers: {
                        'x-access-token': accessToken
                    }
                }).then((res) => {
                    if (res.data.success == true) {
                        //toastr.success("Submitted Successfully");
                        showModelSuccess(true);
                        setsuccessModalData(res.data.username);
                        reset();
                    }
                    else {
                        toastr.error(res.data.message);
                    }
                }).catch((err) => {
                    toastr.error(err.message);
                })
        }

    }
    const reset = () => {
        formRef.current.reset();
        setMaster({ suser_adderss: "" });
        setuserIdToBeDeleted(null); setuserIdToBeUpdated(null);
        setselectedShop(null);
        getStaffData();
    }
    const getStaffData = () => {
        axios
            .get(API_URL + "shop-user/all-list", {
                headers: {
                    'x-access-token': accessToken
                }
            }).then((res) => {
                var list = [];
                res.data.data &&
                    res.data.data.map((item, index) => {
                        item.id = index + 1;
                        if (item.suser_added_from == 0) {
                            item.staff_name = item?.addedUser?.fname;
                        }
                        else {
                            item.staff_name = item?.addedStaff?.suser_name;
                        }
                        item.shop_shortcode = item?.Shop?.shop_shortcode;
                        item.action = (
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <i
                                    className="far fa-eye"
                                    style={{
                                        fontSize: "1em",
                                        cursor: "pointer",
                                        marginLeft: "0.5em",
                                        marginRight: "0.5em",
                                    }}
                                    onClick={() => {
                                        setViewModal(true);
                                        setViewModalData(item);

                                    }}
                                ></i>
                                <i
                                    className="far fa-edit"
                                    style={{
                                        fontSize: "1em",
                                        cursor: "pointer",
                                        marginLeft: "0.5em",
                                        marginRight: "0.5em",
                                    }}
                                    onClick={() => {
                                        preupdatestaffDetail(item);
                                    }}
                                ></i>
                                <i className="far fa-trash-alt"
                                    style={{ fontSize: '1em', cursor: 'pointer' }}
                                    onClick={() => {
                                        setuserIdToBeDeleted(item.suser_id);
                                        setConfirmDeleteAlert(true);
                                    }}>

                                </i>
                            </div>
                        )
                        list.push(item);
                    });
                console.log(list);
                setStaffList(list);
            })
    }
    const preupdatestaffDetail = (item) => {
        toTop();
        setuserIdToBeUpdated(item.suser_id);
        setselectedShop({ label: item?.Shop?.shop_name, value: item?.suser_shop_id });
        setMaster(item);
    }
    const handleSelectedShop = (selected) => {
        setselectedShop(selected);
        setMaster({
            ...master,
            suser_shop_id: selected.value
        });
    }
    const getShops = () => {
        axios.get(API_URL + "shop/options-new", {
            headers: {
                'x-access-token': accessToken
            }
        })
            .then((res) => {
                setshopOptions(res.data.data);
            })
    }
    useEffect(() => {
        getStaffData();
        getShops();
    }, [])
    return (
        <React.Fragment>
            {
                isModelOpenSuccess ? (
                    <SweetAlert success title="Submitted Successfully"
                        closeOnClickOutside={false}
                        onConfirm={() => showModelSuccess(false)}
                        onCancel={() => showModelSuccess(false)}
                        confirmBtnBsStyle="primary">
                        <b style={{
                            fontWeight: 'bold',
                            color: 'brown'
                        }}>Merchant Login Details</b><br></br>
                        Username : <b style={{
                            fontSize: '12px',
                            color: 'black',
                        }}>{successModalData}</b><br></br>
                        Default password is <b
                            style={{
                                fontSize: '12px',
                                color: 'black',
                            }}>123456</b><br></br>
                    </SweetAlert>
                ) : ""
            }
            {confirmDeleteAlert ? (
                <SweetAlert
                    title=""
                    showCancel
                    confirmButtonText="Delete"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        axios
                            .post(`${API_URL}shop-user` + "/delete-user/" + userIdToBeDeleted, {}, {
                                headers: {
                                    "x-access-token": accessToken,
                                },
                            })
                            .then((res) => {
                                if (res.data.status === 200) {
                                    toastr.success("Order deleted successfully");
                                    reset();
                                } else {
                                    toastr.error(res.data.message, "Failed to delete Staff");
                                    return;
                                }
                            });
                        setConfirmDeleteAlert(false);
                    }}
                    onCancel={() => setConfirmDeleteAlert(false)}
                >
                    Are you sure you want to delete it?
                </SweetAlert>
            ) : null}
            <Modal isOpen={viewModal} size="lg" centered={true} toggle={() => setViewModal(!viewModal)}>
                <div className="modal-header">
                    <button
                        type="button"
                        onClick={() => {
                            setViewModal(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div className="modal-body">
                    <Row>
                        <Col md="12">
                            <table className="StaffDetailsTable">
                                <tr>
                                    <th>ID</th><td>: {viewModalData?.suser_username}</td>
                                    <th>Name</th><td>: {viewModalData?.suser_name}</td>
                                </tr>
                                <tr>
                                    <th>Email</th><td>: {viewModalData?.suser_email}</td>
                                    <th>Mobile</th><td>: {viewModalData?.suser_mobile}</td>
                                </tr>
                                <tr>
                                    <th>Designation</th><td>: {viewModalData?.suser_designation}</td>
                                    <th>Shop</th><td>: {viewModalData?.Shop?.shop_shortcode}</td>
                                </tr>
                                <tr>
                                    <th>Address</th><td>: {viewModalData?.suser_adderss}</td>
                                    <th>Added By</th><td>: {viewModalData?.staff_name}</td>
                                </tr>
                            </table>
                        </Col>
                    </Row>
                </div>
            </Modal>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="StarLee" breadcrumbItem="Manage Staff" />
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <AvForm
                                        ref={formRef}
                                        className="needs-validation"
                                        onValidSubmit={(e, v) => {
                                            handleValidSubmit(e, v);
                                        }}
                                    >
                                        <Row>
                                            <Col md="3">
                                                <Label htmlFor="validationCustom05">Shop</Label>
                                                <Select
                                                    name="shop"
                                                    value={selectedShop}
                                                    onChange={(value) => {
                                                        handleSelectedShop(value);
                                                    }}
                                                    options={shopOptions}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </Col>
                                            <Col md="3">
                                                <Label htmlFor="validationCustom01">Name</Label>
                                                <AvField
                                                    name="suser_name"
                                                    placeholder="Name"
                                                    type="text"
                                                    errorMessage="Name is required"
                                                    className="form-control"
                                                    validate={{
                                                        required: { value: true }
                                                    }}
                                                    id="validationCustom01"
                                                    value={master?.suser_name}
                                                    onChange={handleChangeInput}
                                                />
                                            </Col>
                                            <Col md="3">
                                                <Label htmlFor="validationCustom01">Mobile</Label>
                                                <AvField
                                                    name="suser_mobile"
                                                    placeholder="Mobile"
                                                    type="text"
                                                    errorMessage="Mobile is required"
                                                    className="form-control"
                                                    validate={{
                                                        required: { value: true },
                                                        pattern: { value: "^[0-9]{10,12}", errorMessage: "Invalid Mobile No." }
                                                    }}
                                                    id="validationCustom01"
                                                    value={master?.suser_mobile}
                                                    onChange={handleChangeInput}
                                                />
                                            </Col>
                                            <Col md="3">
                                                <Label htmlFor="validationCustom01">Email</Label>
                                                <AvField
                                                    name="suser_email"
                                                    placeholder="Email"
                                                    type="email"
                                                    errorMessage="Enter a valid email address"
                                                    className="form-control"
                                                    validate={{
                                                        required: { value: true }
                                                    }}
                                                    id="validationCustom01"
                                                    value={master?.suser_email}
                                                    onChange={handleChangeInput}
                                                />
                                            </Col>
                                            <Col md="3" className="mt-2">
                                                <Label htmlFor="validationCustom01">Designation</Label>
                                                <AvField
                                                    name="suser_designation"
                                                    placeholder="Designation"
                                                    type="text"
                                                    errorMessage="Designation is required"
                                                    className="form-control"
                                                    validate={{
                                                        required: { value: true }
                                                    }}
                                                    id="validationCustom01"
                                                    value={master?.suser_designation}
                                                    onChange={handleChangeInput}
                                                />
                                            </Col>
                                            <Col md="6" className="mt-2">
                                                <Label htmlFor="validationCustom01">Address</Label>
                                                <AvField
                                                    name="suser_adderss"
                                                    placeholder="Address"
                                                    type="textarea"
                                                    errorMessage="Address is required"
                                                    className="form-control"
                                                    validate={{
                                                        required: { value: true }
                                                    }}
                                                    id="validationCustom01"
                                                    value={master?.suser_adderss}
                                                    onChange={handleChangeInput}
                                                />
                                            </Col>
                                            <Col md="1" style={{ paddingTop: "50px" }} >
                                                {
                                                    userIdToBeUpdated ? (
                                                        <Button
                                                            color="primary"
                                                            type="submit"
                                                        >
                                                            Update
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            color="primary"
                                                            type="submit"
                                                        >
                                                            Submit
                                                        </Button>
                                                    )
                                                }

                                            </Col>
                                            <Col md="1" style={{ paddingTop: "50px" }} >
                                                <Button
                                                    color="danger"
                                                    type="reset"
                                                    onClick={reset}
                                                >
                                                    Reset
                                                </Button>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>

                        </Col>
                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <MDBDataTable
                                        id="staffListTable"
                                        responsive
                                        bordered
                                        data={data}
                                        disableRetreatAfterSorting={true}
                                        entries={20}
                                    />
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    )
}
export default ManageStaff;

