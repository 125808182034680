import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { getCategoriesOptions } from "../../../store/actions";
import { formatMoney } from "../../../helpers/generalFunctions";
import { CSVLink } from "react-csv";
import { getDate } from "../../../helpers/globalFunctions";
import "./itemWise.scss";

const PurchaseItemWise = (props) => {
  const [purchaseItemWiseList, setPuchaseItemWiseList] = useState([]);
  const [categoryOption, setCategoryOption] = useState([]);
  const [master, setMaster] = useState({});
  const [subCategory, setSubCategory] = useState([]);
  const [brandGroup, setBrandGroup] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [dataToBeExported, setDataToBeExported] = useState([]);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  const { categoryOptions } = useSelector((state) => state.categories);
  const dispatch = useDispatch();
  useEffect(() => {
    handleTableData();
    dispatch(getCategoriesOptions());
    fetch_all_brands();
    fetch_all_supplier();
  }, []);

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Code",
        field: "product_sku_autogen",
        sort: "asc",
        width: 400,
      },
      {
        label: "Name",
        field: "product_name_english",
        sort: "asc",
        width: 400,
      },
      {
        label: "Qty",
        field: "quantity",
        sort: "asc",
        width: 400,
      },
      {
        label: "MRP",
        field: "product_mrp",
        sort: "asc",
        width: 400,
      },
      {
        label: "Cost",
        field: "product_cost",
        sort: "asc",
        width: 400,
      },
      {
        label: "Tax (%)",
        field: "tax_slab_percentage",
        sort: "asc",
        width: 400,
      },
      {
        label: "Tax (₹)",
        field: "tax_value",
        sort: "asc",
        width: 400,
      },
      {
        label: "Total",
        field: "amount",
        sort: "asc",
        width: 400,
      },
    ],
    rows: purchaseItemWiseList,
  };
  useEffect(() => {
    var data =
      categoryOptions &&
      categoryOptions.map((item) => {
        return {
          label: item.category_name_english,
          value: item.category_id,
        };
      });

    setCategoryOption([
      {
        options: data,
      },
    ]);
  }, [categoryOptions]);
  function fetch_all_brands() {
    axios
      .get(`${API_URL}brands`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var brand_data =
          res.data.data &&
          res.data.data.map((item) => {
            return {
              label: item.brand_name_english,
              value: item.brand_id,
            };
          });

        setBrandGroup([
          {
            options: brand_data,
          },
        ]);
      });
  }
  const fetch_all_supplier = () => {
    var url = `${API_URL}supplier/options`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        console.log("res: ", res);
        var supplierData =
          res.data.data &&
          res.data.data.map((element) => {
            return {
              label: element.supplier_name,
              value: element.supplier_id,
            };
          });
        setSupplierOptions(supplierData);
      });
  };
  function handleTableData(
    from_date = "",
    to_date = "",
    category = "",
    sub_category = "",
    brand = "",
    supplier = ""
  ) {
    var url = `${API_URL}report/purchase_items`;

    url =
      url +
      "?from_date=" +
      from_date +
      "&to_date=" +
      to_date +
      "&category_id=" +
      category +
      "&subcategory_id=" +
      sub_category +
      "&brand_id=" +
      brand +
      "&supplier_id=" +
      supplier;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let dataToBeExported = [];
        result.map((item, index) => {
          let exportItem = {};
          item.id = index + 1;
          item.tax_value = Number(
            parseFloat((item.product_cost * item.tax_slab_percentage) / 100)
          ).toFixed(2);
          item.product_mrp = formatMoney(item.product_mrp);
          item.product_cost = formatMoney(item.product_cost);
          item.amount = formatMoney(item.amount);

          // setting export data
          exportItem.Id = item.id;
          exportItem.Code = item.product_sku_autogen;
          exportItem.Name = item.product_name_english;
          exportItem.Qty = item.quantity;
          exportItem.MRP = item.product_mrp;
          exportItem.Cost = item.product_cost;
          exportItem.Tax_Percentage = item.tax_slab_percentage;
          exportItem.Tax_Amount = item.tax_value;
          exportItem.Total = item.amount;

          dataToBeExported.push(exportItem);
        });

        setPuchaseItemWiseList(result);
        setDataToBeExported(dataToBeExported);
      });
  }

  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
    let category = master?.category_id ? master.category_id : "";
    let sub_category = master?.sub_category_id ? master.sub_category_id : "";
    let brand = master?.brand_id ? master.brand_id : "";
    let supplier = master?.supplier_id ? master.supplier_id : "";

    handleTableData(date1, date2, category, sub_category, brand, supplier);
  };

  const handleSelectChange = (selected, name) => {
    let category = master?.category_id ? master.category_id : "";
    let sub_category = master?.sub_category_id ? master.sub_category_id : "";
    let brand = master?.brand_id ? master.brand_id : "";
    let supplier = master?.supplier_id ? master.supplier_id : "";
    let date1 = master?.from_date ? master.from_date : "";
    let date2 = master?.to_date ? master.to_date : "";

    switch (name) {
      case "category":
        setSelectedCategory(selected);
        setSelectedSubCategory(null);
        setMaster({
          ...master,
          ["category_id"]: selected.value,
          ["sub_category_id"]: "",
        });
        axios
          .get(`${API_URL}subcategory?category_id=` + selected.value, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            var subCategoryData =
              res.data &&
              res.data.map((item) => {
                return {
                  label: item.subcategory_name_english,
                  value: item.subcategory_id,
                };
              });
            setSubCategory([
              {
                options: subCategoryData,
              },
            ]);
          });
        handleTableData(date1, date2, selected.value, "", brand, supplier);
        break;
      case "subcategory":
        setSelectedSubCategory(selected);
        setMaster({
          ...master,
          ["sub_category_id"]: selected.value,
        });
        handleTableData(
          date1,
          date2,
          category,
          selected.value,
          brand,
          supplier
        );
        break;
      case "brand":
        setSelectedBrand(selected);
        setMaster({
          ...master,
          ["brand_id"]: selected.value,
        });
        handleTableData(
          date1,
          date2,
          category,
          sub_category,
          selected.value,
          supplier
        );
        break;
      case "supplier":
        setSelectedSupplier(selected);
        setMaster({
          ...master,
          ["supplier_id"]: selected.value,
        });
        handleTableData(
          date1,
          date2,
          category,
          sub_category,
          brand,
          selected.value
        );
        break;
      default:
        break;
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Home"
            breadcrumbItem="Report - Purchase Item Wise"
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            id="from_date"
                            name="from_date"
                            onChange={handleDate}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To</Label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            id="to_date"
                            name="to_date"
                            onChange={handleDate}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Category</Label>
                        <Select
                          name="category_id"
                          value={selectedCategory}
                          onChange={(selected) => {
                            handleSelectChange(selected, "category");
                          }}
                          options={categoryOption}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Sub-Category</Label>
                        <Select
                          name="sub_category_id"
                          value={selectedSubCategory}
                          onChange={(selected) => {
                            handleSelectChange(selected, "subcategory");
                          }}
                          options={subCategory}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Brand</Label>
                        <Select
                          name="brand_id"
                          value={selectedBrand}
                          onChange={(selected) => {
                            handleSelectChange(selected, "brand");
                          }}
                          options={brandGroup}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Supplier</Label>
                        <Select
                          name="supplier_id"
                          value={selectedSupplier}
                          onChange={(selected) => {
                            handleSelectChange(selected, "supplier");
                          }}
                          options={supplierOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="1">
                      <div className="mt-4" style={{ paddingTop: "7px" }}>
                        <Button color="success" type="submit">
                          <CSVLink
                            data={dataToBeExported}
                            filename={"StarLee_purchase_item_report_" + getDate(new Date()) + ".xls"}
                            style={{ color: "white" }}
                          >
                            Export
                          </CSVLink>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <MDBDataTable
                    id="purchaseItemWise"
                    responsive
                    striped
                    bordered
                    data={data}
                    info={true}
                    searching={true}
                    disableRetreatAfterSorting={true}
                    entries={25}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => { };

export default withRouter(connect(mapStateToProps, {})(PurchaseItemWise));
