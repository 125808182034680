import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import {
  getUsers,
  addUser,
  deleteUser,
  apiError,
  getPrivilagesOptions,
  getCompaniesOptions,
  getBranchesOptions,
  updateUser,
  //getPrivilagesOptions,
} from "../../../store/actions";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import $ from 'jquery';
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import { formatMoney } from "../../../helpers/generalFunctions";
import { CSVLink } from "react-csv";
import { getDate } from "../../../helpers/globalFunctions";
import "./topSellingCategory.scss";

const TopSellingCategory = (props) => {
  const [usersForTable, setUsersForTable] = useState([]);
  const [accountType, setAccountType] = useState("");
  const [dataToBeExported, setDataToBeExported] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  useEffect(() => {
    handleTableData();
  }, []);

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Category",
        field: "category_name_english",
        sort: "asc",
        width: 150,
      },
      {
        label: "Total Quantity",
        field: "total_quantity",
        sort: "asc",
        width: 400,
      },
      {
        label: "Amount",
        field: "total_amount1",
        sort: "asc",
        width: 200,
      },
      {
        label: "Cost",
        field: "total_cost1",
        sort: "asc",
        width: 200,
      },
      {
        label: "Total GST",
        field: "total_gst1",
        sort: "asc",
        width: 200,
      },
      {
        label: "Total Amount",
        field: "total_net_amount1",
        sort: "asc",
        width: 200,
      },
      {
        label: "Profit",
        field: "total_profit1",
        sort: "asc",
        width: 200,
      },
    ],
    rows: usersForTable,
  };

  function handleTableData(from_date = null, to_date = null) {
    var url = `${API_URL}report/top_category`;
    if (from_date != null && to_date != null) {
      url = url + "?from_date=" + from_date + "&to_date=" + to_date;
    }
    else if (from_date != null) {
      url = url + "?from_date=" + from_date;
    }
    else if (to_date != null) {
      url = url + "?to_date=" + to_date;
    }
    axios.get(url,
      {
        headers: {
          'x-access-token': accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let usersData = [];
        let dataToBeExported = [];
        result.map((item, index) => {
          let exportItem = {};
          item.id = index + 1;

          item.total_amount1 = formatMoney(item.total_amount);
          item.total_cost1 = formatMoney(item.total_cost);
          item.total_gst1 = formatMoney(item.total_gst);
          item.total_net_amount1 = formatMoney(item.total_net_amount);
          item.total_profit1 = formatMoney(item.total_profit);
          // item.total_amount = parseFloat(item.total_amount)+parseFloat(item.gst);
          // item.total_amount = item.total_amount.toFixed(2);
          // item.total_profit = parseFloat(item.amount)-parseFloat(item.cost);
          // item.total_profit = item.total_profit.toFixed(2);

          // setting export data
          exportItem.Id = item.id;
          exportItem.Category = item.category_name_english;
          exportItem.Total_Quantity = item.total_quantity;
          exportItem.Amount = item.total_amount1;
          exportItem.Cost = item.total_cost1;
          exportItem.Total_GST = item.total_gst1;
          exportItem.Total_Amount = item.total_net_amount1;
          exportItem.Profit = item.total_profit1;

          usersData.push(item);
          dataToBeExported.push(exportItem);
        });
        setUsersForTable(usersData);
        setDataToBeExported(dataToBeExported);

      });
  }

  let handleDate = () => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();
    handleTableData(date1, date2);
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Home"
            breadcrumbItem="Report -  Sales Category Wise"
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            id="from_date"
                            name="from_date"
                            onChange={handleDate}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To</Label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            id="to_date"
                            name="to_date"
                            onChange={handleDate}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="1">
                      <div className="mt-4" style={{ paddingTop: "5px" }}>
                        <Button color="success" type="submit">
                          <CSVLink
                            data={dataToBeExported}
                            filename={"StarLee_sales_category_report_" + getDate(new Date()) + ".xls"}
                            style={{ color: "white" }}
                          >
                            Export
                          </CSVLink>
                        </Button>
                      </div>
                    </Col>
                    {/* <Col md="1">
                      <div className="mt-4">
                        <Button color="success" type="submit">
                          <i className="fas fa-print"></i>
                          {"  "}
                          Print
                        </Button>
                      </div>
                    </Col> */}
                  </Row>
                  <MDBDataTable
                    id="topSellingCategory"
                    responsive
                    striped
                    bordered
                    data={data}
                    info={true}
                    searching={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => { };

export default withRouter(
  connect(mapStateToProps, { apiError })(TopSellingCategory)
);
