import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Card, CardBody, Container, Label, Button } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import { MDBDataTable } from "mdbreact";
import "./recharge.css";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from "react-select";
import toastr from "toastr";
import Pagination from "@mui/material/Pagination";
import { getDate } from "../../helpers/globalFunctions";
const Recharges = (props) => {
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  const [searchData, setsearchData] = useState(null);
  const [dataObject, setdataObject] = useState(null);
  const [master, setMaster] = useState({
    total_count: 0,
    tot_customers: 0,
    total_amount: 0,
    today_amount: 0,
    total_buyback: 0,
    total_jio: 0,
    tot_buyback_cust: 0,
    tot_jio_cust: 0,
  });
  const [customerOptions, setcustomerOptions] = useState([]);
  const [selectedCustomer, setselectedCustomer] = useState(null);
  const [dataTable, setdataTable] = useState([]);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [amountOptions, setAmountOptions] = useState([
    { label: "Rs.200", value: 200 },
    { label: "Rs.400", value: 400 },
    { label: "Rs.600", value: 600 },
    { label: "Rs.800", value: 800 },
    { label: "Rs.1000", value: 1000 },
    { label: "Rs.2000", value: 2000 },
    { label: "Rs.3000", value: 3000 },
    { label: "Rs.5000", value: 5000 },
    { label: "Rs.10000", value: 10000 },
  ]);
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "datetime",
        sort: "asc",
        width: 150,
      },
      {
        label: "Customer",
        field: "customer",
        sort: "asc",
        width: 150,
      },
      {
        label: "Recharge",
        field: "amount",
        sort: "asc",
        width: 150,
      },
      {
        label: "Buyback Offer",
        field: "point1",
        sort: "asc",
        width: 150,
      },
      {
        label: "Jio Offer",
        field: "point2",
        sort: "asc",
        width: 150,
      },
      {
        label: "By",
        field: "added_by",
        sort: "asc",
        width: 150,
      },
    ],
    rows: dataTable,
  };
  const handleData = () => {
    axios
      .get(API_URL + "customer/recharges-dashboard", {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        setMaster(res.data.data);
      });
  };
  const handleTableData = (customer_id = "", from_date = "", to_date = "", start = 0, end = PerPageCount) => {
    axios
      .get(API_URL + "customer/recharges?start=" + start + "&end=" + end + "&customer_id=" + customer_id + "&from_date=" + from_date + "&to_date=" + to_date, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        setTotalPages(res.data.limit)
        var id = start;
        res.data.data &&
          res.data.data.map((item, index) => {
            id++;
            item.id = id;
            item.customer = (
              <a href={`/customer/${item.customers_unique_id}`} target="_blank">
                {item.customer_name}
              </a>
            );
            if (item.locked == 0 && item.point1 == "Earned") {
              item.point1 = (
                <>
                  <i
                    className="mdi mdi-lock-alert"
                    style={{ color: "#dc3545" }}
                  ></i>{" "}
                  Locked
                </>
              );
            } else if (item.locked == 1 && item.point1 == "Earned") {
              item.point1 = (
                <>
                  <i
                    className="mdi mdi-lock-open-check"
                    style={{ color: "#4cae89" }}
                  ></i>{" "}
                  {item.point1}
                </>
              );
            }
            if (item.locked2 == 0 && item.point2 == "Earned") {
              item.point2 = (
                <>
                  <i
                    className="mdi mdi-lock-alert"
                    style={{ color: "#dc3545" }}
                  ></i>{" "}
                  Locked
                </>
              );
            } else if (item.locked2 == 1 && item.point2 == "Earned") {
              item.point2 = (
                <>
                  <i
                    className="mdi mdi-lock-open-check"
                    style={{ color: "#4cae89" }}
                  ></i>{" "}
                  {item.point2}
                </>
              );
            }
          });
        setTotalPages(res.data.limit)
        setdataTable(res.data.data);
      });
  };
  const getCustomers = () => {
    axios
      .get(API_URL + "customer/optionsNew", {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        setcustomerOptions(res.data.data);
      });
  };
  const handleValidSubmit = () => {
    if (!dataObject?.customer_id) {
      toastr.warning("Please choose a customer");
      return;
    }
    if (!dataObject?.amount) {
      toastr.warning("Please choose a recharge amount");
      return;
    }
    setLoading(true);
    axios
      .post(API_URL + "customer_wallet_statement", dataObject, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.success) {
          toastr.success("Recharged successfully");
          setLoading(false);
          handleData();
          handleTableData(selectedCustomer?.value, searchData?.from_date, searchData?.to_date, indexOfFirstTransfer, indexOfLastTransfer);
          setdataObject(null);
          setSelectedAmount(null);
          setselectedCustomer(null);
          formRef.current.reset();
        } else {
          toastr.error("Failed to recharge");
          setLoading(false);
        }
      })
      .catch((err) => {
        toastr.error("Failed to recharge");
        setLoading(false);
      });
  };
  /** pagination */
  const [searchValue, setSearchValue] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [PerPageCount, setPerPageCount] = useState(20);
  const [indexOfLastTransfer, setindexOfLastTransfer] = useState(
    currentPage * PerPageCount
  );

  const [indexOfFirstTransfer, setindexOfFirstTransfer] = useState(
    indexOfLastTransfer - PerPageCount
  );
  const paginate = (event, value) => {
    setCurrentPage(value);
    const indexOfLastTransfer = value * PerPageCount;
    const indexOfFirstTransfer = indexOfLastTransfer - PerPageCount;
    setindexOfFirstTransfer(indexOfFirstTransfer);
    setindexOfLastTransfer(indexOfLastTransfer);

    handleTableData(selectedCustomer?.value, searchData?.from_date, searchData?.to_date, indexOfFirstTransfer, indexOfLastTransfer);
  };
  const resetPaginate = () => {
    setCurrentPage(1);
    const indexOfLastTransfer = 20;
    const indexOfFirstTransfer = 0;
    setindexOfFirstTransfer(indexOfFirstTransfer);
    setindexOfLastTransfer(indexOfLastTransfer);
  }
  const handleSearch = (value) => {
    setSearchValue(value)
    resetPaginate();
    handleTableData(value);
  }
  useEffect(() => {
    if (dataTable.length == 0 && indexOfFirstTransfer != 0) {
      resetPaginate();
      handleTableData(selectedCustomer?.value, searchData?.from_date, searchData?.to_date,);
    }

  }, [dataTable])
  /** end pagination */
  useEffect(() => {
    handleData();
    handleTableData();
    getCustomers();
  }, []);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          <Breadcrumbs title="Home" breadcrumbItem="Recharges" />
          <Row>
            <Col md={6} xl={3}>
              <Card>
                <CardBody>
                  <div className="float-end mt-2">
                    <i className="bx bx-money dashboard-icon1"></i>
                  </div>
                  <div>
                    <h4 className="mb-1 mt-1">
                      <span>
                        <i className="fas fa-rupee-sign"></i>{" "}
                        {master?.today_amount}
                      </span>
                    </h4>
                    <p className="text-muted mb-0">Today's Recharge </p>
                  </div>
                  <p className="text-muted mt-3 mb-0">
                    <span style={{ color: "#e8418f" }} className={"me-1"}>
                      <i className="fas fa-rupee-sign"></i>{" "}
                      {master?.total_amount}
                    </span>{" "}
                    Total Recharge
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} xl={3}>
              <Card>
                <CardBody>
                  <div className="float-end mt-2">
                    <i className="bx bx-collection dashboard-icon1"></i>
                  </div>
                  <div>
                    <h4 className="mb-1 mt-1">
                      <span>{master?.total_count}</span>
                    </h4>
                    <p className="text-muted mb-0">Total No.of Recharges </p>
                  </div>
                  <p className="text-muted mt-3 mb-0">
                    <span style={{ color: "#e8418f" }} className={"me-1"}>
                      <i className="fas fa-user"></i> {master?.tot_customers}
                    </span>{" "}
                    Recharged Customers
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} xl={3}>
              <Card>
                <CardBody>
                  <div className="float-end mt-2">
                    <i className="bx bx-bolt-circle dashboard-icon1"></i>
                  </div>
                  <div>
                    <h4 className="mb-1 mt-1">
                      <span>{master?.total_buyback}</span>
                    </h4>
                    <p className="text-muted mb-0">Buyback Offer Earnings</p>
                  </div>
                  <p className="text-muted mt-3 mb-0">
                    <span style={{ color: "#e8418f" }} className={"me-1"}>
                      <i className="fas fa-user"></i> {master?.tot_buyback_cust}
                    </span>{" "}
                    Customers
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} xl={3}>
              <Card>
                <CardBody>
                  <div className="float-end mt-2">
                    <i className="bx bxs-offer dashboard-icon1"></i>
                  </div>
                  <div>
                    <h4 className="mb-1 mt-1">
                      <span>{master?.total_jio}</span>
                    </h4>
                    <p className="text-muted mb-0">Jio Offer Earnings</p>
                  </div>
                  <p className="text-muted mt-3 mb-0">
                    <span style={{ color: "#e8418f" }} className={"me-1"}>
                      <i className="fas fa-user"></i> {master?.tot_jio_cust}
                    </span>{" "}
                    Customers
                  </p>
                </CardBody>
              </Card>
            </Col>
            {/* <Col md="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                    encType="multipart/form-data"
                  >
                    <Row>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label>Customer</Label>
                          <Select
                            name="customer_id"
                            id="customer_id"
                            value={selectedCustomer}
                            onChange={(selected) => {
                              setselectedCustomer(selected);
                              setdataObject({
                                ...dataObject,
                                customer_id: selected.value,
                              });
                            }}
                            options={customerOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label>Amount</Label>
                          <Select
                            name="amount"
                            id="amount"
                            value={selectedAmount}
                            onChange={(selected) => {
                              setSelectedAmount(selected);
                              setdataObject({
                                ...dataObject,
                                amount: selected.value,
                              });
                            }}
                            options={amountOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="1" style={{ paddingTop: "26px" }}>
                        <Button
                          color="primary"
                          type="submit"
                          disabled={isLoading}
                        >
                          Recharge
                        </Button>
                      </Col>
                      <Col md="1" style={{ paddingTop: "26px" }}>
                        <Button
                          color="danger"
                          type="reset"
                          onClick={() => {
                            setdataObject(null);
                            setSelectedAmount(null);
                            setselectedCustomer(null);
                            formRef.current.reset();
                          }}
                        >
                          Reset
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col> */}
            <Col md="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md={3}>
                      <div className="mb-3">
                        <Label>Customer</Label>
                        <Select
                          name="customer_id"
                          id="customer_id"
                          value={selectedCustomer}
                          onChange={(selected) => {
                            setselectedCustomer(selected);
                            resetPaginate()
                            handleTableData(selected.value, searchData?.from_date, searchData?.to_date)
                          }}
                          options={customerOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label >From</Label>
                        {/* <div className="col-md-10"> */}
                        <input
                          className="form-control"
                          type="date"
                          id="from_date"
                          name="from_date"
                          value={searchData?.from_date}

                          onChange={(selected) => {
                            resetPaginate()
                            setsearchData({ ...searchData, "from_date": selected.target.value })
                            handleTableData(selectedCustomer?.value, selected.target.value, searchData?.to_date)
                          }}
                          max={getDate(new Date())}
                        />
                        {/* </div> */}
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label >To</Label>
                        {/* <div className="col-md-10"> */}
                        <input
                          className="form-control"
                          type="date"
                          id="to_date"
                          name="to_date"
                          value={searchData?.to_date}
                          onChange={(selected) => {
                            resetPaginate()
                            setsearchData({ ...searchData, "to_date": selected.target.value })
                            handleTableData(selectedCustomer?.value, searchData?.from_date, selected.target.value)
                          }}
                          min={searchData?.from_date}
                        />
                        {/* </div> */}
                      </div>
                    </Col>
                    <Col md="1" style={{ paddingTop: "26px" }}>
                      <Button
                        color="danger"
                        type="reset"
                        onClick={() => {
                          resetPaginate();
                          setselectedCustomer(null);
                          setsearchData({ from_date: "", to_date: "" });
                          handleTableData()
                        }}
                      >
                        Reset
                      </Button>
                    </Col>
                  </Row>
                  <MDBDataTable
                    responsive
                    id="rechargeTableId"
                    bordered
                    data={data}
                    searching={false}
                    info={false}
                    disableRetreatAfterSorting={true}
                    paging={false}
                    onSearch={(value) => { handleSearch(value) }}
                    // onSearch={debounce(handleSearch, 500)}
                    entries={PerPageCount}
                  />
                  {totalPages > 20 && (
                    <div className="pagination-div">
                      {/* Display the page details */}
                      <div className="page-details">
                        Showing {indexOfFirstTransfer + 1} to {indexOfFirstTransfer + dataTable.length} of {totalPages} entries
                      </div>
                      <Pagination
                        color="standard"
                        shape="rounded"
                        defaultPage={1}
                        count={Math.ceil(totalPages / PerPageCount)}
                        page={currentPage}
                        onChange={paginate}
                        info={true}
                        size="large"
                      />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default Recharges;
