import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Label,
    Container,
    Table,
} from "reactstrap";
import Select from 'react-select';
import toastr from "toastr";
import SweetAlert from "react-bootstrap-sweetalert";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import { MDBDataTable } from "mdbreact";
import "./order.css";
import moment from "moment";
import { getDate, getFirstday } from "../../helpers/globalFunctions";
import { CSVLink } from "react-csv";

const Orders = (props) => {
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
    const [filterObject, setfilterObject] = useState({ from_date: getFirstday(new Date()), to_date: getDate(new Date()) });
    const [viewOrderForTable, setViewOrderForTable] = useState([]);
    const [orderIdToBeDeleted, setorderIdToBeDeleted] = useState(null);
    const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
    const [selectedShop, setselectedShop] = useState(null);
    const [dataToBeExported, setDataToBeExported] = useState([])
    const [shopOptions, setshopOptions] = useState([]);
    const data = {
        columns: [
            {
                label: "#",
                field: "id",
                sort: "asc",
                width: 150,
            },
            {
                label: "Date",
                field: "date",
                sort: "asc",
                width: 400,
            },
            {
                label: "Time",
                field: "time",
                sort: "asc",
                width: 200,
            },
            {
                label: "Order#",
                field: "orders_unique_id",
                sort: "asc",
                width: 100,
            },
            // {
            //     label: "Source",
            //     field: "order_from",
            //     sort: "asc",
            //     width: 100,
            // },
            {
                label: "Name",
                field: "order_customer_name",
                sort: "asc",
                width: 100,
            },
            {
                label: "Moblie",
                field: "order_contact_number",
                sort: "asc",
                width: 100,
            },
            {
                label: "Amount",
                field: "amount_column",
                sort: "asc",
                width: 100,
            },
            {
                label: "Shop",
                field: "shop_name",
                sort: "asc",
                width: 100,
            },
            {
                label: "Invoice No",
                field: "order_bill_no",
                sort: "asc",
                width: 100,
            },
            {
                label: "Invoice Date",
                field: "order_bill_date",
                sort: "asc",
                width: 100,
            },
            // {
            //     label: "Action",
            //     field: "action",
            //     sort: "disabled",
            //     width: 100,
            // },
        ],
        rows: viewOrderForTable,
    };

    const handleFilter = (e) => {
        var name = e.target.name;
        var value = e.target.value;
        setfilterObject({
            ...filterObject,
            [name]: value
        })
        switch (name) {
            case "from_date":
                getOrders(value, filterObject?.to_date, selectedShop?.value);
                break;
            case "to_date":
                getOrders(filterObject?.from_date, value, selectedShop?.value);
                break;
        }

    }
    const handleSelectedShop = (selected) => {
        setselectedShop(selected);
        getOrders(filterObject?.from_date, filterObject?.to_date, selected?.value);
    }
    const getShops = () => {
        axios.get(API_URL + "shop/options-new", {
            headers: {
                'x-access-token': accessToken
            }
        })
            .then((res) => {
                setshopOptions(res.data.data);
            })
    }
    const getOrders = (from_date = "", to_date = "", shop = "") => {
        if (from_date == "")
            from_date = getFirstday(new Date());
        if (to_date == "")
            to_date = getDate(new Date());
        axios.get(`${API_URL}vieworders/all-offline-orders/?orderDate1=` + from_date + "&orderDate2=" + to_date + "&shop=" + shop, {
            headers: {
                "x-access-token": accessToken,
            },
        })
            .then((res) => {
                var data = res.data.data;
                let ordersData = [];
                let dataToBeExported = [];
                data.map((item, index) => {

                    let exportItem = {};
                    item.id = index + 1;
                    item.date = moment(item.order_date).local().format("DD-MM-YYYY");
                    if (item?.order_bill_date)
                        item.order_bill_date = moment(item.order_bill_date).local().format("DD-MM-YYYY");
                    item.time = moment(item.order_time, "h:mm:ss.*").format("hh:mm a");
                    item.order_customer_name = (
                        <a href={`/customer/${item.customers_unique_id}`} target="_blank">{item.customer_name}</a>
                    )
                    if (item.order_payment_status === 1)
                        item.payment_status = (
                            <div
                                class="btn-group btn-group-sm"
                                role="group"
                                aria-label="Basic example"
                            >
                                <button
                                    class="btn btn-danger"
                                    style={{ padding: "1px", minWidth: "50px" }}
                                >
                                    Not Paid
                                </button>
                            </div>
                        );
                    else if (item.order_payment_status === 2)
                        item.payment_status = (
                            <div
                                class="btn-group btn-group-sm"
                                role="group"
                                aria-label="Basic example"
                            >
                                <button
                                    class="btn btn-success"
                                    style={{ padding: "1px", minWidth: "50px" }}
                                >
                                    Paid
                                </button>
                            </div>
                        );
                    else if (item.order_payment_status === 3)
                        item.payment_status = (
                            <div
                                class="btn-group btn-group-sm"
                                role="group"
                                aria-label="Basic example"
                            >
                                <button
                                    class="btn btn-warning"
                                    style={{ padding: "1px", minWidth: "50px" }}
                                >
                                    Failed
                                </button>
                            </div>
                        );
                    item.amount_column = (
                        <div className="text-right">{item.order_grand_amount.toFixed(2)}</div>
                    );
                    item.action = (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <i className="far fa-trash-alt"
                                style={{ fontSize: '1em', cursor: 'pointer' }}
                                onClick={() => {
                                    setorderIdToBeDeleted(item.order_id);
                                    setConfirmDeleteAlert(true);
                                }}>

                            </i>
                        </div>
                    )
                    // export
                    exportItem.Id = item.id;
                    exportItem.Date = item.date;
                    exportItem.Time = item.time;
                    exportItem.Order = item.orders_unique_id;
                    exportItem.Name = item.customer_name;
                    exportItem.Moblie = item.order_contact_number;
                    exportItem.Amount = item.order_grand_amount.toFixed(2);
                    exportItem.Shop = item.shop_name;
                    exportItem.Invoice_No = item.order_bill_no;
                    exportItem.Invoice_Date = item.order_bill_date;

                    ordersData.push(item);
                    dataToBeExported.push(exportItem);
                });
                setViewOrderForTable(ordersData);

                setDataToBeExported(dataToBeExported)
            });
    }
    const reset = () => {
        setfilterObject({ from_date: getFirstday(new Date()), to_date: getDate(new Date()) });
        setselectedShop(null)
        getOrders();
    }
    useEffect(() => {
        getShops();
        getOrders();
    }, []);



    return (
        <React.Fragment>
            {confirmDeleteAlert ? (
                <SweetAlert
                    title=""
                    showCancel
                    confirmButtonText="Delete"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        axios
                            .delete(`${API_URL}vieworders/offline` + "/" + orderIdToBeDeleted, {
                                headers: {
                                    "x-access-token": accessToken,
                                },
                            })
                            .then((res) => {
                                if (res.data.status === 200) {
                                    toastr.success("Order deleted successfully");
                                    getOrders(filterObject?.from_date, filterObject?.to_date, selectedShop?.value);
                                } else {
                                    toastr.error(res.data.message, "Failed to delete order");
                                    return;
                                }
                            });
                        setConfirmDeleteAlert(false);
                    }}
                    onCancel={() => setConfirmDeleteAlert(false)}
                >
                    Are you sure you want to delete it?
                </SweetAlert>
            ) : null}

            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="StarLee" breadcrumbItem="Offline Orders" />
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md="2">
                                            <Label htmlFor="validationCustom05">From</Label>
                                            <input
                                                className="form-control"
                                                type="date"
                                                id="from_date"
                                                name="from_date"
                                                onChange={handleFilter}
                                                value={filterObject?.from_date}
                                            />
                                        </Col>
                                        <Col md="2">
                                            <Label htmlFor="validationCustom05">To</Label>
                                            <input
                                                className="form-control"
                                                type="date"
                                                id="to_date"
                                                name="to_date"
                                                onChange={handleFilter}
                                                value={filterObject?.to_date}
                                            />
                                        </Col>
                                        <Col md="2">
                                            <Label htmlFor="validationCustom05">Shop</Label>
                                            <Select
                                                name="shop"
                                                value={selectedShop}
                                                onChange={(value) => {
                                                    handleSelectedShop(value);
                                                }}
                                                options={shopOptions}
                                                classNamePrefix="select2-selection"
                                            />
                                        </Col>
                                        <Col md="2" style={{ marginTop: "25px", display: "flex" }}>
                                            <div>
                                                <Button color="danger" type="submit"
                                                    onClick={reset}
                                                >Reset
                                                </Button>
                                            </div>

                                            <div style={{ marginLeft: '19px' }}>
                                                <Button color="success" type="export" >
                                                    <CSVLink data={dataToBeExported}
                                                        filename={"Offline_orders_" + getDate(new Date()) + ".xls"}
                                                        style={{ color: "white" }}
                                                    >Export</CSVLink>
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <MDBDataTable
                                        id="offlineorderListTable1"
                                        responsive
                                        bordered
                                        data={data}
                                        searching={true}
                                        info={false}
                                        entries={25}
                                        disableRetreatAfterSorting={true}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
};
export default Orders