import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Card, CardBody, Button, Label, } from "reactstrap";
import toastr from "toastr";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import { MDBDataTable } from "mdbreact";
import { AvForm, AvField } from "availity-reactstrap-validation";
import moment from "moment";
import ReactSelect from "react-select";
const MerchantCashTransfer = () => {
    const [shopOptions, setShopOptions] = useState([])
    const [amount, setAmount] = useState('')
    const [shop, setShop] = useState(null)
    const [datas, setDatas] = useState([])
    const [selectedState, setselectedState] = useState(null);
    const [districtOptions, setDistrictOptions] = useState([]);
    const [townOptions, setTownOptions] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [district, setDistrict] = useState(null);
    const [town, setTown] = useState(null);
    const [category, setCategory] = useState(null);
    const statusOptions = [
        { label: "Pending", value: '0' },
        { label: "Rejected", value: '1' },
        { label: "Completed", value: '2' }
    ]
    const typeOption =[
        { label: "All", value: "" },
        { label: "Wallet", value: "0" },
        { label: "Point", value: "1" }
    ]
    const [filters, setFilters] = useState({
        from: moment().startOf('month').format('YYYY-MM-DD'),
        to: moment().endOf('month').format('YYYY-MM-DD'),
        status: 'null',
        category: '',
        shop: ''
    });
    const formRef = useRef()
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
    const data = {
        columns: [
            {
                label: "#",
                field: "id",
                sort: "asc",
                width: 150,
            },
            {
                label: "Date",
                field: "date",
                sort: "asc",
                width: 400,
            },
            {
                label: "Time",
                field: "time",
                sort: "asc",
                width: 200,
            },
            {
                label: "Shop",
                field: "shop_name",
                sort: "asc",
                width: 200,
            },
            {
                label: "From",
                field: "suser_name",
                sort: "asc",
                width: 200,
            },
            {
                label: "Amount",
                field: "transfer_amount1",
                sort: "asc",
                width: 200,
            },
            {
                label: "Comment",
                field: "transfer_remarks",
                sort: "asc",
                width: 200,
            },
            {
                label: "Status",
                field: "status1",
                sort: "asc",
                width: 200,
            },
            {
                label: "Log",
                field: "log",
                sort: "asc",
                width: 400,
            },
            {
                label: "Action",
                field: "action",
                sort: "asc",
                width: 200,
            },
        ],
        rows: datas.map(item => ({
            id: item.transfer_id,
            date: item.date,
            time: item.time,
            shop_name: item.shop.shop_name,
            suser_name: item.addedBy.suser_name,
            transfer_amount1: item.transfer_amount,
            transfer_remarks: item.transfer_remarks || "No Remarks",
            status1: <span style={{ color: item.color }}>{item.status}</span>,
            log: item.cashTransferLog.length > 0
                ? item.cashTransferLog.map(log => (
                    <div key={log.log_id} style={{ marginBottom: '8px' }}>
                        <span style={{ color: log.color }}>{log.date} {log.time}: {log.status} - {log.log_remarks || "No Remarks"} (by {log.username || log.staffname || "Unknown"})</span>
                    </div>
                ))
                : "No Logs",
            // action: <Button color="primary">View</Button>
        }))
    };



    useEffect(() => {
        fetchShop();
       
        fetchDistrictOptions();
        fetchCategoryOptions();
    },[] );
    useEffect(()=>{
        fetchTransferLogData()
    },[filters])

    useEffect(() => {
        if (district || town || category) {
            fetchShop();
        }
    }, [district, town, category]);

    const fetchDistrictOptions = async () => {
        try {
            const res = await axios.get(API_URL + "shop/district-list", {
                headers: { 'x-access-token': accessToken }
            });
            setDistrictOptions(res.data.data.map(d => ({ label: d.dist_name, value: d.dist_id })));
        } catch (error) {
            console.error("Error fetching districts", error);
        }
    };

    const fetchTownOptions = async (districtId) => {
        try {
            const res = await axios.get(API_URL + "shop/town-list", {
                params: { districtId },
                headers: { 'x-access-token': accessToken }
            });
            setTownOptions(res.data.data.map(t => ({ label: t.town_name, value: t.town_id })));
        } catch (error) {
            console.error("Error fetching towns", error);
        }
    };

    const fetchCategoryOptions = async () => {
        try {
            const res = await axios.get(API_URL + "shop/category-list", {
                headers: { 'x-access-token': accessToken }
            });
            setCategoryOptions(res.data.data.map(c => ({ label: c.category_name, value: c.category_id })));
        } catch (error) {
            console.error("Error fetching categories", error);
        }
    };


    const fetchShop = async () => {
        try {
            const query = {
                district: district ? district.value : '',
                town: town ? town.value : '',
                category: category ? category.value : ''
            };
    
            const res = await axios.get(API_URL + "shop/options-new", {
                headers: {
                    'x-access-token': accessToken
                },
                params: query 
            });
    
            setShopOptions(res?.data?.data);
        } catch (err) {
            console.error('Error fetching shop options', err);
        }
    };


    const fetchTransferLogData = async () => {
        try {
            const params = {
                from: filters.from,
                to: filters.to,
                status: filters.status,
                category: filters.category,
                shop: filters.shop
            };

            const response = await axios.get(API_URL + 'shop-cash-transfer/all-log', {
                params: params,
                headers: {
                    'x-access-token': accessToken
                }
            });
            setDatas(response.data.data);
        } catch (error) {
            throw error;
        }
    };

    const reset = () => {
        formRef.current.reset()
        setShop(null);
        setAmount('');
      
 
    }

    const reset2=()=>{
        setFilters({
            from: moment().startOf('month').format('YYYY-MM-DD'),
            to: moment().endOf('month').format('YYYY-MM-DD'),
            status: 'null',
            category: '',
            shop: ''
        });
    }

    const handleSubmit = async () => {
        if (!shop || !amount) {
            alert('Please select a shop and enter an amount.');
            return;
        }
        try {
            const response = await axios.post(API_URL + "shop-cash-transfer/cashtransfer", {
                amount: parseFloat(amount),
                shop_id: shop.value
            }, {
                headers: {
                    'x-access-token': accessToken
                }
            });

            if (response.data.success) {
                toastr.success("Cash transfer successful!")
            } else {
                toastr.error('Cash transfer failed: ' + response.data.message);
            }
        } catch (error) {
            alert('Cash transfer failed. Please try again.');
        }
    }

    const handleFilterChange = (filterKey, value) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [filterKey]: value
        }));
    };

  const formatOptionLabel = ({ label, total_wallet_amount }) => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>{label}</span>
            <span style={{ color: 'red' }}>Wallet: {total_wallet_amount}</span>
        </div>
    );
    



    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Home" breadcrumbItem="Merchant Cashtransfer" />
                    <Card>
                        <CardBody>
                            <AvForm
                                onValidSubmit={handleSubmit} ref={formRef}>
                                <Row>
                                <Col md="3">
                            <Label>District</Label>
                            <ReactSelect
                                id="districtselect"
                                options={districtOptions}
                                value={district}
                                placeholder="Select District"
                                onChange={(option) => {
                                    setDistrict(option);
                                    fetchTownOptions(option.value);
                                }}
                                isClearable
                                isSearchable
                            />
                        </Col>
                        <Col md="3">
                            <Label>Town</Label>
                            <ReactSelect
                                id="townselect"
                                options={townOptions}
                                value={town}
                                placeholder="Select Town"
                                onChange={(option) => setTown(option)}
                                isClearable
                                isSearchable
                            />
                        </Col>
                        <Col md="3">
                            <Label>Category</Label>
                            <ReactSelect
                                id="categoryselect"
                                options={categoryOptions}
                                value={category}
                                placeholder="Select Category"
                                onChange={(option) => setCategory(option)}
                                isClearable
                                isSearchable
                            />
                        </Col>
                                       <Col md="3">
                            <Label for="shopselect">Shop Name</Label>
                            <ReactSelect
                                id="shopselect"
                                options={shopOptions.map(shop => ({
                                    label: `${shop.label} (Wallet: ${shop.balance})`,
                                    value: shop.value
                                }))}
                                value={shop}
                                placeholder="Select Shop"
                                onChange={(option) => setShop(option)}
                                 formatOptionLabel={({ label, value }) => (
                                <div>
                                {label.split(' (Wallet:')[0]} 
                                <span style={{ color: 'red' }}>
                                    {` (Wallet: ${shopOptions.find(s => s.value === value)?.balance})`}
                                </span>
                                </div>
                                        )}
                              
                                isClearable
                                isSearchable
                            />
                        </Col>
                                    <Col md="3">
                                        <Label for="amountselect">Enter Amount</Label>
                                        <AvField name="amount" id="amountselect" type="number" value={amount || ''} min="0" onChange={
                                            (e) => { setAmount(e.target.value) }} required />
                                    </Col>
                                    <Col md="1" style={{ paddingTop: "26px" }}>
                                        <Button color="primary" type="submit">Submit</Button>
                                    </Col>
                                    <Col md="1" style={{ paddingTop: "26px" }} >
                                        <Button color="danger" onClick={reset}>Reset</Button>
                                    </Col>
                                </Row>
                            </AvForm>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <AvForm>
                                <Row>
                                    <Col md="2">
                                        <Label for="shopselect">From</Label>
                                        <input type="date"
                                               name="from" className="form-control"
                                               value={filters.from}
                                               onChange={
                                                (e) =>
                                                    {
                                                         console.log("from filter",e.target.value)
                                                         handleFilterChange('from', e.target.value)
                                                        }
                                                } />
                                    </Col>
                                    <Col md="2">
                                        <Label for="shopselect">To</Label>
                                        <input type="date"
                                               name="to"
                                               className="form-control"
                                               value={filters.to}
                                               onChange={(e) =>
                                               {console.log("to filter",e.target.value)
                                                 handleFilterChange('to', e.target.value)}} />
                                    </Col>
                                    <Col md="2">
                                        <Label for="shopFilter">Shop</Label>
                                        <ReactSelect
                                            id="shopFilter"
                                            options={shopOptions}
                                            value={shopOptions.find(option => option.value === filters.shop) || ''}
                                            onChange={(option) => { handleFilterChange("shop",option.value) }}
                                            placeholder="Select shop"
                                            formatOptionLabel={(shop) => (
                                                <div>
                                                    {shop.label} 
                                                    <span style={{ color: 'red' }}>
                                                        ₹{shop.balance}
                                                    </span>
                                                    
                                                </div>
                                            )}
                                        />
                                    </Col>
                                    <Col md="2">
                                        <Label for="shopFilter">Status</Label>
                                        <ReactSelect
                                            id="shopFilter"
                                            options={statusOptions}
                                            value={statusOptions.find(option => option.value === filters.status) || ''}
                                            onChange={(option) => handleFilterChange('status', option.value )}
                                        />
                                    </Col>
                                    <Col md="2">
                                        <Label for="shopFilter">Type</Label>
                                        <ReactSelect
                                            id="shopFilter"
                                            options={typeOption}
                                            value={typeOption.find(option => option.value === filters.category) || ''}
                                            onChange={(option) => handleFilterChange('category', option.value )}
                                            placeholder="Select shop"
                                        />
                                    </Col>
                                    <Col md='2'><Button style={{marginTop:"28px"}} color="danger" onClick={reset2}>Reset</Button></Col>
                                </Row>
                            </AvForm>
                            <MDBDataTable
                                responsive
                                bordered
                                data={data}
                                searching={true}
                                info={false}
                                disableRetreatAfterSorting={true}
                                entries={25}
                                data-mdb-fixed-header="true"
                            />
                        </CardBody>
                    </Card>
                </div>
            </div>

        </React.Fragment>
    );;
}

export default MerchantCashTransfer;