import React from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardImg,
  CardText,
  CardHeader,
  CardImgOverlay,
  CardFooter,
  CardDeck,
  CardColumns,
  Container,
  CardGroup,
} from "reactstrap"

// import images
import { Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const UiCards = props => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="UI Elements" breadcrumbItem="Cards" />

          <Row>
            <Col md={6}>
              <Card body>
                <CardTitle className="h3 mt-0">Special title treatment</CardTitle>
                <CardText>
                  With supporting text below as a natural lead-in to additional
                  content.
                </CardText>
                <Link
                  to="#"
                  className="btn btn-primary waves-effect waves-light"
                >
                  Go somewhere
                </Link>
              </Card>
            </Col>
            <Col md={6}>
              <Card body>
                <CardTitle className="h4 mt-0">Special title treatment</CardTitle>
                <CardText>
                  With supporting text below as a natural lead-in to additional
                  content.
                </CardText>
                <Link
                  to="#"
                  className="btn btn-primary waves-effect waves-light"
                >
                  Go somewhere
                </Link>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={4}>
              <Card body>
                <CardTitle className="h4 mt-0">Special title treatment</CardTitle>
                <CardText>
                  With supporting text below as a natural lead-in to additional
                  content.
                </CardText>
                <Link
                  to="#"
                  className="btn btn-primary waves-effect waves-light"
                >
                  Go somewhere
                </Link>
              </Card>
            </Col>

            <Col lg={4}>
              <Card body className="text-center">
                <CardTitle className="h4 mt-0">Special title treatment</CardTitle>
                <CardText>
                  With supporting text below as a natural lead-in to additional
                  content.
                </CardText>
                <Link
                  to="#"
                  className="btn btn-primary waves-effect waves-light"
                >
                  Go somewhere
                </Link>
              </Card>
            </Col>

            <Col lg={4}>
              <Card body className="text-end">
                <CardTitle className="h4 mt-0">Special title treatment</CardTitle>
                <CardText>
                  With supporting text below as a natural lead-in to additional
                  content.
                </CardText>
                <Link
                  to="#"
                  className="btn btn-primary waves-effect waves-light"
                >
                  Go somewhere
                </Link>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={4}>
              <Card>
                <CardHeader className="h4">Featured</CardHeader>
                <CardBody>
                  <CardTitle className="h4 mt-0">
                    Special title treatment
                  </CardTitle>
                  <CardText>
                    With supporting text below as a natural lead-in to
                    additional content.
                  </CardText>
                  <Link to="#" className="btn btn-primary">
                    Go somewhere
                  </Link>
                </CardBody>
              </Card>
            </Col>

            <Col lg={4}>
              <Card>
                <CardHeader>Quote</CardHeader>
                <CardBody>
                  <blockquote className="card-blockquote mb-0">
                    <CardText>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Integer posuere erat a ante.
                    </CardText>
                    <footer className="blockquote-footer mt-3 font-size-12">
                      {" "}Someone famous in{" "}
                      <cite title="Source Title">Source Title</cite>
                    </footer>
                  </blockquote>
                </CardBody>
              </Card>
            </Col>

            <Col lg={4}>
              <Card>
                <CardHeader>Featured</CardHeader>
                <CardBody>
                  <CardTitle className="h4 mt-0">
                    Special title treatment
                  </CardTitle>
                  <CardText>
                    With supporting text below as a natural lead-in to
                    additional content.
                  </CardText>
                  <Link
                    to="#"
                    className="btn btn-primary waves-effect waves-light"
                  >
                    Go somewhere
                  </Link>
                </CardBody>
                <CardFooter className="text-muted">2 days ago</CardFooter>
              </Card>
            </Col>
          </Row>


          <Row>
            <Col lg={4}>
              <Card color="primary" className="text-white-50">
                <CardBody>
                  <h5 className="mt-0 mb-4 text-white">
                    <i className="uil uil-user me-3"></i> Primary Card
                  </h5>
                  <CardText>
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </CardText>
                </CardBody>
              </Card>
            </Col>

            <Col lg={4}>
              <Card color="success" className="text-white-50">
                <CardBody>
                  <h5 className="mt-0 mb-4 text-white">
                    <i className="uil uil-check-circle me-3"></i> Success Card
                  </h5>
                  <CardText>
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </CardText>
                </CardBody>
              </Card>
            </Col>

            <Col lg={4}>
              <Card color="info" className="text-white-50">
                <CardBody>
                  <h5 className="mt-0 mb-4 text-white">
                    <i className="uil uil-question-circle me-3"></i>Info
                    Card
                  </h5>
                  <CardText>
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={4}>
              <Card color="warning" className="text-white-50">
                <CardBody>
                  <h5 className="mt-0 mb-4 text-white">
                    <i className="uil uil-exclamation-triangle me-3"></i>Warning Card
                  </h5>
                  <CardText>
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </CardText>
                </CardBody>
              </Card>
            </Col>

            <Col lg={4}>
              <Card color="danger" className="text-white-50">
                <CardBody>
                  <h5 className="mt-0 mb-4 text-white">
                    <i className="uil uil-exclamation-octagon me-3"></i>Danger Card
                  </h5>
                  <CardText>
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </CardText>
                </CardBody>
              </Card>
            </Col>

            <Col lg={4}>
              <Card color="dark" className="text-light">
                <CardBody>
                  <h5 className="mt-0 mb-4 text-white">
                    <i className="uil uil-arrow-circle-right me-3"></i>Dark
                    Card
                  </h5>
                  <CardText>
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={4}>
              <Card outline color="primary" className="border">
                <CardHeader className="bg-transparent">
                  <h5 className="my-0 text-primary">
                    <i className="uil uil-user me-3"></i>Primary
                    outline Card
                  </h5>
                </CardHeader>
                <CardBody>
                  <CardTitle className="h5 mt-0">card title</CardTitle>
                  <CardText>
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </CardText>
                </CardBody>
              </Card>
            </Col>

            <Col lg={4}>
              <Card outline color="danger" className="border">
                <CardHeader className="bg-transparent">
                  <h5 className="my-0 text-danger">
                    <i className="uil uil-exclamation-octagon me-3"></i>Danger outline
                    Card
                  </h5>
                </CardHeader>
                <CardBody>
                  <CardTitle className="h5 mt-0">card title</CardTitle>
                  <CardText>
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </CardText>
                </CardBody>
              </Card>
            </Col>

            <Col lg={4}>
              <Card outline color="success" className="border">
                <CardHeader className="bg-transparent">
                  <h5 className="my-0 text-success">
                    <i className="uil uil-check-circle me-3"></i>Success Card
                  </h5>
                </CardHeader>
                <CardBody>
                  <CardTitle className="h5 mt-0">card title</CardTitle>
                  <CardText>
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>


        </Container>
      </div>
    </React.Fragment>
  )
}
export default UiCards
