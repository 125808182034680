import React, { useState, useEffect, useRef } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label, Progress } from "reactstrap";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import "./adv.scss";
const Ads = (props) => {
  const [filterObject, setFilterObject] = useState(null);
  const [dataTable, setDataTable] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  const getDataTable = (start = "", end = "", status = "") => {
    axios
      .get(
        `${API_URL}advertisement/ads-history?adv_start=` +
          start +
          "&adv_end=" +
          end,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        var data = res.data.data;
        var list = [];
        data.map((item, index) => {
          item.serial = index + 1;
          item.customer = (
            <a href={"/customer/"+item.customers_unique_id} target="_blank">{item.customer_name}</a>
          )
          list.push(item);
        });
        setDataTable(list);
      });
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "serial",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 150,
      },
      {
        label: "Customer",
        field: "customer",
        sort: "asc",
        width: 150,
      },
      {
        label: "Ad",
        field: "adv_title",
        sort: "asc",
        width: 150,
      },
    ],
    rows: dataTable,
  };
  useEffect(() => {
    getDataTable(
      filterObject?.adv_start,
      filterObject?.adv_end,
    );
  }, []);
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Ad History" />
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Start</Label>
                        <input
                          name="adv_start"
                          value={filterObject?.adv_start}
                          placeholder=""
                          type="date"
                          errorMessage="Start Date is required"
                          className="form-control"
                          validate={{
                            required: { value: true },
                          }}
                          id="validationCustom02"
                          onChange={(e) => {
                            setFilterObject({
                              ...filterObject,
                              [e.target.name]: e.target.value,
                            });
                            getDataTable(
                              e.target.value,
                              filterObject?.adv_end,
                              filterObject?.adv_active?.value
                            );
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>End</Label>
                        <input
                          name="adv_end"
                          value={filterObject?.adv_end}
                          placeholder=""
                          type="date"
                          errorMessage="End Date is required"
                          className="form-control"
                          validate={{
                            required: { value: true },
                          }}
                          min={filterObject?.adv_start}
                          id="validationCustom02"
                          onChange={(e) => {
                            setFilterObject({
                              ...filterObject,
                              [e.target.name]: e.target.value,
                            });
                            getDataTable(
                              filterObject?.start,
                              e.target.value,
                              filterObject?.adv_active?.value
                            );
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="1" style={{ paddingTop: "26px" }}>
                      <Button
                        color="danger"
                        type="reset"
                        onClick={(e) => {
                          setFilterObject({
                            adv_start: "",
                            adv_end: "",
                            adv_active: null,
                          });
                          getDataTable();
                        }}
                      >
                        Reset
                      </Button>
                    </Col>
                  </Row>
                  <MDBDataTable
                    responsive
                    id="adsHistoryTableId"
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Ads;
