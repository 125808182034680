import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import {jwtDecode} from 'jwt-decode';

import SweetAlert from 'react-bootstrap-sweetalert';
import { MDBDataTable } from 'mdbreact';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Modal,
} from 'reactstrap';

import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';

import {
  getUsers,
  addUser,
  deleteUser,
  updateUser,
  getPrivilagesOptions,
} from '../../store/actions';

// Redux

import { AvForm, AvField } from 'availity-reactstrap-validation';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import './user.scss';
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import moment from "moment";
const Users = (props) => {
  const [userObject, setUserObject] = useState({});
  const [userTemp, setUserTemp] = useState([]);

  const [userIdTobeUpdated, setUserIdToBeUpdated] = useState(null);
  const [userIdToBeDeleted, setUserIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);

  const [userId, setUserId] = useState(1);
  const [showFields, setshowFields] = useState(0);
  const [btnStyle, setBtnStyle] = useState({ paddingTop: "33px" });
  const [districtMulti, setDistrictMulti] = useState(false);
  const [selectedPrivilage, setSelectedPrivilage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedDistrict, setselectedDistrict] = useState(null);
  const [districtOptions, setdistrictOptions] = useState([]);
  const [selectedCompany, setselectedCompany] = useState(null);
  const [companyOptions, setcompanyOptions] = useState([]);
  const [selectedState, setselectedState] = useState(null);
  const [stateOptions, setstateOptions] = useState([]);
  const [selectedPanchayat, setSelectedPanchayat] = useState(null);
  const [panchayatOptions, setpanchayatOptions] = useState([]);
  const [selectedTown, setselectedTown] = useState(null);
  const [townOptions, settownOptions] = useState([]);
  const [modalObject, setModalObject] = useState(null);
  const [showModal1, setShowModal1] = useState(false);
  const [shareLink, setshareLink] = useState("");
  const [shareModal, setshareModal] = useState(false);
  const [linkCopied, setlinkCopied] = useState(false);
  const [userStatusOptions, setUserStatusOptions] = useState([{ label: "Pending", value: 0, color: "btn-warning" }, { label: "Approved", value: 1, color: "btn-success" }, { label: "Rejected", value: 2, color: "btn-danger" }]);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  const [passwordObject, setPasswordObject] = useState({
    oldPassword: '',
    password: '',
    confirmPassword: '',
  });

  const {
    users,
    addingUser,
    addUserResponse,
    deleteUserResponse,
    updateUserResponse,
    error,
  } = useSelector((state) => state.Users);

  const privilagesOptions = useSelector(
    (state) => state.privilages.privilagesOptions
  );

  const dispatch = useDispatch();
  const formRef = useRef();
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };


  useEffect(() => {
    dispatch(getUsers());
    dispatch(getPrivilagesOptions());
    getStates();
  }, [dispatch]);

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      const obj = jwtDecode(localStorage.getItem('authUser'));
      console.log(obj);

      setUserId(obj.user);
      setUserObject({ ['auth_userid']: obj.user });
    }
  }, [userId]);

  useEffect(() => {
    if (addUserResponse.type === 'success') {
      toastr.success(addUserResponse.message);
      reset();
    } else if (addUserResponse.type === 'failure') {
      toastr.error(error.data.message, addUserResponse.message);
    }
  }, [addUserResponse]);

  useEffect(() => {
    if (deleteUserResponse.type === 'success') {
      toastr.success(deleteUserResponse.message);
      reset();
    } else if (deleteUserResponse.type === 'failure') {
      toastr.error(error.data.message, deleteUserResponse.message);
    }
  }, [deleteUserResponse]);

  useEffect(() => {
    console.log(updateUserResponse);
    if (updateUserResponse.type === 'success') {
      setUserIdToBeUpdated(null);
      setUserIdToBeUpdated(null);
      setShowModal(false)
      toastr.success(updateUserResponse.message);
      reset();
    } else if (updateUserResponse.type === 'failure') {
      toastr.error(error.data.message, updateUserResponse.message);
    }
  }, [updateUserResponse]);

  let preUpdateUser = (item) => {
    console.log(item.username);
    setUserIdToBeUpdated(item.id);
    let privilege = {
      label: item?.privilege_name,
      value: item?.previlage,
    };
    let newValue = {
      name: item?.privilege_name,
      _id: item?.previlage,
    };
    item.newValue = newValue;
    handleSelectedPrivilage(privilege, 1);
    setselectedState({ label: item?.name, value: item?.state_id });
    getDistricts(item?.state_id);
    getTowns(item?.district_id);
    getPanchayats(item?.district_id);

    if (item?.previlage == 7) {
      var dist_name = item?.dist_name.split(",");
      var dist_id = item?.dist_ids.split(",");
      var dist = [];
      for (var i = 0; i < dist_name.length; i++) {
        dist.push({ label: dist_name[i], value: dist_id[i] });
      }
      item.district_id = dist;
    }
    else {
      var dist = { label: item?.dist_name, value: item?.dist_ids }
      item.district_id = [dist];
    }


    setselectedDistrict(dist);
    setSelectedPanchayat({ label: item?.panchayath_name, value: item?.panchayath_id });
    setselectedTown({ label: item?.town_name, value: item?.town_id });
    item.password = null;
    setUserObject(item);
  };
  let preUpdateUserPassword = (item) => {
    setUserIdToBeUpdated(item.id);
    setShowModal(true);
  };

  const handleClickopen = (item) => {
    setModalObject(item);
    setShowModal1(true);
  }
  const handleUserStatus = (e, id) => {
    axios
      .post(`${API_URL}user/approve_reject`, { status: e.value, id: id }, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (e.value == 1)
          toastr.success("Approved successfully");
        else if (e.value == 2)
          toastr.success("Rejected successfully");
        else if (e.value == 0)
          toastr.success("Updated successfully");
        dispatch(getUsers());
      })
  }
  const copyToClipboard = () => {
    const textField = document.createElement('textarea');
    textField.innerText = shareLink.link;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    document.body.removeChild(textField);
    setlinkCopied(true)
  };
  useEffect(() => {
    var prv = '';
    var user_id = '';
    if (localStorage.getItem('authUser')) {
      const obj = jwtDecode(localStorage.getItem('authUser'));
      prv = obj.Privilege;
      user_id = obj.user;
    }
    let userArray = JSON.parse(JSON.stringify(users));

    let userData = [];
    userArray.map((item, index) => {
      item.serial_id = (index + 1);
      item.name1 = `${item.fname} ${item.lname}`;
      item.auth_userid = userId;
      item.privilage1 = item?.privilege_name;
      item.branch_name = item?.branch_name;
      item.district_name = item?.dist_name;
      item.sname = item?.name;
      item.tname = item?.town_name;
      item.pname = item?.panchayath_name;
      if (item.id != 1) {
        item.action = (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <i
              className="fas fa-share-alt"
              style={{ fontSize: '1em', cursor: 'pointer' }}
              onClick={() => {
                setlinkCopied(false);
                setshareModal(true);
                setshareLink({ link: item?.share_link, name: item?.fname + " " + item?.lname });
              }}
            >
              {' '}
            </i>
            <i
              className="fas fa-eye"
              style={{
                fontSize: '1em', cursor: 'pointer', marginLeft: "0.5em",
              }}
              onClick={() => {
                ///////password update
                handleClickopen(item);
              }}
            >
              {' '}
            </i>
            <i
              className="fas fa-key"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.5em",
                marginRight: "0.5em",
                display: ((prv == 1 || prv == 3)) ? 'block' : 'none'
              }}
              onClick={() => {
                ///////password update
                preUpdateUserPassword(item);
              }}
            >
              {' '}
            </i>
            <i
              className="far fa-edit"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.5em",
                marginRight: "0.5em",
              }}
              onClick={() => {
                toTop();
                preUpdateUser(item);
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              style={{ fontSize: '1em', cursor: 'pointer' }}
              onClick={() => {
                setUserIdToBeDeleted(item.id);
                setConfirmDeleteAlert(true);
              }}
            ></i>
          </div>
        );
      }

      if (prv == 1 || prv == 3) {
        if (item.approved_status == 0) {
          var selected = { label: "Pending", value: 0 }
        }
        else if (item.approved_status == 1) {
          var selected = { label: "Approved", value: 1 }
        }
        else {
          var selected = { label: "Rejected", value: 2 }
        }
        item.approval = (
          <Select
            name="user_approved"
            value={selected}
            options={userStatusOptions}
            classNamePrefix="select2-selection"
            onChange={(e) => {
              handleUserStatus(e, item.id);
            }}
            menuPosition={"fixed"}
          />
        );
      }
      else {
        if (item.approved_status == 0) {
          item.approval = (
            <span className='badge bg-warning'>Pending</span>
          )
        }
        else if (item.approved_status == 1) {
          item.approval = (
            <span className='badge bg-success'>Approved</span>
          )
        }
        else {
          item.approval = (
            <span className='badge bg-danger'>Rejected</span>
          )
        }
      }




      // item.id = index + 1;

      userData.push(item);

    });
    setUserTemp(userData);
  }, [users, userId]);

  const data = {
    columns: [
      {
        label: '#',
        field: 'serial_id',
        sort: 'asc',
        width: 150,
      },
      // {
      //   label: 'ID',
      //   field: 'username',
      //   sort: 'asc',
      //   width: 270,
      // },
      {
        label: 'Ref.Code',
        field: 'reference_id',
        sort: 'asc',
        width: 270,
      },
      {
        label: 'Name',
        field: 'name1',
        sort: 'asc',
        width: 270,
      },


      // {
      //   label: 'Email',
      //   field: 'user_email',
      //   sort: 'asc',
      //   width: 200,
      // },
      // {
      //   label: 'Mobile',
      //   field: 'mobile',
      //   sort: 'asc',
      //   width: 100,
      // },
      {
        label: 'State',
        field: 'sname',
        sort: 'asc',
        width: 100,
      },
      {
        label: 'District',
        field: 'district_name',
        sort: 'asc',
        width: 100,
      },
      {
        label: 'Town',
        field: 'tname',
        sort: 'asc',
        width: 100,
      },
      {
        label: 'Panchayat',
        field: 'pname',
        sort: 'asc',
        width: 100,
      },
      // {
      //   label: 'Address',
      //   field: 'user_address',
      //   sort: 'asc',
      //   width: 100,
      // },
      {
        label: 'Privilege',
        field: 'privilage1',
        sort: 'asc',
        width: 150,
      },
      {
        label: 'Status',
        field: 'approval',
        sort: 'asc',
        width: 150,
      },
      {
        label: 'Action',
        field: 'action',
        width: 100,
      },
    ],

    rows: userTemp,
  };

  let privilagesOptionsData =
    privilagesOptions &&
    privilagesOptions.map((item) => {
      return {
        label: item.privilege_name,
        value: item.id,
      };
    });

  const privilagesOptionsGroup = [
    {
      options: privilagesOptionsData,
    },
  ];

  let handleChangeInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setUserObject({
      ...userObject,
      [name]: value,
    });
  };
  const handleSelected = (selected, type) => {
    switch (type) {
      case "1":
        setselectedState(selected);
        setUserObject({
          ...userObject,
          "state_id": selected.value,
          "district_id": null,
          "town_id": null,
          "panchayath_id": null
        });
        setselectedDistrict(null)
        setSelectedPanchayat(null);
        setselectedTown(null);
        setdistrictOptions([]);
        setpanchayatOptions([]);
        settownOptions([]);
        getDistricts(selected.value);
        break;
      case "2":
        setselectedDistrict(selected);
        setSelectedPanchayat(null);
        setselectedTown(null);
        if (showFields == 2) {
          var dist = selected;
        }
        else {
          var dist = [selected];
          getPanchayats(selected.value);
          getTowns(selected.value);
        }
        setUserObject({
          ...userObject,
          "district_id": dist,
          "town_id": null,
          "panchayath_id": null
        });
        break;
      case "4":
        setSelectedPanchayat(selected);
        setUserObject({
          ...userObject,
          "panchayath_id": selected.value
        });
        break;
      case "3":
        setselectedTown(selected);
        setUserObject({
          ...userObject,
          "town_id": selected.value
        });
        break;
    }
  }
  const getStates = () => {
    axios
      .get(`${API_URL}state`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var list = [];
        res.data?.data?.map((item) => {
          list.push({ label: item.name, value: item.id });
        })
        setstateOptions(list);
      })
  }
  const getDistricts = (id, type = "") => {
    axios
      .get(`${API_URL}district/options/?id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var list = [];
        res.data?.data?.map((item) => {
          list.push({ label: item.dist_name, value: item.dist_id });
        })
        if (type == "")
          setdistrictOptions(list);
        //else
        // setdistrictOptionsFlr(list);
      })
  }
  const getTowns = (id, type = "") => {
    axios
      .get(`${API_URL}manage-town/options?id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var list = [];
        res.data?.data?.map((item) => {
          list.push({ label: item.town_name, value: item.town_id });
        })
        if (type == "")
          settownOptions(list);
        // else
        // settownOptionsFlr(list);
      })
  }
  const getPanchayats = (id, type = "") => {
    axios
      .get(`${API_URL}manage-panchayath/options?id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var list = [];
        res.data?.data?.map((item) => {
          list.push({ label: item.panchayath_name, value: item.panchayath_id });
        })
        if (type == "")
          setpanchayatOptions(list);
        // else
        //setpanchayathOptionsFlr(list);
      })
  }
  const getCompanies = (id) => {
    axios
      .get(`${API_URL}company/options?id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var list = [];
        res.data?.data?.map((item) => {
          list.push({ label: item.branch_name, value: item.branch_id });
        })
        setcompanyOptions(list);
      })
  }

  function handleSelectedPrivilage(value, type = "") {
    let newValue = {
      name: value.label,
      _id: value.value,
    };
    if (userIdTobeUpdated) {
      type = 1;
    }

    setSelectedPrivilage(value);
    setUserObject({
      ...userObject,
      newValue: newValue,
      state_id: null,
      district_id: null,
      town_id: null,
      panchayath_id: null
    });
    setDistrictMulti(false);

    setselectedState(null);
    setselectedDistrict(null);
    setSelectedPanchayat(null);
    setselectedTown(null);
    setdistrictOptions([]);
    setpanchayatOptions([]);
    settownOptions([]);
    if (value.value == 6) {
      setshowFields(1);
      setBtnStyle({ paddingTop: "33px" })

    }
    else if (value.value == 7) {
      setshowFields(2);
      setBtnStyle({ paddingTop: "33px" })
      setDistrictMulti(true)
    }
    else if (value.value == 8) {
      setshowFields(3);
      setBtnStyle({ paddingTop: "33px" })
    }
    else if (value.value == 9 || value.value == 10) {
      setshowFields(4);
      if (type)
        setBtnStyle({ paddingTop: "33px" })
      else
        setBtnStyle({ paddingTop: "33px" })
    }
    else {
      setshowFields(0);
      setBtnStyle({ paddingTop: "33px" })
    }
  }

  const handleValidSubmit = (event, values) => {

    if (!selectedPrivilage) {
      toastr.error("Please select a privilege");
      return;
    }
    if (showFields >= 1 && (!selectedState)) {
      toastr.error("Please select a state");
      return;
    }
    if (showFields >= 2 && (!selectedDistrict)) {
      toastr.error("Please select a district");
      return;
    }
    if (showFields >= 3 && (!selectedTown)) {
      toastr.error("Please select a town");
      return;
    }
    if (showFields >= 4 && (!selectedPanchayat)) {
      toastr.error("Please select a panchayat");
      return;
    }
    // if (!selectedCompany) {
    //   toastr.error("Please select a branch");
    //   return;
    // }

    userIdTobeUpdated
      ? dispatch(updateUser(userObject))
      : dispatch(addUser(userObject));
  };
  const reset = () => {
    formRef.current.reset();
    setSelectedPrivilage(null);
    setselectedState(null);
    setselectedDistrict(null);
    setdistrictOptions([]);
    setSelectedPanchayat(null);
    setpanchayatOptions([]);
    setselectedTown(null);
    settownOptions([]);
    setselectedCompany(null);
    setcompanyOptions([]);
    setUserObject({ fname: "", lname: "", user_email: "", mobile: "", newValue: null, state_id: null, district_id: null, town_id: null, panchayath_id: null, user_address: "", password: "" })
    setDistrictMulti(false);
    setBtnStyle({ paddingTop: "33px" });
    setshowFields(0);
  }

  function handleChangePassword(e) {
    let name = e.target.name;
    let value = e.target.value;
    setPasswordObject({ ...passwordObject, [name]: value });
  }

  const handleValidSubmitPassword = (event, values) => {
    if (passwordObject.password === passwordObject.confirmPassword) {
      let item = {
        id: userIdTobeUpdated,
        password: passwordObject.password,
      };
      dispatch(updateUser(item));
    } else {
      toastr.error('Passwords are not matching');
    }
  };
  let closeModal = () => {
    setShowModal(false);
    setUserIdToBeUpdated(null);
  };

  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            dispatch(deleteUser(userIdToBeDeleted));
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}

      <Modal
        isOpen={showModal}
        toggle={() => {
          closeModal();
        }}
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Confirmation</h5>
          <button
            type="button"
            onClick={() => {
              closeModal();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <AvForm
          className="needs-validation"
          onValidSubmit={(e, v) => {
            handleValidSubmitPassword(e, v);
          }}
        >
          <div className="modal-body">
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="validationCustom05">Password</Label>
                  <AvField
                    name="password"
                    placeholder="Password"
                    type="password"
                    errorMessage=" Please provide a valid password"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom05"
                    value={passwordObject.password}
                    onChange={handleChangePassword}
                  />
                </div>
              </Col>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="validationCustom05">Confirm Password</Label>
                  <AvField
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    type="password"
                    errorMessage=" Please confirm the password"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom05"
                    value={passwordObject.confirmPassword}
                    onChange={handleChangePassword}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-dark"
              style={{ marginRight: '1rem' }}
              onClick={closeModal}
            >
              Close
            </button>
            <button className="btn btn-primary" type="submit">
              Confirm
            </button>
          </div>
        </AvForm>
      </Modal>
      <Modal
        isOpen={shareModal}
        toggle={() => {
          setshareModal(!shareModal)
        }}
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0 tocapitalize"><i className="fas fa-link"></i> <span>Share Link of {shareLink.name?.toLowerCase()}</span></h5>
          <button
            type="button"
            onClick={() => {
              setshareModal(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body" style={{ textAlign: 'center' }}>
          <p className='prevent-select'>{shareLink.link}</p>
          <button className={linkCopied ? "btn btn-after-copy" : "btn btn-before-copy"} type="button" disabled={linkCopied ? "disabled" : ""}
            onClick={copyToClipboard}>
            {
              linkCopied ? (<>
                <i className='fas fa-check'></i> Copied
              </>) : "Copy"
            }
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={showModal1}
        toggle={() => {
          setShowModal1(!showModal1)
        }}
        centered={true}
        size="xl"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">{modalObject?.username}</h5>
          <button
            type="button"
            onClick={() => {
              setShowModal1(!showModal1)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className='modal-body'>
          <table className="usersModalTable">
            <tr>
              <th>Name</th><td>: {modalObject?.name1}</td>
              <th>Email</th><td>: {modalObject?.user_email}</td>
              <th>Mobile</th><td>: {modalObject?.mobile}</td>
              <th>Privilege</th><td>: {modalObject?.privilage1}</td>
            </tr>
            <tr>
              <th>State</th><td>: {modalObject?.sname}</td>
              <th>District</th><td>: {modalObject?.district_name}</td>
              <th>Town</th><td>: {modalObject?.tname}</td>
              <th>Panchayat</th><td>: {modalObject?.pname}</td>
            </tr>
            <tr>
              <th>Reference Code</th><td>: {modalObject?.reference_id}</td>
              <th>Username / ID</th><td>: {modalObject?.username}</td>
              <th>Address</th><td colSpan={3}>: {modalObject?.user_address}</td>
            </tr>
            <tr>
              <th>Added By</th><td>: {modalObject?.added_by}</td>
              <th>Added On</th><td>: {moment(modalObject?.createdAt).format("DD-MM-YYYY hh:mm a")}</td>
              <th>{(modalObject?.approved_status == 1) ?
                "Approved"
                : ((modalObject?.approved_status == 2) ?
                  "Rejected"
                  :
                  "Pending"
                )} By</th><td>: {modalObject?.approved_by}</td>
              <th>On</th><td>: {(modalObject?.approved_on != "") ? moment(modalObject?.approved_on).format("DD-MM-YYYY hh:mm a") : ""}</td>
            </tr>
            <tr>
              <th>Status</th><td>: {(modalObject?.approved_status == 1) ? (
                <span className='badge bg-success'>Approved</span>
              ) : ((modalObject?.approved_status == 2) ? (
                <span className='badge bg-danger'>Rejected</span>
              ) : (
                <span className='badge bg-warning'>Pending</span>
              ))}</td>

            </tr>
          </table>
        </div>
      </Modal>

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Manage Users" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <Label htmlFor="validationCustom01">First name</Label>
                        <AvField
                          name="fname"
                          placeholder="First name"
                          type="text"
                          errorMessage="Enter First Name"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="validationCustom01"
                          value={userObject?.fname}
                          onChange={handleChangeInput}
                        />
                      </Col>

                      <Col md="3">
                        <Label htmlFor="validationCustom02">Last name</Label>
                        <AvField
                          name="lname"
                          placeholder="Last name"
                          type="text"
                          errorMessage="Enter Last name"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="validationCustom02"
                          value={userObject?.lname}
                          onChange={handleChangeInput}
                        />
                      </Col>

                      <Col md="3">
                        <Label htmlFor="validationCustom03">Email</Label>
                        <AvField
                          name="user_email"
                          placeholder="Email"
                          type="email"
                          errorMessage="Enter valid Email"
                          className="form-control"
                          //validate={{ required: { value: true } }}
                          id="validationCustom03"
                          value={userObject?.user_email}
                          onChange={handleChangeInput}
                        />
                      </Col>
                      <Col md="3">
                        <Label htmlFor="validationCustom04">Mobile</Label>
                        <AvField
                          name="mobile"
                          placeholder="Mobile"
                          type="text"
                          errorMessage="Please provide a valid mobile."
                          className="form-control"
                          validate={{ required: { value: true }, pattern: { value: "^[0-9]{10}$", errorMessage: "Invalid mobile no." } }}
                          id="validationCustom04"
                          value={userObject?.mobile}
                          onChange={handleChangeInput}
                        />
                      </Col>
                      <Col md="3" className='mt-2'>
                        <Label>Privilege</Label>
                        <Select
                          name="previlage"
                          value={selectedPrivilage}
                          onChange={(value) => {
                            handleSelectedPrivilage(value);
                          }}
                          options={privilagesOptionsGroup}
                          isDisabled={userIdTobeUpdated ? true : false}
                          classNamePrefix="select2-selection"
                        />
                      </Col>
                      {(showFields >= 1) ? (
                        <Col md="3" className='mt-2'>
                          <Label>State</Label>
                          <Select
                            name="customer_state"
                            value={selectedState}
                            onChange={(selected) => {
                              handleSelected(selected, "1");
                            }}
                            options={stateOptions}
                            classNamePrefix="select2-selection"
                            menuPosition="auto"
                            menuPlacement="auto"
                          />

                        </Col>
                      ) : ""}
                      {(showFields >= 2) ? (
                        <Col md="3" className='mt-2'>
                          <Label>District</Label>
                          <Select
                            name="district_id"
                            value={selectedDistrict}
                            onChange={(value) => {
                              handleSelected(value, "2");
                            }}
                            options={districtOptions}
                            classNamePrefix="select2-selection"
                            isMulti={districtMulti}
                          />
                        </Col>
                      ) : ""}
                      {(showFields >= 3) ? (
                        <Col md="3" className='mt-2'>
                          <Label>Town</Label>
                          <Select
                            name="town"
                            value={selectedTown}
                            onChange={(selected) => {
                              handleSelected(selected, "3");
                            }}
                            options={townOptions}
                            classNamePrefix="select2-selection"
                            menuPosition="auto"
                            menuPlacement="auto"
                          />

                        </Col>
                      ) : ""}
                      {(showFields >= 4) ? (
                        <Col md="3" className='mt-2'>
                          <div className="mb-3">
                            <Label >Panchayat</Label>
                            <Select
                              name="panchayat"
                              value={selectedPanchayat}
                              onChange={(selected) => {
                                handleSelected(selected, "4");
                              }}
                              options={panchayatOptions}
                              classNamePrefix="select2-selection"
                              menuPosition="auto"
                              menuPlacement="auto"
                            />
                          </div>
                        </Col>
                      ) : ""}
                      {/* <Col md={3} className='mt-2'>
                        <Label>Branch</Label>
                        <Select
                          name="user_branch"
                          value={selectedCompany}
                          onChange={(value) => {
                            handleSelected(value, "2");
                          }}
                          options={companyOptions}
                          classNamePrefix="select2-selection"
                        />
                      </Col> */}
                      <Col md="3" className='mt-2'>
                        <Label htmlFor="validationCustom01">Address</Label>
                        <AvField
                          name="user_address"
                          placeholder="Address"
                          type="textarea"
                          errorMessage="Enter Address"
                          className="form-control"
                          //validate={{ required: { value: true } }}
                          id="validationCustom01"
                          value={userObject?.user_address}
                          onChange={handleChangeInput}
                        />
                      </Col>
                      {/* <Col md="3" className='mt-2'>
                        <Label htmlFor="validationCustom03">Username</Label>
                        <AvField
                          name="username"
                          placeholder="Username"
                          type="text"
                          errorMessage="Enter valid Username"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="validationCustom03"
                          value={userObject?.username}
                          onChange={handleChangeInput}
                        />
                      </Col> */}



                      {userIdTobeUpdated ? null : (
                        <Col md="3" className='mt-2' >
                          <Label htmlFor="validationCustom05">Password</Label>
                          <AvField
                            name="password"
                            placeholder="Password"
                            type="password"
                            errorMessage=" Please provide a valid password"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                            value={userObject?.password}
                            onChange={handleChangeInput}
                          />
                        </Col>
                      )}
                      {/* <AvField
                        name="createdBy"
                        value={userId}
                        type="hidden"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="validationCustom01"
                        onChange={handleChangeInput}
                      /> */}
                      <Col md="1" style={btnStyle}>
                        {userIdTobeUpdated ? (
                          <Button
                            color="primary"
                            type="submit"
                            disabled={addingUser ? true : false}
                          >
                            {addingUser ? 'Updating' : 'Update'}
                          </Button>
                        ) : (
                          <Button
                            color="primary"
                            type="submit"
                            disabled={addingUser ? true : false}
                          >
                            {addingUser ? 'Adding' : 'Submit'}
                          </Button>
                        )}
                      </Col>
                      <Col md="1" style={btnStyle}>
                        <Button
                          color="danger"
                          type="reset"
                          onClick={reset}
                        >
                          Rest
                        </Button>
                      </Col>


                    </Row>


                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={false}
                    id="usersListTable"
                    disableRetreatAfterSorting={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  // const { error } = state.Users;
  // return { error };
};
export default Users;
