import React, { useState, useEffect, useRef } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import SweetAlert from "react-bootstrap-sweetalert";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label, Progress } from "reactstrap";
import toastr from "toastr";
import { AvForm, AvField } from "availity-reactstrap-validation";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import "./category.scss";
import Select from "react-select";
const Category = (props) => {
  const formRef = useRef();
  const [masterObject, setMasterObject] = useState(null);
  const [categoryIdTobeUpdated, setCategoryIdTobeUpdated] = useState(null);
  const [dataTable, setDataTable] = useState([]);
  const [categoryIdTobeDeleted, setCategoryIdTobeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(false);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [uploadProgress, setUploadProgress] = useState();
  const [loading, setLoading] = useState("");
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const handleChangeInput = (e) => {
    setMasterObject({
      ...masterObject,
      [e.target.name]: e.target.value,
    });
  };
  const handleValidSubmit = () => {
    if (categoryIdTobeUpdated) {
      axios
        .put(`${API_URL}shop-category/`, masterObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status == 200) {
            toastr.success("Updated Successfully");
            setCategoryIdTobeUpdated(null);
            formRef.current.reset();
            setMasterObject(null);
            getDataTable();
          } else {
            toastr.error(res.data.message);
          }
        })
        .catch((err) => {
          toastr.error("Failed to update category");
        });
    } else {
      axios
        .post(`${API_URL}shop-category/`, masterObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status == 200) {
            toastr.success("Submitted Successfully");
            formRef.current.reset();
            setMasterObject(null);
            getDataTable();
          } else {
            toastr.error(res.data.message);
          }
        })
        .catch((err) => {
          toastr.error("Failed to add category");
        });
    }
  };
  const preUpdateCategory = (item) => {
    setCategoryIdTobeUpdated(item.category_id);
    setMasterObject(item);
  };
  const getDataTable = () => {
    axios
      .get(`${API_URL}shop-category/`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var data = res.data.data;
        var list = [];
        data.map((item, index) => {
          item.serial = index + 1;
          item.name = item.addedUser?.fname;
          if (item?.category_image)
            item.image = (
              <img
                alt=""
                width="50"
                height="50"
                src={`${API_URL}/uploads/shop category/${item?.category_image}`}
              />
            );
          item.action = (
            <>
              <i
                className="far fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  toTop();
                  preUpdateCategory(item);
                }}
              ></i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
                onClick={() => {
                  setCategoryIdTobeDeleted(item.category_id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </>
          );
          list.push(item);
        });
        setDataTable(list);
      });
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "serial",
        sort: "asc",
        width: 150,
      },
      {
        label: "Category",
        field: "category_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Image",
        field: "image",
        sort: "asc",
        width: 150,
      },
      {
        label: "Staff",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: dataTable,
  };
  const uploadImage = (e) => {
    const fd = new FormData();
    setLoading(true);
    fd.append("photo", e.target.files[0]);
    axios
      .post(`${API_URL}shop-category/upload_image/`, fd, {
        headers: {
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        setLoading("");
        if (response.data.status === "success") {
          setMasterObject({
            ...masterObject,
            [`category_image`]: response.data.file.filename,
          });
        } else {
          toastr.error(response.data.message);
        }
      });
  };
  const deleteImage = () => {
    setMasterObject({
      ...masterObject,
      [`category_image`]: "",
    });
  };
  useEffect(() => {
    getDataTable();
  }, []);
  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}shop-category?id=` + categoryIdTobeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.status === 200) {
                  toastr.success("Deleted successfully");
                  if (
                    masterObject &&
                    masterObject.category_id === categoryIdTobeDeleted
                  ) {
                    formRef.current.reset();
                    setMasterObject(null);
                    setCategoryIdTobeUpdated(null);
                  }
                  setCategoryIdTobeDeleted(null);
                  getDataTable();
                } else {
                  toastr.error(res.data.message, "Failed to delete");
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message);
                return;
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Manage Shop Category" />
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Category Name</Label>
                          <AvField
                            name="category_name"
                            value={masterObject?.category_name}
                            placeholder="Category Name"
                            type="text"
                            errorMessage="Category Name is required"
                            className="form-control"
                            validate={{
                              required: { value: true },
                            }}
                            id="validationCustom02"
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <Label htmlFor="validationCustom03">
                          Image (512x512)
                        </Label>
                        {masterObject?.category_image ? (
                          <div div className="img-wraps">
                            <img
                              alt=""
                              width="150"
                              height="150"
                              src={`${API_URL}/uploads/shop category/${masterObject?.category_image}`}
                            />
                            <button
                              className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                              onClick={deleteImage}
                              style={{ width: "150px" }}
                            >
                              Delete
                            </button>
                          </div>
                        ) : (
                          <AvField
                            name="photo"
                            type="file"
                            className="form-control"
                            id="validationCustom03"
                            onChange={uploadImage}
                            rows="1"
                          />
                        )}
                        {uploadProgress && uploadProgress < 100 && (
                          <div className="mt-4">
                            {" "}
                            <Progress color="primary" value={uploadProgress}>
                              Uploading {uploadProgress}%
                            </Progress>
                          </div>
                        )}
                      </Col>
                      <Col md="1" style={{ paddingTop: "26px" }}>
                        {categoryIdTobeUpdated ? (
                          <Button
                            color="primary"
                            type="submit"
                            disabled={loading}
                          >
                            Update
                          </Button>
                        ) : (
                          <Button
                            color="primary"
                            type="submit"
                            disabled={loading}
                          >
                            Submit
                          </Button>
                        )}
                      </Col>
                      <Col md="1" style={{ paddingTop: "26px" }}>
                        <Button
                          color="danger"
                          type="reset"
                          onClick={(e) => {
                            setMasterObject(null);
                            setCategoryIdTobeDeleted(null);
                            setCategoryIdTobeUpdated(null);
                            formRef.current.reset();
                          }}
                        >
                          Reset
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
            <Col md="12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    responsive
                    id="categoryTableId"
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Category;
