import React, { useState, useEffect,useRef } from "react";
import PropTypes from "prop-types";
import toastr from "toastr";
import {jwtDecode }from "jwt-decode";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import { MDBDataTable } from "mdbreact";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Modal,
  Progress,
} from "reactstrap";

import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategories,
  addCategory,
  deleteCategory,
  toggleCategoryActiveStatus,
  apiError,
  updateCategory,
} from "../../../store/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./category.scss";

const Categories = (props) => {
  const [toggleSwitch, settoggleSwitch] = useState(true);
  const [categoryObject, setCategoryObject] = useState({});
  const [categoriesTemp, setCategoriesTemp] = useState([]);
  const [categoryIdTobeUpdated, setCategoryIdToBeUpdated] = useState(null);
  const [categoryIdToBeDeleted, setCategoryIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [userId, setUserId] = useState(1);
  const formRef = useRef();
  const [isUploadjpg, setIsUploadjpg] = useState(false);
  const [isUploadwebp, setIsUploadwebp] = useState(false);
  const [brandFileName,setBrandFileName] = useState(null);
  const [uploadProgress, setUploadProgress] = useState();
  const {
    categories,
    addingCategory,
    addCategoryResponse,
    deleteCategoryResponse,
    updateCategoryResponse,
    statusCategoryResponse,
    error,
  } = useSelector((state) => state.categories);

  const dispatch = useDispatch();
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    //getting the category list
    dispatch(getCategories());
  }, []);

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = jwtDecode(localStorage.getItem("authUser"));

      setUserId(obj.user);
      setCategoryObject({
        ["auth_userid"]: userId,
      });
    }
  }, [props.success, props]);

  useEffect(() => {
    if (addCategoryResponse.type === "success") {
      toastr.success(addCategoryResponse.message);
      setCategoryObject({});
    } else if (addCategoryResponse.type === "failure") {
      toastr.error(error.data.message, addCategoryResponse.message);
    }
  }, [addCategoryResponse]);

  useEffect(() => {
    if (deleteCategoryResponse.type === "success") {
      toastr.success(deleteCategoryResponse.message);
    } else if (deleteCategoryResponse.type === "failure") {
      toastr.error(error.data.message, deleteCategoryResponse.message);
    }
  }, [deleteCategoryResponse]);

  useEffect(() => {
    if (statusCategoryResponse.type === "success") {
      toastr.success(statusCategoryResponse.message);
      dispatch(getCategories());
    } else if (statusCategoryResponse.type === "failure") {
      toastr.error(error.data.message, statusCategoryResponse.message);
    }
  }, [statusCategoryResponse]);

  useEffect(() => {
    if (updateCategoryResponse.type === "success") {
      setShowModal(false);
      setCategoryIdToBeUpdated(null);
      // setPasswordObject({});
      setCategoryIdToBeUpdated(null);
      setCategoryObject({});
      // clearCategoryDetails();
      toastr.success(updateCategoryResponse.message);
    } else if (updateCategoryResponse.type === "failure") {
      toastr.error(error.data.message, updateCategoryResponse.message);
    }
  }, [updateCategoryResponse]);

  let preupdateCategory = (item) => {

    setCategoryIdToBeUpdated(item.category_id);
    setCategoryObject(item);
    console.log(item.category_icon_svg);
    if (
      item.category_icon_svg &&
      item.category_icon_svg.length > 2
    ) {
      if (
        item.category_icon_svg.indexOf(
          "public/uploads/category/"
        ) > -1
      ) {
        setCategoryObject({
          ...item,
          [`category_icon_svg`]:
            item.category_icon_svg.slice(
              item.category_icon_svg.indexOf(
                "public/uploads/category/"
              ) ((API_URL.length-1)+12)
            ),
        });
      } else {
        setCategoryObject({
          ...item,
          [`category_icon_svg`]:
            item.category_icon_svg,
        });
      }
    } else {
      setCategoryObject({
        ...item,
        [`category_icon_svg`]: "",
      });
    }


 console.log(item.category_icon_webp);

    if (
      item.category_icon_webp &&
      item.category_icon_webp.length > 2
    ) {
      if (
        item.category_icon_webp.indexOf(
          "public/uploads/category/"
        ) > -1
      ) {
        setCategoryObject({
          ...item,
          [`category_icon_webp`]:
            item.category_icon_webp.slice(
              item.category_icon_webp.indexOf(
                "public/uploads/category/"
              ) + ((API_URL.length-1)+12)
            ),
        });
      } else {
        setCategoryObject({
          ...item,
          [`category_icon_webp`]:
            item.category_icon_webp,
        });
      }
    } else {
      setCategoryObject({
        ...item,
        [`category_icon_webp`]: "",
      });
    }
  };

  useEffect(() => {
    let unitsDuplicate = JSON.parse(JSON.stringify(categories));
    // console.log(unitsDuplicate);
    let unitData = [];
    unitsDuplicate.map((item, index) => {
      item.fname = "";
      if(item.User!=null)
      {
        item.fname = item.User.fname + " " + item.User.lname;
      }
      
      item.auth_userid = userId;

      item.action = (
        <div
          style={{
            textAlign: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <i
              className="far fa-edit"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.5em",
                marginRight: "0.5em",
              }}
              onClick={() => {
                toTop();
                preupdateCategory(item);
              }}
            ></i>{" "}
            <i
              className="far fa-trash-alt"
              style={{
                fontSize: "1em",
                cursor: "pointer",
              }}
              onClick={() => {
                setCategoryIdToBeDeleted(item.category_id);
                setConfirmDeleteAlert(true);
              }}
            ></i>{" "}
          </div>{" "}
        </div>
      );
      item.id = index + 1;
      item.category_icon_svgs="";
      // console.log(item.category_icon_svg, "item.category_icon_svg");
      if (item.category_icon_svg != "") {
        
        item.category_icon_svgs = (
          <div
            style={{
              textAlign: "center",
            }}
          >
            <img
              src={
                process.env.REACT_APP_APIURL +
                `public/uploads/category/${item.category_icon_svg}`
              }
              height="50"
              width="50"
            />
          </div>
        );
      }
     
        item.category_is_active1 = (
          <div
            style={{
              textAlign: "center",
              width: "100%",
            }}
          >
            <div
              class="form-check form-switch mb-3"
              style={{
                textAlign: "center",
                marginLeft: "40%",
              }}
            >
              <input
                type="checkbox"
                class="form-check-input"
                id="customSwitch2"
                checked={(item.category_is_active==0)?true:false}
                onClick={(e) => {
                  dispatch(toggleCategoryActiveStatus(item.category_id, userId));
                  settoggleSwitch(!toggleSwitch);
                }}
              />{" "}
            </div>{" "}
          </div>
        );


      unitData.push(item);
    });
    setCategoriesTemp(unitData);
  }, [categories]);

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 10,
      },

      {
        label: "English",
        field: "category_name_english",
        sort: "asc",
        width: 70,
      },
      {
        label: "Malayalam",
        field: "category_name_malayalam",
        sort: "asc",
        width: 70,
      },
      {
        label: "Image",
        field: "category_icon_svgs",
        sort: "asc",
        width: 90,
      },
      {
        label: "Status",
        field: "category_is_active1",
        sort: "asc",
        width: 90,
      },

      {
        label: "User",
        field: "fname",
        sort: "asc",
        width: 100,
      },

      {
        label: "Action",
        field: "action",
        sort: "disabled",
        width: 100,
      },
    ],
    rows: categoriesTemp,
  };
  const make_file_id = (e) => {
    var length           = 16;
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
 charactersLength));
   }
   result = result +'_category';
   setBrandFileName(result);
   return result;
}
  const deleteCategoryIcon = () => {
    setIsUploadjpg(false);
    setCategoryObject({
      ...categoryObject,
      [`category_icon_svg`]: "",
    });
  };

  const deleteCategoryIconwebp = () => {
    setIsUploadwebp(false);
    setCategoryObject({
      ...categoryObject,
      [`category_icon_webp`]: "",
    });
  };
  const uploadCategoryIcon = (e) => {
    
    e.preventDefault();

if(!isUploadwebp)
    {
     var barnd_file_name=make_file_id();
    }
    else
    {
      var barnd_file_name = brandFileName;
    }
    setIsUploadjpg(true);

    let fd = new FormData();
    fd.append("image", e.target.files[0]);
    axios
      .post(`${API_URL}category/uploadCategorySingleImage?name=${barnd_file_name}`, fd, {
        headers: {
          "x-access-token": accessToken,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setCategoryObject({
            ...categoryObject,
            [`category_icon_svg`]: response.data.file.filename,
          });
        } else {
          toastr.error(response.data.message);
        }
      })
      .catch(err=>{
        deleteCategoryIcon();
        toastr.error("File extension not supported");
      });
  };

  const uploadCategoryIconwebp = (e) => {
    e.preventDefault();
    var fileInput = 
    document.getElementById('category_icon_webp');
  
var filePath = fileInput.value;

// Allowing file type
//var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
var allowedExtensions = /(\.webp)$/i;
        
  
if (!allowedExtensions.exec(filePath)) {
    toastr.error("File extension not supported")
    return false;
} 

if(!isUploadwebp)
    {
     var barnd_file_name=make_file_id();
    }
    else
    {
      var barnd_file_name = brandFileName;
    }
    setIsUploadwebp(true);

    let fd = new FormData();
    fd.append("image", e.target.files[0]);
    axios
      .post(`${API_URL}category/uploadCategorySingleImagewebp?name=${barnd_file_name}`, fd, {
        headers: {
          "x-access-token": accessToken,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setCategoryObject({
            ...categoryObject,
            [`category_icon_webp`]: response.data.file.filename,
          });
        } else {
          toastr.error(response.data.message);
        }
      })
      .catch(err=>{
        deleteCategoryIconwebp();
        toastr.error("File extension not supported");
      });;
  };


  let handleChangeInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setCategoryObject({
      ...categoryObject,
      [name]: value,
    });
  };

  const handleValidSubmitUnit = (event, values) => {
    event.preventDefault();
    if (
      categoryObject.category_icon_svg &&
      categoryObject.category_icon_svg.length > 2
    ) {
      if (categoryObject.category_icon_svg.indexOf("uploads/category/") > -1) {
        // categoryObject.category_icon_svg.slice(categoryObject.category_icon_svg.indexOf('uploads/category-icons/') + 23)
        setCategoryObject({
          ...categoryObject,
          [`category_icon_svg`]: categoryObject.category_icon_svg.slice(
            categoryObject.category_icon_svg.indexOf("uploads/category/") ((API_URL.length-1)+12)
          ),
        });
      }
    }

    categoryIdTobeUpdated
      ? dispatch(updateCategory(categoryObject))
      : dispatch(addCategory(categoryObject));
      formRef.current.reset();
      setCategoryObject({ ['auth_userid']: userId });
      setIsUploadwebp(false);
      setIsUploadjpg(false);
  };

  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            dispatch(deleteCategory(categoryIdToBeDeleted, userId));
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it ?
        </SweetAlert>
      ) : null}{" "}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Categories" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                  ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmitUnit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Category Name
                          </Label>
                          <AvField
                            name="category_name_english"
                            value={categoryObject.category_name_english}
                            placeholder="Category Name"
                            type="text"
                            errorMessage="Enter Category Name"
                            className="form-control"
                            validate={{
                              required: {
                                value: true,
                              },
                            }}
                            id="validationCustom01"
                            onChange={handleChangeInput}
                          />{" "}
                        </div>{" "}
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Category Name Malayalam{" "}
                          </Label>{" "}
                          <AvField
                            name="category_name_malayalam"
                            value={categoryObject.category_name_malayalam}
                            placeholder="Category Name Malayalam"
                            type="text"
                            errorMessage="Enter Category Name Malayalam"
                            className="form-control"
                            validate={{
                              required: {
                                value: true,
                              },
                            }}
                            id="validationCustom03"
                            onChange={handleChangeInput}
                          />{" "}
                        </div>{" "}
                      </Col>{" "}
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02"> JPG Image (400x400) </Label>{" "}
                          {categoryObject.category_icon_svg &&
                          categoryObject.category_icon_svg.length > 2 ? (
                            <div div className="img-wraps">
                              {" "}
                              {categoryObject.category_icon_svg &&
                              categoryObject.category_icon_svg.startsWith(
                                "http"
                              ) ? (
                                <img
                                  className="img-thumbnail"
                                  alt=""
                                  width="200"
                                  src={`${categoryObject.category_icon_svg}`}
                                />
                              ) : (
                                <img
                                  className="img-thumbnail"
                                  alt=""
                                  width="200"
                                  src={`${API_URL}public/uploads/category/${categoryObject.category_icon_svg}`}
                                />
                              )}
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={deleteCategoryIcon}
                                style={{ width: "200px" }}
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <AvField
                              name="category_icon_svg"
                              id="category_icon_svg"
                              type="file"
                              value=""
                              errorMessage="Upload Category Icon png"
                              className="form-control"
                              validate={{
                                required: {
                                  value: true,
                                },
                              }}
                              // onChange={onChangeHandler}
                              onChange={uploadCategoryIcon}
                            />
                          )}
                          {uploadProgress && uploadProgress < 100 && (
                            <div className="mt-4">
                              {" "}
                              <Progress color="primary" value={uploadProgress}>
                                Uploading {uploadProgress}%
                              </Progress>
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02"> Webp Image (400x400) </Label>{" "}
                          {categoryObject.category_icon_webp &&
                          categoryObject.category_icon_webp.length > 2 ? (
                            <div div className="img-wraps">
                              {" "}
                              {categoryObject.category_icon_webp &&
                              categoryObject.category_icon_webp.startsWith(
                                "http"
                              ) ? (
                                <img
                                  className="img-thumbnail"
                                  alt=""
                                  width="200"
                                  src={`${categoryObject.category_icon_webp}`}
                                />
                              ) : (
                                <img
                                  className="img-thumbnail"
                                  alt=""
                                  width="200"
                                  src={`${API_URL}public/uploads/category/${categoryObject.category_icon_webp}`}
                                />
                              )}
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={deleteCategoryIconwebp}
                                style={{ width: "200px" }}
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <AvField
                              name="category_icon_webp"
                              id="category_icon_webp"
                              type="file"
                              value=""
                              errorMessage="Upload Category Icon webp"
                              className="form-control"
                              validate={{
                                required: {
                                  value: true,
                                },
                              }}
                              // onChange={onChangeHandler}
                              onChange={uploadCategoryIconwebp}
                            />
                          )}
                          {uploadProgress && uploadProgress < 100 && (
                            <div className="mt-4">
                              {" "}
                              <Progress color="primary" value={uploadProgress}>
                                Uploading {uploadProgress}%
                              </Progress>
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mt-4">
                          {categoryIdTobeUpdated ? (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingCategory ? true : false}
                            >
                              {addingCategory ? "Updating" : "Update"}
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingCategory ? true : false}
                            >
                              {addingCategory ? "Adding" : "Submit"}
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable responsive bordered data={data}  disableRetreatAfterSorting={true} entries={25}/>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  // const { error } = state.Users;
  // return { error };
};

export default withRouter(connect(mapStateToProps, { apiError })(Categories));

Categories.propTypes = {
  error: PropTypes.any,
  categories: PropTypes.array,
};
